import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import DoghnutBox from "../../../../_4GeneralHelpers/1_Boxes/DoghnutBox";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { calculateMinutes } from "./_DoghnutHelpers/calculateMintues";
import { calculateMinutesDone } from "./_DoghnutHelpers/calculateMintuesDone";
import { DoghnutChart } from "./_DoghnutHelpers/DoghnutChart";
import { DoghnutText } from "./_DoghnutHelpers/DoghnutText";
import BoxHeader from "../../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";
const { arraysAreEqual } = require("../../../../_3HelperFunctions/_AppHelpers/arraysAreEqual");

export const StatusPriorityDoghnutView = ({ parameter, flipCard }) => {
  const theme = useTheme();
  const {
    isATeacher,
    statusList,
    priorityList,
    setStopDoughnutChartReRender,
    stopReRenderProcess,
    weekStatusCount,
    setWeekStatusCount,
    weekPriorityCount,
    setWeekPriorityCount,
    setThisWeeksMinutesTotal,
    setThisWeeksMinutesDone,
    percentageDone,
    setPercentageDone,
    appInDarkMode,
    relevantUserAssignments,
    verifyDoughnutChart,
    setVerifyDoughnutChart,
  } = useAppContext();
  const [calibratingDoghnut, setCalibratingDoghnut] = useState(false);
  const [dataCurrentWeek, setDataCurrentWeek] = useState({
    datasets: [],
    labels: parameter === "status" ? statusList : priorityList,
  });
  /**
   * Locks
   */

  useEffect(() => {
    // console.log("general: ", setStopDoughnutChartReRender, "verify: ", verifyDoughnutChart, "calibrate: ", calibratingDoghnut)
    if (verifyDoughnutChart !== undefined && !verifyDoughnutChart && !calibratingDoghnut) {
      // console.log("freeze process is over");
      setStopDoughnutChartReRender(false);
    }
  }, [verifyDoughnutChart, calibratingDoghnut]);

  //unlock calibration
  useEffect(() => {
    const unlock = async () => {
      // console.log("We are allowed to calculate the doghnut");
      await setCalibratingDoghnut(true);
    };
    if (verifyDoughnutChart) {
      unlock();
    }
  }, [verifyDoughnutChart]);

  useEffect(() => {
    // console.log(calibratingDoghnut, relevantUserAssignments)
    if (calibratingDoghnut && relevantUserAssignments) {
      // console.log("we go here");
      calibrateDoghnutData(); //sets calibratingWorkload to false once process is finished
    }
  }, [calibratingDoghnut, relevantUserAssignments]);

  const calibrateDoghnutData = async () => {
    await setVerifyDoughnutChart(false); // Change the locks
    // console.log(relevantUserAssignments)

    let relevantWeekAssignmentCopy = relevantUserAssignments;
    //put the pipes together
    let allRelevantAssignments = relevantWeekAssignmentCopy.thisWeek.concat(
      relevantUserAssignments.additional.daysToPrepare,
      relevantUserAssignments.additional.extraDates
    );
    //remove possible undefined values
    allRelevantAssignments = allRelevantAssignments.filter(function (assignment) {
      return assignment !== undefined;
    });

    // console.log("allrel", allRelevantAssignments)
    const minutesTotal =
      Math.floor(allRelevantAssignments.length > 0
        ? await calculateMinutes(allRelevantAssignments)
        : 0)
    const minutesDone =
      Math.floor(allRelevantAssignments.length > 0
        ? await calculateMinutesDone(allRelevantAssignments)
        : 0)

    const percentageDone = minutesTotal > 0 ? Math.floor((minutesDone / minutesTotal) * 100) : 0
    // console.log("percentage", percentageDone, "total: ", minutesTotal, "done: , ", minutesDone)
    if (parameter === "status") {
      // console.log(minutesTotal, minutesDone, percentageDone)
      await setThisWeeksMinutesTotal(minutesTotal);
      await setThisWeeksMinutesDone(minutesDone);
      await setPercentageDone(percentageDone);
    }

    //set counters depending on parameter that is passed to the component
    if (parameter === "status") {
      // calculate color pattern
      if (allRelevantAssignments.length !== 0) {
        const currentCount = [weekStatusCount];
        var newCount = [0, 0, 0, 0, 0];

        allRelevantAssignments.forEach((assignment) => {
          let i = assignment.status;
          newCount[i] = newCount[i] + assignment.my_minutes_done;
          if (assignment.status !== 0) {
            //add the "undone minutes to status 0"
            newCount[0] = newCount[0] + assignment.my_minutes - assignment.my_minutes_done
          }
          else { // status === 0
            newCount[0] = newCount[0] + assignment.my_minutes
          }
        });
        // console.log("counter, ", newCount)
        if (
          !arraysAreEqual(newCount, [0, 0, 0, 0, 0]) &&
          !arraysAreEqual(newCount, currentCount)
        ) {
          setWeekStatusCount(newCount);
        }
        else {
          //catch empty cases for minutes
          setWeekStatusCount([1, 0, 0, 0, 0]);
        }
      } else {
        //there are no assignments
        setWeekStatusCount([0, 0, 0, 0, 1]);
      }
    }

    if (parameter === "priority") {
      //calculate priority colors
      if (allRelevantAssignments.length !== 0) {
        const currentCount = [weekPriorityCount];
        var newCount = [0, 0, 0, 0];

        allRelevantAssignments.forEach((assignment) => {
          let i = assignment.priority;
          newCount[i] = newCount[i] + 1;
        });

        if (
          !arraysAreEqual(newCount, [0, 0, 0, 0, 0]) &&
          !arraysAreEqual(newCount, currentCount)
        ) {
          setWeekPriorityCount(newCount);
        }
      } else {
        //there are no assignments
        setWeekPriorityCount([1, 0, 0, 0]);
      }
    }

    await setCalibratingDoghnut(false);
  };
  /**
   * set the weekdata for the current week
   * once all the relevant elements have been calculated
   */
  // console.log(weekStatusCount)
  useEffect(() => {
    setDataCurrentWeek({
      datasets: [
        {
          //default semesterStatusCount is: [0, 0, 0, 0, 0]
          pointStyle: "rect",
          data:
            !stopReRenderProcess && parameter === "status"
              ? weekStatusCount
              : !stopReRenderProcess && parameter === "priority"
                ? weekPriorityCount
                : parameter === "status"
                  ? [0, 0, 0, 0, 1]
                  : [0, 0, 0, 1],
          fill: false,
          data: parameter === "status" ? weekStatusCount : weekPriorityCount,
          backgroundColor:
            parameter === "status"
              ? [
                theme.palette.levels.red,
                theme.palette.levels.orange,
                theme.palette.levels.yellow,
                theme.palette.levels.lightGreen,
                theme.palette.levels.green,
              ]
              : [
                theme.palette.levels.green,
                theme.palette.levels.lightGreen,
                theme.palette.levels.orange,
                theme.palette.levels.red,
              ],
          borderWidth: 2,
          borderColor: theme.palette.background.paper,
          hoverBorderColor: theme.palette.background.paper,
          hoverOffset: 25,
          cutout: "75%",
          spacing: percentageDone === 0 ? 1 : 5,
        },
      ],
      labels: parameter === "status" ? statusList : priorityList,
    });
  }, [stopReRenderProcess]);

  //styles the doughnut https://www.chartjs.org/docs/latest/charts/doughnut.html#doughnut
  const options = {
    animation: true,
    layout: { padding: 0 },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        // labels: {
        //   usePointStyle: true,
        // },
        display: false,
        // title: {
          // color: "black",
        // },
        // position: "bottom",
        // align: "start",
        // onClick: null,
        // color: "black",
        // marginBottom: "0.2em",
      },
      tooltip: {
        callbacks: {
          beforeLabel: function () {
            let label = parameter === "status"?"Minuten in diesem Status": "Prioritäten in dieser Woche";
            return label;
          },
          labelTextColor: function () {
            return appInDarkMode ? theme.palette.levels.light : theme.palette.levels.dark;
          },
        },
        enabled: true,
        position: "average",
      },
      // doughnutLabel: {
      //   labels: [
      //     {
      //       text: "Your Center Text",
      //       font: {
      //         size: "24", // adjust the font size as needed
      //       },
      //       color: "black",
      //     },
      //   ],
      // },
    },
  };
  // console.log(stopReRenderProcess);


  //this is for the layout of the doghnut. Pass the values on to the child components
  const containerRef = useRef(null);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  useEffect(() => {
    const adjustFontSize = async () => {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight; // Use clientHeight
      await setWidth(containerWidth);
      await setHeight(containerHeight);
      // console.log(containerWidth, containerHeight);
    };

    // Call the adjustFontSize function initially and whenever the window is resized
    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", adjustFontSize);
    };
  }, []);

  return (
    <>
      <BoxHeader
        title={
          parameter === "status"
            ? `Status`
            : parameter === "priority"
              ? "Prioritäten"
              : ""
        }
        flipCard={flipCard}
        options={
          // isATeacher
          //   ? ["classview", "flip"]
            // : 
            ["flip"]
        }
      />
      {/* parent box */}
      <DoghnutBox>
        <Box
          ref={containerRef}
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // backgroundColor: "red",
          }}
        >
          {/* Text component */}
          <div
            style={{
              position: "absolute",
              width: "100%",
              zIndex: 1,
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!calibratingDoghnut && <DoghnutText width={width * 0.80} height={height * 0.80} />}
          </div>

          {/* Chart component */}
          <div
            style={{
              position: "absolute",
              width: "100%",
              zIndex: 2,
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DoghnutChart
              dataCurrentWeek={dataCurrentWeek}
              options={options}
              width={width}
              height={height}
            />
          </div>
        </Box>
      </DoghnutBox>
    </>
  );
};
