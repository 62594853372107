import React, { memo } from "react";
import { Card, CardContent, CardHeader, Divider } from "@mui/material";

import { useTheme } from "@mui/styles";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import moment from "moment";
import AssignmentAddCard from "./AssignmentAddCard";

function AssignmentAddPopoverForm({
  handleClose,
  selectedValue,
}) {
  //assignment properties
  const theme = useTheme();
  const { currentUser, isALeader, isAReferenceStudent} = useAppContext();

  // console.log(selectedValue, moment(selectedValue.$d).format("YYYY-MM-DD"));
  return (
    <Card>
      <CardHeader
        title="Hinzufügen"
        sx={{ color: theme.palette.primary.main }}
      />
      <Divider></Divider>
      <CardContent
        sx={{
          width: "100%",
          minWidth: 424,
          maxWidth: 450,
          // bgcolor: 'background.paper',
          position: "relative",
          overflow: "auto",
          // maxHeight: 313,
        }}
      >
        {currentUser && <AssignmentAddCard handleClose={handleClose} date={moment(selectedValue?.$d).format("YYYY-MM-DD")} //notice the .$d!!!
          restricted={!isALeader && !isAReferenceStudent} />}
      </CardContent>
    </Card>
  );
}

export default memo(AssignmentAddPopoverForm);
