import moment from "moment";
import { referenceDateCheck } from "./data.service.helpers/referenceDateCheck";
import { dataCheck } from "./data.service.helpers/dataCheck";
import { dataDoesNotExistsCheck } from "./data.service.helpers/dataDoesNotExistsCheck";
import { createAssignmentArray } from "./data.service.helpers/createAssignmentArray";
import { dataWasUpdatedCheck } from "./data.service.helpers/dataWasUpdatedCheck";
/**
 * Helpers
 */
function filterAdditionalAssignments(data, sunday, property) {
  return data.filter(
    (assignment) =>
      //look at the assignments that take place after the end of the week
      moment(assignment.due_date).isAfter(
        moment(sunday).endOf("day"),
        "days",
        "[]"
      ) &&
      //but the assignment has an impact on the current week:
      moment(assignment.due_date)
        .startOf("day")
        .subtract(assignment[property], "days")
        .isSameOrBefore(sunday)
  );
}

function filterByVisible(data) {
  return data.filter((assignment) => assignment.visible);
}
function filterByStatusAndPriority(data, statusSelection, prioritySelection) {
  return data.filter(
    (assignment) =>
      statusSelection.includes(assignment.status) &&
      prioritySelection.includes(assignment.priority)
  );
}

class DataService {
  /**
   * Service Call Functions:
   */
  /**
   * updates a given data set by replacing the existing item with the provided item
   */

  relevantAssignmentsHaveReallyChanged(newData, oldData) {
    if (!oldData) {
      console.log("we begin from scratch");
      return true;
    } else if (newData.referenceDate !== oldData.referenceDate) {
      console.log("we changed the week");
      return true;
    } else {
      let change = false;
      const newDataAssignments = newData.thisWeek.concat(
        newData.additional,
        newData.extra_dates
      );
      const oldDataAssignments = oldData.thisWeek.concat(
        oldData.additional,
        oldData.extra_dates
      );
      newDataAssignments.forEach(function (assignment, index) {
        if (
          assignment.real_edit_date !==
          oldDataAssignments[index].real_edit_date ||
          assignment.realEditDateUrs !==
          oldDataAssignments[index].real_edit_date_user
        ) {
          change = true;
          return;
        }
      });
      return change;
    }
  }

  async updateDataSet(schoolYearData, updatedAssignment, isALeader, key) {
    try {
      // console.log("schoolYearData2:", schoolYearData)
      // Ensure immutability by creating a copy
      const updatedDataSchoolYearData = { ...schoolYearData };
      const oldSchoolYearData = { ...schoolYearData };

      // Update userAssignments: this is for all users
      const updatedSchoolYearArray = await this.updateAssignmentNormal(
        updatedAssignment,
        oldSchoolYearData.userAssignments,
        key
      );
      updatedDataSchoolYearData.userAssignments =
        updatedSchoolYearArray;

      // Check user roles for updating classAssignments
      // console.log("upass", updatedAssignment)
      if (
        isALeader && updatedAssignment?.type !== "personal"
      ) {
        const updatedCoreCourseArray = await this.updateAssignmentClass(
          updatedAssignment,
          oldSchoolYearData.classAssignments,
          key
        );
        updatedDataSchoolYearData.classAssignments =
          updatedCoreCourseArray;
      }

      return updatedDataSchoolYearData;
    } catch (error) {
      // Handle errors, or you can let them propagate up to the calling function
      console.error("Error in updateDataSet:", error);
      throw error;
    }
  }

  //updatedData is either the schoolYearData or the CoreCourseData
  async updateAssignmentNormal(updatedAssignment, oldDataArray, key) {
    // console.log("schoolYearData", oldDataArray)
    try {
      // Ensure immutability by creating a copy of the array
      const updatedDataCopy = [...oldDataArray];

      // Find the index of the assignment
      const index = await this.findAssignmentIndex(
        updatedDataCopy,
        updatedAssignment.assignment_id
      );

      // Handle different cases based on the key
      if (key === "delete") {
        // Delete the assignment
        updatedDataCopy.splice(index, 1);
      } else if (key === "add") {
        // Find the correct index for new assignment entry
        const insertIndex = updatedDataCopy.findIndex(function (assignment) {
          return moment(assignment.due_date).isAfter(
            moment(updatedAssignment.due_date)
          );
        });

        // Insert the assignment at the correct position
        if (insertIndex > -1) {
          updatedDataCopy.splice(insertIndex, 0, updatedAssignment);
        } else {
          updatedDataCopy.push(updatedAssignment);
        }
      } else {
        // Update the assignment
        updatedDataCopy[index] = updatedAssignment;
      }

      // Return the updated array
      return updatedDataCopy;
    } catch (error) {
      // Handle errors, or you can let them propagate up to the calling function
      console.error("Error in updateAssignmentString:", error);
      throw error;
    }
  }

  async updateAssignmentClass(newAssignment, classAssignments, key) {
    // console.log("schoolYearData", oldDataArray)
    try {
      // Ensure immutability by creating a copy of the array
      let classAssignmentsCopy = [...classAssignments];

      // Find the indexes of the assignments based on assignment_id
      const assignmentIndexList = await this.findAllAssignmentIndexes(
        classAssignmentsCopy,
        newAssignment.assignment_id
      );

      // Handle different cases based on the key
      if (key === "delete") {
        // Delete the assignment

        classAssignmentsCopy.filter((assignment) => assignment.assignment_id === newAssignment.assignment_id);
      } else if (key === "add") {
        // Find the correct index for new assignment entry
        const insertIndex = classAssignmentsCopy.findIndex(function (assignment) {
          return moment(assignment.due_date).isAfter(
            moment(newAssignment.due_date)
          );
        });

        // Insert the assignment at the correct position
        if (insertIndex === -1) { //not found
          classAssignmentsCopy.push(newAssignment);
        } else {
          classAssignmentsCopy.splice(insertIndex, 0, newAssignment);
        }
      } else { // Update the assignment
        // console.log("We update....")
        assignmentIndexList.forEach((indexNumber) => {
          let updatedAssignment = this.updateProperties(classAssignmentsCopy[indexNumber], newAssignment)
          classAssignmentsCopy[indexNumber] = updatedAssignment;
        });
      }
      // Return the updated array
      // console.log("classCopy...", classAssignmentsCopy)
      return classAssignmentsCopy;
    } catch (error) {
      // Handle errors, or you can let them propagate up to the calling function
      console.error("Error in updating of ClassAssignments:", error);
      throw error;
    }
  }

  updateProperties(currentAssignment, newAssignment) {
    //update all these properties
    currentAssignment.core_course_id = newAssignment?.core_course_id
    currentAssignment.counting_factor = newAssignment?.counting_factor
    currentAssignment.course_id = newAssignment?.course_id
    currentAssignment.course_name = newAssignment?.course_name
    currentAssignment.create_date = newAssignment?.create_date
    currentAssignment.due_date = newAssignment?.due_date
    currentAssignment.mixed_course = newAssignment?.mixed_course
    currentAssignment.name = newAssignment?.name
    currentAssignment.subject_id = newAssignment?.subject_id
    currentAssignment.subject_name = newAssignment?.subject_name
    currentAssignment.suggested_days = newAssignment?.suggested_days
    currentAssignment.suggested_minutes = newAssignment?.suggested_minutes
    currentAssignment.tag = newAssignment?.tag
    currentAssignment.type = newAssignment?.type
    currentAssignment.description = newAssignment?.description
    currentAssignment.visible = newAssignment?.visible
    currentAssignment.real_edit_date = newAssignment?.real_edit_date
    return currentAssignment
  }

  findAssignmentIndex(assignments, targetId) {
    for (let i = 0; i < assignments.length; i++) {
      if (assignments[i].assignment_id === targetId) {
        return i; // Return the index if the assignment ID matches the target
      }
    }
    return -1; // Return -1 if the assignment ID is not found in the array
  }

  async findAllAssignmentIndexes(classAssignments, targetAssignmentId) {
    const assignmentIndexes = [];

    // Loop through the class assignments to find the indexes of assignments with the specified assignment_id
    for (let i = 0; i < classAssignments.length; i++) {
      const assignment = classAssignments[i];
      if (assignment.assignment_id === targetAssignmentId) {
        assignmentIndexes.push(i);
      }
    }

    return assignmentIndexes;
  }

  async relevantDataHasReallyChanged(newData, oldData, type) {
    try {
      let relevantWeekDataHasChanged = false;

      // most frequently the relevantDate will have changed

      relevantWeekDataHasChanged = await dataCheck(newData, oldData, type);

      if (!relevantWeekDataHasChanged) {
        relevantWeekDataHasChanged = await referenceDateCheck(newData, oldData);
      }

      if (!relevantWeekDataHasChanged) {
        let newAssignments = await createAssignmentArray(newData);
        let oldAssignments = await createAssignmentArray(oldData);

        if (await dataDoesNotExistsCheck(newAssignments, oldAssignments)) {
          relevantWeekDataHasChanged = false;
        } else if (newAssignments.length !== oldAssignments.length) {
          relevantWeekDataHasChanged = true;
        } else {
          relevantWeekDataHasChanged = await dataWasUpdatedCheck(
            newAssignments,
            oldAssignments
          );
        }
      }

      return relevantWeekDataHasChanged;
    } catch (error) {
      console.error("Error in relevantDataHasReallyChanged:", error);
      throw error; // Rethrow the error to indicate that an error occurred
    }
  }

  getAssignments(assignmentArray, type, statusSelection, prioritySelection) {
    return filterByStatusAndPriority(
      filterByVisible(
        assignmentArray.filter((assignment) => assignment.type === type)
      ),
      statusSelection,
      prioritySelection
    );
  }
  getFutureAssignments(assignmentArray, referenceDate) {
    const monday = moment(referenceDate).startOf("isoWeek");
    const futureAssignments = assignmentArray.filter((assignment) =>
      moment(assignment.due_date).isSameOrAfter(monday, "days", "[]")
    );
    // console.log("fut", futureAssignments, "original: ", assignmentArray)
    return futureAssignments
  }
  getAssignmentsForWeek(assignmentArray, referenceDate, assignmentType) {
    return assignmentArray.filter(
      (assignment) =>
        assignment.type === assignmentType &&
        moment(assignment.due_date).isBetween(
          moment(referenceDate).startOf("isoWeek"),
          moment(referenceDate).startOf("isoWeek").add(6, "days"),
          "days",
          "[]"
        )
    );
  }

  getAssignmentsUntil(data, referenceDate) {
    const sunday = moment(referenceDate).endOf("isoWeek");
    return data.filter((assignment) =>
      moment(assignment.due_date).isSameOrBefore(
        moment(sunday).endOf("day"),
        "days",
        "[]"
      )
    );
  }
  //define property for my_days / suggested_days
  getAdditionalRelevantBydue_date(data, referenceDate, property) {
    const sunday = moment(referenceDate).endOf("isoWeek");
    return filterAdditionalAssignments(data, sunday, property);
  }

  getAdditionalRelevantByExtraDates(data, referenceDate, property) {
    const monday = moment(referenceDate).startOf("isoWeek");
    let additionalRelevantByExraDates = [];
    // create an arry with the relevant days
    const relevantDays = [
      moment(monday).add(0, "days").format("YYYY-MM-DD"),
      moment(monday).add(1, "days").format("YYYY-MM-DD"),
      moment(monday).add(2, "days").format("YYYY-MM-DD"),
      moment(monday).add(3, "days").format("YYYY-MM-DD"),
      moment(monday).add(4, "days").format("YYYY-MM-DD"),
      moment(monday).add(5, "days").format("YYYY-MM-DD"),
      moment(monday).add(6, "days").format("YYYY-MM-DD"),
    ];

    //make sure additionalAssignments are excluded from this list!
    data.forEach((assignment, indexAssignment) => {
      relevantDays.forEach((element, indexDay) => {
        if (
          assignment.extra_dates !== null &&
          assignment.extra_dates.includes(relevantDays[indexDay])
        ) {
          // console.log("when we look at: " + relevantDays[indexDay]);
          // console.log(
          //   assignment.extra_dates !== null &&
          //     assignment.extra_dates.includes(relevantDays[indexDay])
          // );
          const relevantAssignment = [data[indexAssignment]];
          // console.log(relevantAssignment)
          additionalRelevantByExraDates.push(relevantAssignment[0]);
          // console.log(additionalRelevantByExraDates.length);
          return;
        }
      });
    });
    // console.log(additionalRelevantByExraDates);
    // console.log(additionalRelevantByExraDates.length);

    return additionalRelevantByExraDates;
  }

  getRelevantWeekAssignments(
    referenceDate,
    assignmentsUntil,
    daysToPrepare,
    extraDates,
    personalView
  ) {
    if (!personalView) {
      const copy = [...assignmentsUntil]
      // console.log("assUntil: ", copy)
    }
    let object = {
      referenceDate: moment(referenceDate).startOf("isoWeek").format("YYYY-MM-DD"),
      thisWeek: assignmentsUntil,
      additional: {
        daysToPrepare: daysToPrepare,
        extraDates: extraDates,
      },
      verifiedChart: false,
      verifiedDoughnut: false,
    };
    if (!personalView) {
      // console.log("assUntil: ", assignmentsUntil, "object:", object)
    }
    return object;
  }

  getMostRecentAssignments = (
    assignmentArray,
    showExams,
    showHomeworks,
    statusSelection,
    prioritySelection
  ) => {
    var allAssignments = [];
    if (assignmentArray && assignmentArray.length > 0) {
      var exams = [];
      if (showExams) {
        exams = assignmentArray.filter(
          (assignment) => assignment.type === "exam"
        );
        if (exams.length > 0) {
          exams = exams.filter((assignment) =>
            moment(assignment.create_date).isBetween(
              moment(new Date()).startOf("day").subtract(5, "days"),
              moment(new Date()).endOf("day"),
              "days",
              "[]"
            )
          );
        }
      }

      var homeworks = [];
      if (showHomeworks) {
        homeworks = assignmentArray.filter(
          (assignment) => assignment.type === "homework"
        );
        if (homeworks.length > 0) {
          homeworks = homeworks.filter(
            (assignment) =>
              moment(assignment.create_date).isSameOrBefore(
                moment(new Date()).endOf("day")
              ) &&
              moment(assignment.create_date).isSameOrAfter(
                moment(new Date()).subtract(5, "days").startOf("day")
              )
          );
        }
      }
      // we do not need to see personals here
      //put the pipes together
      allAssignments = exams.concat(homeworks);
    }
    // console.log(customDate);
    // console.log(allAssignments)

    //filter with assignment status filter in every case:
    //catch empty array case
    if (allAssignments.length) {
      allAssignments = allAssignments.filter(
        (assignment) =>
          assignment !== null &&
          statusSelection.includes(assignment.status) &&
          prioritySelection.includes(assignment.priority)
      );
    }
    allAssignments.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      var c = new Date(a.create_date);
      var d = new Date(b.create_date);

      //multiple key order: https://stackoverflow.com/questions/9175268/javascript-sort-function-sort-by-first-then-by-second
      return c - d || a.assignment_id - b.assignment_id;
    });

    // Here is an assignment array, that gets the last 6 elements of an array
    // reverse the order so the most recently added is at the top
    return allAssignments.slice(-6).reverse();
  };
}

const dataServiceInstance = new DataService();
export default dataServiceInstance;
