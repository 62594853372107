import { filterOutByShowAssignments } from "./filterOutByShowAssignments";

export const getAssignmentList = async (schoolYearData, relevantUserAssignments, showExams, showHomeworks, showPersonals) => {
    if (schoolYearData && relevantUserAssignments) {
      let assignmentList = relevantUserAssignments.thisWeek.concat(
        relevantUserAssignments.additional.daysToPrepare,
        relevantUserAssignments.additional.extraDates
      );
      // Remove possible undefined values and filter by view
      assignmentList = assignmentList.filter(function (assignment) {
        return assignment !== undefined && !filterOutByShowAssignments(assignment, showExams, showHomeworks, showPersonals)
      });
      return assignmentList;
    } else {
      return [];
    }
  };