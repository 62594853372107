import { useState } from "react";
import { Close, Done } from "@mui/icons-material";
import { useTheme } from "@mui/styles";
// import {
//   CircularProgress,
//   IconButton,
//   ,
// } from "@material-ui/core";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import moment from "moment";
import { updateDataSet } from "../../_3HelperFunctions/_LocalUpdates/updateDataSet";
import { createAssignment } from "../../_3HelperFunctions/_AssignmentFunctions/createAssignment";
import { saveAssignmentChanges } from "../../_3HelperFunctions/_AssignmentFunctions/saveAssignmentChanges";
import { Grid, TextField, IconButton, CircularProgress } from "@mui/material";

export default function AddToDoForm({ DoNotShowForm, elements, updateElements }) {
  const theme = useTheme();
  const {
    currentUser,
    isALeader,
    schoolYearData,
    setUnresolvedSchoolYearData,
  } = useAppContext();
  const [showSubmit, setShowSubmit] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [localLoading, setLocalLoading] = useState(false);

  //controll the textField
  const onChangeNewItemName = (e) => {
    if (showSubmit !== true) {
      setShowSubmit(true);
    }
    if (e.target.value === "") {
      setShowSubmit(false);
    }
    setNewItemName(e.target.value);
  };

  //submission to DB
  const onSubmitNewToDo = () => {
    setLocalLoading(true);
    setShowSubmit(false);
    createNewToDo();
  };

  const addItemToList = (list, assignmentToAdd) => {
    // If the index is out of bounds, add the new item to the end of the list
    // console.log(assignmentToAdd)
    if (list.length === 0) {
      list.push(assignmentToAdd);
      // console.log(list)
    } else {
      // Insert the new item at the specified index
      list.splice(0, 0, assignmentToAdd); //first parameter is the index
    }

    //updated ranking
    list.forEach(async (assignment, index) => {
      if (index > 0) {//only the ranking of the elements that followed need to be updated
        // console.log("assignment, ", assignment)
        assignment.ranking = index
      }
    });
    // console.log("list", list)
    // Return the updated list
    return list;
  };

  const replaceAssignment = (list, updatedAssignment) => {
    // console.log("replace", list, updatedAssignment)
    const indexToReplace = list.findIndex(
      (assignment) => assignment.assignment_id === updatedAssignment.assignment_id
    );
    if (indexToReplace === -1) {// If the assignment is not found, return the original list
      console.log("Assignment not found");
      return list;
    }
    list[indexToReplace] = updatedAssignment;     // Replace the assignment at the specified index with the updatedAssignment
    return list;
  };

  const createNewToDo = async () => {
    try {
      const nowDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      const due_date = moment(new Date()).endOf("isoWeek").format('YYYY-MM-DD HH:mm:ss');
      const my_days = //Difference in number of days
        moment.duration(moment(nowDate).diff(moment(due_date))).asDays()

      const assignmentProperties = [
        { key: "name", value: newItemName },
        { key: "due_date", value: due_date },
        { key: "suggested_days", value: my_days },
        { key: "my_days", value: my_days },
        { key: "type", value: "personal" },
        { key: "status", value: 0 },
        { key: "priority", value: 3 },
        { key: "ranking", value: 0 },
        { key: "suggested_minutes", value: 20 },
        { key: "my_minutes", value: 20 },
        { key: "is_a_to_do", value: true }
      ];

      var createdAssignment = await createAssignment(currentUser, assignmentProperties);

      //update Elements
      let listCopy = { ...elements };
      let currentDestinationList = [...listCopy["A Priorität"]]
      // console.log("1st List: ", currentDestinationList)

      currentDestinationList = addItemToList(currentDestinationList, createdAssignment)//updates Ranking
      // console.log("updatedList: ", currentDestinationList)
      listCopy["A Priorität"] = [...currentDestinationList];
      updateElements(listCopy) //just provisionary for fast rendering

      //dealWithDB Changes
      let updatedSchoolYearData = { ...schoolYearData };
      updatedSchoolYearData = await updateDataSet(
        schoolYearData, createdAssignment, isALeader,
        "add"
      );
      var currentDestinationListCopy = currentDestinationList
      currentDestinationList.forEach(async (assignment, index) => {
        // Check if the current index is higher than result.source.index
        if (index > 0) {//only the ranking of the elements that followed need to be updated
          let currentUpdateValues = [{ property: "ranking", value: index }];
          let thisResponseAssignment = await saveAssignmentChanges(
            assignment,
            currentUpdateValues,
            "toDoList",
            currentUser
          );
          currentDestinationListCopy = replaceAssignment(currentDestinationListCopy, thisResponseAssignment) //updates current elments
          updatedSchoolYearData = await updateDataSet(
            updatedSchoolYearData,
            thisResponseAssignment,
            isALeader,
            "toDoList"
          );
        }
      });

      await setUnresolvedSchoolYearData(updatedSchoolYearData);
      listCopy["A Priorität"] = [...currentDestinationListCopy];
      updateElements(listCopy) //just provisionary for fast rendering

      setNewItemName("")
      setLocalLoading(false)
      DoNotShowForm() //hides the form

    } catch (error) {
      console.log(error)
      alert("Probleme mit dem Assignment building...")
    }


  };

  const onReject = (e) => {
    setNewItemName("");
    setShowSubmit(false);
    DoNotShowForm();
  };

  return (
    <Grid container alignItems={"center"} justifyContent={"flex-end"} style={{ width: "100%" }}>
      {!localLoading && showSubmit && schoolYearData.userAssignments && (<Grid item xs={3}>
        <Grid container justifyContent={"space-between"}>
          <Grid item xs={6} style={{ marginLeft: "-0.25em" }}>
            <IconButton
              size="small"
              variant="contained"
              onClick={onSubmitNewToDo}
            >
              <Done
                style={{
                  color: theme.palette.levels.green,
                  // marginTop: "0.35em",
                  marginLeft: "-0.5em",
                }}
                size={"1rem"}
              />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <IconButton size="small" variant="contained" onClick={onReject}>
              <Close
                style={{
                  color: theme.palette.levels.red,
                  // marginTop: "0.35em",
                  marginLeft: "-0.5rem",
                }}
                size={"1rem"}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>)}
      {localLoading && showSubmit && (<Grid item xs={3}>
        <CircularProgress
          style={{
            color: theme.palette.levels.orange,
            marginLeft: "0.5rem",
          }}
          size={"1.25rem"}
        />

      </Grid>)}
      <Grid item xs={!localLoading && !showSubmit ? 12 : 9} style={{ width: "100%" }}>
        <TextField
          autoFocus={false}
          label="ToDo Name"
          size="small"
          placeholder="Was ist zu tun?"
          value={newItemName}
          onChange={onChangeNewItemName}
          style={{
            // marginTop: "-0.25em",
            // marginLeft: "0.6rem",
            marginBottom: "0.3em",
            width: '100%'
          }}
          inputProps={{ style: { fontSize: 14 } }} // font size of input text
          InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
        />
      </Grid>


    </Grid >
  );
}
