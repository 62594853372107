import styled from "styled-components";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableWorkboardColumn from "./_WorkboardHelpers/DraggableWorkboardColumn";
import WorkboardColumnBox from "../../../../_4GeneralHelpers/1_Boxes/WorkboardColumnBox";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { saveAssignmentChanges } from "../../../../_3HelperFunctions/_AssignmentFunctions/saveAssignmentChanges";
import { updateDataSet } from "../../../../_3HelperFunctions/_LocalUpdates/updateDataSet";
import { useDashboardContext } from "../../../../_8HusibuechContexts/dashboardContext";
import { getUpdatedExtraDates } from "./_WorkboardHelpers/getUpdatedExtraDates";

const DragDropContextContainer = styled.div`
  padding: 20px;
  border-radius: 6px;
`;

const ListGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  display: flex;
`;


function WorkboardContent({ list, elements, updateElements }) {

  const {
    currentUser,
    isALeader,
    referenceDate,
    schoolYearData,
    setUnresolvedSchoolYearData, setPassKey, } = useAppContext()
  const { setOpenSnackbar } = useDashboardContext();


  const getStatusValue = (resultId) => {
    return list.indexOf(resultId) - 1 //-1 because we added "spätere Arbeiten"
  };

  const replaceAssignment = (list, updatedAssignment) => {
    // console.log("replace", list, updatedAssignment)
    const indexToReplace = list.findIndex(
      (assignment) => assignment.assignment_id === updatedAssignment.assignment_id
    );
    if (indexToReplace === -1) {// If the assignment is not found, return the original list
      console.log("Assignment not found");
      return list;
    }
    list[indexToReplace] = updatedAssignment;     // Replace the assignment at the specified index with the updatedAssignment
    return list;
  };

  const storeRankingChange = (updateList, updatedSchoolYearData, minUpdateIndex) => {
    updateList.forEach(async (assignment, index) => {
      // Check if the current index is higher than sourceIndex
      if (index >= minUpdateIndex) {//only the ranking of the elements that followed need to be updated, as well as the assignment
        // console.log("this, ", assignment.name, assignment.ranking, index)
        const currentUpdateValues = [{ property: "ranking", value: index }];
        const thisResponseAssignment = await saveAssignmentChanges(
          assignment,
          currentUpdateValues,
          "kanbanBoard",
          currentUser
        );
        updateList = replaceAssignment(updateList, thisResponseAssignment) //updates current elments
        updatedSchoolYearData = { ...schoolYearData };
        updatedSchoolYearData = await updateDataSet(
          updatedSchoolYearData,
          thisResponseAssignment,
          isALeader,
          "kanbanBoard"
        );
      }
    });

    return updateList;
  };

  const updateLocalRanking = (list, source, destination) => {
    let minUpdateIndex = Math.min(source.index, destination.index);
    list.forEach(async (assignment, index) => {
      if (index >= minUpdateIndex) {//only the ranking of the elements that followed need to be updated
        // console.log("assignment, ", assignment)
        assignment.ranking = index
      }
    })
    return list
  }

  const addItemToList = (list, assignmentToAdd, source, destination) => {
    // If the index is out of bounds, add the new item to the end of the list
    if (destination.index < 0 || destination.index >= list.length) {
      list.push(assignmentToAdd);
    } else {
      // Insert the new item at the specified index
      assignmentToAdd.ranking = destination.index
      list.splice(destination.index, 0, assignmentToAdd);
    }

    list = updateLocalRanking(list, source, destination)

    // Return the updated list
    return [assignmentToAdd, list];
  };

  const removeFromList = (list, source, destination) => {
    // const result = Array.from(list);
    const [removed] = list.splice(source.index, 1);
    list = updateLocalRanking(list, source, destination)
    return [removed, list];
  };

  const reorder = (taskDataArr, source, destination) => {
    let taskData = taskDataArr

    let [removed, sourceListCopyWithElementRemoved] = removeFromList(taskData[source.droppableId], source, destination)

    taskData[source.droppableId] = sourceListCopyWithElementRemoved;

    let [hello, updatedList] = addItemToList(taskData[destination.droppableId], removed, source, destination)

    taskData[destination.droppableId] = updatedList;

    return taskData;
  }

  const onDragEnd = async (result) => {
    let updatedSchoolYearData;
    let listCopy = { ...elements };

    const resultIndex = result?.destination?.index
    const sourceIndex = result?.source?.index
    const resultId = result?.destination?.droppableId
    const sourceId = result?.source?.droppableId
    const source = result?.source
    const destination = result?.destination
    // console.log(result);
    let movedElement = elements[sourceId][sourceIndex];

    try {
      if (!destination || (sourceId === resultId &&
        sourceIndex === resultIndex)) {
        return; //there was an error || we have not changed neither status nor ranking
      } else if (sourceId === resultId) {//only change rankings
        listCopy = reorder(listCopy, source, destination);
        updateElements(listCopy) //just provisionary for fast rendering
        // //now deal with db update in background...
        updatedSchoolYearData = { ...schoolYearData };
        let updateList = listCopy[resultId]
        let minUpdateIndex = Math.min(resultIndex, sourceIndex);

        updateList = storeRankingChange(updateList, updatedSchoolYearData, minUpdateIndex)
        listCopy[resultId] = updateList;

      }
      else { // element was moved to new box (covers normal case or "extra assignments")
        listCopy = reorder(listCopy, source, destination)
        //update listCopy according to case:
        if (sourceId !== list[0] && resultId !== list[0]) { //"normal case"
          listCopy[resultId][resultIndex].status = getStatusValue(resultId)
          listCopy[resultId][resultIndex].my_minutes_done = Math.round(
            listCopy[resultId][resultIndex].my_minutes *
            listCopy[resultId][resultIndex].status /
            4)
        }
        else { // user addd an extra assignment (sourceId === list[0]) || adds or removes referenceDate (resultId === list[0])
          listCopy[resultId][resultIndex].status = sourceId === list[0] ? getStatusValue(resultId) : 0
          listCopy[resultId][resultIndex].my_minutes_done = sourceId === list[0] ? Math.round(movedElement.my_minutes * movedElement.status / 4) : 0
          listCopy[resultId][resultIndex].extra_dates = await getUpdatedExtraDates( //adds or removes referenceDate
            listCopy[resultId][resultIndex].extra_dates,
            referenceDate,
            sourceId,
            resultId,
            list
          );
        }
        // console.log("update1")
        updateElements(listCopy) //just provisionary for fast rendering
        // //now deal with db update in background...
        updatedSchoolYearData = { ...schoolYearData };
        //first update source list
        let updateListOne = listCopy[sourceId]
        let minUpdateIndex = Math.min(resultIndex, sourceIndex);
        updateListOne = storeRankingChange(updateListOne, updatedSchoolYearData, minUpdateIndex)
        listCopy[sourceId] = updateListOne;
        //dealwith destination
        let updateListTwo = listCopy[resultId]
        updateListTwo = storeRankingChange(updateListTwo, updatedSchoolYearData, minUpdateIndex)
        //update changed assignment

        listCopy[resultId] = updateListTwo;

        //update changed assignment
        let updatedAssignment = listCopy[resultId][resultIndex];
        let updatedAssignmentUpdateValues
        if (sourceId !== listCopy[0] && resultId !== listCopy[0]) { // "normal assignment was moved"
          updatedAssignmentUpdateValues = [
            { property: "status", value: updatedAssignment.status }, //value was updated above
            { property: "my_minutes_done", value: updatedAssignment.my_minutes_done },
          ]
        }
        else { //same update logic for adding or removing extra date assignments!
          updatedAssignmentUpdateValues = [
            { property: "status", value: updatedAssignment.status }, //value was updated above
            { property: "my_minutes_done", value: updatedAssignment.my_minutes_done }, // value was updated above
            { property: "extra_dates", value: updatedAssignment.extra_dates }, // value was updated above
          ]
        }
        // console.log("values", updatedAssignmentUpdateValues)
        let thisResponseAssignment = await saveAssignmentChanges(
          updatedAssignment,
          updatedAssignmentUpdateValues,
          "kanbanBoard",
          currentUser
        );
        listCopy[resultId] = replaceAssignment(listCopy[resultId], thisResponseAssignment)
        updatedSchoolYearData = await updateDataSet(
          updatedSchoolYearData,
          thisResponseAssignment,
          isALeader,
          "kanbanBoard"
        );
      }
      //update for all cases in which there have been changes
      if (updatedSchoolYearData) {
        updateElements(listCopy) // force this update, because if elements are only changed within a list, the change won't be noticed
        setUnresolvedSchoolYearData(updatedSchoolYearData);
        setPassKey(true); //especially here!
        if (movedElement?.status === 4) {
          setOpenSnackbar(true);
        }
      }
    } catch (error) {
      console.log(error);
      alert("es gab ein problem mit dem Update");
    }
  };



  return (
    <DragDropContextContainer>
      <DragDropContext onDragEnd={onDragEnd}>
        <ListGrid>
          {list.map(
            (
              statusName,
              index //list contains the different kanbanBoard stages
            ) => (
              <WorkboardColumnBox
                key={index}
                sx={{
                  width: "100%",
                  overflow: "auto",
                  maxHeight: 570,
                  minWidth: 115,
                }}
              >
                <DraggableWorkboardColumn
                  key={index}
                  elements={elements[statusName]}
                  prefix={statusName}
                  setOpenSnackbar={setOpenSnackbar}
                />
              </WorkboardColumnBox>
            )
          )}
        </ListGrid>
      </DragDropContext>
    </DragDropContextContainer>
  );
}

export default WorkboardContent;
