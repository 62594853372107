import React, { Fragment, memo } from "react";
import { TableBody } from "@mui/material";
import SemesterTableWeekRow from "./SemesterTableWeekRow";

const SemesterTableBody = ({ currentSemesterData }) => {
  // console.log(currentSemesterData);

  return (
    <TableBody>
      {currentSemesterData &&
        currentSemesterData.map((semesterWeek) => (
          <Fragment key={semesterWeek.id}>
            <SemesterTableWeekRow semesterWeek={semesterWeek} />
          </Fragment>
        ))}
    </TableBody>
  );
};

export default memo(SemesterTableBody);
