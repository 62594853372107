import { useEffect, useState } from "react";
import { StatusDistributionDoghnut } from "../../_5Pages/4DashboardPage/DashboardParts/Doughnut/StatusDistributionDoghnut";
import { arraysAreEqual } from "../../_3HelperFunctions/_AppHelpers/arraysAreEqual";
import { filterTasksByProperty } from "../../_3HelperFunctions/_AssignmentFunctions/filterTasksByProperty";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";

export default function StatisticsView({ assignment }) {
  const { schoolYearData } = useAppContext()
  const [classAssignments, setClassAssignments] = useState(undefined)
  const [semesterStatusCount, setSemesterStatusCount] = useState([
    0, 0, 0, 0, 0
  ]);
  const [hasStudents, setHasStudents] = useState(false) 

  useEffect(() => {
    const check = async () => {
      setHasStudents(!arraysAreEqual(semesterStatusCount, [0, 0, 0, 0, 0])) // if the array is empty, there are no students
    }
    check()
  }, [semesterStatusCount])

  useEffect(() => {
    const calculateData = async () => {
      let classAssignments = await filterTasksByProperty(schoolYearData.classAssignments, assignment, "assignment_id")
      classAssignments = classAssignments.filter((assignment) => assignment.role_id !== 2)
      setClassAssignments(classAssignments)
      // console.log("calculate...", classAssignments)
      let currentDataArray = [0,0,0,0,0];
      classAssignments.forEach((element) => {
        switch (element.status) {
          case 0:
            currentDataArray[0] += 1;
            // console.log("we came here 4");
            break;
          case 1:
            currentDataArray[1] += 1;
            // console.log("we came here");
            break;
          case 2:
            currentDataArray[2] += 1;
            // console.log("we came here2 ");
            break;
          case 3:
            currentDataArray[3] += 1;
            // console.log("we came here3");
            break;

          case 4:
            currentDataArray[4] += 1;
            // console.log("we came here 5");
            break;
          default:
            console.error("no status found")
        }
      });
      if (!arraysAreEqual(currentDataArray, semesterStatusCount)) {
        // console.log("newArray...", currentDataArray);
        setSemesterStatusCount(currentDataArray)
      }
    };
    if (schoolYearData) {
      calculateData()
    }
  }, [schoolYearData]);

  return (
    <>
      {/* <WorkloadDistributionChart assignment={assignment} /> */}
      <StatusDistributionDoghnut classAssignments={classAssignments} semesterStatusCount={semesterStatusCount} hasStudents={hasStudents} />
    </>
  );
}
