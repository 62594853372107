import React from "react";
import { Grid } from "@mui/material";

const LoginBox = ({ children }) => (
  <Grid
  container
  padding={0.5}
  direction={{
    xs: "row",
  }}
  rowGap={{
    xs: 4,
  }}
  sx={{
    // backgroundColor: "red",
    width: "100%",
    maxWidth: "900px",
    height: "100%",
    minHeight: {
      xs: "100%",
      ss: "500px",
    },
  }}
>
    {children}
  </Grid>
);

export default LoginBox;
