import React from "react";
import { ListItemText, Popover } from "@mui/material";

import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import { useState } from "react";
import { Cottage, Person, School } from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import HusibuechCircle from "../../3_SeverityPills/_HusibuechCircle";
import StatusPopUpView from "../../3_SeverityPills/StatusPopUpView";
import StyledTooltip from "../../../_4GeneralHelpers/2_Tooltips/StyledTooltip";
import { saveAssignmentChanges } from "../../../_3HelperFunctions/_AssignmentFunctions/saveAssignmentChanges";
import { updateDataSet } from "../../../_3HelperFunctions/_LocalUpdates/updateDataSet";

const DragItem = styled.div`
  padding-left: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 4px;

  minheight: 80px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: ${(props) => (props.isDragging ? props.theme.palette.primary.light : props.theme.palette.background.paper)};
  margin: 14px 14px 8px 8px;
  display: grid;
  grid-gap: 20px;
  flex-direction: column;

  // overflow-x: auto;
  // overflow-y: auto;
`;

export const ToDoItem = ({ item, index, setOpenSnackbar }) => {
  const theme = useTheme();
  const { schoolYearData, setUnresolvedSchoolYearData, currentUser, isALeader } =
    useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // console.log(open)

  const handleClick = (value, event) => {
    if (schoolYearData?.userAssignments) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeStatusAndClose = async (newStatus) => {
    if (newStatus !== item.status) {
      try {
        const updateValues = [{ property: "status", value: newStatus }, { property: "priority", value: item.priority }]

        const responseAssignment = await saveAssignmentChanges(
          item,
          updateValues,
          "toDoList",
          currentUser
        );
        handleClose();
        const updatedSchoolYearData = await updateDataSet(schoolYearData, responseAssignment, isALeader, "toDoList")
        await setUnresolvedSchoolYearData(updatedSchoolYearData);

        if (responseAssignment.status === 4) {
          setOpenSnackbar(true);
        }
      } catch (error) {
        console.log(error);
        alert("Es gab ein Problem im Update");
      }
    }
  };



  return (
    <Draggable draggableId={item.assignment_id.toString()} index={index}>
      {(provided, snapshot) => {
        return (
          <DragItem
            key={item.assignment_id}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            snapshot={snapshot}
            isDragging={snapshot.isDragging}
            theme={theme}
          >
            <ListItemText
              primary={
                <>
                  {item.type === "exam" ? (
                    <StyledTooltip
                      title={"Prüfung"}
                      placement="bottom-start"
                      arrow
                    >
                      <School
                        sx={{
                          height: 22,
                          width: 22,
                          // marginLeft: 0.7,
                        }}
                        style={{
                          color: theme.palette.levels.gray,
                        }}
                      />
                    </StyledTooltip>
                  ) : item.type === "homework" ? (
                    <StyledTooltip
                      title={"Hausaufgabe"}
                      placement="bottom-start"
                      arrow
                    >
                      <Cottage
                        sx={{
                          height: 22,
                          width: 22,
                          // marginLeft: 0.7,
                        }}
                        style={{
                          color: theme.palette.levels.gray,
                        }}
                      />
                    </StyledTooltip>
                  ) : item.type === "personal" ? (
                    <StyledTooltip
                      title={"Persönlich"}
                      placement="bottom-start"
                      arrow
                    >
                      <Person
                        sx={{
                          height: 22,
                          width: 22,
                          // marginLeft: 0.7,
                        }}
                        style={{
                          color: theme.palette.levels.gray,
                        }}
                      />
                    </StyledTooltip>

                  ) : (
                    <></>
                  )}
                  &nbsp;{item.name} &nbsp;
                  <HusibuechCircle
                    type={"status"}
                    keyValue={item.status}
                    onClick={handleClick}
                    fill={false}
                    select={"own"}
                  />
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <StatusPopUpView
                      changeStatus={changeStatusAndClose}
                      assignment={item}
                    />
                  </Popover>
                </>
              }
            />
          </DragItem>
        );
      }}
    </Draggable>
  );
};


