async function userConfirmDialogue(type) {
  return window.confirm(
    `${
      type !== "personal"
        ? "Assignment wirklich unwiderruflich für alle Teilnehmer löschen?"
        : "Assignment wirklich löschen?"
    }`
  );
}
exports.userConfirmDialogue = userConfirmDialogue;
