import React, { forwardRef } from "react";
import { Grid } from "@mui/material";

const MainContentGrid = forwardRef(({ children }, ref) => (
  <Grid
    container
    direction="row"
    spacing={{ xs: 1, md: 3 }}
    justifyContent="space-between"
    alignItems="flex-start"
    // padding={{ xs: 3, md: 3 }}
    paddingLeft={{ xs: 3 }}
    paddingRight={{ xs: 3 }}
  
    style={{ overflowY: "auto", overflowX: "hidden", 
    // backgroundColor: "green" 
  }}
    ref={ref}
  >
    {children}
  </Grid>
));

export default MainContentGrid;
