import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DragDropContext } from "react-beautiful-dnd";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import { Grid } from "@material-ui/core";
import { memo } from "react";
import { saveAssignmentChanges } from "../../../_3HelperFunctions/_AssignmentFunctions/saveAssignmentChanges";
import { updateDataSet } from "../../../_3HelperFunctions/_LocalUpdates/updateDataSet";
import PriorityDragGridBackground from "./_Helpers/PriorityDragGridBackground";
import PriorityDragGridBottom from "./_Helpers/PriorityDragGridBottom";
import PriorityDragBoxes from "./_Helpers/PriorityDragBoxes";

const DragDropContextContainer = styled.div`
  padding: 10px;
  border-radius: 6px;
  // background: green;
  overflow: auto
`;

function PriorityDragGrid({ elements, updateElements, priorityLabels }) {
  const { currentUser, isALeader, schoolYearData, setUnresolvedSchoolYearData, setPassKey } = useAppContext();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [open, setOpen] = useState(false);

  const getPriorityValue = (droppableId) => {
    if (droppableId === 'A Priorität') return 3
    else if (droppableId === 'B Priorität') return 2
    else if (droppableId === 'C Priorität') return 1
    else return 0
  };

  const replaceAssignment = (list, updatedAssignment) => {
    // console.log("replace", list, updatedAssignment)
    const indexToReplace = list.findIndex(
      (assignment) => assignment.assignment_id === updatedAssignment.assignment_id
    );
    if (indexToReplace === -1) {// If the assignment is not found, return the original list
      console.log("Assignment not found");
      return list;
    }
    list[indexToReplace] = updatedAssignment;     // Replace the assignment at the specified index with the updatedAssignment
    return list;
  };
  const removeFromList = (list, assignmentToRemove) => {
    // Find the index of the element with the specified assignment_id
    const indexToRemove = list.findIndex(
      (assignment) => assignment.assignment_id === assignmentToRemove.assignment_id
    );

    // If the element is not found, return the original list
    if (indexToRemove === -1) {
      console.log("we did not fiind this")
      return [null, list];
    }

    // Remove the element from the list and store it in removedElement
    let removedElement = list.splice(indexToRemove, 1)[0];

    //updated ranking
    list.forEach(async (assignment, index) => {
      if (index > 0) {//only the ranking of the elements that followed need to be updated
        // console.log("assignment, ", assignment)
        assignment.ranking = index
      }
    });

    // Return the removed element and the updated list
    return [removedElement, list];
  }

  const addItemToList = (list, assignmentToAdd, indexToAdd) => {
    // If the index is out of bounds, add the new item to the end of the list
    if (indexToAdd < 0 || indexToAdd >= list.length) {
      list.push(assignmentToAdd);
    } else {
      // Insert the new item at the specified index
      assignmentToAdd.ranking = indexToAdd
      list.splice(indexToAdd, 0, assignmentToAdd);
    }

    //updated ranking
    list.forEach(async (assignment, index) => {
      if (index > 0) {//only the ranking of the elements that followed need to be updated
        // console.log("assignment, ", assignment)
        assignment.ranking = index
      }
    });

    // Return the updated list
    return [assignmentToAdd, list];
  };

  const onDragEnd = async (result) => {
    let updatedSchoolYearData;
    let listCopy = { ...elements };
    const resultIndex = result?.destination?.index
    const sourceIndex = result?.source?.index
    const resultId = result?.destination?.droppableId
    const sourceId = result?.source?.droppableId
    // console.log("result:", resultId, " with: ", elements[resultId], ", source: ", sourceId, "with: ", elements[sourceId])
    let movedElement = elements[sourceId][sourceIndex];

    if (!result.destination) { // no result
      return;
    } else if (
      sourceId === resultId &&
      sourceIndex === resultIndex
    ) {// item has not changed position
      return;
    } else if (resultId === "deleteBin") { //item moved to bin
      // console.log("we delete this from the todo")
      updatedSchoolYearData = { ...schoolYearData }
      let currentPriorityBoxList = [...listCopy[sourceId]];

      let [removed, newSourceList] = removeFromList(
        currentPriorityBoxList,
        movedElement
      );
      // console.log("newSource, ", newSourceList)
      listCopy[sourceId] = newSourceList;
      updateElements(listCopy) //just provisionary for fast rendering

      const updateValues = [{ property: "is_a_to_do", value: false }, { property: "ranking", value: 0 }];
      const responseAssignment = await saveAssignmentChanges(
        removed,
        updateValues,
        "toDoList",
        currentUser
      );
      updatedSchoolYearData = await updateDataSet(
        updatedSchoolYearData,
        responseAssignment,
        isALeader,
        "toDoList"
      );

      var newSourceListCopy = newSourceList
      newSourceList.forEach(async (assignment, index) => {
        // Check if the current index is higher than sourceIndex
        if (index > sourceIndex) {//only the ranking of the elements that followed need to be updated
          let currentUpdateValues = [{ property: "ranking", value: index }];
          let thisResponseAssignment = await saveAssignmentChanges(
            assignment,
            currentUpdateValues,
            "toDoList",
            currentUser
          );
          newSourceListCopy = replaceAssignment(newSourceListCopy, thisResponseAssignment) //updates current elments
          updatedSchoolYearData = await updateDataSet(
            updatedSchoolYearData,
            thisResponseAssignment,
            isALeader,
            "toDoList"
          );
        }
      });

      listCopy[sourceId] = newSourceListCopy;
      setOpen(true)
    } else if (sourceId === resultId) {//only change rankings
      // console.log("resIndex ", resultIndex, ", sourceIndex:", sourceIndex)
      let currentPriorityBoxList = listCopy[resultId];
      let [removed, newSourceList] = removeFromList(
        currentPriorityBoxList,
        movedElement
      );
      [movedElement, newSourceList] = addItemToList(newSourceList, removed, resultIndex)
      listCopy[sourceId] = newSourceList;
      updateElements(listCopy) //just provisionary for fast rendering

      //deal with db updates in background
      let minUpdateIndex = Math.min(resultIndex, sourceIndex);

      updatedSchoolYearData = schoolYearData;
      var newSourceListCopy = newSourceList
      newSourceList.forEach(async (assignment, index) => {
        // Check if the current index is higher than sourceIndex
        if (index >= minUpdateIndex) {//only the ranking of the elements that followed need to be updated, as well as the assignment
          const currentUpdateValues = [{ property: "ranking", value: index }];
          const thisResponseAssignment = await saveAssignmentChanges(
            assignment,
            currentUpdateValues,
            "toDoList",
            currentUser
          );
          newSourceListCopy = replaceAssignment(newSourceListCopy, thisResponseAssignment) //updates current elments
          updatedSchoolYearData = { ...schoolYearData };
          updatedSchoolYearData = await updateDataSet(
            updatedSchoolYearData,
            thisResponseAssignment,
            isALeader,
            "toDoList"
          );
        }
      });

      listCopy[sourceId] = newSourceListCopy;

    } else { // element was moved to new box
      updatedSchoolYearData = { ...schoolYearData };
      movedElement.ranking = resultIndex
      movedElement.priority = getPriorityValue(resultId)

      //create the lists that are involved
      let sourceList = [...listCopy[sourceId]];
      let currentDestinationList = [...listCopy[resultId]];

      let [hello, currentSourceList] = removeFromList(
        sourceList,
        movedElement
      );
      //updatemovedelement
      // console.log(removed)
      [movedElement, currentDestinationList] = addItemToList(
        currentDestinationList,
        movedElement, resultIndex
      );
      //update the relevant lists
      listCopy[sourceId] = currentSourceList;
      listCopy[resultId] = currentDestinationList;
      // console.log("update1")
      updateElements(listCopy) //just provisionary for fast rendering

      //deal with updates in background
      //first update source list
      let originalIndex = sourceIndex
      let minUpdateIndex = Math.min(resultIndex, sourceIndex);

      //update source list
      var currentSourceListCopy = currentSourceList
      currentSourceList.forEach(async (assignment, index) => {
        // Check if the current index is higher than sourceIndex
        if (index >= originalIndex) {//only the ranking of the elements that followed need to be updated
          let currentUpdateValues = [{ property: "ranking", value: index }];
          let thisResponseAssignment = await saveAssignmentChanges(
            assignment,
            currentUpdateValues,
            "toDoList",
            currentUser
          );
          currentSourceListCopy = replaceAssignment(currentSourceListCopy, thisResponseAssignment) //updates current elments

          updatedSchoolYearData = await updateDataSet(
            updatedSchoolYearData,
            thisResponseAssignment,
            isALeader,
            "toDoList"
          );
        }
      });

      listCopy[sourceId] = currentSourceListCopy;

      //dealwith destination
      var currentDestinationListCopy = currentDestinationList
      //update changed assignment
      let updatedAssignment = movedElement;
      let updatedAssignmentUpdateValues = [
        { property: "priority", value: updatedAssignment.priority }, //value was updated above
        { property: "ranking", value: updatedAssignment.ranking } // value was updated above
      ];
      // console.log("values", updatedAssignmentUpdateValues)
      let thisResponseAssignment = await saveAssignmentChanges(
        updatedAssignment,
        updatedAssignmentUpdateValues,
        "toDoList",
        currentUser
      );
      currentDestinationListCopy = replaceAssignment(currentDestinationListCopy, thisResponseAssignment)
      // console.log("storedAssignment: ", thisResponseAssignment)
      updatedSchoolYearData = await updateDataSet(
        updatedSchoolYearData,
        thisResponseAssignment,
        isALeader,
        "toDoList"
      );

      currentDestinationList.forEach(async (assignment, index) => {
        // Check if the current index is higher than sourceIndex
        if (index >= minUpdateIndex && assignment.assignment_id !== thisResponseAssignment.assignment_id) {//only the ranking of the elements that followed need to be updated
          let currentUpdateValues = [{ property: "ranking", value: index }];
          let thisResponseAssignment = await saveAssignmentChanges(
            assignment,
            currentUpdateValues,
            "toDoList",
            currentUser
          );
          currentDestinationListCopy = replaceAssignment(currentDestinationListCopy, thisResponseAssignment)

          updatedSchoolYearData = await updateDataSet(
            updatedSchoolYearData,
            thisResponseAssignment,
            isALeader,
            "toDoList"
          );
        }
      });

      listCopy[resultId] = currentDestinationListCopy
      // console.log("new destinationList: ", destinationList)
    }

    if (updatedSchoolYearData) {
      // console.log("update...2") //update if changes...
      updateElements(listCopy) // force this update, because if elements are only changed within a list, the change won't be noticed
      setUnresolvedSchoolYearData(updatedSchoolYearData);
      setPassKey(true); //especially here!

    }
  };

  // console.log("currentElements", elements)
  return (
    <DragDropContextContainer>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
          <PriorityDragGridBackground>
            <PriorityDragBoxes priorityLabels={priorityLabels} elements={elements} setOpenSnackbar={setOpenSnackbar} />
          </PriorityDragGridBackground>
          <PriorityDragGridBottom open={open} setOpen={setOpen} openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} />
        </Grid>
      </DragDropContext>
    </DragDropContextContainer>
  );
}

export default memo(PriorityDragGrid);
