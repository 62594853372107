import { MenuItem } from "@mui/material";
import { memo } from "react";
import { CustomTextField } from "../../../../../_3HelperFunctions/CustomTextField";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";
import { useTheme } from "@mui/styles";

function CourseBoxComponent({
  restricted,
  selectedAssignmentCourse,
  change,
}) {
  const { userCourses, appInDarkMode } = useAppContext();
  const theme = useTheme()
  // console.log(selectedAssignmentCourse)
  return (
    <>
      <CustomTextField
        // labelId="course-lable"
        // defaultValue={}
        id="course"
        disabled={restricted}
        select
        onChange={change}
        value={selectedAssignmentCourse?.name}
        label="Kurs"
        inputProps={{ style: { fontSize: "0.9em" } }}
        appindarkmode={appInDarkMode.toString()}
        theme={theme}
      >
        <MenuItem
          disabled={false}
          key={selectedAssignmentCourse.id}
          value={selectedAssignmentCourse.name}
          style={{ fontSize: "0.8rem" }}
        >
          {selectedAssignmentCourse.name}
        </MenuItem>
        {userCourses?.filter(
          (course) => course.id !== selectedAssignmentCourse.id
        ).length > 0 &&
          userCourses
            .filter((course) => course.id !== selectedAssignmentCourse.id)
            .map((course, i) => (
              <MenuItem style={{ fontSize: "0.8rem" }} key={i} value={course}>
                {course?.name?course.name:"hello"}
              </MenuItem>
            ))}
      </CustomTextField>
    </>
  );
}

export default memo(CourseBoxComponent);
