import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import WideBox from "../../../../_4GeneralHelpers/1_Boxes/WideBox";
import WorkboardContent from "./WorkboardContent";
import BoxHeader from "../../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";
import { useEffect, useState } from "react";
import { processOtherAssignments } from "./_WorkboardHelpers/processOtherAssignments";
import { getAssignmentList } from "../../../../_3HelperFunctions/_AssignmentFunctions/getAssignmentList";
import { generateDragList } from "./_WorkboardHelpers/generateDragList";
export const Workboard = () => {

  const {
    stopReRenderProcess,
    showDetail,
    isAStudent,
    statusList,
    referenceDate,
    schoolYearData,
    showExams,
    showHomeworks,
    showPersonals,
    statusSelection,
    relevantUserAssignments, } = useAppContext()

  const [elements, setElements] = useState([]);
  const [list] = useState(["spätere Arbeiten"].concat(statusList));   //initialize the empty elements list with the extra element "spätere Aufgaben"

  const [calculatingAssignments, setCalculatingAssignments] = useState(false)
  const [assignmentList, setAssignmentList] = useState(undefined);
  const [otherAssignmentsList, setOtherAssignmentList] = useState(undefined);



  useEffect(() => {
    if (list.length === statusList.length + 1) {
      setElements(
        list.reduce((acc, listKey) => ({ ...acc, [listKey]: [] }), {})
      );
    }
  }, [list]);

  useEffect(() => {
    const calculateAssignments = async () => {
      const assignmentList = await getAssignmentList(
        schoolYearData,
        relevantUserAssignments,
        showExams,
        showHomeworks,
        showPersonals
      );
      setAssignmentList(assignmentList);
      const otherAssignmentList = await processOtherAssignments(
        schoolYearData,
        relevantUserAssignments,
        referenceDate,
        showExams,
        showHomeworks,
        showPersonals,
        statusSelection
      );
      setOtherAssignmentList(otherAssignmentList);
      setCalculatingAssignments(false)
    };
    if (calculatingAssignments) {
      calculateAssignments();
    }
  }, [
    calculatingAssignments
  ]);


  useEffect(() => {
    if (!stopReRenderProcess && schoolYearData && relevantUserAssignments) {
      // console.log("start to calculate")
      setCalculatingAssignments(true)
    }
  }, [stopReRenderProcess]);

  useEffect(() => {
    const calculateElements = () => {
      // console.log("we calculate...")
      const draglist = generateDragList(
        list,
        assignmentList,
        otherAssignmentsList
      );
      setElements(draglist);
    };
    if ((assignmentList && otherAssignmentsList && list)) {
      calculateElements();
    }
  }, [assignmentList, otherAssignmentsList, list]);

  const updateElements = (elements) => {
    // Iterate over each priority in elements
    let updatedElements = {};

    for (const status in elements) {
      if (elements.hasOwnProperty(status)) {
        // Sort the list for the current priority by multiple keys: ranking, real_edit_date_user
        const sortedList = elements[status].sort((a, b) =>
          a.ranking - b.ranking
          // || b.real_edit_date_user.localeCompare(a.real_edit_date_user)
        );

        // Assign the sorted list to the updatedElements object
        updatedElements[status] = sortedList;
      }
    }
    setElements(updatedElements);
  };

  return (
    <>
      <BoxHeader
        title={isAStudent ? `Dein Workboard` : `Ihr persönliches Workboard`}
        showDetail={showDetail}
        options={["detail"]}

      />
      <WideBox>
        <WorkboardContent
          list={list}
          elements={elements}
          updateElements={updateElements}
        />
      </WideBox>
    </>
  );
};
