// assignmentUtils.js

import moment from 'moment';
import { filterOutByShowAssignments } from './filterOutByShowAssignments';

export const getAssignmentsAfter = async (assignments, sunday, showExams, showHomeworks, showPersonals) => {
  const assignmentsAfter = await assignments.filter(
    (assignment) =>
      moment(assignment.due_date).isAfter(moment(sunday)) &&
      moment(assignment.due_date)
        .startOf("day")
        .subtract(assignment.my_days, "days")
        .isAfter(sunday)

      && !filterOutByShowAssignments(assignment, showExams, showHomeworks, showPersonals)
  );

  return assignmentsAfter
};
