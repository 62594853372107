import { Grid, Typography } from "@mui/material";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

export const NKTitle = () => {
  const { currentUser, isMobile } = useAppContext();
  // console.log(currentUser)

  return (
    // This is the date overview item
    <>
      <div
        style={{
          width: "110%",
          whiteSpace: "nowrap", // Prevent content from wrapping
          textAlign: isMobile?"center":"", // Center along the x-axis
        }}
      >
        <Typography variant="h6" color="primary">
          Aktuelle
        </Typography>
      </div>
      <>
        <Grid item sm={12}>
          <div
            style={{
              width: "110%",
              whiteSpace: "nowrap", // Prevent content from wrapping
              textAlign: isMobile?"center":"", // Center along the x-axis
            }}
          >
            <Typography color="primary" variant="h4" fontWeight={"bold"}>
              Nachkontrolle
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <div
            style={{
              width: "110%",
              whiteSpace: "nowrap", // Prevent content from wrapping
              textAlign: isMobile?"center":"", // Center along the x-axis
            }}
          >
              <Typography color="primary" variant="caption">
                von {currentUser.firstName} {currentUser.lastName}
              </Typography>
          </div>
        </Grid>
      </>
    </>
  );
};
