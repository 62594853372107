import { memo } from "react";
import { Grid } from "@mui/material";
function UserQuestion({text}) {

  return (
    <Grid
            item
            style={{
              width: "90%",
            }}
            sx={{
              display: {
                xs: "none",
                ss: "block",
                sm: "block",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <h1
              style={{ color: "black", fontSize: "24px", textAlign: "center" }}
            >
              {text}
            </h1>
          </Grid>
  );
}

export default memo(UserQuestion);
