import { Grid } from "@mui/material";
import { memo, useEffect, useState } from "react";
import MainContentGrid from "../../../_4GeneralHelpers/1_Boxes/MainContentGrid";
import { Item } from "../../4DashboardPage/DashboardHelpers/Item";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import NachkontrolleChart from "./NachkontrolleChart";
import NachkontrolleSubjectTable from "./NachkontrolleSubjectTable";
import NachkontrolleTable from "./NachkontrolleTable";
import WideBox from "../../../_4GeneralHelpers/1_Boxes/WideBox";
import { filterTasksByStatusAndFilter } from "../../../_3HelperFunctions/_AssignmentFunctions/filterTasksByStatusAndFilter";
import { sortTasksByDueDateAndPriority } from "../../../_3HelperFunctions/_AssignmentFunctions/sortTasksByDueDateAndPriority";
import { filterUniqueAssignments } from "../../../_3HelperFunctions/_AssignmentFunctions/filterUniqueAssignments";
import BoxHeader from "../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";
import UnfinishedDataGrid from "../../7SemesterOverview/_SemesterTableHelpers/UnfinishedDataGrid";
import FinishedDataGrid from "../../7SemesterOverview/_SemesterTableHelpers/FinishedDataGrid";

function NachkontrolleContent() {
  const {
    schoolYearData,
    isAStudent,
    showExams,
    showHomeworks,
    showPersonals,
  } = useAppContext();

  const [dataCompleted, setDataCompleted] = useState([]);
  const [dataUncompleted, setDataUncompleted] = useState([]);
  const [showUncompleted, setShowUncompleted] = useState(false);

  const handleClick = () => {
    setShowUncompleted(!showUncompleted);
  };

  useEffect(() => {
    const fetchData = async (isCompleted) => {
      const schoolYearDataCopy = await schoolYearData
      const assignmentsData = isAStudent
        ? schoolYearDataCopy.userAssignments
        : await filterUniqueAssignments(
          schoolYearDataCopy.userAssignments,
          "assignment_id"
        );
      const filteredAssignments = await filterTasksByStatusAndFilter(
        assignmentsData,
        4,
        showExams,
        showHomeworks,
        showPersonals,
        isCompleted
      );
      // console.log("we fetch new data: ", filteredAssignments);
      const sortedAssignments = await sortTasksByDueDateAndPriority(
        filteredAssignments
      );

      if (isCompleted) {
        setDataCompleted(sortedAssignments);
      } else {
        setDataUncompleted(sortedAssignments);
      }
    };
    if (schoolYearData) {
      fetchData(true); // Completed assignments
      fetchData(false); // Uncompleted assignments
    }
  }, [schoolYearData, showExams, showHomeworks, showPersonals]);

  return (
    <MainContentGrid>
      <Grid item xs={12} mds={6}>
        <Item
          elevation={5}
          sx={{
            padding: { xs: 1, ss: 2, lg: 3 },
          }}
        >
          <NachkontrolleChart data={dataCompleted} />
        </Item>
      </Grid>

      <Grid item xs={12} mds={6}>
        <Item
          elevation={5}
          sx={{
            padding: { xs: 1, ss: 2, lg: 3 },
          }}
        >
          <NachkontrolleSubjectTable data={dataCompleted} />
        </Item>
      </Grid>

      <Grid item xs={12}>
        <Item
          elevation={5}
          sx={{
            padding: { xs: 1, ss: 2, lg: 3 },
          }}
        >
          <BoxHeader
            title={`${showUncompleted ? "Unerledigte" : "Vollständig erledigte"
              } Assignments`}
            options={["flipAssignment"]}
            flipAssignment={showUncompleted}
            setFlipAssignment={handleClick}
          />
          <WideBox>
            {showUncompleted ? (
              // <NachkontrolleTable data={dataUncompleted} />
              <UnfinishedDataGrid data={dataUncompleted}/>
            ) : (
              <FinishedDataGrid data={dataCompleted} />
              // <NachkontrolleTable data={dataCompleted} />
            )}
          </WideBox>
        </Item>
      </Grid>
    </MainContentGrid>
  );
}

export default memo(NachkontrolleContent);
