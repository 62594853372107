import { MenuItem } from "@mui/material";
import React, { memo } from "react";
import { CustomTextField } from "../../../../../_3HelperFunctions/CustomTextField";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";
import { useTheme } from "@mui/styles";

function TypeBoxComponent({ restricted, type, change }) {
  // console.log(type)
  const theme = useTheme()
  const {appInDarkMode} = useAppContext()
  const types = [
    {
      value: "homework",
      label: "Hausaufgabe",
    },
    {
      value: "exam",
      label: "Prüfung",
    },
    {
      value: "personal",
      label: "Persönlich",
    },
  ];

  return (
    <>
      {/* Type Box */}
      <CustomTextField
        id="type-box"
        select
        disabled={restricted}
        onChange={change}
        value={type}
        label="Typ"
        inputProps={{ style: { fontSize: "0.9em" } }}
        appindarkmode={appInDarkMode.toString()}
        theme={theme}
      >
        {
          types.map((option, i) => (
            <MenuItem
              key={i}
              value={option.value}
              style={{ fontSize: "0.8rem" }}
            >
              {option.label}
            </MenuItem>
          ))}
      </CustomTextField>
    </>
  );
}

export default memo(TypeBoxComponent);
