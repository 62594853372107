async function getToDoSuggestions(relevantUserAssignments) {
  // var suggestionAssignments = relevantUserAssignments?.thisWeek.concat(
  //   relevantUserAssignments?.additional.daysToPrepare,
  //   relevantUserAssignments?.additional.extraDates
  // );
  var suggestionAssignments = [
    ...(relevantUserAssignments?.thisWeek || []),
    ...(relevantUserAssignments?.additional?.daysToPrepare || []),
    ...(relevantUserAssignments?.additional?.extra_dates || [])
  ];
  var filteredSuggestions = await suggestionAssignments?.filter(function (assignment) {
    return !assignment.is_a_to_do;
  });
  // console.log("suggesteAss:", suggestionAssignments, "filtered: ", filteredSuggestions)

  filteredSuggestions?.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    var c = new Date(a.due_date);
    var d = new Date(b.due_date);

    var e = a.priority;
    var f = b.priority;

    var g = a.ranking;
    var h = b.ranking;

    //multiple key order:
    return (
      f - e ||
      c - d ||
      g - h ||
      b.my_minutes - a.my_minutes
    );
  });
return filteredSuggestions
}
exports.getToDoSuggestions = getToDoSuggestions;
