import React, { memo, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { useTheme } from "@mui/styles";
import dayjs from "dayjs";
import StatusBox from "./CardElements/StatusBox";
import PriorityBox from "./CardElements/PriorityBox";
import AufwandBox from "./CardElements/AufwandBox";
import AufwandDoneBox from "./CardElements/AufwandDoneBox";
import { updateDataSet } from "../../../../_3HelperFunctions/_LocalUpdates/updateDataSet";
import { saveAssignmentChanges } from "../../../../_3HelperFunctions/_AssignmentFunctions/saveAssignmentChanges";
import { userConfirmDialogue } from "../../../../_3HelperFunctions/userConfirmDialogue";
import { deleteAssignmentFromDB } from "../../../../_3HelperFunctions/_AssignmentFunctions/deleteAssignmentFromDB";
import DaysToPrepareBox from "./CardElements/DaysToPrepareBox";
import DescriptionBox from "./CardElements/DescriptionBox";
import NotesBox from "./CardElements/NotesBox";
import EditLineOne from "./_EditAssignmentHelpers/EditLineOne";
import TypeBox from "./CardElements/TypeBox";
import CountingFactorBox from "./CardElements/CountingFactorBox";
import ExamTypeBox from "./CardElements/ExamTypeBox";
import AssignmentEditButtons from "./PopoverContentHelpers/AssignmentEditButtons";

function EditAssignmentStudentRestricted({
  assignment,
  restricted,
  handleClose,
  classView,
}) {
  // console.log("restr: ", restricted, "class: ", classView);
  const {
    schoolYearData,
    setUnresolvedSchoolYearData,
    currentUser,
    isALeader,
    isAnAdmin,
    userCourses,
    husibuechExamTypes,
  } = useAppContext();
  const theme = useTheme();
  const [previewAssignment, setPreviewAssignment] = useState(assignment);
  // console.log("previewAssignment: ", previewAssignment);
  /**
   * Update Elements
   */
  const type = previewAssignment.type;
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(previewAssignment.status);
  const [priority, setPriority] = useState(previewAssignment.priority);
  const [my_minutes_done, setWorkloadMinutesDone] = useState(
    parseInt(previewAssignment.my_minutes_done)
  );
  const [suggested_minutes, setWorkloadMinutesTotalSugg] = useState(
    parseInt(previewAssignment.suggested_minutes)
  );
  const [my_minutes, setMyMinutes] = useState(
    previewAssignment.my_minutes === -1
      ? previewAssignment.suggested_minutes
      : previewAssignment.my_minutes
  );
  const [suggested_days, setSuggestedDays] = useState(
    parseInt(previewAssignment.suggested_days)
  );
  const [my_days, setMyDays] = useState(parseInt(previewAssignment.my_days));
  const [assignmentName, setAssignmentName] = useState(assignment.name);
  const [dueDate, setDueDate] = useState(
    dayjs(previewAssignment.due_date).format("YYYY-MM-DD")
  );
  const selectedAssignmentCourse =
    previewAssignment.course_id
      ? userCourses.filter(
        (course) => course.id === previewAssignment.course_id
      )[0]
      : userCourses[0]
  const countingFactor = assignment.counting_factor ? parseFloat(assignment.counting_factor) : 0
  const [assignmentExamTypeId, setAssignmentExamTypeId] = useState(
    husibuechExamTypes[0].id
  );
  const [description, setDescription] = useState(
    previewAssignment.description === undefined
      ? ""
      : previewAssignment.description
  );
  const [notes, setNotes] = useState(
    previewAssignment.notes === undefined ? "" : previewAssignment.notes
  );

  /**
   * Processing
   */
  const [changesCount, setChangesCount] = useState(0);
  const [loadingCircle, setLoadingCircle] = useState(false);
  const [saveColor, setSaveColor] = useState("primary");
  /**
   * Errors
   */
  const workloadErr = {};
  const availablePrepDaysErr = {};

  //save process
  const save = async () => {
    try {
      setLoadingCircle(true);
      const updateValues = [
        { property: "status", value: status },
        { property: "priority", value: priority },
        { property: "name", value: assignmentName },
        { property: "type", value: type },
        type !== "personal" &&
        { property: "course_id", value: selectedAssignmentCourse.id },
        type === "exam" &&
        { property: "counting_factor", value: countingFactor },
        type === "exam" &&
        { property: "exam_type_id", value: assignmentExamTypeId },
        { property: "due_date", value: dueDate },
        !restricted && !classView &&
        { property: "suggested_minutes", value: suggested_minutes },
        !restricted && !classView &&
        { property: "suggested_days", value: suggested_days },
        { property: "my_minutes", value: my_minutes },
        { property: "my_minutes_done", value: my_minutes_done },
        { property: "my_days", value: my_days },
        { property: "description", value: description },
        { property: "notes", value: notes },
        // Add other property-value pairs as needed
      ]
      //connect to db
      const responseAssignment = await saveAssignmentChanges(
        assignment,
        updateValues,
        "update",
        currentUser
      );
      // console.log("server response: ", responseAssignment.real_edit_date, responseAssignment.real_edit_date_user)

      setSaveColor("success");
      setMessage("Änderungen gespeichert!");
      setLoadingCircle(false);


      //local update
      const updatedSchoolYearData = await updateDataSet(schoolYearData, responseAssignment, isALeader, "update")
      await setUnresolvedSchoolYearData(updatedSchoolYearData);

      // Set a timeout before resetting default values

      setTimeout(async () => {
        // console.log("we come here")
        setSaveColor("primary");
        setMessage("");
        setChangesCount(0);
      }, 750);
      setTimeout(async () => {
        handleClose();
      }, 1000);
    } catch (error) {
      console.log(error)
      setSaveColor("warning");
      setMessage(
        error.response?.data.message && isAnAdmin
          ? error.response.data.message
          : "Fehler beim Speichern"
      );
      setLoadingCircle(false);

      setTimeout(() => {
        setSaveColor("primary");
        setMessage("");
      }, 6000);
    }
  };

  const onClickDelete = (func) => {
    // set loading to true during submission process
    setLoadingCircle(true); //user confirmation interaction
    setTimeout(async () => {
      const answer = await userConfirmDialogue(type);
      if (!answer) {
        alert("Assignment wird beibehalten!");
        setLoadingCircle(false);
      } else {
        try { //delete the assignment!
          var responseAssignment = await deleteAssignmentFromDB(
            previewAssignment,
            currentUser
          );
          setSaveColor("success");
          setMessage("Bye bye... " + previewAssignment.name);
          setLoadingCircle(false);
          const updatedSchoolYear = await updateDataSet(
            schoolYearData,
            responseAssignment,
            isALeader,
            "delete"
          );
          setUnresolvedSchoolYearData(updatedSchoolYear);

          //set a timeout before default is reinstalled
          setTimeout(async () => {
            handleClose()
            setTimeout(async () => {
              // setOpenChangeManaged(true);
              setMessage("");
              setChangesCount(0);
            }, 300);
          }, 1500);


        } catch (error) {
          setSaveColor("warning");
          setMessage(
            error.response.data.message && isAnAdmin
              ? error.response.data.message
              : "Fehler beim Löschen"
          );
          setLoadingCircle(false);

          setTimeout(() => {
            setSaveColor("primary");
            setMessage("");
          }, 6000);
        }
      }
    }, 300);
  };

  return (
    <Grid
      container
      display="flex"
      justifyContent="flex-start"
      direction="column"
    // sx={{ width: "100%", background: "orange" }}
    >
      {/* 1st Line in Edit */}
      <EditLineOne
        previewAssignment={previewAssignment}
        assignment={assignment}
        changesCount={changesCount}
        setChangesCount={setChangesCount}
        assignmentName={assignmentName}
        setAssignmentName={setAssignmentName}
        type={type}
        dueDate={dueDate}
        setDueDate={setDueDate}
        status={status}
        restricted={restricted}
        classView={classView}
      />

      {/* Line 2 box */}
      <Grid item style={{ marginTop: "0.75em", marginBottom: "0.75em" }}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems={"center"}
          alignContent={"center"}
          direction="row"
          spacing={3}
        >
          {/* Assignment Type */}
          <Grid
            item
            xs={type !== "exam" ? 6 : 3}
          >
            <TypeBox
              restricted={true}
              assignment={assignment}
              previewAssignment={previewAssignment}
              type={type}
              setType={null}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
            />
          </Grid>
          {type === "exam" &&
            <Grid item xs={3} style={{
              marginLeft: "1.2em",
              // background: "orange",
            }}>
              <CountingFactorBox
                restricted={restricted}
                previewAssignment={previewAssignment}
                countingFactor={countingFactor}
                setCountingFactor={() => console.log("hi")}
                changesCount={changesCount}
                setChangesCount={setChangesCount}
                countingFactorErr={{}}
              />
            </Grid>}
          {type === "exam" && <Grid
            item
            xs={6}
            style={{
              marginLeft: "-1.2em",
              // background: "orange",
            }}
          >
            <ExamTypeBox
              restricted={restricted}
              assignment={assignment}
              previewAssignment={previewAssignment}
              assignmentExamTypeId={assignmentExamTypeId}
              setAssignmentExamTypeId={setAssignmentExamTypeId}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
              husibuechExamTypes={husibuechExamTypes}
            />
          </Grid>
          }
        </Grid>
      </Grid>

      {/* Official Workload */}
      <Grid
        item    >
        <Grid
          container
          spacing={1}
          direction="row"
          style={{
            marginTop: "0.25em",
          }}
        >
          {/* Aufwand */}
          <Grid
            item
            xs={4}
          >
            <AufwandBox
              classView={true} //restricts
              restricted={true}
              previewAssignment={previewAssignment}
              setPreviewAssignment={setPreviewAssignment}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
              my_minutes={my_minutes}
              setMyMinutes={setMyMinutes}
              suggested_minutes={suggested_minutes}
              setWorkloadMinutesTotalSugg={setWorkloadMinutesTotalSugg}
              my_minutes_done={my_minutes_done}
              setWorkloadMinutesDone={setWorkloadMinutesDone}
              type={type}
              status={status}
              setStatus={setStatus}
              workloadErr={{}}
              personalView={false}
            />
          </Grid>

          {/* Vorbereitung SuS */}
          <Grid
            item
            xs={4}
          >
            <DaysToPrepareBox
              classView={true} //restricts
              restricted={true}
              previewAssignment={previewAssignment}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
              my_days={my_days}
              setMyDays={setMyDays}
              suggested_days={suggested_days}
              setSuggestedDays={setSuggestedDays}
              availablePrepDaysErr={availablePrepDaysErr}
              workloadErr={workloadErr}
              type={type}
              personalView={false}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Official Description */}
      <Grid item xs={12} style={{ marginTop: "0.75em" }}>
        <Grid
          container
          justifyContent="space-between"
          direction="column"
          rowGap={2}
        // sx={{ width: "100%", background: "pink" }}
        >
          <DescriptionBox
            restricted={restricted}
            assignment={assignment}
            previewAssignment={previewAssignment}
            description={description===null?"":description}
            setDescription={setDescription}
            changesCount={changesCount}
            setChangesCount={setChangesCount}
          />
        </Grid>
      </Grid>

      {/* "Persönlich" Title container */}
      <Grid item style={{ marginTop: "0.75em", marginBottom: "0.75em", marginLeft: "0.2em" }}>
        <Typography
          variant="body2"
          color={theme.palette.primary.main}
          fontWeight={"bold"}
        >
          Persönlich:
        </Typography>
      </Grid>

      {/* status and priority */}
      <Grid item style={{ marginTop: "0.25em" }}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems={"center"}
          alignContent={"center"}
          direction="row"
        >
          <Grid
            item
            xs={5}
            style={{
              paddingLeft: "0.2em",
              // background: "green"
            }}
          >
            <StatusBox
              assignment={assignment}
              previewAssignment={previewAssignment}
              setPreviewAssignment={setPreviewAssignment}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
              status={status}
              setStatus={setStatus}
              my_minutes_done={my_minutes_done}
              setWorkloadMinutesDone={setWorkloadMinutesDone}
              my_minutes={my_minutes}
              personalView={true}
            />
          </Grid>
          <Grid
            item
            xs={5}
            style={{ marginLeft: "-1em" }}
          >
            <PriorityBox
              assignment={assignment}
              previewAssignment={previewAssignment}
              setPreviewAssignment={setPreviewAssignment}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
              priority={priority}
              setPriority={setPriority}
              personalView={true}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Personal Minutes Container */}
      <Grid
        item
      >
        <Grid
          container
          spacing={1}
          direction="row"
          style={{
            marginTop: "0.75em",
          }}
        >
          {/* MyAufwand */}
          <Grid
            item
            xs={4}
          >
            <AufwandBox
              classView={classView}
              restricted={restricted}
              previewAssignment={previewAssignment}
              setPreviewAssignment={setPreviewAssignment}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
              my_minutes={my_minutes}
              setMyMinutes={setMyMinutes}
              suggested_minutes={suggested_minutes}
              setWorkloadMinutesTotalSugg={setWorkloadMinutesTotalSugg}
              my_minutes_done={my_minutes_done}
              setWorkloadMinutesDone={setWorkloadMinutesDone}
              type={type}
              status={status}
              setStatus={setStatus}
              workloadErr={workloadErr}
              personalView={true}
            />
          </Grid>

          {/* Minutes Done */}
          <Grid
            item
            xs={4}
          >
            <AufwandDoneBox
              restricted={false}
              previewAssignment={previewAssignment}
              setPreviewAssignment={setPreviewAssignment}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
              my_minutes_done={my_minutes_done}
              setWorkloadMinutesDone={setWorkloadMinutesDone}
              my_minutes={my_minutes}
              setMyMinutes={setMyMinutes}
              suggested_minutes={suggested_minutes}
              status={status}
              setStatus={setStatus}
              workloadErr={workloadErr}
              personalView={true}
            />
          </Grid>

          {/* Vorbereitung SuS */}
          <Grid
            item
            xs={3}
          >
            <DaysToPrepareBox
              classView={classView}
              restricted={restricted}
              previewAssignment={previewAssignment}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
              my_days={my_days}
              setMyDays={setMyDays}
              suggested_days={suggested_days}
              setSuggestedDays={setSuggestedDays}
              availablePrepDaysErr={availablePrepDaysErr}
              workloadErr={workloadErr}
              type={type}
              personalView={true}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Comment Box */}
      <Grid item xs={12} style={{ marginTop: "0.75em" }}>
        <Grid
          container
          justifyContent="space-between"
          direction="column"
          rowGap={2}
        >
          <Grid
            item
            xs={
              restricted ? 12 : !restricted && type === "personal" ? 8 : 6
            }
            sx={{ marginTop: "0.3em" }}
          >
            <NotesBox
              restricted={restricted}
              previewAssignment={previewAssignment}
              notes={notes===null?"":notes}
              setNotes={setNotes}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Assignment Buttons */}
      <AssignmentEditButtons previewAssignment={previewAssignment} changes={changesCount > 0} message={message} saveColor={saveColor} deleteFunction={onClickDelete} saveFunction={save} loadingCircle={loadingCircle} type={type} />
    </Grid>
  );
}

export default memo(EditAssignmentStudentRestricted);
