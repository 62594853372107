export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user"));
  // console.log(user);

  if (user && user.token) {
    return {
      Authorization: "Bearer " + user.token,
      "Content-type": "application/json",
      'Accept': 'application/json'
    }; // for Spring Boot husibuech back-end
  } else {
    return {};
  }
}
