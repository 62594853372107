import styled from "@emotion/styled";
import { tooltipClasses } from "@mui/material";
import { Tooltip } from "@mui/material";

const StyledTooltipAvatar = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    left: -10,
    top: -16,
  },
});

export default StyledTooltipAvatar;
