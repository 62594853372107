export function sortHolidays(holidays, referenceDate) {
  // Filter holidays whose start_date is after the reference date
  const filteredHolidays = holidays.filter((holiday) => {
    return new Date(holiday.end_date) > new Date(referenceDate);
  });

  // Sort the filtered holidays by start_date
  const sortedHolidays = filteredHolidays.sort((a, b) => {
    return new Date(a.start_date) - new Date(b.start_date);
  });

  return sortedHolidays;
}
