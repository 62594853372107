import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import { Typography } from "@mui/material";
// import ErrorBox from "../CardElements/ErrorBox";

function LineInfo() {
    return (
        <Grid item xs={12} style={{ marginLeft: "0.25em" }}>
            <Grid
                container
                justifyContent="flex-start"
                direction="row"
            // spacing={1}
            >
                <Typography variant="body2">
                    Du willst für deine Klasse Hausaufgaben und Prüfungen eintragen?
                    <br />
                    Kontaktiere husibuech.ch und werde Referenzschüler*in
                    (info@husibuech.ch)
                </Typography>
            </Grid>
        </Grid>
    );
}

export default memo(LineInfo);
