const { saveAssignmentChanges } = require("./saveAssignmentChanges");

async function deleteAssignmentFromDB(previewAssignment, currentUser) {
  const updateValues = [{ property: "visible", value: false }
  ]
  const responseAssignment = await saveAssignmentChanges(previewAssignment, updateValues,
    "simpleAssignmentEditPopover", //origin
    currentUser
  );

  return responseAssignment;
}
exports.deleteAssignmentFromDB = deleteAssignmentFromDB;
