import React, { memo, useEffect, useState } from "react";

import MenuTopBarNavigation from "./MenuTopBarNavigation";
import MenuTopBarRight from "./MenuTopBarRight";
import MenuTopBarTitle from "./MenuTopBarTitle";

function MenuTopBarAuthorized() {
  const [flexValue, setFlexValue] = useState("33%");
  const [flexValue1, setFlexValue1] = useState("33%");
  const [flexValue2, setFlexValue2] = useState("33%");

  const [currentScreenWidth, setCurrentScreenWidth] = useState(
    window.innerWidth
  );


  useEffect(() => {
    const updateFlexValue = () => {
      const screenWidth = window.innerWidth;
      // console.log(screenWidth);
      setCurrentScreenWidth(screenWidth);
      if (screenWidth <= 800) {
        if (screenWidth <= 400) {
          setFlexValue("50%");
          setFlexValue1("50%");
          setFlexValue2("50%");
        } else {
          setFlexValue("50%");
          setFlexValue1("50%");
          setFlexValue2("50%");
        }
      } else {
        setFlexValue("33%");
        setFlexValue1("33%");
        setFlexValue2("33%");
      }
    };

    updateFlexValue(); // Call the update function on mount

    window.addEventListener("resize", updateFlexValue); // Event listener for window resize

    return () => {
      window.removeEventListener("resize", updateFlexValue); // Clean up the event listener on component unmount
    };
  }, []);

  return (
    <div
      style={{
        height: "4em",
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        // backgroundColor: "red",
        maxWidth: "100%", // Set maximum width to 90% of the viewport width
        overflowX: "hidden", // Hide horizontal overflow
        overflowY: "hidden",
      }}
    >
      {/* LEFT item */}
      <div
        role="left-item"
        style={{
          flex: `0 0 ${flexValue}`,
          // backgroundColor: "orange",
        }}
      >
        <MenuTopBarNavigation />
      </div>

      {/* CENTER item */}
      <div
        role="center-item"
        style={{
          height: "100%", // Set height to 100%
          flex: `0 0 ${flexValue1}`,
          overflow: "hidden",
          display: `${flexValue1 === "50%" ? "" : "flex"}`,
          marginLeft: `${flexValue1 === "50%" ? "0.1em" : "0"}`,
          justifyContent: "flex-start", // Align items along the main axis to the start (left)
          alignItems: "center",
          WebkitOverflowScrolling: "touch",
          WebkitScrollbar: {
            width: "0 !important",
            height: "0 !important",
          },
          scrollbarWidth: "thin",
          scrollbarColor: "transparent transparent",
          marginInline: "-17px", // Adjust this value to hide the x-axis scrollbar
        }}
      >
        <MenuTopBarTitle flexValue={flexValue} />
      </div>

      {/* RIGHT item */}
      <div
        role="right-item"
        style={{
          flex: `0 0 ${flexValue2}`,
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          scrollbarWidth: "thin",
          scrollbarColor: "transparent transparent",
          // backgroundColor: "green",
          justifyContent: "flex-end",
          alignItems: "center",
          display: "flex",
        }}
      >
        <MenuTopBarRight />
        <style>
          {`
          @media (max-width: 800px) {
            div[role="left-item"] {
              display: ${currentScreenWidth < 800 ? "none" : "flex"};
            },
          }
        `}
        </style>
      </div>
    </div>
  );
}
export default memo(MenuTopBarAuthorized);