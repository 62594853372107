import { useState } from "react";
import { useTheme } from "@mui/styles";
import { Button, Grid } from "@mui/material";
import AddToDoForm from "./AddToDoForm";

export default function AddOwnToDoButton({ text, elements, updateElements }) {
  const theme = useTheme();
  const [showForm, setShowForm] = useState(false);

  const startShowForm = () => {
    setShowForm(!showForm);
  };
  const hideForm = () => {
    setShowForm(false);
  };

  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Button
          variant="contained"
          onClick={startShowForm}
          style={{
            backgroundColor: theme.palette.levels.green,
            marginLeft: "0.5rem",
            marginBottom: "0.5rem",
            fontSize: "0.7rem",
          }}
        >
          {text}
        </Button>
      </Grid>
      <Grid item>
        {showForm && <AddToDoForm DoNotShowForm={hideForm} elements={elements} updateElements={updateElements} />}
      </Grid>
    </Grid>

  );
}
