import { memo, useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import PrivateRoute from "./.Routing/PrivateRoute";
import DashboardTheme from "../4DashboardPage/DashboardTheme";
import SemesterView from "../7SemesterOverview/SemesterView";
import Profile from "../3ProfilePage/Profile";
import UploadPage from "../8UploadPage/UploadPage";
import AdminPage from "../6AdminPage/AdminPage";
import NotFound from "../1GeneralPages/NotFound";
import EmailVerify from "../2LoginAndAuthentication/EmailVerify/EmailVerify";
import Login from "../2LoginAndAuthentication/Login/Login";
import Nachkontrolle from "../5Nachkontrolle/Nachkontrolle";
import LoginStory from "../2LoginAndAuthentication/Login/LoginStory";

function AppRoutes() {
  const {isAuthenticated, isALeader, isAnAdmin, isMobile } = useAppContext();

  return (
    <Routes>
      {/* Login */}
      {/* <Route path={"/login"} element={isMobile?<Login />:<LoginStory/>} /> */}
      <Route path={"/login"} element={isMobile?<Login />:<LoginStory/>} />
      <Route path={"/api/user/verify/:id/:code"} element={<EmailVerify />} />

      {/* Dashboard */}
      <Route path={"/"} element={<PrivateRoute />}>
        <Route path={"/"} element={<DashboardTheme />} />
      </Route>
      <Route path={""} element={<PrivateRoute />}>
        <Route path={""} element={<Navigate replace to="/" />} />
      </Route>
      <Route path={"/dashboard"} element={<PrivateRoute />}>
        <Route path={"/dashboard"} element={<Navigate replace to="/" />} />
      </Route>
      
      {/* SemesterPlan */}
      <Route path={"/semesterplan"} element={<PrivateRoute />}>
        <Route path={"/semesterplan"} element={<SemesterView />} />
      </Route>

      {/* Nachkontrolle */}
      <Route path={"/nachkontrolle"} element={<PrivateRoute />}>
        <Route path={"/nachkontrolle"} element={<Nachkontrolle />} />
      </Route>

      {/* Upload (only Admins and Teachers) */}
      <Route path={"/upload"} element={<PrivateRoute />}>
        {isAuthenticated && (isALeader) ? (
          <Route path={"/upload"} element={<UploadPage />} />
        ) : (
          <Route path={"/upload"} element={<Navigate replace to="*" />} />
        )}
      </Route>

      {/* Settings (only Admins) */}
      <Route path={"/einstellungen"} element={<PrivateRoute />}>
        {isAuthenticated && isAnAdmin ? (
          <Route path={"/einstellungen"} element={<AdminPage />} />
        ) : (
          <Route
            path={"/einstellungen"}
            element={<Navigate replace to="*" />}
          />
        )}
      </Route>

      {/* Profile */}
      <Route path={"/profil"} element={<PrivateRoute />}>
        <Route path={"/profil"} element={<Profile />} />
      </Route>

      {/* Everything else */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
export default memo(AppRoutes);
