import { memo, useState } from "react";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import appService from "../../../_6Services/app.service";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RegisterCoreCourse() {
  const { husibuechCoreCourses, setHusibuechCoreCourses, husibuechTeachers } =
    useAppContext();

  const [change, setChange] = useState(false);
  const [success, setSuccess] = useState(false);

  const [localLoading, setLocalLoading] = useState(false);

  const [tutor, setTutor] = useState(undefined);
  const [setTutorErr] = useState({});

  const [message, setMessage] = useState("");
  const [coreCourseName, setCoreCourseName] = useState("");
  const [coreCourseNameErr, setCoreCourseNameErr] = useState({});
  const theme = useTheme();

  const onChangeCoreCourseName = (e) => {
    setCoreCourseNameErr({});
    setCoreCourseName(e.target.value);
    setChange(
      e.target.value.length && e.target.value.length <= 10 ? true : false
    );
  };

  // console.log("teachers", husibuechTeachers)

  const handleChangeTutor = (e) => {
    setTutor(e.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      registerCoreCourse();
    } else {
      setTimeout(() => {
        // go back to normal
        setMessage("");
        setChange(false);
      }, 4000);
    }
  };

  const formValidation = () => {
    let tutorErr = {};
    let coreCourseNameErr = {};

    let isValid = true;

    if (
      husibuechCoreCourses &&
      husibuechCoreCourses.find((cc) => cc.name === coreCourseName)
    ) {
      coreCourseNameErr.notUnique = "Diese Klasse gibt es bereits!";
      isValid = false;
    }

    if (coreCourseName.length > 10) {
      coreCourseNameErr.tooLong = "max 10 Zeichen!";
      isValid = false;
    }
    // allow for unassigned tutors!
    // if (!tutor) {
    //   tutorErr.empty = "Kein Klassenlehrer";
    //   isValid = false;
    // }

    setTutorErr(tutorErr);
    setCoreCourseNameErr(coreCourseNameErr);

    return isValid;
  };
  const updateLocalSystemData = (newCoreCourse) => {
    let updatedSystemData = husibuechCoreCourses;
    updatedSystemData = updatedSystemData.concat(newCoreCourse);
    setHusibuechCoreCourses(updatedSystemData);
  };
  const registerCoreCourse = () => {
    setLocalLoading(true);
    const newCoreCourse = {
      name: coreCourseName,
      tutorId: tutor ? tutor.id : null,
    };
    // console.log(newCoreCourse)
    appService
      .registerNewCoreCourse(newCoreCourse)
      .then(
        (response) =>
          updateLocalSystemData(response.data) +
          console.log(response.data) +
          setLocalLoading(false) +
          setSuccess(true) +
          setMessage("Update erfolgreich") +
          setCoreCourseName("") +
          setTutor(undefined) +
          setTimeout(() => {
            setSuccess(false);
            setMessage("");
            setTutorErr({});
            setCoreCourseNameErr({});
            setChange(false);
          }, 2500)
      )
      .catch((error) => {
        console.log(error);
        setLocalLoading(false);
        setMessage(
          error.response.data.message
            ? error.response.data.message
            : "ungültige Angaben"
        );
        setTimeout(() => {
          setMessage("");
          setTutorErr({});
          setCoreCourseNameErr({});
          setChange(false);
        }, 4000);
      });
  };

  return (
    <Card>
      <CardHeader
        title="Neue Klasse"
        subheader="hinzufügen"
        subheaderTypographyProps={{ color: theme.palette.levels.white }}
        sx={{
          color: theme.palette.levels.white,
          backgroundColor: theme.palette.levels.blue,
        }}
      />
      <Divider />
      <CardContent>
        {/* Box container */}
        <Grid
          container
          direction="row"
          spacing={3}
          rowGap={6}
          justifyContent="space-between"
        >
          {/* Form item */}
          <Grid item md={6} ss={6} xs={12}>
            <form id="registerCoreCourse" onSubmit={onSubmit}>
              {/* Form container including the button */}
              <Grid
                container
                direction="column"
                spacing={3}
                justifyContent="center"
              >
                <Grid item md={6} xs={12}>
                  <InputLabel id="demo-multiple-name-label">
                    Name für neue Klasse *
                  </InputLabel>
                  <TextField
                    type="name"
                    placeholder="Name * (Bsp: G20a)"
                    fullWidth
                    name="name"
                    variant="outlined"
                    required
                    autoFocus={false}
                    value={coreCourseName}
                    onChange={onChangeCoreCourseName}
                  />
                </Grid>
                {Object.keys(coreCourseNameErr).map((key) => {
                  return (
                    <Grid
                      item
                      marginLeft={3}
                      md={12}
                      xs={12}
                      className="alert alert-danger"
                      role="alert"
                    >
                      {coreCourseNameErr[key]}
                    </Grid>
                  );
                })}
                <Grid item md={6} xs={12}>
                  <Grid item md={6} xs={12}>
                    <InputLabel id="demo-multiple-name-label">
                      Klassenlehrperson *
                    </InputLabel>
                    <Select
                      displayEmpty
                      // required
                      onChange={handleChangeTutor}
                      input={<OutlinedInput />}
                      value={tutor ? tutor : ``}
                      renderValue={(selected) => {
                        return !selected ? (
                          <em>Wähle aus</em>
                        ) : (
                          selected.last_name +
                            " " +
                            selected.first_name +
                            " (" +
                            tutor.abbreviation +
                            ")"
                        );
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        <em>bereits erfasst</em>
                      </MenuItem>
                      {husibuechTeachers &&
                        husibuechTeachers.length > 0 &&
                        husibuechTeachers.map((tutor) => (
                          <MenuItem key={tutor.id} value={tutor}>
                            {tutor.last_name +
                              " " +
                              tutor.first_name +
                              " (" +
                              tutor.abbreviation +
                              ")"}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>

          {/*right item*/}
          <Grid item md={6} ss={6} xs={12}>
            <Grid container direction="column" spacing={1} justifyContent="flex-start">
              {/* Stystem information item */}
              <Grid item md={6} ss={6} xs={12}>
                <InputLabel id="demo-multiple-name-label">Klassen *</InputLabel>
                <Select
                  displayEmpty
                  onChange={null}
                  input={<OutlinedInput />}
                  value={``}
                  renderValue={(selected) => {
                    return <em>bereits im System vorhanden</em>;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>bereits erfasst</em>
                  </MenuItem>
                  {husibuechCoreCourses &&
                    husibuechCoreCourses.length > 0 &&
                    husibuechCoreCourses.map((coreCourse) => (
                      <MenuItem key={coreCourse.id} value={coreCourse}>
                        {coreCourse.name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              {/* Button item */}
              <Grid item md={6} xs={12}>
                <Button
                  style={{
                    visibility: !localLoading && !change ? "hidden" : "visible",
                  }}
                  variant="contained"
                  color="primary"
                  // type="submit"
                  onClick={onSubmit}
                  className="button-block"
                  disabled={localLoading}
                >
                  Erfassen
                </Button>

                {localLoading && <LinearProgress />}
              </Grid>
              {message && success && (
                <Grid item>
                  <Typography className="alert alert-success" role="alert">
                    {message}
                  </Typography>
                </Grid>
              )}
              {message && !success && (
                <Grid item>
                  <Typography className="alert alert-danger" role="alert">
                    {message}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  );
}
export default memo(RegisterCoreCourse);
