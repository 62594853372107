import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import TypeBox from "../CardElements/TypeBox";
import StatusBox from "../CardElements/StatusBox";
import PriorityBox from "../CardElements/PriorityBox";
//L for leader
function EditLineThreeLPers({ previewAssignment, assignment, changesCount, setChangesCount, setPreviewAssignment, status, setStatus, priority, setPriority, my_minutes_done, setWorkloadMinutesDone, my_minutes }) {
  return (
    <Grid item style={{ marginTop: "0.75em"}}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems={"center"}
        alignContent={"center"}
        direction="row"
        
      // style={{ marginTop: "0.75em", marginBottom:"0.75em" }}

      // sx={{ width: "100%", background: "orange" }}
      >
        <Grid
          item
          xs={4}
          style={{
            paddingLeft: "0.2em",
            // background: "green"
          }}
        >
          <StatusBox
            assignment={assignment}
            previewAssignment={previewAssignment}
            setPreviewAssignment={setPreviewAssignment}
            changesCount={changesCount}
            setChangesCount={setChangesCount}
            status={status}
            setStatus={setStatus}
            my_minutes_done={my_minutes_done}
            setWorkloadMinutesDone={setWorkloadMinutesDone}
            my_minutes={my_minutes}
            //for myStatus:
            personalview={false}
          />
        </Grid>
        <Grid
          item
          xs={4}
        // sx={{ background: "orange" }}
        >
          <PriorityBox
            assignment={assignment}
            previewAssignment={previewAssignment}
            setPreviewAssignment={setPreviewAssignment}
            changesCount={changesCount}
            setChangesCount={setChangesCount}
            priority={priority}
            setPriority={setPriority}
            //for myStatus:
            personalview={false}
          />
        </Grid>
      </Grid>
    </Grid>

  );
}

export default memo(EditLineThreeLPers);
