import React, { memo } from "react";
import TypeBoxComponent from "./TypeBoxComponent";

function TypeBox({
  restricted,
  type,
  assignment,
  previewAssignment,
  changesCount,
  setChangesCount,
  setType,
}) {
  const change = (e) => {
    let changesCountCopy = changesCount;
    // console.log(type)
    if (e.target.value === previewAssignment.type) {
      //we have gone back to default
      setChangesCount(changesCountCopy - 1);
    } else if (assignment.type !== type) {
      // this is not our first change
      // do noting
      // console.log("lazy")
    } else {
      // this is our first change
      // console.log("first")
      setChangesCount(changesCountCopy + 1);
    }
    // console.log("we set the type: ", e.target.value)

    setType(e.target.value);
  };
  return (
    <>
      <TypeBoxComponent restricted={restricted} type={type} change={change} />
    </>
  );
}

export default memo(TypeBox);
