import { memo, useState } from "react";
import styles from "./styles.module.css";
import { useTheme } from "@mui/styles";
import { CircularProgress, Grid } from "@mui/material";
import authService from "../../../_6Services/auth.service";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import AppStarter from "../Login/LoginHelpers/AppStarter";
import MyButton from "../Login/LoginHelpers/MyButton";
import FeedbackMessage from "../Login/LoginHelpers/FeedbackMessage";
import LoginBox from "../../../_4GeneralHelpers/1_Boxes/LoginBox";
// import emailSender from "./emailSender";

function SignUpForm({ flipCard }) {
  const theme = useTheme();
  const { verificationCodeStore, setVerificationCodeStore } = useAppContext();
  const [email, setEmail] = useState(""); //email
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userPasswordConfirmation, setUserPasswordConfirmation] = useState("");

  const [emailErr, setEmailErr] = useState({});
  const [userFirstNameErr, setUserFirstNameErr] = useState({});
  const [userLastNameErr, setUserLastNameErr] = useState({});
  const [userPasswordErr, setUserPasswordErr] = useState({});
  const [userPasswordConfirmationErr, setUserPasswordConfirmationErr] =
    useState({});

  const [error, setError] = useState([]);
  const [msg, setMsg] = useState("");

  const onChangeEmail = (e) => {
    setError([]);
    if (e.target.value.length < 40) {
      setEmailErr({});
    }
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setUserPasswordErr({});
    setUserPasswordConfirmationErr({});
    setUserPassword(e.target.value);
  };
  const onChangePasswordConfirmation = (e) => {
    setUserPasswordConfirmationErr({});
    setUserPasswordErr({});
    setUserPasswordConfirmation(e.target.value);
  };

  const onChangeFirstName = (e) => {
    if (e.target.value.length < 30) {
      setUserFirstNameErr({});
    }
    setFirstName(e.target.value);
  };
  const onChangeLastName = (e) => {
    if (e.target.value.length < 30) {
      setUserLastNameErr({});
    }
    setLastName(e.target.value);
  };

  const formValidation = () => {
    let emailErr = {};
    let userPasswordErr = {};
    let userFirstNameErr = {};
    let userLastNameErr = {};
    let isValid = true;

    //design the desired rules for form completion here:
    if (email === "") {
      emailErr.userNameEmpty = "E-Mail eingegeben!";
      isValid = false;
    } else if (!email.includes("@sluz")) {
      emailErr.username = "E-mail muss eine SLUZ-Mailadresse sein";
      isValid = false;
    }
    // if (!email.includes("@sluz.ch")) {
    //   emailErr.username = "Verwende eine @sluz Adresse";
    //   isValid = false;
    // }
    else if (email.length >= 40) {
      emailErr.userNameTooLong = "E-Mail zu lange!";
      isValid = false;
    }

    if (userPassword === "") {
      userPasswordErr.userPasswordEmpty = "Passwort eingegeben!";
      isValid = false;
    } else if (userPassword.length < 7) {
      userPasswordErr.userPasswordTooShort = "längeres Passwort eingeben";
      isValid = false;
    }

    if (userPassword !== userPasswordConfirmation) {
      userPasswordConfirmationErr.userPasswordNotConfirmed =
        "Passwörter nicht identisch!";
      isValid = false;
    }
    if (firstName === "") {
      userFirstNameErr.firstNameEmpty = "Vornamen eingegeben!";
      isValid = false;
    } else if (firstName.length >= 30) {
      userFirstNameErr.firstNameTooLong = "Vorname zu lange!";
      isValid = false;
    }

    if (lastName === "") {
      userLastNameErr.lastNameEmpty = "Nachnamen eingegeben!";
      isValid = false;
    } else if (lastName.length >= 30) {
      userLastNameErr.lastNameTooLong = "Nachname zu lange!";
      isValid = false;
    }
    setEmailErr(emailErr);
    setUserPasswordErr(userPasswordErr);
    setUserPasswordConfirmationErr(userPasswordConfirmationErr);
    setUserFirstNameErr(userFirstNameErr);
    setUserLastNameErr(userLastNameErr);

    return isValid;
  };

  const handleRegister = () => {
    const newUser = {
      email: email,
      password: userPassword,
      firstName: firstName,
      lastName: lastName,
    };

    try {
      setMsg("");
      authService.register(newUser).then((response) =>
        response.status === 409
          ? //error
            // console.log(response.responseText) +
            setError([response.responseText]) + setVerificationCodeStore("")
          : response.status === 500
          ? // console.log(response) +
            setError(["Problem mit dem Passwort, wähle neues Passwort"]) +
            setVerificationCodeStore("")
          : response.status === 401
          ? // console.log(response) +
            setError([response.responseText]) + setVerificationCodeStore("")
          : response.status === 400
          ? // console.log(response) +
            setError(["Serverprobleme versuche es später nochmals"]) +
            setVerificationCodeStore("")
          : //success
            setMsg("Nachricht an die Mailaddresse geschickt") +
            // console.log(response.message) +
            setVerificationCodeStore(response.verificationCode)
      );
    } catch (err) {
      console.error(err);
      setError(["Server Probleme"]);
      setVerificationCodeStore("");
    }
  };

  const submit = async () => {
    // e.preventDefault();
    const isValid = formValidation();

    if (isValid) {
      setVerificationCodeStore("waiting");
      handleRegister();
    }
  };

  return (
    <LoginBox>
      <Grid
        item
        xs={3}
        ss={4}
      >
        <AppStarter flipCard={flipCard} loginPage={false} />
      </Grid>
      <Grid
        item
        xs={12}
        ss={8}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor: "green",
          borderTopRightRadius: "15px",
          borderBottomRightRadius: "15px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          style={{
            width: "100%",
            // height: "100%",
            // flex: 1,
            display: "flex",
            flexDirection: "center",
            alignItems: "center",
            justifyContent: "center",
            // backgroundColor: "orange",
          }}
        >
          <Grid
            item
            style={{
              width: "90%",
              justifyContent: "center",
              display: "flex",
              // backgroundColor: "blue",
            }}
          >
            <h1>
              {verificationCodeStore.length === 0
                ? "Registrierung"
                : "Mailadresse Verifizieren"}
            </h1>
          </Grid>
          <Grid
            item
            style={{
              width: "90%",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              // justifyContent: "center",
              // backgroundColor: theme.palette.background.default,
            }}
          >
            <Grid
              container
              direction="column"
              // spacing={3}
              justifyContent="center"
              style={{
                width: "100%",
                // height: "100%",
                // flex: 1,
                display: "flex",
                flexDirection: "center",
                alignItems: "center",
                justifyContent: "center",
                // backgroundColor: "yellow",
              }}
            >
              {verificationCodeStore.length === 0 ? (
                <>
                  <Grid
                    item
                    style={{
                      width: "100%",
                      // backgroundColor: "red",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Vorname"
                      name="firstName"
                      onChange={onChangeFirstName}
                      value={firstName}
                      required
                      className={styles.input}
                    />
                    {Object.keys(userFirstNameErr).map((key, i) => {
                      return (
                        <div
                          key={i}
                          style={
                            {
                              // backgroundColor: "red",
                            }
                          }
                          className={`${styles.error_msg} ${styles.visible}`}
                        >
                          {userFirstNameErr[key]}
                        </div>
                      );
                    })}
                  </Grid>

                  <Grid
                    item
                    style={{
                      width: "100%",
                      // backgroundColor: "red",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Nachname"
                      name="lastName"
                      onChange={onChangeLastName}
                      value={lastName}
                      required
                      className={styles.input}
                    />
                    {Object.keys(userLastNameErr).map((key, i) => {
                      return (
                        <div
                          key={i}
                          className={`${styles.error_msg} ${styles.visible}`}
                        >
                          {userLastNameErr[key]}
                        </div>
                      );
                    })}
                  </Grid>

                  <Grid
                    item
                    style={{
                      width: "100%",
                      // backgroundColor: "red",
                    }}
                  >
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={onChangeEmail}
                      value={email}
                      required
                      className={styles.input}
                    />
                    {Object.keys(emailErr).map((key, i) => {
                      return (
                        <div
                          key={i}
                          className={`${styles.error_msg} ${styles.visible}`}
                          role="alert"
                        >
                          {emailErr[key]}
                        </div>
                      );
                    })}
                  </Grid>

                  <Grid
                    item
                    style={{
                      width: "100%",
                      // backgroundColor: "red",
                    }}
                  >
                    <input
                      type="password"
                      placeholder="Passwort"
                      name="userPassword"
                      onChange={onChangePassword}
                      value={userPassword}
                      required
                      className={styles.input}
                    />
                    {Object.keys(userPasswordErr).map((key, i) => {
                      return (
                        <div
                          item
                          key={i}
                          className={`${styles.error_msg} ${styles.visible}`}
                        >
                          {userPasswordErr[key]}
                        </div>
                      );
                    })}
                  </Grid>

                  <Grid
                    item
                    style={{
                      width: "100%",
                      // backgroundColor: "red",
                    }}
                  >
                    <input
                      type="password"
                      placeholder="Passwort wiederholen"
                      name="userPassword"
                      onChange={onChangePasswordConfirmation}
                      value={userPasswordConfirmation}
                      required
                      className={styles.input}
                    />
                    {Object.keys(userPasswordConfirmationErr).map((key, i) => {
                      return (
                        <div
                          item
                          key={i}
                          className={`${styles.error_msg} ${styles.visible}`}
                        >
                          {userPasswordConfirmationErr[key]}
                        </div>
                      );
                    })}
                  </Grid>
                </>
              ) : (
                <div>
                  <CircularProgress
                    style={{
                      color: theme.palette.levels.green,
                      marginTop: "1.5rem",
                      marginBottom: "1.5rem",
                    }}
                    size={"3.25rem"}
                  />
                </div>
              )}
            </Grid>
          </Grid>
          <Grid
            style={{
              width: "90%",
              // height:"100%",
              justifyContent: "center",
              display: "flex",
              marginTop: "1.5em",
              marginBottom: "1.5em",
              // backgroundColor: "blue",
            }}
          >
            <MyButton
              job={submit}
              text={"registrieren"}
              disabled={verificationCodeStore.length > 0}
            />
          </Grid>
          <FeedbackMessage errors={error} msg={msg} />
        </Grid>
      </Grid>
    </LoginBox>
  );
}

export default memo(SignUpForm);
