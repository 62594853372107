import React, { memo } from "react";
import NameBoxComponent from "./NameBoxComponent";

function NameBox({
  restricted,
  assignmentName,
  previewAssignment,
  changesCount,
  setChangesCount,
  setAssignmentName,
}) {
  const change = (e) => {
    let changesCountCopy = changesCount;
    if (
      e.target.value === previewAssignment.name ||
      (e.target.value === "" && !previewAssignment.name)
    ) {
      //we have gone back to default
      setChangesCount(changesCountCopy - 1);
    } else if (previewAssignment.name !== assignmentName) {
      // not our first change
      // do noting
    } else {
      // its our first change
      setChangesCount(changesCountCopy + 1);
    }
    setAssignmentName(e.target.value);
  };
  return (
    <>
      {/* Assignment Name */}
      <NameBoxComponent
        restricted={restricted}
        assignmentName={assignmentName}
        change={change}
      />
    </>
  );
}

export default memo(NameBox);
