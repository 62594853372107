import React, { memo } from "react";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";

import MiddleBox from "../../../../_4GeneralHelpers/1_Boxes/MiddleBox";
import AssignmentAddCard from "./AssignmentAddCard";
import BoxHeader from "../../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";

function AssignmentAddBox({ flipAssignment, setFlipAssignment, type, date, handleClose, boxType, single }) {
  const { currentUser, isALeader,isAReferenceStudent } = useAppContext();

  return (
    <>
      <BoxHeader
        title={"Neues Assignment erfassen"}
        options={!type && !single ? ["flipAssignment"] : []}
        flipAssignment={flipAssignment} setFlipAssignment={setFlipAssignment}
      />
      <MiddleBox>
        {/* for authorized users -> can add homeworks and exams for course */}
        {currentUser && <AssignmentAddCard handleClose={handleClose} date={date} restricted={!(isALeader || isAReferenceStudent)} setFlipAssignment={setFlipAssignment} boxType={boxType} single={single}/>}
      </MiddleBox>
    </>
  );
}
export default memo(AssignmentAddBox);
