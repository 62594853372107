import { styled, Paper } from "@mui/material";

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "#1A2027"
      : theme.palette.background.paper,
  ...theme.typography.body2,
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "#1A2027" : theme.palette.background.paper,
  ...theme.typography.body2,
  padding: theme.spacing(5),
  textAlign: "left",
  color: theme.palette.text.primary,
  borderRadius: 0,
  position: "fixed",
  zIndex: 995,
  width: "100%",
  height: "8em",
  paddingLeft: "3em",
  paddingRight: "3em",
  paddingBottom: "1em",
  // backgroundColor: "blue"
}));
