import { Draggable } from "react-beautiful-dnd";
import React from "react";
import styled from "styled-components";
import { Grid, ListItemText } from "@mui/material";
// import PrioritySeverityPill from "../_SeverityPills/PrioritySeverityPill";
import moment from "moment";
import { memo } from "react";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";
import { useTheme } from "@mui/styles";
import DraggableWorkboardAssignmentAvatar from "./DraggableWorkboardAssignmentAvatar";
import AssignmentDistributionStats from "../../AddAssignmentBox/AssignmentDistributionStats";
const DragItem = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 4px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: ${(props) =>
    props.isDragging
      ? props.theme.palette.primary.light
      : props.theme.palette.background.paper};
  margin: 6px 6px 0px 0px;
  display: grid;
  grid-gap: 20px;
  flex-direction: column;
`;

function DraggableWorkboardAssignment({ assignment, index }) {
  const { isAStudent, isATeacher, selectedCourse, userCourses, isMobile } =
    useAppContext();

  const theme = useTheme();
  // console.log(assignment);

  return (
    <Draggable draggableId={assignment.assignment_id.toString()} index={index}>
      {(provided, snapshot) => {
        return (
          <DragItem
            key={assignment.assignment_id}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            snapshot={snapshot}
            isDragging={snapshot.isDragging}
            theme={theme}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginLeft: 1,
                marginRight: 1,
              }}
            >
              <div style={{ flex: 1, marginRight: "0.3em" }}>
                <DraggableWorkboardAssignmentAvatar assignment={assignment} />
              </div>
              <div style={{ flex: 3 }}>
                <div>
                  <div>
                    {assignment.name}
                    <br />
                    {assignment.type !== "personal" && isAStudent
                      ? ` (${assignment.tag})`
                      : assignment.type !== "personal" && isATeacher
                      ? ` (${assignment.course_name})`
                      : assignment.type !== "personal" && isATeacher
                      ? ` (${assignment.tag})`
                      : null}
                    {isATeacher &&
                    userCourses?.some(
                      (course) => course.id === assignment.course_id
                    ) ? (
                      <>
                        &nbsp;
                        <AssignmentDistributionStats assignment={assignment} />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </DragItem>
        );
      }}
    </Draggable>
  );
}

export default memo(DraggableWorkboardAssignment);
