import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { useTheme } from "@emotion/react";


const marks = [
  {
    value: 0,
    label: "0h",
  },
  {
    value: 120,
    label: "2h",
  },
  {
    value: 240,
    label: "4h",
  },
  {
    value: 360,
    label: "6h",
  },
  {
    value: 480,
    label: "8h",
  },
  {
    value: 600,
    label: "10h",
  },
];

function valueLabelFormat(value) {
  let scaledValue = value;

  return `${
    scaledValue === 0 ? "0 min" :
    Math.floor(scaledValue / 60) !== 0 ? Math.floor(scaledValue / 60) : ""
  } ${Math.floor(scaledValue / 60) !== 0 ? "h" : ""} ${
    scaledValue % 60 !== 0 ? scaledValue % 60 : ""
  } ${scaledValue % 60 !== 0 ? "min" : ""}`;
}

function calculateValue(value) {
  return value;
}

export default function RatingSlider({ value, setValue }) {
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    if (typeof newValue === "number") {
      setValue(newValue);
    }
  };

  return (
    <Box sx={{ width: 300 }}>
      <Typography id="non-linear-slider" gutterBottom sx={{ ml: 2, flex: 1, marginLeft: 0 }}>
        Total letzte Woche: {valueLabelFormat(calculateValue(value))}
      </Typography>
        <Slider
          sx={{marginLeft: 1}}
          value={value}
          min={0}
          step={10}
          max={600}
          scale={calculateValue}
          getAriaValueText={valueLabelFormat}
          valueLabelFormat={valueLabelFormat}
          onChange={handleChange}
          valueLabelDisplay="auto"
          marks={marks}
          aria-labelledby="non-linear-slider"
          color="primary"
        />
    </Box>
  );
}
