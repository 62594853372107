const { getDict } = require("./getDict");

const getRelevantAssignmentsByDay = async (
  personalAssignments,
  classAssignments,
  referenceDate
) => {
  // console.log("pers", personalAssignments )
  const relevantPersonalAssignmentsByDay = await getDict(
    personalAssignments,
    "personal",
    referenceDate
  );

  const relevantClassAssignmentsByDay = await getDict(
    classAssignments,
    "class",
    referenceDate
  );

  const relevantExtraDateAssignmentsByDay = await getDict(
    personalAssignments, //why did I have classAssignments here?
    "extra",
    referenceDate,
  );
  // console.log("by Day: ", relevantExtraDateAssignmentsByDay)


  return {
    relevantPersonalAssignmentsByDay,
    relevantClassAssignmentsByDay,
    relevantExtraDateAssignmentsByDay,
  };
};

module.exports = { getRelevantAssignmentsByDay };
