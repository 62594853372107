import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { memo } from "react";

function NotFound() {
  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <img
          alt="404 | husibuech.ch"
          // src="./static/images/undraw_page_not_found_su7k.svg"
          src="../logo195.png"
          style={{
            marginTop: 50,
            display: "inline-block",
            maxWidth: "100%",
            width: 300,
          }}
        />
        <Typography marginTop={3} marginBottom={3} align="center" color="primary" variant="h1">
          Diese Seite existiert nicht!
        </Typography>
        <Button
          href="/"
          // href="/husibuech/"
          component="a"
          startIcon={<ArrowBackIcon fontSize="small" />}
          sx={{ mt: 3 }}
          variant="contained"
        >
          Zurück
        </Button>
      </Box>
    </>
  );
}
export default memo(NotFound);
