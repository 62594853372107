import React, { memo, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import dayjs from "dayjs";
import DescriptionBox from "./CardElements/DescriptionBox";
import EditLineOne from "./_EditAssignmentHelpers/EditLineOne";
import EditLineTwoS from "./_EditAssignmentHelpers/EditLineTwoS";
import EditLineFourExams from "./_EditAssignmentHelpers/EditLineFourExams";
import EditLineFiveS from "./_EditAssignmentHelpers/EditLineFiveS";
import NameBox from "./CardElements/NameBox";
import DatumBox from "./CardElements/DatumBox";
import AvatarBox from "./CardElements/AvatarBox";
import TypeBox from "./CardElements/TypeBox";

function AssignmentClassViewRestricted({
  assignment,
  restricted,
  classView,
}) {
  // console.log("PopUp: ", restricted);
  const {
    husibuechExamTypes,
  } = useAppContext();
  const [previewAssignment, setPreviewAssignment] = useState(assignment);
  // console.log("previewAssignment: ", previewAssignment);
  /**
   * Update Elements
   */
  const [type, setType] = useState(previewAssignment.type);
  const [status, setStatus] = useState(previewAssignment.status);
  const [my_minutes_done, setWorkloadMinutesDone] = useState(
    parseInt(previewAssignment.my_minutes_done)
  );
  const [suggested_minutes, setWorkloadMinutesTotalSugg] = useState(
    parseInt(previewAssignment.suggested_minutes)
  );
  const [my_minutes, setMyMinutes] = useState(
    previewAssignment.my_minutes === -1
      ? previewAssignment.suggested_minutes
      : previewAssignment.my_minutes
  );
  const [suggested_days, setSuggestedDays] = useState(
    parseInt(previewAssignment.suggested_days)
  );
  const [my_days, setMyDays] = useState(parseInt(previewAssignment.my_days));
  const [assignmentName, setAssignmentName] = useState(assignment.name);
  const [dueDate, setDueDate] = useState(
    dayjs(previewAssignment.due_date).format("YYYY-MM-DD")
  );
  const [countingFactor, setCountingFactor] = useState(
    assignment.counting_factor ? parseFloat(assignment.counting_factor) : 0
  );
  const [assignmentExamTypeId, setAssignmentExamTypeId] = useState(
    husibuechExamTypes[0].id
  );
  const [description, setDescription] = useState(
    previewAssignment.description === undefined
      ? ""
      : previewAssignment.description
  );
  /**
   * Processing
   */
  const [changesCount, setChangesCount] = useState(0);
  const [changes, setChanges] = useState(changesCount > 0 ? true : false);
  /**
   * Errors
   */
  const [countingFactorErr, setCountingFactorErr] = useState({});
  const [workloadErr, setWorkloadErr] = useState({});
  const [availablePrepDaysErr, setAvailablePrepDaysErr] = useState({});

  /**
   * Testing
   */
  useEffect(() => {
    changesCount > 0 ? setChanges(true) : setChanges(false);
    // console.log(type);
  }, [changesCount]);



  // console.log("... let's go")

  return (
    <Grid
      container
      display="flex"
      justifyContent="flex-start"
      direction="column"
    // sx={{ width: "100%", background: "orange" }}
    >
      {/* 1st Line in Edit */}
      <Grid item>
        <Grid
          container
          justifyContent="space-between"
          alignItems={"center"}
          alignContent={"center"}
          direction="row"
          style={{
            marginTop: "1.5em",
            // width: "100%",
            // background: "red"
          }}
        >
          {/* Assignment Name */}
          <Grid item xs={6}>
            <NameBox
              restricted={restricted}
              previewAssignment={previewAssignment}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
              assignmentName={assignmentName}
              setAssignmentName={setAssignmentName}
            />
          </Grid>
          {/* Datum Box for students */}

          <Grid item xs={4}>
            <DatumBox
              restricted={restricted}
              classView={classView}
              assignment={assignment}
              previewAssignment={previewAssignment}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
              dueDate={dueDate}
              setDueDate={setDueDate}
            />
          </Grid>

          {/* Assignment Avatar */}
          <AvatarBox type={type} status={status} />
        </Grid>
      </Grid>

      {/* Line 2 box */}
      <Grid item style={{ marginTop: "0.75em", marginBottom: "0.75em" }}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems={"center"}
          alignContent={"center"}
          direction="row"
          spacing={3}
        >
          {/* Assignment Type */}
          <Grid
            item
            xs={6}
            sx={{
              marginLeft: !restricted && type !== "personal" ? "0.9em" : "",
              //  background: "orange"
            }}
          >
            <TypeBox
              restricted={true}
              assignment={assignment}
              previewAssignment={previewAssignment}
              type={type}
              setType={null}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Line 3 for Exam information */}
      {type === "exam" && (
        <EditLineFourExams
          previewAssignment={previewAssignment}
          changesCount={changesCount}
          setChangesCount={setChangesCount}
          countingFactor={countingFactor}
          setCountingFactor={setCountingFactor}
          countingFactorErr={countingFactorErr}
          setAssignmentExamTypeId={setAssignmentExamTypeId}
          husibuechExamTypes={husibuechExamTypes}
          assignmentExamTypeId={assignmentExamTypeId}
          restricted={true}
        />
      )}

      {/* Workload Container */}
      <EditLineFiveS
        classView={true}
        previewAssignment={previewAssignment}
        setPreviewAssignment={setPreviewAssignment}
        assignment={assignment}
        changesCount={changesCount}
        setChangesCount={setChangesCount}
        type={type}
        dueDate={dueDate}
        setDueDate={setDueDate}
        status={status}
        setStatus={setStatus}
        my_minutes_done={my_minutes_done}
        setWorkloadMinutesDone={setWorkloadMinutesDone}
        my_minutes={suggested_minutes}
        setMyMinutes={setMyMinutes}
        suggested_minutes={suggested_minutes}
        setWorkloadMinutesTotalSugg={setWorkloadMinutesTotalSugg}
        workloadErr={workloadErr}
        setMyDays={setMyDays}
        my_days={suggested_days}
        setSuggestedDays={setSuggestedDays}
        suggested_days={suggested_days}
        availablePrepDaysErr={availablePrepDaysErr}
        restricted={true}
      />

      {/* Asssignment Kommentar etc. */}
      <Grid item xs={12} style={{ marginTop: "0.75em" }}>
        <Grid
          container
          justifyContent="space-between"
          direction="column"
          rowGap={2}
        // sx={{ width: "100%", background: "pink" }}
        >
          <DescriptionBox
            restricted={true}
            assignment={assignment}
            previewAssignment={previewAssignment}
            description={description}
            setDescription={setDescription}
            changesCount={changesCount}
            setChangesCount={setChangesCount}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(AssignmentClassViewRestricted);
