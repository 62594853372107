import React, { memo } from "react";
import dayjs from "dayjs";
import DatumBoxComponent from "./DatumBoxComponent";

function DatumBox({
  restricted,
  classView,
  assignment,
  previewAssignment,
  changesCount,
  setChangesCount,
  dueDate,
  setDueDate,
}) {
  //this only changes the preview, not the assignment database!
  const change = (date) => {
    // console.log(dayjs(dueDate).format("YYYY-MM-DD"))
    if (!restricted) {
      let changesCountCopy = changesCount;
      if (
        dayjs(date).format("YYYY-MM-DD") ===
        dayjs(assignment.due_date).format("YYYY-MM-DD")
      ) {
        //we have gone back to default
        // console.log("back default");
        setChangesCount(changesCountCopy - 1);
      } else if (
        dayjs(previewAssignment.due_date).format("YYYY-MM-DD") !==
        dayjs(dueDate).format("YYYY-MM-DD")
      ) {
        // do noting
        // console.log("do noting");
      } else {
        // new change
        // console.log("new change");

        setChangesCount(changesCountCopy + 1);
      }
      setDueDate(date);
    }
  };

  return (
    <>
      <DatumBoxComponent
        restricted={restricted}
        classView={classView}
        dueDate={dueDate}
        change={change}
      />
    </>
  );
}

export default memo(DatumBox);
