const getToBeAddedCourses = async (zusatzFaecher, userCourses) => {
  //zusatzFaecher are the courses that a user has picked from the dropdown select menu
  //make sure the user does not add courses that have already been registered
  return await zusatzFaecher?.filter(
    (kurs) =>
      !userCourses.filter((course) => course.id === kurs.id).length > 0
  )
};

export default getToBeAddedCourses;
