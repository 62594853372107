import React, { memo } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
import StyledTooltip from "../../../_4GeneralHelpers/2_Tooltips/StyledTooltip";
import { useTheme } from "@mui/styles";

const SemesterWeekCell = ({ semesterWeek, handleClick, special }) => {
  const theme = useTheme()

  const clickFunction = (event) => {
    handleClick(event, moment(semesterWeek.monday).format("YYYY-MM-DD"));
  };
  return (
    <StyledTooltip
      title={"KW: " + semesterWeek.calendarWeek}
      placement="bottom-start"
      arrow
    >
      <TableCell
        key={semesterWeek.id}
        style={{ color: theme.palette.text.primary, backgroundColor: special && theme.palette.background.default}}
        onClick={clickFunction}
      >
        {semesterWeek.id}
      </TableCell>
    </StyledTooltip>
  );
};

export default memo(SemesterWeekCell);
