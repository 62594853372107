import React, { memo } from "react";
import { CustomTextField } from "../../../../../_3HelperFunctions/CustomTextField";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";
import { useTheme } from "@mui/styles";

function NameBoxComponent({ restricted, assignmentName, change }) {
  const {appInDarkMode} = useAppContext()
  const theme = useTheme()
  return (
    <>
      {/* Assignment Name */}
      <CustomTextField
        required
        id="name-box"
        label="Name"
        disabled={restricted}
        value={assignmentName}
        onChange={change}
        inputProps={{ style: { fontSize: "0.9em" } }}
        appindarkmode={appInDarkMode.toString()}
        theme={theme}
      ></CustomTextField>
    </>
  );
}

export default memo(NameBoxComponent);
