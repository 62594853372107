import React, { memo, useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import StyledTooltip from "../../../_4GeneralHelpers/2_Tooltips/StyledTooltip";
import MiddleBox from "../../../_4GeneralHelpers/1_Boxes/MiddleBox";
import BoxHeader from "../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";
import { useTheme } from "@mui/styles";

const NachkontrolleSubjectTable = ({ data }) => {
  const { userSubjects,userCourses, schoolyearData, isAStudent, appInDarkMode } = useAppContext();
  const theme = useTheme()

  const [dataBySubject, setDataBySubject] = useState([]);

  const getTimeString = (m) => {
    if (m > 180) {
      let hours = Math.floor(m / 60);
      let minutes = Math.round((Math.fround(m / 60) - hours) * 60);
      return hours + "h " + (minutes === 0 ? "" : minutes + "min");
    } else {
      return m + "min";
    }
  };

  useEffect(() => {
    let dataBySubjectTemp
    if(isAStudent){
    dataBySubjectTemp = userSubjects
      .filter((subject) =>
        data.some(
          (assignment) =>
            assignment.type !== "personal" && assignment.tag === subject.tag
        )
      )
      .map((subject) => {
        const assignmentsForSubject = data.filter(
          (assignment) =>
            assignment.type !== "personal" && assignment.tag === subject.tag
        );

        const workloadMinutesTotalSum = assignmentsForSubject.reduce(
          (sum, assignment) => sum + assignment.my_minutes,
          0
        );

        const workloadMinutesTotalSuggSum = assignmentsForSubject.reduce(
          (sum, assignment) => sum + assignment.suggested_minutes,
          0
        );

        const numAssignments = assignmentsForSubject.length;

        return {
          subject: subject.tag,
          workloadMinutesTotalSum,
          workloadMinutesTotalSuggSum,
          numAssignments,
        };
      });
    } else {

      dataBySubjectTemp = userCourses
      .filter((course) =>
        data.some(
          (assignment) =>
            assignment.type !== "personal" && assignment.course_name === course.name
        )
      )
      .map((course) => {
        const assignmentsForSubject = data.filter(
          (assignment) =>
            assignment.type !== "personal" && assignment.course_name === course.name
        );

        const workloadMinutesTotalSum = assignmentsForSubject.reduce(
          (sum, assignment) => sum + assignment.my_minutes,
          0
        );

        const workloadMinutesTotalSuggSum = assignmentsForSubject.reduce(
          (sum, assignment) => sum + assignment.suggested_minutes,
          0
        );

        const numAssignments = assignmentsForSubject.length;

        return {
          subject: course.name, // a little trick for teachers
          workloadMinutesTotalSum,
          workloadMinutesTotalSuggSum,
          numAssignments,
        };
      });
    }


    // Calculate values for "personal" assignments
    const personalAssignments = data.filter(
      (assignment) =>
        assignment.type === "personal"
    );
    const personalWorkloadMinutesTotalSumForPers = personalAssignments.reduce(
      (sum, assignment) => sum + assignment.my_minutes,
      0
    );

    const personalWorkloadMinutesTotalSuggSumForPers = personalAssignments.reduce(
      (sum, assignment) => sum + assignment.suggested_minutes,
      0
    );

    const numPersonalAssignmentsForPers = personalAssignments.length;
    dataBySubjectTemp.push({
      subject: 'Personals',
      workloadMinutesTotalSum: personalWorkloadMinutesTotalSumForPers,
      workloadMinutesTotalSuggSum: personalWorkloadMinutesTotalSuggSumForPers,
      numAssignments: numPersonalAssignmentsForPers
    });

    setDataBySubject(dataBySubjectTemp);
  }, [data, schoolyearData, userSubjects]);

  return (
    <>
      <BoxHeader title={isAStudent?"Nach Fach (vollständig erledigt)":"Nach Kurs (vollständig erledigt)"} options={[]} />
      <MiddleBox>
        <Grid
          container
          direction="row"
          spacing={0}>
          {/* Spacer to push down content */}
          <Grid item xs={12}
          // style={{ backgroundColor: "red" }}
          >
            <div style={{ height: "1em" }} />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          spacing={0}
          justifyContent="space-between"
          alignItems="flex-start"
          padding={0}
          // overflow="scroll"
          height="calc(100vh - 17em)"
          sx={{
            position: "relative",
            overflowY: "auto",
            overflowX: "auto", // Hide x-axis scrollbar
            "&::-webkit-scrollbar": {
              width: "0px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "your-desired-color", // Set your desired color
              borderRadius: "6px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent", // or any background color
            },
          }}
        >
          <Table stickyHeader aria-label="sticky table" style={{ position: "relative", zIndex: 0 }}>
            <TableHead style={{}}>
              <TableRow>
                <TableCell align="center" style={{backgroundColor:appInDarkMode?"":theme.palette.primary.main}}>{isAStudent ? "Fach" : "Kurs"}</TableCell>
                <TableCell align="center" style={{backgroundColor:appInDarkMode?"":theme.palette.primary.main}}>
                  <StyledTooltip
                    title={
                      <div style={{ whiteSpace: "pre-line" }}>
                        {"Von der Lehrperson vorgeschlagener Zeitaufwand (Total)" + "\nAusser natürlich bei den persönlichen Aufgaben"}
                      </div>
                    }
                    placement="bottom-start"
                    arrow
                  >
                    <span>{isAStudent ? "Vorschlag Lehrperson" : "Vorschlag an Klasse"}</span>
                  </StyledTooltip>
                </TableCell>
                <TableCell align="center" style={{backgroundColor:appInDarkMode?"":theme.palette.primary.main}}>
                  <StyledTooltip
                    title={"Von dir eingeplanter Zeitaufwand (Total)"}
                    placement="bottom-start"
                    arrow
                  >
                    <span>{isAStudent ? "Mein Aufwand" : "Ihr Aufwand"}</span>
                  </StyledTooltip>
                </TableCell>
                <TableCell align="center" style={{backgroundColor:appInDarkMode?"":theme.palette.primary.main}}>
                  <StyledTooltip
                    title={
                      <div style={{ whiteSpace: "pre-line" }}>
                        {"Deine gesamte Abweichung vom Vorgebenenen" + "\n\"+\" bedeutet, dass du mehr Zeit eingeplant hast als vorgegeben" + "\n\"-\" bedeutet, dass du weniger Zeit eingeplant hast als vorgegeben"}
                      </div>
                    }
                    placement="bottom-start"
                    arrow
                  >
                    <span>Total Abweichung</span>
                  </StyledTooltip>
                </TableCell>
                <TableCell align="center" style={{backgroundColor:appInDarkMode?"":theme.palette.primary.main}}>
                  <StyledTooltip
                    title={
                      <div style={{ whiteSpace: "pre-line" }}>
                        {"Deine durchschnittliche Abweichung vom Vorgebenenen" + "\n\"+\" bedeutet, dass du mehr Zeit eingeplant hast als vorgegeben" + "\n\"-\" bedeutet, dass du weniger Zeit eingeplant hast als vorgegeben"}
                      </div>
                    }
                    placement="bottom-start"
                    arrow
                  >
                    <span>&empty; Abweichung</span>
                  </StyledTooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataBySubject.length > 0 ? (
                dataBySubject.map((row, index) => (
                  <TableRow key={index} selected hover size="small">
                    <TableCell align="center" >{row.subject}</TableCell>
                    <TableCell align="center">
                      {getTimeString(row.workloadMinutesTotalSuggSum)}
                    </TableCell>
                    <TableCell align="center">
                      {getTimeString(row.workloadMinutesTotalSum)}
                    </TableCell>
                    <TableCell align="center">
                      {row.workloadMinutesTotalSum -
                        row.workloadMinutesTotalSuggSum === 0
                        ? "-"
                        : (row.workloadMinutesTotalSum -
                          row.workloadMinutesTotalSuggSum >
                          0
                          ? "+"
                          : "") +
                        getTimeString(
                          row.workloadMinutesTotalSum -
                          row.workloadMinutesTotalSuggSum
                        )}
                    </TableCell>
                    <TableCell align="center">
                      {Math.round(
                        (row.workloadMinutesTotalSum -
                          row.workloadMinutesTotalSuggSum) /
                        row.numAssignments
                      ) === 0
                        ? "-"
                        : (Math.round(
                          (row.workloadMinutesTotalSum -
                            row.workloadMinutesTotalSuggSum) /
                          row.numAssignments
                        ) > 0
                          ? "+"
                          : "") +
                        Math.round(
                          (row.workloadMinutesTotalSum -
                            row.workloadMinutesTotalSuggSum) /
                          row.numAssignments
                        ) +
                        "min"}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    Aktuell noch keine Daten
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table></Grid>
      </MiddleBox>
    </>
  );
};

export default memo(NachkontrolleSubjectTable);
