import React, { memo } from "react";

import { School } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

function MenuTopBarTitleUnauthorized() {
  const theme = useTheme();
  return (
    <div className="nav-center">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{marginLeft:"1em"
      }}
      >
        <Grid item>
          <Typography variant="h3" 
          >
            Husibuech
          </Typography>
        </Grid>
        <Grid
          item
          marginBottom={-1}
          sx={
            {
              marginLeft:"0.4em"
            }
          }
        >
          <IconButton sx={{ mt: -1 }} disabled={true}>
            <School sx={{ color: theme.palette.background.paper, marginLeft:"-0.4em" }} />
            <Typography
              variant="caption"
              sx={{ color: theme.palette.background.paper, fontSize: "0.8em", mr: 0.2, mt: -0.2 }}
            >
              &nbsp;Dein Planungstool
            </Typography>
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}
export default memo(MenuTopBarTitleUnauthorized);
