import {
  Checkbox,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import { memo } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MixedKursAuswahl({
  mixedCourse,
  change,
  error,
  checked,
  changeSelection,
}) {
  const { husibuechCourses } = useAppContext();

  // console.log("mixedCourse:", mixedCourse);

  return (
    <>
      <Grid item xs={5} md={12} style={{ marginTop: "-0.5em"}}>
        <InputLabel id="demo-multiple-name-label">
          Klassenübergreifender Kurs?{" "}
          <Checkbox
            checked={checked}
            onChange={changeSelection}
            style={{ marginTop: "0em" }}
          />
        </InputLabel>

        <Select
          style={{
            visibility: checked ? "visible" : "hidden",
            marginTop: "-0.5em",
          }}
          displayEmpty
          onChange={change}
          input={<OutlinedInput />}
          value={mixedCourse !== undefined ? mixedCourse : ""}
          renderValue={(selected) => {
            return !selected ? (
              <em>Wähle aus</em>
            ) : typeof selected === "object" ? (
              selected.name
            ) : (
              selected
            );
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>bereits erfasst</em>
          </MenuItem>
          {husibuechCourses?.length > 0 &&
            husibuechCourses.map((course, index) => (
              <MenuItem key={index} value={course}>
                {course.name}
              </MenuItem>
            ))}
        </Select>
        {Object.keys(error).map((key) => (
          <Grid
            item
            key={key}
            marginTop={1}
            className="alert alert-danger"
            role="alert"
          >
            {error[key]}
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default memo(MixedKursAuswahl);
