import { memo } from "react";
import { Grid, LinearProgress } from "@mui/material";
import CircularStatic from "./CircularProgressWithLabel";

function LoadingDashboardContent() {
  return (
    <>
          <LinearProgress color="primary" />
          <Grid container alignItems="center" direction="column">
            <Grid item xs={12} sx={{ marginTop: { xs: "8em" } }}>
              <CircularStatic />
            </Grid>
          </Grid>
        </>
  );
}
export default memo(LoadingDashboardContent);
