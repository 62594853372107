import React, { memo } from "react";
import { LineChart } from '@mui/x-charts/LineChart';
import { ChartsReferenceLine } from '@mui/x-charts';
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { useTheme } from "@mui/styles";
import { Paper } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";

function YearViewComponent({ height, mondays, weeks, userWorkload, classWorkload }) {
  const {
    isALeader,
    appInDarkMode,
    isMobile
  } = useAppContext()
  const theme = useTheme()


  const CustomAxisTooltipContent = (props) => {
    const { axisData, series } = props;
    // console.log(axisData);
    return (
      <Paper sx={{ padding: 3, marginTop: "-5em", marginLeft: "2em", marginRight: "2em" }}>
        <div style={{ display: 'flex', color: theme.palette.background.white }}>
          <div>{`Schulwoche: ${axisData.x.index},`}&nbsp;</div>
          <div>{`Kalenderwoche: ${weeks[axisData.x.index]},`}</div>
        </div>
        <div style={{ display: 'flex', color: theme.palette.background.white, marginBottom: "0.5em" }}>
          {`Montag: ${moment(mondays[axisData.x.index]).format("DD. MMMM")}`}
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ color: series[0].color }}>{series[0].label}:&nbsp;</div>
          <div style={{ color: series[0].color }}>{`${series[0].data[axisData.x.index]} min`}</div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ color: series[1].color }}>{series[1].label}:&nbsp;</div>
          <div style={{ color: series[1].color }}>{`${series[1].data[axisData.x.index]} min`}</div>
        </div>
      </Paper>
    );
  };

  return (
    <>
      <div
        style={{
          marginTop: "-2.5em", marginLeft: "0.3em", paddingBottom: "1em", overflow: "hidden",
          width: '100%',
          display: 'flex',
          alignItems: 'center',
        }}>
        {weeks.length > 0 && userWorkload.length > 0 && classWorkload.length > 0 &&
          <LineChart
            tooltip={{ trigger: "axis", axisContent: CustomAxisTooltipContent }}
            sx={{
              //general for graph:
              // backgroundColor: "green",
              paddingBottom: "-1em",
              overflow: "hidden",
              //rot element:
              '& .MuiLineElement-root': {
                // stroke: theme.palette.primary.main, // Adjust this color as needed
                strokeWidth: 2,
              },
              '& .MuiAreaElement-series-personal': {
                fill: "url('#yearViewGradient')",
              },

              //change stroke color of both axes
              "& .MuiChartsAxis-root .MuiChartsAxis-tick": {
                stroke: theme.palette.primary.main,
                strokeWidth: 0.5,
              },
              // change all labels fontFamily shown on both xAxis and yAxis
              "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
                fontFamily: "Oswald",
              },
              //change left yAxis label styles
              "& .MuiChartsAxis-root .MuiChartsAxis-tickLabel": {
                fill: theme.palette.primary.main,
                // stroke: theme.palette.primary.main,

              },
              // both Line Styles
              "& .MuiChartsAxis-root .MuiChartsAxis-line": {
                stroke: theme.palette.primary.main,
                strokeWidth: 1,
              },
              "& .MuiChartsAxis-left .MuiChartsAxis-label": {
                fill: theme.palette.primary.main,
                transform: 'translateX(-0.5em)',
              },
              "& .MuiChartsAxis-bottom .MuiChartsAxis-label": {
                fill: theme.palette.primary.main,
                transform: 'translateY(0.5em)',
              },
              "& .MuiChartsAxis-directionX": {
                "& .MuiChartsAxis-tickLabel": {
                  strokeWidth: 0.5,
                  // stroke: theme.palette.primary.main,
                  fill: theme.palette.primary.main,
                  rotate: "-40deg",
                  transform: 'translateX(-1.5em) translateY(-0.3em)', // Adjust the translateY value as needed
                  tickSize: 1
                }
              }
            }}
            xAxis={[{
              // label: 'Schulwoche',
              scaleType: 'point',
              data: weeks,
              // valueFormatter: (week) => `${weeks.indexOf(week)}, ${week}`,
              valueFormatter: (week) => `SW ${weeks.indexOf(week) + 1}`,
              // ...xAxisCommon,
              id: 'bottomAxis',
              scaleType: 'point',
              tickInterval: (week) => (weeks.indexOf(week) + 1) % 5 === 0,
            }]}
            yAxis={[{
              label: 'Aufwand [in Minuten]',
            }]}

            series={[
              {
                id: 'personal',
                label: isALeader ? 'Ihr persönlicher Aufwand' : 'Dein persönlicher Aufwand',
                data: userWorkload,
                // stack: 'total',
                curve: "catmullRom",
                showMark: false,
                color: appInDarkMode ? theme.palette.primary.main : theme.palette.primary.main,
                // showMark: ({ index }) => index % 2 === 0,
                area: true,
                valueFormatter: (element) =>
                  `${element}min`
              },
              {
                id: 'class',
                label: 'Aufwand der Klasse',
                data: classWorkload,
                // stroke: "transparent",
                curve: "catmullRom",
                showMark: false,
                color: theme.palette.secondary.main,
                stack: 'total',
                area: false,
                valueFormatter: (element) =>
                  `${element}min`
              },
            ]}
            // slots={{ popper: CustomPopperRoot }}
            slotProps={{ legend: { hidden: true } }}
            skipAnimation={false}
            // width={500}
            height={isMobile ? height * 1.1 : height * 1.1}
          >
            <defs>
              <linearGradient id="yearViewGradient" gradientTransform="rotate(90)">
                <stop offset="20%" stopColor={theme.palette.levels.red} stopOpacity={appInDarkMode?"0.3":"0.9"} />
                <stop offset="100%" stopColor={theme.palette.levels.green} stopOpacity={appInDarkMode?"0.9":"0.9"} />
              </linearGradient>
            </defs>
            <ChartsReferenceLine
              x={dayjs().week()}
              label="heute"
              lineStyle={{ stroke: theme.palette.levels.lightGreen, strokeDasharray: '5 5' }}
              labelAlign='start'
              labelStyle={{ fill: theme.palette.levels.lightGreen }}              
            />
          </LineChart>}
      </div >
    </>
  );
}

export default memo(YearViewComponent);
