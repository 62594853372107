import { Droppable } from "react-beautiful-dnd";
import React from "react";
import styled from "styled-components";
import { memo } from "react";
import { Typography } from "@mui/material";
import { ToDoItem } from "./ToDoItem";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

const ColumnHeader = styled.div`
  text-transform: uppercase;
  margin-bottom: ${(props) =>
    props.ismobile==="true" ? "10px"  : "20px"};;
`;

const TaskList = styled.div`
  padding-left: ${(props) =>
    props.ismobile==="true" ? "4px"  : "6px"};
  border-radius: 6px;
  transition: background-color 0.2s ease;
  background-color: ${(props) =>
    props.isDraggingOver ? "#e8eaf6" : "#6b95cd"};
  display: flex;
  flex-direction: column;
  width: ${(props) =>
    props.ismobile==="true" ? "9em"  : "16em"};
  height: ${(props) =>
    props.ismobile==="true" ? "17.5em"  : "17.8em"};;
  overflow-x: auto; /* Hide horizontal scrollbar */
  overflow-y: auto; /* Add vertical scrollbar when necessary*/
`;

const DroppableStyles = styled.div`
  padding: ${(props) =>
    props.ismobile==="true" ? "4px"  : "10px"};
  // border-radius: 6px;
  background-color: "#d4d4d4";
  // #40649c
  flex-grow: 1;
`;

//the columns of the to do list:
function DraggableContainer({ prefix, elements, setOpenSnackbar }) {
  const {isMobile} = useAppContext()
  return (
    <DroppableStyles ismobile={isMobile?"true":"false"}>
      <ColumnHeader ismobile={isMobile?"true":"false"}>{prefix}</ColumnHeader>
      <Droppable droppableId={`${prefix}`}>
        {(provided, snapshot) => (
          <TaskList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
            ismobile={isMobile?"true":"false"}
          >
            {elements === undefined ? (
              <div>error</div>
            ) : elements && elements.length !== 0 ? (
              elements.map((item, index) => (
                // <div key={index}>hi</div>
                <ToDoItem
                  id={item.assignment_id}
                  key={item.assignment_id}
                  index={index}
                  item={item}
                  setOpenSnackbar={setOpenSnackbar}
                />
              ))
            ) : prefix === "D Priorität" && elements.length === 0 ? (
              <>
                <Typography color="textSecondary" variant="button">
                  <br />
                  Vernachlässigbar
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  Hat geringste Priorität.
                  <br /> <br />
                  Tipp:
                  <br />
                  Sag auch mal was Persönliches ab oder entferne das ToDo aus
                  dieser Liste.
                  <br />
                  <i>(durch nach unten ziehen)</i>
                </Typography>
              </>
            ) : prefix === "C Priorität" && elements.length === 0 ? (
              <>
                <Typography color="textSecondary" variant="button">
                  <br />
                  Reduzieren
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  Scheindringlichkeiten vermeiden
                  <br /> <br />
                  <i>
                    Entscheidend ist, auch mal "Jetzt grad nicht" zu sagen.
                    <br />
                  </i>
                </Typography>
              </>
            ) : prefix === "B Priorität" && elements.length === 0 ? (
              <>
                <Typography color="textSecondary" variant="button">
                  <br />
                  rechtzeitig angehen
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  Nicht auf die lange Bank schieben!
                  <br /> <br />
                  Tipp: <br /> Nimm dir regelmässig Zeit für deine B-Aufgaben.
                  So kommst du nachhaltig deinen Zielen näher.
                </Typography>
              </>
            ) : prefix === "A Priorität" && elements.length === 0 ? (
              <>
                <Typography color="textSecondary" variant="button">
                  <br />
                  Sofort erledigen
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  Höchste Priorität!
                  <br /> <br />
                  Hier musst du sofort handeln.
                </Typography>
              </>
            ) : (
              <></>
            )}

            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </DroppableStyles>
  );
}

export default memo(DraggableContainer);
