import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import DaysToPrepareBoxComponent from "../CardElements/DaysToPrepareBoxComponent";
import AufwandBoxComponent from "../CardElements/AufwandBoxComponent";
import DatumBoxComponent from "../CardElements/DatumBoxComponent";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";

function LineThree({ type, date, onChangeDueDate, my_minutes, onChangeWorkloadMinutesTotal, workloadErr, my_days, onChangeAvailablePrepDays, restricted, boxType }) {
    const { referenceDate } = useAppContext()
    return (
        <Grid container justifyContent="flex-start" direction="row" spacing={1}>
            {/* Date */}
            <Grid item xs={4}>
                <DatumBoxComponent
                    restricted={restricted}
                    dueDate={date ? date : referenceDate}
                    change={onChangeDueDate}
                    boxType={boxType}
                />
            </Grid>
            {/* Aufwand */}
            <Grid item xs={4}>
                <AufwandBoxComponent
                    restricted={restricted}
                    my_minutes={my_minutes}
                    suggested_minutes={my_minutes}
                    type={type}
                    workloadErr={workloadErr}
                    personalView={false}
                    change={onChangeWorkloadMinutesTotal}
                />
            </Grid>

            {/* Vorbereitung */}
            <Grid item xs={3}>
                <DaysToPrepareBoxComponent
                    restricted={restricted}
                    my_days={my_days}
                    suggested_days={my_days}
                    type={type}
                    personalView={false}
                    change={onChangeAvailablePrepDays}
                />
                {/* <ErrorBox error={availablePrepDaysErr} /> */}
            </Grid>
        </Grid>

    );
}

export default memo(LineThree);
