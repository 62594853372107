import { Grid } from "@material-ui/core";
import { memo } from "react";

function PriorityDragGridBackground({ children }) {
  return (
    <Grid item>
      <div className="iDoContainerWrapper">
        <div className="iDoHorizontalText">
          <b>Dringlichkeit (Zeit)</b>
        </div>
        <div className="iDoVerticalText">
          <b>Wichtigkeit (Ziel)</b>
        </div>
        <div className="iDoArrowUp" />
        <div className="iDoArrowRight" />
        {children}
      </div></Grid>
  );
}

export default memo(PriorityDragGridBackground);
