const { default: dataService } = require("../../_6Services/data.service");

async function updateDataSet(
  schoolYearData,
  updatedAssignment,
  isALeader,
  key
) {
  try {
    return await dataService.updateDataSet(
      schoolYearData,
      updatedAssignment,
      isALeader,
      key
    );
  } catch (error) {
    console.error("Error in updateDataSet:", error);
    throw error;
  }
}

exports.updateDataSet = updateDataSet;
