import { useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import {
  Badge,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Info, Notifications } from "@mui/icons-material";
import dayjs from "dayjs"; // Import dayjs
import MoreButton from "./MoreButton";
import LessButton from "./LessButton";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import AssignmentSneakListView from "./AssignmentSneakListView";
import StyledTooltip from "../2_Tooltips/StyledTooltip";

export default function NewNotificationsButton() {
  const { schoolYearData, checkTime, setCheckTime } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [newAssignments, setNewAssignments] = useState([]);
  const [snackBar, setSnackBar] = useState([]);
  const [chosenAssignment, setChosenAssignment] = useState(undefined);
  const [detail, setDetail] = useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setTimeout(() => {
    clearNewAssignments();
    // }, 3000);
  };

  const clearNewAssignments = () => {
    // console.log("clear new Assignments list");
    setCheckTime(dayjs()); // Use dayjs to set current date and time
    setNewAssignments([]);
  };
  // useEffect(() => {
  //   console.log(checkTime.format()); // Use format() to get the string representation of the date
  // }, [checkTime]);

  const handleClickOpenDetail = () => {
    setDetail(!detail);
  };

  useEffect(() => {
    if (schoolYearData) {
      var updateNewAssignments = [...newAssignments];

      updateNewAssignments = schoolYearData?.userAssignments
        .filter((assignment) => assignment.type !== "personal")
        .filter((assignment) =>
          dayjs(assignment.real_edit_date).isAfter(checkTime)
        );
      // console.log("after assignments...", updateNewAssignments)
      var sortedNewAssignments = updateNewAssignments.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        var c = dayjs(a.real_edit_date);
        var d = dayjs(b.real_edit_date);

        //multiple key order: https://stackoverflow.com/questions/9175268/javascript-sort-function-sort-by-first-then-by-second
        return c - d || b.assignment_id - a.assignment_id;
      });

      setNewAssignments(sortedNewAssignments);
    }
  }, [schoolYearData, checkTime]);

  useEffect(() => {
    // console.log(newAssignments);
    var size = 3;
    newAssignments.length <= 3
      ? setSnackBar(newAssignments)
      : detail
        ? setSnackBar(newAssignments)
        : setSnackBar(newAssignments.slice(0, size));
  }, [newAssignments, detail]);

  const handleClickOpen = (key) => {
    setChosenAssignment(key);
  };

  return (
    <>
      <StyledTooltip title="Neuigkeiten" placement="bottom-start" arrow>
        <IconButton
          // color="primary"
          sx={{ ml: 1 }}
          aria-label="neuigkeiten"
          onClick={handleClick}
        >
          <Badge
            badgeContent={newAssignments.length}
            color="error"
            variant="dot"
          >
            <Notifications />
          </Badge>
        </IconButton>
      </StyledTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          {newAssignments.length ? (
            snackBar.map((assignment, i) => (
              <AssignmentSneakListView
                key={i}
                divider={i < newAssignments.length - 1}
                handleClick={handleClickOpen}
                assignment={assignment}
              // onClose={handleClose}
              />
            ))
          ) : (
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <ListItemText primary="Keine Neuigkeiten" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
        {newAssignments.length > 3 && !detail ? (
          <MoreButton handleClickOpenDetail={handleClickOpenDetail} />
        ) : (
          <></>
        )}
        {newAssignments.length >= 3 && detail ? (
          <LessButton handleClickOpenDetail={handleClickOpenDetail} />
        ) : (
          <></>
        )}
      </Popover>
    </>
  );
}
