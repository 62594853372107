import React, { useEffect, useState } from "react";

import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import { LinearProgress, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useTheme } from "@mui/styles";
import PriorityDragGrid from "./PriorityDragGrid";
import AddOwnToDoButton from "../../../_4GeneralHelpers/7_ToDos/AddOwnToDoButton";
import AddSuggToDoButton from "../../../_4GeneralHelpers/7_ToDos/AddSuggToDoButton";
import CircularProgressWithoutLabel from "../../../_5Pages/0Navigation/3Loading/CircularProgressWithoutLabel";
import CustomWidthTooltip from "../../../_4GeneralHelpers/2_Tooltips/CustomWidthTooltip";
import iDoExplain from "./ExplanationIDoList";
import { useMediaQuery } from "@mui/material";
import MyAvatar from "../../10_Avatar/MyAvatar";


const useStyles = makeStyles((theme) => ({
  menuSliderContainer: {
    width: "100%",
    maxWidth: "100%",
    background: "#38679c",
    height: "100%",
    //if there is an overflow, it should not be shown!
    overflow: "hidden",
  },
  avatar: {
    marginTop: "0rem",
    marginLeft: "1rem",
    marginRight: "0.2rem",
    marginBottom: "0.2rem",
    padding: "0.3rem",
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  listItem: {
    color: "tan",
  },
}));

export const SideList = () => {
  const { currentUser, loggingOut, toDos, setOpenToDoList, openToDoList, isMobile } = useAppContext();
  const [elements, setElements] = useState([]);
  const [priorityLabels, setPriorityLabels] = useState([])
  const theme = useTheme();

  //for slider
  const classes = useStyles();

  useEffect(() => {
    // console.log(isMediumOrMore)
    if (isMobile) {
      setPriorityLabels(["B Priorität", "A Priorität", "D Priorität", "C Priorität"])

    } else {
      // setPriorityLabels(["A Priorität", "B Priorität", "C Priorität", "D Priorität"])
      setPriorityLabels(["B Priorität", "A Priorität", "D Priorität", "C Priorität"])

    }

  }, [isMobile])

  useEffect(() => {
    if (toDos) {
      // Initialize elements with empty arrays for each priority label
      // console.log("currentToDos: ", toDos)
      let elements = {
        'A Priorität': [],
        'B Priorität': [],
        'C Priorität': [],
        'D Priorität': []
      };
      // Iterate over toDos and categorize assignments
      toDos.forEach(assignment => {
        switch (assignment.priority) {
          case 0:
            elements['D Priorität'].push(assignment);
            break;
          case 1:
            elements['C Priorität'].push(assignment);
            break;
          case 2:
            elements['B Priorität'].push(assignment);
            break;
          case 3:
            elements['A Priorität'].push(assignment);
            break;
          default:
            // Handle other cases if needed
            break;
        }
      });
      updateElements(elements)
      // console.log(elements);
    }
  }, [toDos]);

  const updateElements = (elements) => {
    // Iterate over each priority in elements
    let updatedElements = {};

    for (const priority in elements) {
      if (elements.hasOwnProperty(priority)) {
        // Sort the list for the current priority by multiple keys: ranking, real_edit_date_user
        const sortedList = elements[priority].sort((a, b) =>
          a.ranking - b.ranking || b.real_edit_date_user.localeCompare(a.real_edit_date_user)
        );

        // Assign the sorted list to the updatedElements object
        updatedElements[priority] = sortedList;
      }
    }
    setElements(updatedElements);
  };

  const handleClose = () => {
    setOpenToDoList(!openToDoList)
  };


  return loggingOut ? (
    <>
      <LinearProgress color="primary" />
      <Grid container alignItems="center" direction="column">
        <Grid item xs={12} sx={{ marginTop: { xs: "5.5em", ss: "6.2em" } }}>
          <CircularProgressWithoutLabel />
        </Grid>
      </Grid>
    </>
  ) : (
    currentUser && (
      <Box className="iDoWrapper">
        <Grid container sx={{ alignItems: "center", justifyContent: "center" }} style={{ backgroundColor: theme.palette.primary.dark, paddingTop: "0.5em" }}>
          <Grid item xs={6} mds={6}>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid item>
                <MyAvatar change={handleClose} tooltipText={"zurück"} ariaLabel={"zurück"} isToDo={true} />
              </Grid>
              <Grid>
                <>
                  <br />
                  <Typography
                    variant="h4"
                    color={"white"}
                    marginLeft={0.1}
                    marginTop={0}
                    marginBottom={1}
                  // marginBottom={3}
                  >
                    iDo Liste
                    <CustomWidthTooltip
                      title={iDoExplain}
                      placement="bottom-start"
                      arrow
                    >
                      <Info
                        aria-label="zusatzinformation"
                        fontSize="small"
                        sx={{ margin: "-1em 0.1em 0 0" }}
                        style={{
                          color: theme.palette.levels.lightGreen,
                        }}
                      />
                    </CustomWidthTooltip>
                  </Typography>
                </>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} mds={6}>
            <Grid container spacing={1} direction="column" alignItems="flex-end" justifyContent="flex-end" style={{ paddingRight: "1em" }}>
              <Grid item xs={12}>
                <AddSuggToDoButton text={"Vorschlag"} />
              </Grid>
              <Grid item xs={12} style={{ heigth: "100%" }}>
                <AddOwnToDoButton text={"Eigenes ToDo"} elements={elements} updateElements={updateElements} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <PriorityDragGrid elements={elements} updateElements={updateElements} priorityLabels={priorityLabels} />
      </Box>
    )
  );
};
