export const separateMixedCourses = async (assignments) => {
  if (!assignments || assignments.length === 0) {
    return { nonMixed: [], mixed: [] };
  }

  const nonMixed = [];
  const mixed = [];

  assignments.forEach((assignment) => {
    if (assignment.mixed_course) {
      mixed.push(assignment);
    } else {
      nonMixed.push(assignment);
    }
  });

  return { nonMixed, mixed };
};
