import { memo, useState } from "react";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

import {
  Box,
  Grid,
  TextField,
} from "@mui/material";
import userService from "../../../_6Services/user.service";
import ContentBox from "../../../_4GeneralHelpers/1_Boxes/ContentBox";
import ButtonGrid from "../_CourseSelectionHelpers/ButtonGrid";
import BoxHeader from "../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";

function PasswordUpdate() {
  const [change, setChange] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);

  const { currentUser } = useAppContext();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRep, setNewPasswordRep] = useState("");

  const [currentPasswordErr, setCurrentPasswordErr] = useState({});
  const [newPasswordErr, setNewPasswordErr] = useState({});
  const [newPasswordRepErr, setNewPasswordRepErr] = useState({});
  //to show errors
  const [message, setMessage] = useState("");
  const [saveColor, setSaveColor] = useState("primary");

  const onChangeCurrentPassword = (e) => {
    setCurrentPasswordErr({});
    setCurrentPassword(e.target.value);
  };

  const onChangeNewPassword = (e) => {
    setChange(true);
    setNewPasswordErr({});
    setNewPassword(e.target.value);
  };
  const onChangeNewPasswordRep = (e) => {
    setNewPasswordRepErr({});
    setNewPasswordRep(e.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      // console.log(newPassword);
      // console.log(newPasswordRep);
      // set localLoading to true during submission process
      // setLocalLoading(true);
      createPasswordUpdate();
    } else {
      setCurrentPassword("");
      setNewPasswordRep("");
      setNewPassword("");
      setTimeout(() => {
        // console.log("Let's go back to normal")
        setMessage("");
        setCurrentPasswordErr({});
        setNewPasswordErr({});
        setNewPasswordRepErr({});
        setChange(false);
        // setChange(false);
      }, 4000);
    }
  };

  const createPasswordUpdate = () => {
    setLocalLoading(true);

    const passwordUpdate = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };
    // console.log(passwordUpdate);
    userService
      .updateUser("password", passwordUpdate, currentUser.id)
      .then(
        (response) =>
          setLocalLoading(false) +
          setSaveColor("success") +
          setMessage("Update erfolgreich") +
          setCurrentPassword("") +
          setNewPassword("") +
          setNewPasswordRep("") +
          setTimeout(() => {
            // console.log("Let's go back to normal")
            setSaveColor("primary");
            setMessage("");
            setCurrentPasswordErr({});
            setNewPasswordErr({});
            setNewPasswordRepErr({});
            setChange(false);
          }, 2500)
      )
      .catch((error) => {
        setLocalLoading(false);
        setSaveColor("warning");
        setCurrentPassword("");
        setNewPassword("");
        setNewPasswordRep("");
        setMessage(
          error.response.data.message
            ? error.response.data.message
            : "ungültige Angaben"
        );
        setTimeout(() => {
          setSaveColor("primary");
          setMessage("");
          setCurrentPasswordErr({});
          setNewPasswordErr({});
          setNewPasswordRepErr({});
          setChange(false);
        }, 4000);
      });
    // .then(() => setChange(false));
  };

  const formValidation = () => {
    let currentPasswordErr = {};
    let newPasswordErr = {};
    let newPasswordRepErr = {};

    let isValid = true;

    //design the desired rules for form completion here:

    if (currentPassword === "") {
      currentPasswordErr.notGiven = "altes Passwort eingeben!";
      isValid = false;
    }
    setCurrentPasswordErr(currentPasswordErr);

    if (currentPassword === newPassword) {
      newPasswordErr.newPasswordSame = "Neues Passwort eingeben!";
      isValid = false;
    }
    setNewPasswordErr(newPasswordErr);

    if (newPassword.length < 6) {
      newPasswordErr.newPasswordShort =
        "Neues Passwort mit min. 6 Zeichen eingeben!";
      isValid = false;
    }
    setNewPasswordErr(newPasswordErr);

    if (newPasswordRep !== newPassword) {
      newPasswordRepErr.notSame = "Passwörter stimmen nicht überein!";
      isValid = false;
    }
    setNewPasswordRepErr(newPasswordRepErr);

    return isValid;
  };

  const clearHandler = async (event) => {
    event.preventDefault();

    //we return to default
    await setCurrentPassword("");
    await setNewPassword("");
    await setNewPasswordRep("");

    await setCurrentPasswordErr({});
    await setNewPasswordErr({});
    await setNewPasswordRepErr({});
    await setChange(false);
  };

  return (
    <form autoComplete="off" noValidate>
      <BoxHeader title={`Passwort ändern`} />

      <ContentBox>
        <Grid container spacing={3} >
          <Grid item md={6} xs={12} sx={{ marginTop: "5px"}} style={{ zIndex: 0 }}>
            <TextField
              fullWidth
              label="aktuelles Passwort"
              name="Passwort"
              type="password"
              autoComplete="current-password"
              onChange={onChangeCurrentPassword}
              required
              value={currentPassword}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item md={6} xs={12} style={{ zIndex: 0 }}>
            <TextField
              fullWidth
              label="neues Passwort"
              name="Passwort"
              type="password"
              autoComplete="current-password"
              onChange={onChangeNewPassword}
              value={newPassword}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12} style={{ zIndex: 0 }}>
            <TextField
              fullWidth
              label="neues Passwort wiederholen"
              name="Passwort"
              type="password"
              autoComplete="current-password"
              onChange={onChangeNewPasswordRep}
              required
              value={newPasswordRep}
              variant="outlined"
            />
          </Grid>
        </Grid>
        {/* </Box> */}


        {/* Error messages from validation form */}
        {Object.keys(currentPasswordErr).map((key, i) => {
          return (
            <div
              key={i}
              className="alert alert-danger"
              role="alert"
              style={{ marginLeft: "35px", marginRight: "20px" }}
            >
              {currentPasswordErr[key]}
            </div>
          );
        })}
        {Object.keys(newPasswordRepErr).map((key, i) => {
          return (
            <div
              key={i}
              className="alert alert-danger"
              role="alert"
              style={{ marginLeft: "35px", marginRight: "20px" }}
            >
              {newPasswordRepErr[key]}
            </div>
          );
        })}
        {Object.keys(newPasswordErr).map((key, i) => {
          return (
            <div
              key={i}
              className="alert alert-danger"
              role="alert"
              style={{ marginLeft: "35px", marginRight: "20px" }}
            >
              {newPasswordErr[key]}
            </div>
          );
        })}
        <div style={{ visibility: !change ? "hidden" : "visible" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ButtonGrid disabled={!change} loading={localLoading} submit={onSubmit} clear={clearHandler} />

          </Box>
        </div>

        {/* Error messages from server */}
        <div style={{ visibility: !message ? "hidden" : "visible" }}>
          <Grid
            item
            marginTop={1}
            marginBottom={4}
            marginLeft={4}
            marginRight={2}
            className={
              saveColor === "success"
                ? "alert alert-success"
                : "alert alert-danger"
            }
            role="alert"
          >
            {message}
          </Grid>
        </div>
      </ContentBox>
    </form >
  );
}
export default memo(PasswordUpdate);
