import { Box, Grid, Typography } from "@mui/material";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

export const ProfileTitle = () => {
  const { currentUser } = useAppContext();
  // console.log(currentUser)

  return (
    // This is the date overview item
    <>
      <div
        style={{
          width: "110%",
          whiteSpace: "nowrap", // Prevent content from wrapping
        }}
      >
        <Typography variant="h6" color="primary">
          User
        </Typography>
      </div>
      <>
        <Grid item sm={12}>
          <div
            style={{
              width: "110%",
              whiteSpace: "nowrap", // Prevent content from wrapping
            }}
          >
            <Typography color="primary" variant="h4" fontWeight={"bold"}>
              Profil
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <div
            style={{
              width: "110%",
              whiteSpace: "nowrap", // Prevent content from wrapping
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                pt: 0,
              }}
            >
              <Typography color="primary" variant="caption">
                von {currentUser.firstName} {currentUser.lastName}
              </Typography>
            </Box>
          </div>
        </Grid>
      </>
    </>
  );
};
