async function createAssignmentArray(data) {
  // console.log(data);
  let assignmenArray = data.thisWeek.concat(
    data.additional.daysToPrepare,
    data.additional.extraDates
  );

  //remove possible undefined values
  assignmenArray = assignmenArray.filter(function (element) {
    return element !== undefined;
  });
  return assignmenArray;
}
exports.createAssignmentArray = createAssignmentArray;
