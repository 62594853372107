import React, { memo, useEffect, useState } from "react";
import { Avatar, ListItemAvatar, Popover } from "@mui/material";
import { useTheme } from "@mui/styles";
import AssignmentAvatar from "../../DeadlineBoxes/AssignmentAvatar";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";
import StyledTooltip from "../../../../../_4GeneralHelpers/2_Tooltips/StyledTooltip";
import EditAssignmentPopover from "../../AddAssignmentBox/EditAssignmentPopover";
// import EditAssignmentPopover from "../AddAssignmentBox/EditAssignmentPopover";

function DraggableWorkboardAssignmentAvatar({ assignment, classView }) {
  const theme = useTheme();
  const { isALeader, isAReferenceStudent, userCourses } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentAssignment, setCurrentAssignment] = useState(assignment);

  const isPersonalType = currentAssignment.type === "personal";
  const viewIsNotRestricted =
    (isALeader || isAReferenceStudent) &&
    (isPersonalType ||
      (userCourses &&
        userCourses.some(
          (course) => course.id === currentAssignment.course_id
        )));

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Update currentAssignment when assignment prop changes
  useEffect(() => {
    // console.log(assignment)
    setCurrentAssignment(assignment);
  }, [assignment]);


  const tooltipTitle = viewIsNotRestricted
    ? "Assignment bearbeiten"
    : "Details ansehen";

  const backgroundColor =
    currentAssignment.status === 4
      ? theme.palette.levels.green
      : currentAssignment.status === 3
        ? theme.palette.levels.lightGreen
        : currentAssignment.status === 2
          ? theme.palette.levels.yellow
          : currentAssignment.status === 1
            ? theme.palette.levels.orange
            : theme.palette.levels.red;

  return (
    <>
      <StyledTooltip title={tooltipTitle} placement="bottom-start" arrow>
        <Avatar
          onClick={handleClick}
          sx={{
            backgroundColor: backgroundColor,
            maxHeight: "90%",
            width: "95%",
            marginTop: "-0.1em",
            marginRight: "-0.2em",
            // paddingLeft: "-0.4em",
            borderRadius: 3,
            cursor: "pointer",
          }}
        >
          <AssignmentAvatar type={currentAssignment.type} marginTop={"0em"} marginLeft={"-0.1em"} />
        </Avatar>
      </StyledTooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <EditAssignmentPopover
          assignment={currentAssignment}
          handleClose={handleClose}
          restricted={!viewIsNotRestricted}
          classView={classView}
        />
      </Popover>
    </>
  );
}

export default memo(DraggableWorkboardAssignmentAvatar);
