import { ArrowDropDown } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { memo } from "react";

function MoreButton({ handleClickOpenDetail }) {
  return (
    <>
      <Button
        color="primary"
        endIcon={<ArrowDropDown fontSize="small" />}
        size="small"
        variant="text"
        onClick={() => handleClickOpenDetail()}
      >
        mehr
      </Button>
    </>
  );
}
export default memo(MoreButton);
