// Function to calculate averages and update assignment properties
export const calculateAssignmentAverages = (assignment, samePropertyAssignments) => {
    // console.log(assignment)
    const averageMyMinutes =
        Math.floor(samePropertyAssignments.reduce(
            (sum, a) => sum + a.my_minutes,
            assignment.my_minutes
        ) / (samePropertyAssignments.length + 1));
    assignment.my_minutes = averageMyMinutes;
    // console.log("averageMinutes: ", averageMyMinutes)

    //update my_minutes_done
    const averageMinutesDone =
        Math.floor(samePropertyAssignments.reduce(
            (sum, a) => sum + a.my_minutes_done,
            assignment.my_minutes_done
        ) / (samePropertyAssignments.length + 1));
    assignment.my_minutes_done = averageMinutesDone;



    // Update the status property of the current assignment
    const percentageNew = averageMinutesDone / assignment.suggested_minutes;
    const averageStatus =
        percentageNew === 0
            ? 0
            : percentageNew < 1 / 3
                ? 1
                : percentageNew < 2 / 3
                    ? 2
                    : percentageNew < 3 / 3
                        ? 3
                        : 4;
    assignment.status = averageStatus
    //Update the average priority
    const averagePriority =
        Math.floor(samePropertyAssignments.reduce(
            (sum, a) => sum + a.priority,
            assignment.priority
        ) / (samePropertyAssignments.length + 1))
    assignment.priority = averagePriority

    return assignment;
};
