import React, { memo } from "react";

function ErrorBox({ error }) {
  return (
    <>
      {Object.keys(error).map((key, i) => {
        return (
          <div key={i} className="alert alert-danger" role="alert">
            {error[key]}
          </div>
        );
      })}
    </>
  );
}

export default memo(ErrorBox);
