import {
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import { memo } from "react";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function FachAuswahl({ subject, change, error }) {
  const { husibuechSubjects } = useAppContext();
  return (
    <>
      <Grid item xs={3} md={12}>
        <InputLabel id="demo-multiple-name-label">
          Fach für neuen Kurs *
        </InputLabel>
        <Select
          displayEmpty
          onChange={change}
          input={<OutlinedInput />}
          value={subject ? subject : ``}
          renderValue={(selected) => {
            return !selected ? <em>Wähle aus</em> : selected.name;
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>bereits erfasst</em>
          </MenuItem>
          {husibuechSubjects &&
            husibuechSubjects.length > 0 &&
            husibuechSubjects.map((course) => (
              <MenuItem key={course.id} value={course}>
                {course.tag + " - " + course.name}
              </MenuItem>
            ))}
        </Select>
        {Object.keys(error).map((key) => {
          return (
            <Grid
              item
              marginTop={1}
              className="alert alert-danger"
              role="alert"
            >
              {error[key]}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
export default memo(FachAuswahl);
