import {Grid } from "@mui/material";
import { memo } from "react";
import AccountProfile from "./AccountProfile";
import CourseSelectionUpdate from "./CourseSelectionUpdate";
import PasswordUpdate from "./PasswordUpdate";
import MainContentGrid from "../../../_4GeneralHelpers/1_Boxes/MainContentGrid";
import { Item } from "../../4DashboardPage/DashboardHelpers/Item";
import UsernameDetail from "./UsernameDetail";
import WorkWeekDetail from "./WorkWeekDetail";
import CurrentCourseSelection from "./CurrentCourseSelection";

function ProfileContent() {
  return (
    <MainContentGrid>
      <Grid item xs={12} mds={6}>
        <Item
          elevation={5}
          sx={{
            padding: { xs: 1, ss: 2, lg: 3 },
          }}
        >
          <AccountProfile />
        </Item>
      </Grid>

      <Grid item xs={12} mds={6}>
        <Item
          elevation={5}
          sx={{
            padding: { xs: 1, ss: 2, lg: 3 },
          }}
        >
          <PasswordUpdate />
        </Item>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Item
          elevation={5}
          sx={{
            padding: { xs: 1, ss: 2, lg: 3 },
          }}
        >
          <UsernameDetail />
        </Item>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Item
          elevation={5}
          sx={{
            padding: { xs: 1, ss: 2, lg: 3 },
          }}
        >
          <WorkWeekDetail />
        </Item>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Item
          elevation={5}
          sx={{
            padding: { xs: 1, ss: 2, lg: 3 },
          }}
        >
          <CourseSelectionUpdate />
        </Item>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Item
          elevation={5}
          sx={{
            padding: { xs: 1, ss: 2, lg: 3 },
          }}
        >
          <CurrentCourseSelection />
        </Item>
      </Grid>
    </MainContentGrid>
  );
}

export default memo(ProfileContent);
