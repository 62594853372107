import React, { useState, useEffect, memo } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import MainContentGrid from "../../../_4GeneralHelpers/1_Boxes/MainContentGrid";
import PersonalDeadlineBox from "./PersonalDeadlineBox";
import { Item } from "./Item";
import { Grid } from "@mui/material";
import userService from "../../../_6Services/user.service";
import authService from "../../../_6Services/auth.service";
import DonutFlip from "../../../_4GeneralHelpers/8_Cardflip/DonutFlip";
import RecentlyAdded from "../DashboardParts/RecentlyAdded/RecentlyAdded";
import { Workboard } from "../DashboardParts/Workboard/Workboard";
import DeadlineAssignments from "../DashboardParts/DeadlineBoxes/DeadlineAssignments";
import WorkloadFlip from "../DashboardParts/WorkloadChart/WorkloadFlip";
const { arraysAreEqual } = require("../../../_3HelperFunctions/_AppHelpers/arraysAreEqual");

function DashboardContent() {
  const { currentUser, isAStudent, isALeader, setCurrentUser, selectedCourse, dashBoardOrderIsFixed } = useAppContext();
  // console.log(currentUser)
  const isALeaderWithCourse =
    isALeader &&
      selectedCourse
      ? true
      : false;

  // console.log(isALeaderWithCourse, currentUser.dashboardOrder)
  // Default items order based on user roles
  const getDefaultItemsOrder = () =>
    isALeaderWithCourse
      ? ["core-deadlines", "personal-deadlines", "recently-added", "workload-box","todo-list"]
      : ["core-deadlines", "personal-deadlines", "doughnut-box", "workload-box", "recently-added", "todo-list"];
  // State to store the order of items
  const [itemsOrder, setItemsOrder] = useState(() => {
    try {
      const storedOrder = currentUser?.dashboardOrder;
      // return getDefaultItemsOrder();
      if (storedOrder && typeof storedOrder === "string") {
        return JSON.parse(storedOrder);
      } else if (Array.isArray(storedOrder)) {
        return storedOrder;
      } else {
        return getDefaultItemsOrder(); // Return the default order
      }
    } catch (error) {
      console.error("Error parsing stored order:", error);
      return getDefaultItemsOrder();
    }
  });

  // Update the order of items when itemsOrder changes
  useEffect(() => {
    const updateDashboard = async () => {
      var shouldUpdateDashboard = true;

      if (currentUser?.dashboardOrder) {
        let storedItems;

        if (typeof currentUser.dashboardOrder === "string") {
          try {
            storedItems = JSON.parse(currentUser.dashboardOrder);
          } catch (error) {
            console.error("Error parsing dashboardOrder:", error);
          }
        } else if (Array.isArray(currentUser.dashboardOrder)) {
          storedItems = currentUser.dashboardOrder;
        }

        if (storedItems) {
          shouldUpdateDashboard = !(arraysAreEqual(itemsOrder, storedItems));
        }
      }

      if (shouldUpdateDashboard) {
        try {
          await updateUserInDB(itemsOrder);
        } catch (error) {
          console.error("Error updating dashboard:", error);
        }
      }
    };

    if (itemsOrder?.length > 0 && currentUser) {
      updateDashboard();
    }
  }, [itemsOrder, currentUser]);

  const updateUserInDB = async (dashboardOrder) => {
    try {
      const response = await userService.updateUser(
        "dashboard",
        { dashboardOrder: dashboardOrder },
        currentUser.id
      );

      const editedUser = { ...currentUser, dashboardOrder: dashboardOrder };
      authService.editCurrentUser(editedUser);
      setCurrentUser(editedUser);
    } catch (error) {
      console.error("Error updating user in DB:", error);
    }
  };

  // Callback function for reordering items
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return; // Dragged outside the list
    }

    const newItemsOrder = Array.from(itemsOrder);
    newItemsOrder.splice(result.source.index, 1);
    newItemsOrder.splice(result.destination.index, 0, result.draggableId);
    await setItemsOrder(newItemsOrder);
  };

  // Components mapping
  const components = {
    "core-deadlines": () => (
      <DeadlineAssignments
        type={isAStudent ? "studentView" : "coreCourse"}
        single={false}
      />
    ),
    "personal-deadlines": () => <PersonalDeadlineBox single={false} />,
    "doughnut-box": () => (isAStudent ? <DonutFlip /> : null),
    "workload-box": () => <WorkloadFlip />,
    "recently-added": () => (
      <RecentlyAdded type={isAStudent ? "student" : "teacher"} />
    ),
    "todo-list": () => (isAStudent ? <Workboard /> : <Workboard />),
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="dashboard-content" direction="vertical">
        {(provided) => (
          <MainContentGrid ref={provided.innerRef} {...provided.droppableProps}>
            {itemsOrder?.map((itemId, index) => {
              if (currentUser?.dashboardOrder?.includes(itemId)) {
                return (
                  <Draggable key={itemId} draggableId={itemId} index={index} isCombineEnabled={true} isDragDisabled={dashBoardOrderIsFixed}>
                    {(provided) => (
                      <Grid
                        item
                        xs={isAStudent && (itemId === "doughnut-box" || itemId === "recently-added") ? 12 : 12}
                        sm={isAStudent && (itemId === "doughnut-box" || itemId === "recently-added") ? 6 : 12}
                        md={itemId === "todo-list" ? 12 : isAStudent && (itemId === "doughnut-box" || itemId === "recently-added") ? 3 : 6}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      // style={{ backgroundColor: itemId === "workload-box" ? "red" : itemId === "recently-added" ? "orange" : itemId === "core-deadlines"? "blue" : "yellow" }}
                      >
                        <Item
                          elevation={5}
                          sx={{ padding: { xs: 1, ss: 2, lg: 3 } }}
                        >
                          {components[itemId]?.()}
                        </Item>
                      </Grid>
                    )}
                  </Draggable>
                );
              } else {
                return null;
              }
            })}
            {provided.placeholder}
          </MainContentGrid>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default memo(DashboardContent);
