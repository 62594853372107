import { memo } from "react";
import { useTheme } from "@mui/styles";
import { CircularProgress, Grid } from "@mui/material";
import MyButton from "./MyButton";
function LoginButtons({ localLoading, submit, errors }) {
  const theme = useTheme();

  return (
    <Grid
      style={{
        width: "90%",
        // height:"100%",
        justifyContent: "center",
        display: "flex",
        marginTop: "1.5em",
        // backgroundColor: "blue",
      }}
    >
      {!localLoading ? (
        <MyButton job={submit} text={"anmelden"} disabled={errors.length>0}/>
      ) : (
        <CircularProgress
          style={{
            color: theme.palette.primary.main,
            // marginTop: "1.5rem",
            // marginLeft: "0.5rem",
          }}
          size={"1.8rem"}
        />
      )}
    </Grid>
  );
}

export default memo(LoginButtons);
