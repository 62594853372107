import React, { memo, useEffect, useState, useRef } from "react";
import { useTheme } from "@mui/styles";
import { Bar } from "react-chartjs-2";
import MiddleBox from "../../../_4GeneralHelpers/1_Boxes/MiddleBox";
import BoxHeader from "../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

const NachkontrolleChart = ({ data }) => {
  const { isAStudent } = useAppContext();
  const theme = useTheme();
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState(undefined);

  useEffect(() => {
    const calculateDataTotal = () => {
      let workloadMinutesTotalSum = 0;
      let workloadMinutesTotalSuggSum = 0;
      let numAssignments = 0;

      for (const assignment of Object.values(data)) {
        workloadMinutesTotalSum += assignment.my_minutes;
        workloadMinutesTotalSuggSum += assignment.suggested_minutes;
        numAssignments++;
      }

      return [
        {
          workloadMinutesTotalSum: Math.fround(workloadMinutesTotalSum / 60),
          workloadMinutesTotalSuggSum: Math.fround(
            workloadMinutesTotalSuggSum / 60
          ),
          numAssignments,
        },
      ];
    };

    const dataTotalTemp = calculateDataTotal();
    setChartData({
      labels: ["Vorschlag Lehrperson", "Deine Planung"],
      datasets: [
        {
          data: [
            dataTotalTemp[0]?.workloadMinutesTotalSuggSum || 0,
            dataTotalTemp[0]?.workloadMinutesTotalSum || 0,
          ],
          backgroundColor: [
            theme.palette.primary.light,
            theme.palette.primary.dark,
          ],
          borderColor: [
            theme.palette.primary.light,
            theme.palette.primary.dark,
          ],
          borderWidth: 1,
        },
      ],
    });
  }, [data, theme.palette.primary]);

  return (
    <>
      <BoxHeader title={isAStudent?"Alle Fächer (vollständig erledigt)":"Alle Kurse (vollständig erledigt)"} options={[]} />
      <MiddleBox>
        <div id="chartWrapper">
          {chartData && chartData.labels && chartData.datasets ? (
            <Bar options={chartOptions} data={chartData} ref={chartRef} />
          ) : (
            "No data available"
          )}
        </div>
      </MiddleBox>
    </>
  );
};

const chartOptions = {
  maintainAspectRatio: false,
  animations: {
    radius: {
      duration: 100,
      easing: "linear",
      loop: (context) => context.active,
    },
  },
  responsive: true,
  scales: {
    y: {
      display: true,
      title: {
        display: true,
        color: "black",
        text: "total erledigte Arbeit [h]",
      },
      beginAtZero: true,
      min: 0,
      grid: {
        display: true,
      },
      ticks: {
        display: true,
      },
    },
    x: {
      display: true,
      grid: {
        display: false,
      },
      stacked: false,
      ticks: {
        autoSkip: false,
        maxRotation: 90,
        minRotation: 0,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

export default memo(NachkontrolleChart);
