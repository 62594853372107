const activationFormValidation = (klasse, zusatzFaecher, abbreviation, currentUser) => {
  const errors = {
    userKlasse: [],
  };

  // Design the desired rules for form completion here:
  if (!klasse && !zusatzFaecher) {
    errors.userKlasse.push("auswählen!")
  }
  if (currentUser.roles.includes("Teacher") && abbreviation.length !== 2) {
    errors.userKlasse.push("Korrektes Kürzel eingeben")
  }

  const isValid = Object.values(errors).every(
    (errorList) => errorList.length === 0
  );
  return {
    isValid,
    errors
  };
};

export default activationFormValidation;
