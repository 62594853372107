import { memo } from "react";
import { Grid, LinearProgress } from "@mui/material";
import CircularProgressWithoutLabel from "./CircularProgressWithoutLabel";

function LoggingOutDashboard() {
  return (
    <>
      <LinearProgress color="primary" />
      <Grid container alignItems="center" direction="column">
        <Grid item xs={12} sx={{ marginTop: { xs: "8em" } }}>
          <CircularProgressWithoutLabel />
        </Grid>
      </Grid>
    </>
  );
}
export default memo(LoggingOutDashboard);
