import { memo } from "react";
import styles from "../../SignUpForm/styles.module.css";
import { Grid } from "@mui/material";

function LoginFields({ email, onChangeEmail, userPassword, onChangePassword }) {
  return (
    <Grid
      item
      style={{
        width: "90%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item style={{ width: "100%" }}>
          <input
            type="email"
            placeholder="Email"
            name="email"
            onChange={onChangeEmail}
            value={email}
            required
            className={styles.input}
          />
        </Grid>
        <Grid item style={{ width: "100%" }}>
          <form>
            <input
              type="password"
              placeholder="Passwort"
              name="userPassword"
              onChange={onChangePassword}
              value={userPassword}
              required
              className={styles.input}
            />
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(LoginFields);
