import { memo } from "react";
import { PageTemplate } from "../0_PageTemplate/PageTemplate";
import UploadPageContent from "./_UploadHelpers/UploadPageContent";

function UploadPage() {

  return (
    <div className="content" sx={{ clear: "left" }}>
      <PageTemplate type={"upload"}>
        <UploadPageContent />
      </PageTemplate>
    </div>
  );
}

export default memo(UploadPage);
