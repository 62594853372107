import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import { memo } from "react";
import HusibuechCircle from "./_HusibuechCircle";

function StatusSelectionPopUpView({ onClose }) {
  const {
    statusList,
    statusSelection,
    setStatusSelection,
  } = useAppContext();

  const changeStatusSelection = (i) => {
    // event.stopPropagation(); // Prevent the event from propagating to the parent elements
    const newStatusArray = [...statusSelection]; // Create a new array to avoid mutating the state directly
    const index = newStatusArray.indexOf(i);
    if (index > -1) {
      newStatusArray.splice(index, 1);
    } else {
      newStatusArray.push(i);
    }
    setStatusSelection(newStatusArray);
  };

  return statusList !== null ? (
    statusList.map((status, i) => (
      <HusibuechCircle
        select={"status"}
        key={i}
        type={"status"}
        keyValue={i}
        fill={statusSelection !== null && statusSelection.includes(i)}
        onClick={(event) => changeStatusSelection(i, event)}
        onClose={() => onClose()}
      />
    ))
  ) : (
    <></>
  );
}

export default memo(StatusSelectionPopUpView);
