import { memo } from "react";
import DashboardContent from "./DashboardHelpers/DashboardContent";
import { PageTemplate } from "../0_PageTemplate/PageTemplate";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import DashboardContentMobile from "./DashboardHelpers/DashboardContentMobile";

function Dashboard() {
  const {isMobile} = useAppContext()
  return (
    <div className="content" sx={{ clear: "left" }}>
      <PageTemplate type={"dashboard"}>
        {isMobile?<DashboardContentMobile/>:
        <DashboardContent />}
      </PageTemplate>
    </div>
  );
}

export default memo(Dashboard);
