import { useState } from "react";
import Popover from "@mui/material/Popover";
import StatusPopUpView from "./StatusPopUpView";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import { saveAssignmentChanges } from "../../_3HelperFunctions/_AssignmentFunctions/saveAssignmentChanges";
import { updateDataSet } from "../../_3HelperFunctions/_LocalUpdates/updateDataSet";
import HusibuechCircle from "./_HusibuechCircle";

//preview and status are used if we are in the popover view and first change the previewAssignment
export default function StatusSeverityPill({
  assignment,
  preview,
  changeStatus,
}) {
  const {
    currentUser,
    isALeader,
    schoolYearData,
    setUnresolvedSchoolYearData,
  } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (keyValue, event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeStatusCloseAndSave = async (i) => {
    // console.log("i, ", i, preview)
    if (preview !== "preview") {
      // console.log("let's go")

      //now also adapt the workload minutes done according to the logic
      let newStatus = i;
      let workloadMinutesDoneCopy = assignment.my_minutes_done;
      let workloadMinutesTotalCopy = assignment.workladMinuesTotaal;
      //with this status we can define a "limits"
      if (i > assignment.status) {
        //we are going up...
        var my_minutes_done
        if (
          workloadMinutesDoneCopy <
          (workloadMinutesTotalCopy * newStatus) / 4
        ) {
          //this is the minimal level for this status
          my_minutes_done =
            (workloadMinutesTotalCopy * newStatus) / 4;
        }
      } else {
        //we are going down
        if (
          workloadMinutesDoneCopy <
          (workloadMinutesTotalCopy * newStatus) / 4 ||
          workloadMinutesDoneCopy >=
          (workloadMinutesTotalCopy * (newStatus + 1)) / 4
        ) {
          //this is the minimal level again
          my_minutes_done =
            (workloadMinutesTotalCopy * newStatus) / 4;
        }
      }

      try {
        const updateValues = [{ property: "status", value: i }, { property: "my_minutes_done", value: my_minutes_done }]
        const responseAssignment = await saveAssignmentChanges(
          assignment,
          updateValues,
          "statusPill",
          currentUser
        );
        const updatedSchoolYearData = await updateDataSet(schoolYearData, responseAssignment, isALeader, "statusPill")
        setUnresolvedSchoolYearData(updatedSchoolYearData);

        if (responseAssignment.status === 4) {
          // setOpenSnackbar(true); (does not work for sneak view)
        }

        handleClose();
      } catch (error) {
        alert("Es gab ein Problem im Update");
        console.log(error);
        handleClose();
      }
    } else {
      changeStatus(i);
      handleClose();
    }
  };


  return (
    <>
      <HusibuechCircle
        type={"status"}
        keyValue={assignment.status}
        onClick={handleClick}
        fill={false}
        select={"own"}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <StatusPopUpView
          changeStatus={changeStatusCloseAndSave}
          assignment={assignment}
        />
      </Popover>
    </>
  );
}
