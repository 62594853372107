import { Typography, Box, Grid } from "@mui/material";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import { FilterBoxFilters } from "./FilterBoxFilters";

export const FilterBox = ({ reduced, toggleOnly }) => {
  const { isAStudent, isSmallScreen } = useAppContext();
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end"
      sx={{
        // backgroundColor: "green",
        overflowX: "auto", // Set overflowX to "auto" to allow horizontal scrolling
        overflowY: "hidden",
        maxHeight: "5em", // Set a fixed height for the container
        marginLeft: "0.6em",
        whiteSpace: "nowrap", // Prevent content from wrapping
        display: "flex",     // Use flexbox to arrange icons horizontally
        "&::-webkit-scrollbar": {
          width: "0px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "your-desired-color", // Set your desired color
          borderRadius: "6px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent", // or any background color
        },
      }}>
      <Grid container direction="column" alignItems={isSmallScreen?"center":"flex-end"}
        style={{
          whiteSpace: "nowrap", // Prevent content from wrapping
        }}>
        <Grid item>

          <Typography
            variant="h6" // textTransform={"uppercase"}
            color="primary"
            textAlign={"right"}
          >
            {isAStudent ? "Was möchtest du sehen?" : "Was möchten Sie sehen?"}
          </Typography>
        </Grid>
        <Grid item style={{
          overflow: "auto", whiteSpace: "nowrap", // Prevent content from wrapping
        }}>
          <FilterBoxFilters reduced={reduced} toggleOnly={toggleOnly} />
        </Grid>
      </Grid>

    </Box>
  );
};
