const courseFormValidation = (klasse, zusatzFaecher) => {
  const errors = {
    userKlasse: [],
  };

  // Design the desired rules for form completion here:
  if (!klasse && !zusatzFaecher) {
    errors.userKlasse.push("auswählen!")
  }

  const isValid = Object.values(errors).every(
    (errorList) => errorList.length === 0
  );
  return {
    isValid,
    errors
  };
};

export default courseFormValidation;
