import { memo, useState } from "react";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

import { Grid } from "@mui/material";
import userService from "../../../_6Services/user.service";
import { useEffect } from "react";
import FeedbackMessage from "../../2LoginAndAuthentication/Login/LoginHelpers/FeedbackMessage";
import ContentBox from "../../../_4GeneralHelpers/1_Boxes/ContentBox";
import CoreCourseSelection from "../_CourseSelectionHelpers/CoreCourseSelection";
import CourseSelection from "../_CourseSelectionHelpers/CourseSelection";
import ButtonGrid from "../_CourseSelectionHelpers/ButtonGrid";
import courseFormValidation from "../_CourseSelectionHelpers/courseFormValidation";
import createUpdateObject from "../_CourseSelectionHelpers/createUpdateObject";
import BoxHeader from "../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";
import { createTeacherName } from "../../../_3HelperFunctions/_NameHelpers/nameHelpers";

function CourseSelectionUpdate({ handleClose }) {
  const {
    currentUser,
    userCourses,
    setUserCourses,
    husibuechCourses,
    isAuthenticated,
    husibuechUserKurse,
    setHusibuechUserKurse,
    husibuechCoreCourses,
    myCoreCourses,
    setMyCoreCourses,
    setSchoolYearLoading,
    setUpdateCurrentUserData,
  } = useAppContext();
  const [change, setChange] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);

  const [message, setMessage] = useState("");
  const [saveColor, setSaveColor] = useState("primary");

  const [klasse, setKlasse] = useState(undefined);
  const [userKlasseErr, setUserKlasseErr] = useState({});

  const [zusatzFaecher, setZusatzfaecher] = useState([]);

  /**
   * Set the views coreCourses
   */
  const [husibuechUserKlasseRegistered, setHusibuechUserKlasseRegistered] =
    useState(
      husibuechCoreCourses?.filter(
        (klasse) =>
          [myCoreCourses]?.filter((coreCourse) => coreCourse.id === klasse.id)
            .length > 0
      )
    );
  const [husibuechUserKlasseFiltered, setHusibuechUserKlasseFiltered] =
    useState(
      husibuechCoreCourses?.filter(
        (klasse) =>
          !myCoreCourses?.filter((coreCourse) => coreCourse.id === klasse.id)
            .length > 0
      )
    );
  // console.log(husibuechCoreCourses)

  /**
   * Set the views courses
   */
  const [husibuechUserKurseRegistered, setHusibuechUserKurseRegistered] =
    useState(
      husibuechCourses?.filter(
        (kurs) =>
          myCoreCourses?.filter((course) => course.id === kurs.id).length > 0
      )
    );
  const [husibuechUserKurseFiltered, setHusibuechUserKurseFiltered] = useState(
    husibuechCourses?.filter(
      (kurs) =>
        !userCourses?.filter((course) => course.id === kurs.id).length > 0
    )
  );

  /**
   * Handle Change Methods
   */

  const handleChangeKlasse = (e) => {
    setChange(true);
    setKlasse(e.target.value);
  };

  const handleChangeZusatfaecher = (e) => {
    setChange(true);
    const {
      target: { value },
    } = e;
    setZusatzfaecher(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.name.split(",") : value
    );
  };
  /**
   * Handle Selection view change for courses
   */
  useEffect(() => {
    if (
      husibuechCourses &&
      husibuechCourses.length > 0 &&
      userCourses &&
      userCourses.length > 0
    ) {
      //  filter the courses that are already part of the user's course
      let husibuechKurseCopy = husibuechCourses.filter(
        (kurs) =>
          !userCourses?.filter((course) => course.id === kurs.id).length > 0
      );

      setHusibuechUserKurse(husibuechKurseCopy);
    }
  }, [userCourses, husibuechCourses]);

  /**
   * Set View of Courses
   */

  useEffect(() => {
    const update = async () => {
      //  filter the courses that are already part of the user's course
      let husibuechKurseCopy = husibuechCourses?.filter(
        (kurs) =>
          !userCourses?.filter((course) => course.id === kurs.id).length > 0
      );
      // console.log("we changed the view of the filtered Courses")
      /**
       * Deal with registered courses
       */
      let husibuechKurseRegisteredCopy = husibuechCourses.filter(
        (kurs) =>
          userCourses.filter((course) => course.id === kurs.id).length > 0
      );

      await setHusibuechUserKurseFiltered(husibuechKurseCopy);
      await setHusibuechUserKurseRegistered(husibuechKurseRegisteredCopy);
    };
    if (husibuechCourses?.length > 0 && userCourses?.length > 0) {
      update();
    }
  }, [userCourses, husibuechCourses]);

  /**
   * Set View of CoreCourses
   */
  useEffect(() => {
    const update = async () => {
      //  filter the courses that are already part of the user's course

      let husibuechKlasseCopy = husibuechCoreCourses.filter(
        (kurs) =>
          !myCoreCourses?.filter((course) => course.id === kurs.id).length > 0
      );
      // console.log("we changed the view of the filtered Courses", husibuechKlasseCopy)
      /**
       * Deal with registered courses
       */
      let husibuechKlasseRegisteredCopy = husibuechCoreCourses.filter(
        (kurs) =>
          myCoreCourses?.filter((course) => course.id === kurs.id).length > 0
      );

      // console.log("we changed the view of the registered Courses", husibuechKlasseRegisteredCopy)

      await setHusibuechUserKlasseFiltered(
        husibuechKlasseCopy ? husibuechKlasseCopy : []
      );
      await setHusibuechUserKlasseRegistered(
        husibuechKlasseRegisteredCopy ? husibuechKlasseRegisteredCopy : []
      );
    };
    if (husibuechCoreCourses?.length > 0 && myCoreCourses?.length >= 0) {
      update();
    }
  }, [myCoreCourses, husibuechCoreCourses]);

  useEffect(() => {
    const update = async () => {
      await setHusibuechUserKurse(husibuechCourses);
    };
    if (isAuthenticated && husibuechCourses?.length && !husibuechUserKurse) {
      update();
    }
  }, [isAuthenticated, husibuechCourses]);

  const rejectAllChanges = async (event) => {
    event.preventDefault();

    //we return to default
    await setKlasse([]);
    await setZusatzfaecher([]);
    await setMessage("");
    await setUserKlasseErr({});
    await setLocalLoading(false);
    await setChange(false);
  };

  /**
   * save the changes
   */
  const submitAllChanges = async (event) => {
    event.preventDefault();
    const { isValid, errors } = await courseFormValidation(
      klasse,
      zusatzFaecher
    );
    setUserKlasseErr(errors);

    if (isValid) {
      //we can work with the update information
      update();
    } else {
      setTimeout(() => {
        setMessage("");
        setUserKlasseErr({});
        setChange(false);
      }, 4000);
    }
  };

  const update = async () => {
    try {
      /**
       * Create the update object
       */
      const updateObject = await createUpdateObject(
        klasse,
        zusatzFaecher,
        userCourses,
        currentUser
      );
      // console.log(updateObject);
      const response = await userService.updateUser(
        "courses",
        updateObject,
        currentUser.id
      );
      //backend update worked successfully
      await updateFrontendData(updateObject);
      //frontend update worked successfully
      await setLocalLoading(false);

      await setSaveColor("success");
      await setMessage("Update erfolgreich");
      setTimeout(async () => {
        // console.log("Let's go back to normal")
        await setSaveColor("primary");
        await setMessage("");
        await setUserKlasseErr({});
        await setChange(false);
        await setUpdateCurrentUserData(true);
        setTimeout(async () => {
          await setSchoolYearLoading(true);
        }, 1000);
      }, 2000);
    } catch (error) {
      console.log(error);
      if (handleClose) {
        handleClose();
      }
      await setLocalLoading(false);
      await setSaveColor("warning");
      await console.log(error);
      await setMessage(
        error?.response?.data?.message
          ? error.response.data.message
          : "ungültige Angaben"
      );
      setTimeout(async () => {
        await setSaveColor("primary");
        await setMessage("");
        await setUserKlasseErr({});
        await setChange(false);
      }, 4000);
    }
  };

  const updateFrontendData = async (updateObject) => {
    // updates the frontend data with the new courses
    // console.log("updateObject: ", updateObject.coreCourseDTO);
    if (updateObject.coreCourseDTO) {
      //deal with new coreCourses
      // console.log("we've got new coreCourses");
      await setMyCoreCourses([updateObject.coreCourseDTO]);
      await setKlasse([]);
    }
    if (updateObject.courseDTOList) {
      //deal with new courses
      let userCoursesCopy = userCourses;
      userCoursesCopy = userCoursesCopy.concat(updateObject.courseDTOList);
      await setUserCourses(userCoursesCopy);
      await setZusatzfaecher([]);
    }
  };

  // console.log(husibuechUserKurseFiltered);
  return (
    <form autoComplete="off" noValidate>
      <BoxHeader title={"Neue Kurse auswählen"} />
      <ContentBox>
        <Grid container spacing={3}>
          <CoreCourseSelection
            klasse={klasse}
            errors={userKlasseErr}
            handleChange={handleChangeKlasse}
            selections={husibuechUserKlasseRegistered}
            options={husibuechUserKlasseFiltered.sort((a, b) =>
              a.name.localeCompare(b.name)
            )}
          />

          <CourseSelection
            zusatzFaecher={zusatzFaecher}
            handleChange={handleChangeZusatfaecher}
            //show already selected courses: first "normal courses", then mixed courses
            selections={husibuechUserKurseRegistered
              .filter((course) => !course.mixed_course)
              .sort((a, b) => a.name.localeCompare(b.name))
              .concat(
                husibuechUserKurseRegistered
                  .filter((course) => course.mixed_course)
                  .sort((a, b) => a.name.localeCompare(b.name))
              )}
            //filter correct options: first: find "mixed courses" then add "normal courses", order by coreCourseName
            options={husibuechUserKurseFiltered
              .filter((course) => course.mixed_course)
              .sort((a, b) => a.name.localeCompare(b.name))
              .concat(
                husibuechUserKurseFiltered
                  .filter((course) => !course.mixed_course)
                  .sort((a, b) =>
                    createTeacherName(a.name).localeCompare(
                      createTeacherName(b.name)
                    )
                  )
              )}
          />

          <ButtonGrid
            disabled={!change}
            loading={localLoading}
            submit={submitAllChanges}
            clear={rejectAllChanges}
          />

          {message.length > 0 && (
            <FeedbackMessage
              style={{ marginTop: "0.75em" }}
              errors={saveColor === "success" ? [] : [message]}
              msg={saveColor !== "success" ? [] : [message]}
            />
          )}
        </Grid>
      </ContentBox>
    </form>
  );
}
export default memo(CourseSelectionUpdate);
