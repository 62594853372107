async function calculateMinutes(
    assignments,
) {
    // console.log(assignments);

    let minutes = 0;
    assignments?.forEach((assignment) => {
        if (
            assignment.my_minutes &&
            assignment.my_minutes !== -1
        ) {
            minutes = minutes + assignment.my_minutes;
        } else {
            minutes = minutes + assignment.suggested_minutes;
        }
    });
    // console.log(minutes);
    return minutes;

}
exports.calculateMinutes = calculateMinutes;
