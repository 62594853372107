import moment from 'moment';

export const getUpdatedExtraDates = (extraDates, referenceDate, sourceId, resultId, list) => {
  // console.log("values: ", sourceId, list[0], resultId)
  if (sourceId === list[0] && resultId !== list[0]) {
    // An extra assignment has moved into the current week - patch it
    const newDate = moment(referenceDate).startOf("isoWeek").format("YYYY-MM-DD");
    // console.log("let's add...", newDate)
    if (!extraDates || extraDates === null || !extraDates.includes(newDate)) {
      // Include the new date if not already present
      extraDates = [newDate, ...(extraDates || '').split(',')].filter(date => date.trim()).join(', ');
    }
  } else if (extraDates && sourceId !== list[0] && resultId === list[0]) {
    // We move the assignment off the kanban board
    // console.log("let's remove...")
    // Remove dates within the same ISO week as referenceDate
    const startDate = moment(new Date(referenceDate)).startOf("isoWeek");
    const endDate = moment(new Date(referenceDate)).endOf("isoWeek");
    extraDates = (extraDates || '').split(',')
      .map(date => date.trim())
      .filter(date => !(moment(new Date(date)).isSameOrAfter( // we keep all the date values that are not in the week of the referenceDate
        startDate
      ) &&
        moment(new Date(date)).isSameOrBefore(
          endDate
        )))
      .join(', ');
  }
  // console.log("new: ", extraDates)

  return extraDates?.length > 0 ? extraDates : null;
};
