import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import StyledTooltip from "../../_4GeneralHelpers/2_Tooltips/StyledTooltip";

const SeverityPillRoot = styled("span")(({ theme, ownerState }) => {
  const backgroundColor = theme.palette[ownerState.color].main;
  const color = theme.palette[ownerState.color].contrastText;

  return {
    alignItems: "center",
    backgroundColor,
    borderRadius: 12,
    color,
    cursor: "default",
    display: "inline-flex",
    flexGrow: 0,
    flexShrink: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 2,
    fontWeight: 600,
    justifyContent: "center",
    letterSpacing: 0.5,
    minWidth: 20,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    // textTransform: "uppercase",
    whiteSpace: "nowrap",
  };
});

export const SeverityPill = (props) => {
  const { color = "primary", children, ...other } = props;
  const { currentUser, isALeader, isAStudent } = useAppContext();
  const ownerState = { color };
  // console.log(props.disabled);

  return (
    <StyledTooltip
      title={
        <>
          {props.origin === "semesterTable" && (
            <div>{props.assignment.name}</div>
          )}
          {isAStudent ? (
            <>
              <div style={{ whiteSpace: "pre-line" }}>
                {"Bereits erledigt: " +
                  props.assignment.my_minutes_done +
                  " min." +
                  "\nNoch: " +
                  (props.assignment.my_minutes -
                    props.assignment.my_minutes_done) +
                  " min."}
              </div>
              <div style={{ whiteSpace: "pre-line" }}>
                {"\nLehrervorschlag: " +
                  props.assignment.suggested_minutes +
                  " min." + "\nVorbereitung: " +
                  props.assignment.suggested_days +
                  (props.assignment.suggested_days === 1 ? " Tag" : " Tage")}
              </div>
            </>
          ) : props.disabled && isALeader || props.classView && isALeader ? ( //the leader is looking at a "foreign" assignment
            <div style={{ whiteSpace: "pre-line" }}>
              {"Lehrervorschlag: " +
                props.assignment.suggested_minutes +
                " min" + "\nVorbereitung: " +
                props.assignment.suggested_days +
                (props.assignment.suggested_days === 1 ? " Tag." : " Tage") +
                "\nBereits durchschnittlich von SuS erledigt: " +
                props.assignment.my_minutes_done + " min" +
                "\nDurchschnittlich erwarteter Aufwand der SuS: " +
                props.assignment.my_minutes +" min"
              }
            </div>
          ) : ( //leader is looking at one of his own assignments
            <>
              <div style={{ whiteSpace: "pre-line" }}>
                { "Aufwand für SuS gemäss Ihrem Vorschlag: " +
                  props.assignment.suggested_minutes +
                  " min" +
                  // "\n" +
                  "\nSie haben bereits für sich erledigt: " +
                  props.assignment.my_minutes_done +
                  " min" +
                  "\nIhr Geschätzer Zeitaufwand: " +
                  props.assignment.my_minutes +
                  " min, Vorbereitung: " +
                  props.assignment.my_days +
                  (props.assignment.my_days === 1 ? " Tag" : " Tage")}
              </div>
            </>
          )}
        </>
      }
      placement="bottom-start"
      arrow
      sx={{ cursor: "null" }}
    >
      <SeverityPillRoot ownerState={ownerState} {...other}>
        {children}
      </SeverityPillRoot>
    </StyledTooltip>
  );
};

SeverityPill.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "info",
    "warning",
    "success",
  ]),
};
