import { Grid } from "@mui/material";
import React, { memo } from "react";
import { CustomTextField } from "../../../../../_3HelperFunctions/CustomTextField";
import { useTheme } from "@mui/styles";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";

function AufwandDoneBox({
  restricted,
  previewAssignment,
  setPreviewAssignment,
  changesCount,
  setChangesCount,
  setWorkloadMinutesDone,
  my_minutes_done,
  my_minutes,
  setMyMinutes,
  suggested_minutes,
  status,
  setStatus,
  workloadErr,
  personalView,
}) {
  const theme = useTheme()
  const {appInDarkMode} = useAppContext()
  const change = (e) => {
    let changesCountCopy = changesCount;
    // console.log(previewAssignment);

    if (parseInt(e.target.value) === previewAssignment.my_minutes_done) {
      //we have gone back to default
      setChangesCount(changesCountCopy - 1);
    } else if (
      isNaN(my_minutes_done) ||
      previewAssignment.my_minutes_done !== my_minutes_done
    ) {
      // do noting
    } else {
      // new change
      setChangesCount(changesCountCopy + 1);
    }
    let finalMinutesDone =
      e.target.value === ""
        ? 0
        : parseInt(e.target.value, 10) < 0
          ? 0
          : parseInt(e.target.value, 10);
    // console.log(finalMinutesDone);
    // console.log(suggested_minutes);
    const newWorkloadMinutesTotal = finalMinutesDone;
    if (finalMinutesDone >= my_minutes) {
      // console.log("we have a check...", my_minutes)
      setMyMinutes(newWorkloadMinutesTotal);
    }

    if (
      finalMinutesDone >= newWorkloadMinutesTotal &&
      finalMinutesDone >= suggested_minutes
    ) {
      // console.log("limit");
      finalMinutesDone = newWorkloadMinutesTotal;
    }

    setWorkloadMinutesDone(finalMinutesDone);

    //then consider the status set!
    let previewAssignmentCopy = previewAssignment;
    let statusCopy = status;
    let workloadMinutesDoneNew = e.target.value;
    let workloadMinutesTotalCopy = my_minutes;

    // consier these boundaries
    const percentageNew = workloadMinutesDoneNew / workloadMinutesTotalCopy;
    //100% done == done:
    const theoreticalStatus =
      percentageNew === 0
        ? 0
        : percentageNew < 1 / 3
          ? 1
          : percentageNew < 2 / 3
            ? 2
            : percentageNew < 3 / 3
              ? 3
              : 4;
    if (statusCopy !== theoreticalStatus) {
      previewAssignmentCopy.status = theoreticalStatus;
      setPreviewAssignment(previewAssignment);
      setStatus(theoreticalStatus);
    }
    // workloadMinutesDoneCopy < (workloadMinutesTotalCopy * newStatus)
  };

  return (
    <>
      <CustomTextField
        id={personalView ? "myminutesDone" : "minutesDone"}
        placeholder={personalView ? "myErledigt" : "Erledigt"}
        label={personalView ? "erledigt min" : "schon erledigt"}
        fullWidth
        variant="outlined"
        // required
        autoFocus={false}
        type="number"
        value={
          //students can change this value box, it will change workloadminutesTotal
          my_minutes_done === -1 ? 0 : my_minutes_done
        }
        disabled={restricted}
        onChange={change}
        inputProps={{ style: { fontSize: "0.9em" } }}
        appindarkmode={appInDarkMode.toString()}
        theme={theme}
      />
      {Object.keys(workloadErr).map((key, i) => {
        return (
          <div key={i} className="alert alert-danger" role="alert">
            {workloadErr[key]}
          </div>
        );
      })}
    </>
  );
}

export default memo(AufwandDoneBox);
