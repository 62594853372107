import styled from "@emotion/styled";
import { tooltipClasses } from "@mui/material";
import { Tooltip } from "@mui/material";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    top: -8,
  },
});

export default StyledTooltip;