import { calculateAssignmentAverages } from "./calculateAssignmentAverages";
import { updateInvolvedCoreCourses } from "./updateInvolvedCoreCourses";

export const filterUniqueAssignments = async (assignments, filterProperty) => {
  const uniqueAssignments = await assignments.reduce(
    async (uniqueListPromise, assignment) => {
      const uniqueList = await uniqueListPromise;

      // Find all assignments with the same filterProperty value
      const samePropertyAssignments = uniqueList.filter(
        (uniqueAssignment) =>
          uniqueAssignment[filterProperty] === assignment[filterProperty]
      );

      if (samePropertyAssignments.length > 0) {
        // Calculate the average of my_minutes for the assignments with the same filterProperty
        assignment = await calculateAssignmentAverages(assignment, samePropertyAssignments)
        //update the involved core_course_ids the return value of assignment.core_course_id is now an array
        assignment = await updateInvolvedCoreCourses(assignment, samePropertyAssignments)
      }

      // Check if the property specified by filterProperty is not already in the uniqueList
      if (
        !uniqueList.some(
          (uniqueAssignment) =>
            uniqueAssignment[filterProperty] === assignment[filterProperty]
        )
      ) {
        uniqueList.push(assignment);
      }

      return uniqueList;
    },
    Promise.resolve([])
  );

  return uniqueAssignments;
};

// export const filterUniqueAssignments = async (assignments, filterProperty) => {
//   const uniqueAssignments = await assignments.reduce(
//     (uniqueList, assignment) => {
//       // Check if the property specified by filterProperty is not already in the uniqueList
//       if (
//         !uniqueList.some(
//           (uniqueAssignment) =>

//             uniqueAssignment[filterProperty] === assignment[filterProperty]
//         )
//       ) {
//         uniqueList.push(assignment);
//       }
//       return uniqueList;
//     },
//     []
//   );
//   return uniqueAssignments;
// };
