async function calculateMinutesDone(
    assignments,
) {
    // console.log(assignments);
    let minutes = 0;
    assignments?.forEach((assignment) => {
        minutes = minutes + assignment.my_minutes_done
    });
    // console.log(minutes);
    return minutes;
}
exports.calculateMinutesDone = calculateMinutesDone;
