import React from "react";
import { Box, Typography } from "@mui/material";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const ModeSwitch = styled(Switch)(({ theme }) => ({
  width: 45,
  height: 30,
  padding: 8,
  "& .MuiSwitch-switchBase": {
    marginTop: 4,
    marginLeft: -1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    // opacity: 1,
    // backgroundColor: theme.palette.mode === "dark" ? '#39393D' : "#f0ecf4",
    backgroundColor: theme.palette.mode === "light" ? "#2ECA45" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    borderRadius: 15 / 2,
    marginTop: -1,
    marginLeft: 1,
  },
}));

export const ThemeSwitchBox = () => {
  const { appInDarkMode, setAppInDarkMode } = useAppContext();
  // console.log("we render this...")
  const onChange = (event) => {
    // console.log("called");
    event.preventDefault();
    setAppInDarkMode(!appInDarkMode);
  };

  return (
    <Box
      sx={{
        // backgroundColor: "red",
        width: "100%",
        minHeight: "4.2em",
        height: "100%",
        display: "flex", // Enable flex layout
        alignItems: "center", // Center vertically
        position: "relative",
        overflowY: "auto",
        overflowX: "auto", // Hide x-axis scrollbar
        "&::-webkit-scrollbar": {
          width: "0px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "your-desired-color", // Set your desired color
          borderRadius: "6px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent", // or any background color
        },
      }}
    >
      <div
        style={{
          width: "100%",
          textAlign: "center",
          // backgroundColor: "green",
        }}
      >
        {/* <FormControlLabel
          style={{
            width: "100%",
            marginLeft: "0.5em",
            textAlign: "center",
            backgroundColor: "green",
          }}
          control={
            <ModeSwitch defaultChecked onChange={onChange} label="End" />
          }
          label={
            appInDarkMode ? "Dark Mode beenden?" : "In den Dark Mode wechseln?"
          }
        /> */}
        <ModeSwitch defaultChecked onChange={onChange} label="End" />
        <Typography variant="h7" color="primary">
          {appInDarkMode ? "Dark Mode beenden?" : "In den Dark Mode wechseln?"}
        </Typography>
      </div>
    </Box>
  );
};
