const {
  default: assignmentService,
} = require("../../_6Services/assignment.service");
const { createUpdateAssignment } = require("./createUpdateAssignment");

async function saveAssignmentChanges(assignment, updateValues, origin, currentUser) {
  // console.log("beforeUpdate: ", assignment)

  const updatedAssignment = await createUpdateAssignment(assignment, updateValues)
  // console.log("beforeService: ", updatedAssignment)
  try {
    const response = await assignmentService.patchAssignment(
      updatedAssignment,
      origin,
      currentUser
    );
    // console.log(response.data.assignment)
    return response?.data?.assignment; // Return the response for further processing if needed
    // return updatedAssignment
  } catch (error) {
    // Handle errors, or you can let them propagate up to the calling function
    console.error("Error in saveAssignmentChanges:", error);
    throw error;
  }
}
exports.saveAssignmentChanges = saveAssignmentChanges;
