import React from "react";
import { Grid, SwipeableDrawer, Typography } from "@mui/material";
import MenuRibbon from "./2_PageRibbon/MenuRibbon";
import { useTheme } from "@mui/styles";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import LoadingDashboardContent from "../0Navigation/3Loading/LoadingDashboardContent";
import LoggingOutDashboard from "../0Navigation/3Loading/LoggingOutDashboard";
import ActivationForm from "../../_4GeneralHelpers/9_popups/UserActivation/ActivationForm";
import { SideList } from "../../_4GeneralHelpers/7_ToDos/ToDoSideBar/SideList";
import RegularFeedbackForm from "../../_4GeneralHelpers/FeedbackForm/RegularFeedbackForm";

export const PageTemplate = ({ type, children }) => {
  const theme = useTheme();
  const { schoolYearLoading, loggingOut, currentUser, openToDoList, setOpenToDoList, openFeedbackView, setOpenFeedbackView } = useAppContext()

  /**
 * for slider:
 */
  const toggleSlider = () => {
    setOpenToDoList(!openToDoList);
  };

  return schoolYearLoading ? (
    <LoadingDashboardContent />
  ) : loggingOut ? (
    <LoggingOutDashboard />
  ) : currentUser ? (
    <Grid container style={{
      display: "flex", flexDirection: "column"
    }}>
      <RegularFeedbackForm open={openFeedbackView} setOpen={setOpenFeedbackView}/>
      <ActivationForm />
      {/* Item 1 */}
      <Grid
        item
        style={{
          backgroundColor: theme.palette.background.paper,
          marginTop: "4em",
          height: "6em", // Set your desired height for Item 1
          zIndex: 1,
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        <MenuRibbon type={type} />
      </Grid>

      {/* Spacer to push down content */}
      <Grid item>
        <div style={{
          height: "6.5em",
        }} />
      </Grid>

      {/* Item 2 */}
      <Grid
        container
        style={{
          flexGrow: 1, // Allow Item 2 to grow and take remaining height
          overflow: "hidden",
          // zIndex: -1, // Set a lower z-index value to make everything disappear behind the menuRibbon
        }}
      >
        <>{children}
          {openToDoList && (
            <SwipeableDrawer
              className="iDoDrawer"
              transitionDuration={1000}
              swipeAreaWidth={30}
              open={openToDoList}
              anchor="left"
              onClose={toggleSlider}
              onOpen={toggleSlider}
            >
              <SideList />
            </SwipeableDrawer>
          )}</>
      </Grid>
      <Grid item>
        <div style={{ height: "4em" }} />
        <div style={{ paddingLeft: "1.5em" }}>
          <Typography variant="h7" color="textSecondary">
            powerd by: husibuech.ch
          </Typography>
        </div>

        <div style={{ height: "2em" }} />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};
