import semesterService from "../../../_6Services/semester.service.js";

export const calculateSemesterValues = async (
  data,
  currentSemesterStartDate,
  setCurrentSemesterStartDate,
  setCurrentSemesterName,
  setSemesterData,
  selectedCourse,
  currentUser,
  personal,
  viewCourseOnly
) => {
  const semesterStart = await semesterService.getStartDateOfSemester(
    data,
    currentSemesterStartDate
  );
  setCurrentSemesterStartDate(semesterStart);
  let name = semesterService.getSemesterName(data, semesterStart)
  setCurrentSemesterName(name)
  const semesterData = await semesterService.getSemesterData(
    data,
    currentSemesterStartDate,
    selectedCourse,
    currentUser,
    personal,
    viewCourseOnly
  );
  await setSemesterData(semesterData);
  // console.log("we set the semesterdata: ", semesterData)
};
