import { memo } from "react";
import WeekNavigation from "./WeekNavigation";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";

function DisabledButtons({ children }) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div style={{
      display: "flex", width: "100%",
      alignItems: "center",
    }}>
      <div>
        <WeekNavigation title="" disabled={true} arrowLeft={true}/>
      </div>
      <div
        style={{
          flex: "1",
          marginLeft: isSmallScreen ? "5%" : "", // Adjust margin as needed for space around the content
          marginRight: isSmallScreen ? "5%" : "-10%", // Adjust margin as needed for space around the content
          //         flex: "0 0 78%", // Allow the children to take up only 98% of the width
          // background: "green",
          width: isSmallScreen ? "60%" : ""

        }}
      >
        {children}
      </div>
      <div>
        <WeekNavigation title="" disabled={true}/>

      </div>
    </div>
  );
}

export default memo(DisabledButtons);
