import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import { useEffect } from "react";
import { SnackbarContent } from "@mui/material";
import { memo } from "react";
import { useTheme } from "@mui/styles";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function TransitionsSnackbar({ open, setOpen, title }) {
  const theme = useTheme();
  const [state, setState] = useState({
    open: open,
    Transition: Fade,
  });
  const vertical = "bottom";
  const horizontal = "right";

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        handleClick();
      }, 100);
    }
  }, [open]);

  const handleClick = () => {
    setState({
      open: true,
      vertical: "bottom",
      horizontal: "right",
      Transition: SlideTransition,
    });
    setTimeout(() => {
      handleClose();
    }, 1200);
  };

  const handleClose = () => {
    setOpen(false);
    setState({
      ...state,
      open: false,
    });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={state.open}
      onClose={handleClose}
      TransitionComponent={state.Transition}
      message="toDo gelöscht"
      key={state.Transition.name}
      autoHideDuration={1500}
      severity="info"
    >
      <SnackbarContent
        style={{
          backgroundColor: theme.palette.levels.lightGreen,
        }}
        message={<span id="client-snackbar">{title}</span>}
      />
    </Snackbar>
  );
}
export default memo(TransitionsSnackbar);
