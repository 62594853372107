import { Typography } from "@mui/material";
import React, { memo } from "react";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";

function WorkloadFitsIn() {
  const { isAStudent, personalNumberOfDoesNotFit, classNumberOfDoesNotFit } =
    useAppContext();
  // console.log("factor:", personalNumberOfDoesNotFit, classNumberOfDoesNotFit)
  const noWorries =
    personalNumberOfDoesNotFit === 0 && classNumberOfDoesNotFit === 0;
  const amountOfWorries = personalNumberOfDoesNotFit + classNumberOfDoesNotFit;

  const getTypeColor = (count) =>
    count === 0 ? "levels.green" : count <= 3 && classNumberOfDoesNotFit<=2 ? "levels.orange" : "levels.red";

  return (
    <>
      <div
        style={{
          // visibility: noWorries ? "hidden" : "visible",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {noWorries ? (
          <Typography color={getTypeColor(amountOfWorries)} variant="h7">
            Alles richtig gemacht mit der Zeitbudgetierung. Weiter so...!
          </Typography>
        ) : (
          <Typography color={getTypeColor(amountOfWorries)} variant="h7">
            {isAStudent ? "Deine" : "Ihre"} Zeitbudgetierung wird{" "}
            {personalNumberOfDoesNotFit > 0
              ? `${personalNumberOfDoesNotFit}-mal`
              : "nie überstiegen"}
            {personalNumberOfDoesNotFit > 0 && classNumberOfDoesNotFit > 0
              ? " und"
              : ", aber"}{" "}
            jene der Klasse{" "}
            {classNumberOfDoesNotFit > 0
              ? `${classNumberOfDoesNotFit}-mal${
                  personalNumberOfDoesNotFit > 0 ? ` überstiegen` : ``
                }!`
              : "nie!"}
          </Typography>
        )}
      </div>
    </>
  );
}

export default memo(WorkloadFitsIn);
