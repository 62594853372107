import { initializeApp } from 'firebase/app'
import {getAuth} from 'firebase/auth'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCpSe0Ne0BP8OSlu7jikWXDXX6_TpbQgzM",
  authDomain: "husibuech.firebaseapp.com",
  projectId: "husibuech",
  storageBucket: "husibuech.appspot.com",
  messagingSenderId: "978845952986",
  appId: "1:978845952986:web:6e16f48e7a8c0866006ee3"
};

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
export {auth}