import { Box, IconButton, Popover } from "@mui/material";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import {
  AssignmentInd,
  CheckCircleOutline,
  Cottage,
  ErrorOutline,
  Person,
  Groups2,
  School,
  PinDrop,
} from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import StyledTooltip from "../../../_4GeneralHelpers/2_Tooltips/StyledTooltip";
import StatusSelectionPopUpView from "../../../_4GeneralHelpers/3_SeverityPills/StatusSelectionPopUpView";
import PrioritySelectionPopUpView from "../../../_4GeneralHelpers/3_SeverityPills/PrioritySelectionPopUpView";
import { useState } from "react";
export const FilterBoxFilters = ({ reduced, toggleOnly }) => {
  const { isAStudent,
    showExams,
    setShowExams,
    showHomeworks,
    setShowHomeworks,
    showPersonals,
    setShowPersonals,
    showCourseAssignmentOnly,
    setShowCourseAssignmentOnly,
    personalView,
    setPersonalView,
    viewCourseOnly,
    setViewCourseOnly,
    dashBoardOrderIsFixed, setDashBoardOrderIsFixed, isMobile } = useAppContext();
  const theme = useTheme()

  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);

  const options =
    isAStudent && reduced
      ? ["toggleTasks"]
      : isAStudent
        ? ["fix", "book", "toggleTasks", "statusFilter", "priorityFilter"]
        : reduced
          ? ["courseView", "toggleTasks"]
          : toggleOnly ? ["togglePersonalView", "toggleCourseViewOnly"]
            : ["fix", "courseView", "book", "toggleTasks", "statusFilter", "priorityFilter"]



  const handleOpen2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open2 = Boolean(anchorEl2);
  const id2 = open2 ? "simple-popover" : undefined;

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const open3 = Boolean(anchorEl3);
  const id3 = open3 ? "simple-popover" : undefined;

  const toggleShowExams = () => {
    setShowExams(!showExams);
  };
  const toggleShowHomeWorks = () => {
    setShowHomeworks(!showHomeworks);
  };

  const changeDashboardFix = () => {
    setDashBoardOrderIsFixed(!dashBoardOrderIsFixed);
  };
  const toggleShowPersonals = () => {
    setShowPersonals(!showPersonals);
  };
  const togglePersonalView = () => {
    setPersonalView(!personalView);
  };
  const toggleCourseOnlyView = () => {
    setViewCourseOnly(!viewCourseOnly);
  };
  const toggleShowCourseAssignments = () => {
    setShowCourseAssignmentOnly(!showCourseAssignmentOnly);
  };

  return (
    <Box
      sx={{
        marginTop: "0.1em",
        // paddingTop: "0.2em",
        // paddingRight: "-1em",
        // backgroundColor: "yellow",
        overflowX: "hidden", // Set overflowX to "auto" to allow horizontal scrolling
        overflowY: "hidden",
        height: "2.7em", // Set a fixed height for the container
        maxWidth: "100%",
        whiteSpace: "nowrap", // Prevent content from wrapping
        display: "flex",     // Use flexbox to arrange icons horizontally


        "&::-webkit-scrollbar": {
          width: "0px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "your-desired-color", // Set your desired color
          borderRadius: "6px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent", // or any background color
        },
      }}
    >
      {options !== undefined && options.includes("fix") && !isMobile ? (
        <StyledTooltip
          title={
            dashBoardOrderIsFixed
              ? "Dashboard Kacheln verschieben"
              : "Dashboard fixieren"
          }
          placement="bottom-start"
          arrow
        >
          <IconButton
            aria-label="settings"
            edge="end"
            size="small"
            // sx={{ float: "right", margin: "0 0.1em 0 0" }}
            onClick={changeDashboardFix}
          >
            {dashBoardOrderIsFixed ? (
              <PinDrop style={{ color: theme.palette.icon.secondary }} />
            ) : (
              <PinDrop
                style={{ color: theme.palette.icon.primary }}
              />
            )}
          </IconButton>
        </StyledTooltip>
      ) : (
        <></>
      )}
      {options !== undefined && options.includes("courseView") ? (
        <StyledTooltip
          title={
            showCourseAssignmentOnly
              ? "andere Kurse einblenden"
              : "nur Kurs anzeigen"
          }
          placement="bottom-start"
          arrow
        >
          <IconButton
            aria-label="settings"
            edge="end"
            size="small"
            sx={{ margin: "0 0.1em 0 0" }}
            onClick={toggleShowCourseAssignments}
          >
            {showCourseAssignmentOnly ? (
              <AssignmentInd style={{ color: theme.palette.icon.primary }} />
            ) : (
              <AssignmentInd
                style={{ color: theme.palette.icon.secondary }}
              />
            )}
          </IconButton>
        </StyledTooltip>
      ) : (
        <></>
      )}

      {options !== undefined && options.includes("toggleTasks") ? (
        <StyledTooltip
          title={
            showPersonals ? "Personals ausblenden" : "Personals einblenden"
          }
          placement="bottom-start"
          arrow
        >
          <IconButton
            aria-label="settings"
            edge="end"
            size="small"
            // sx={{ float: "right", margin: "0 0.1em 0 0" }}
            onClick={() => {
              toggleShowPersonals();
            }}
          >
            {showPersonals ? (
              <Person style={{ color: theme.palette.icon.primary }} />
            ) : (
              <Person style={{ color: theme.palette.icon.secondary }} />
            )}
          </IconButton>
        </StyledTooltip>
      ) : (
        <></>
      )}

      {options !== undefined && options.includes("toggleTasks") ? (
        <StyledTooltip
          title={
            showHomeworks
              ? "Hausaufgaben ausblenden"
              : "Hausaufgaben einblenden"
          }
          placement="bottom-start"
          arrow
        >
          <IconButton
            aria-label="settings"
            edge="end"
            size="small"
            // sx={{ float: "right", margin: "0 0.1em 0 0" }}
            onClick={() => {
              toggleShowHomeWorks();
            }}
          >
            {showHomeworks ? (
              <Cottage style={{ color: theme.palette.icon.primary }} />
            ) : (
              <Cottage style={{ color: theme.palette.icon.secondary }} />
            )}
          </IconButton>
        </StyledTooltip>
      ) : (
        <></>
      )}

      {options !== undefined && options.includes("toggleTasks") ? (
        <StyledTooltip
          title={showExams ? "Prüfungen ausblenden" : "Prüfungen einblenden"}
          placement="bottom-start"
          arrow
        >
          <IconButton
            aria-label="settings"
            edge="end"
            size="small"
            // sx={{ float: "right", margin: "0 0.1em 0 0" }}
            onClick={() => {
              toggleShowExams();
            }}
          >
            {showExams ? (
              <School style={{ color: theme.palette.icon.primary }} />
            ) : (
              <School style={{ color: theme.palette.icon.secondary }} />
            )}
          </IconButton>
        </StyledTooltip>
      ) : (
        <></>
      )}

      {options !== undefined && options.includes("toggleCourseViewOnly") ?
        !personalView ?
          (<StyledTooltip
            title={
              viewCourseOnly ? "Alle Kurse anzeigen":"Nur Kurs anzeigen"
            }
            placement="bottom-end"
            arrow
          >
            <IconButton
              aria-label="settings"
              edge="end"
              size="small"
              // sx={{ float: "right", margin: "0 0.1em 0 0" }}
              onClick={() => {
                toggleCourseOnlyView();
              }}
            >

              {viewCourseOnly ? (
                <School style={{ color: theme.palette.icon.primary }} />
              ) : (
                <School style={{ color: theme.palette.icon.secondary }} />
              )}

            </IconButton>
          </StyledTooltip>
          ) : (
            <></>
          ) : <></>}
      {options !== undefined && options.includes("togglePersonalView") ? (
        <StyledTooltip
          title={
            personalView ? "Ausgewählte Klasse anzeigen" : "Alle meine Prüfungen anzeigen"
          }
          placement="bottom-start"
          arrow
        >
          <IconButton
            aria-label="settings"
            edge="end"
            size="small"
            // sx={{ float: "right", margin: "0 0.1em 0 0" }}
            onClick={() => {
              togglePersonalView();
            }}
          >
            {personalView ? (
              <Groups2 style={{ color: theme.palette.icon.primary }} />
            ) : (
              <Groups2 style={{ color: theme.palette.icon.secondary }} />
            )}
          </IconButton>
        </StyledTooltip>
      ) : (
        <></>
      )}

      {options !== undefined && options.includes("statusFilter") ? (
        <>
          <StyledTooltip
            title={"nach Status filtern"}
            placement="bottom-start"
            arrow
          >
            <IconButton
              aria-describedby={id2}
              edge="end"
              size="small"
              sx={{
                marginRight: -0.3,
              }}
              onClick={handleOpen2}
            >
              <CheckCircleOutline
                style={{ color: theme.palette.icon.primary }}
              />
            </IconButton>
          </StyledTooltip>
          <Popover
            id={id2}
            open={open2}
            anchorEl={anchorEl2}
            onClose={() => handleClose2()}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <StatusSelectionPopUpView onClose={handleClose2} />
          </Popover>
        </>
      ) : (
        <></>
      )}
      {options !== undefined && options.includes("priorityFilter") ? (
        <>
          <StyledTooltip
            title={"nach Priorität filtern"}
            placement="bottom-start"
            arrow
          >
            <IconButton
              aria-describedby={id3}
              edge="end"
              size="small"
              // sx={{ float: "right", margin: "0 0.1em 0 0" }}
              sx={{
                // float: "right",
                // height: 22,
                // width: 22,
                // marginLeft: 0.1,
                marginRight: -0.3,
                // marginTop: 0.1,
                // marginBottom: 0.4,
              }}
              onClick={handleClick3}
            >
              <ErrorOutline style={{ color: theme.palette.icon.primary }} />
            </IconButton>
          </StyledTooltip>
          <Popover
            id={id2}
            open={open3}
            anchorEl={anchorEl3}
            onClose={() => handleClose3()}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <PrioritySelectionPopUpView />
          </Popover>
        </>
      ) : (
        <></>
      )}


    </Box>
  );
};
