import axios from "axios";
import { SERVER_URL } from "../_2Deployment/constants";
import authHeader from "./auth-header";
// Function to convert a file to base64
function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });
}
class ImageService {
  upload = async (id, imageData) => {
    try {
      const response = await axios.post(
        SERVER_URL + "userupdate/avatar/" + id,
        JSON.stringify({ image: await fileToBase64(imageData) }),
        {
          headers: { ...authHeader(), "Content-Type": "multipart/form-data" },
        }
      );

      return response.data; // Assuming the response contains the updated user data with the new avatar
    } catch (error) {
      throw error;
    }
  };

  getCurrentAvatar() {

    return axios
      .get(SERVER_URL + "update/currentavatar", { headers: authHeader() })
  }
}

const imageServiceInstance = new ImageService();
export default imageServiceInstance;
