import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./DashboardContentMobile.css";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import DeadlineAssignments from "../DashboardParts/DeadlineBoxes/DeadlineAssignments";
import PersonalDeadlineBox from "./PersonalDeadlineBox";
import AssignmentAddBox from "../DashboardParts/AddAssignmentBox/AssignmentAddBox";
import MiddleBox from "../../../_4GeneralHelpers/1_Boxes/MiddleBox";
import DeadlineAssignmentsList from "../DashboardParts/DeadlineBoxes/DeadlineAssignmentsList";
import { getBoxHeaderTitle } from "../../../_3HelperFunctions/_BoxHeader/getBoxHeaderTitle";
import BoxHeader from "../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";


const DashboardCarousel = () => {
  const { isAStudent, isATeacher, isAnAdmin, selectedCourse, showCourseAssignmentOnly, flipAssignment, setFlipAssignment, appInDarkMode } = useAppContext();
  // console.log(isMobile)
  const dotColor = appInDarkMode ? "#fff" : "#000"; // Set color based on the dark mode state


  const renderComponent = (component) => (
    <div style={{
      justifyContent: "center",
    }}>
      {component}
    </div>
  );

  // Add custom styles to reduce the space between dots and content
  const customDotStyles = {
    // Add custom styles to reduce the space between dots and content
    margin: "-0.5em", // Adjust as needed
    // Change dot color to white
  }


  const settings = {
    dots: true,
    infinite: true,
    speed: 1100,
    slidesToShow: 1, // Adjust the number of slides to show
    slidesToScroll: 1,
    // centerMode: true,   // Enable center mode
    // centerPadding: "-3em",  // Add space between items (adjust as needed)
    arrows: false,    // Hide arrows
    swipe: true,       // Enable swipe interaction
    dots: true,  // Set to false to hide the swipe dots
    vertical: false,   // Enable vertical swiping
    appendDots: (dots) => (
      <div style={customDotStyles}>
        <ul>{dots}</ul>
      </div>
    ),
  };
  const assignmentBoxProps = {
    flipAssignment,
    setFlipAssignment,
  };
  const boxHeaderTitle = getBoxHeaderTitle(
    isAStudent,
    isATeacher,
    isAnAdmin,
    selectedCourse,
    "additional",
    showCourseAssignmentOnly
  );

  return (
    <div> <style>{`
    .slick-dots li button::before {
      color: ${dotColor};
      opacity: 0.4;
    }

    .slick-dots li.slick-active button::before {
      color: ${dotColor};
      opacity: 1;
    }
  `}</style>
      <Slider {...settings}>
        {!isAStudent && renderComponent(
          <div
            style={{
              paddingTop: "1em"
            }}
          >
            <PersonalDeadlineBox single={true} />
          </div>
        )}
        {renderComponent(
          <div
            style={{
              paddingTop: "1em"
            }}
          >
            <DeadlineAssignments
              type={isAStudent ? "studentView" : "coreCourse"}
              single={true}
            />
          </div>
        )}
        {renderComponent(
          <div
            style={{
              paddingTop: "1em"
            }}
          >
            <BoxHeader
              title={boxHeaderTitle}
              options={[]}
              flipTeacherAssignment={false}
              setFlipTeacherAssignment={null} />
            <MiddleBox>
              <DeadlineAssignmentsList
                type={"additional"}
                single={true}
              />
            </MiddleBox >
          </div>
        )}
        {renderComponent(
          <div
            style={{
              paddingTop: "1em"
            }}
          >
            <AssignmentAddBox {...assignmentBoxProps} boxType={"add"} single={true} />
          </div>
        )}




      </Slider>
    </div>
  );
};

export default DashboardCarousel;
