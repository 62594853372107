function getBoxHeaderOptions(type, single) {
  // console.log("type", type, " single", single)
  switch (type) {
    case "personal":
      // console.log("we go here")
      return single?[]:["flipAssignment"];
    case "coreCourse":
      return ["teacherFlip", "courseView"];
    case "additional":
      // console.log("we go there")
      return single?[]:["flipAssignment"];
    case "personalEmpty":
      return [];
    default:
      return [];
  }
}
exports.getBoxHeaderOptions = getBoxHeaderOptions;
