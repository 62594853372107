import { memo, useEffect, useState } from "react";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

import { Grid, TextField } from "@mui/material";
import userService from "../../../_6Services/user.service";
import ButtonController from "./ButtonController";
import ContentBox from "../../../_4GeneralHelpers/1_Boxes/ContentBox";
import BoxHeader from "../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";

const workMinutes = [
  {
    value: 0,
    label: "gar nicht!",
  },
  {
    value: 30,
    label: "30 Minuten",
  },
  {
    value: 60,
    label: "1 Stunde",
  },
  {
    value: 90,
    label: "90 Minuten",
  },
  {
    value: 120,
    label: "2 Stunden",
  },
  {
    value: 180,
    label: "3 Stunden",
  },
  {
    value: 240,
    label: "4 Stunden",
  },
  {
    value: 300,
    label: "5 Stunden",
  },
];

const WorkDayTextField = ({
  label,
  name,
  handleChange,
  required,
  values,
  workMinutes,
}) => (
  <Grid item xl={1.7} md={3} sm={4} xs={6}>
    <TextField
      fullWidth
      label={label}
      name={name}
      onChange={handleChange}
      required={required}
      select
      SelectProps={{ native: true }}
      value={values[name]}
      variant="outlined"
    >
      {workMinutes.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </TextField>
  </Grid>
);

function WorkWeekDetail() {
  const [change, setChange] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const { currentUser, setCurrentUser } = useAppContext();
  const days = [
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
    "Sonntag",
  ];

  const [values, setValues] = useState({
    name: currentUser.userNickName ? currentUser.userNickName : "",
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
  });

  useEffect(() => {
    if (currentUser) {
      // console.log(currentUser)
      setValues({
        monday: currentUser.personalWorkWeek?.working_minutes_monday,
        tuesday: currentUser.personalWorkWeek?.working_minutes_tuesday,
        wednesday: currentUser.personalWorkWeek?.working_minutes_wednesday,
        thursday: currentUser.personalWorkWeek?.working_minutes_thursday,
        friday: currentUser.personalWorkWeek?.working_minutes_friday,
        saturday: currentUser.personalWorkWeek?.working_minutes_saturday,
        sunday: currentUser.personalWorkWeek?.working_minutes_sunday,
      });
    }
  }, [currentUser]);

  const handleChange = (event) => {
    setChange(true);
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const updateCurrentUser = () => {
    var updateUser = currentUser;
    if (
      values.name !== updateUser.username ||
      values.name !== updateUser.userNickName
    ) {
      updateUser.userNickName = values.name;
    }

    updateUser.personalWorkWeek.working_minutes_monday = parseInt(
      values.monday
    );
    updateUser.personalWorkWeek.working_minutes_tuesday = parseInt(
      values.tuesday
    );
    updateUser.personalWorkWeek.working_minutes_wednesday = parseInt(
      values.wednesday
    );
    updateUser.personalWorkWeek.working_minutes_thursday = parseInt(
      values.thursday
    );
    updateUser.personalWorkWeek.working_minutes_friday = parseInt(
      values.friday
    );
    updateUser.personalWorkWeek.working_minutes_saturday = parseInt(
      values.saturday
    );
    updateUser.personalWorkWeek.working_minutes_sunday = parseInt(
      values.sunday
    );
    // console.log("we trigger this...7")
    setCurrentUser(updateUser);
    localStorage.setItem("user", JSON.stringify(updateUser));
  };

  const updateHandler = async () => {
    setLocalLoading(true);

    const workWeekMap = new Map();
    workWeekMap.set("working_minutes_monday", parseInt(values.monday));
    workWeekMap.set("working_minutes_tuesday", parseInt(values.tuesday));
    workWeekMap.set("working_minutes_wednesday", parseInt(values.wednesday));
    workWeekMap.set("working_minutes_thursday", parseInt(values.thursday));
    workWeekMap.set("working_minutes_friday", parseInt(values.friday));
    workWeekMap.set("working_minutes_saturday", parseInt(values.saturday));
    workWeekMap.set("working_minutes_sunday", parseInt(values.sunday));

    await userService.updateUser(
      "personalweek",
      JSON.stringify(Object.fromEntries(workWeekMap)),
      currentUser.id
    );
    await updateCurrentUser();
    await clearHandler();
  };

  const clearHandler = async () => {
    await setValues({
      monday: currentUser.personalWorkWeek.working_minutes_monday,
      tuesday: currentUser.personalWorkWeek.working_minutes_tuesday,
      wednesday: currentUser.personalWorkWeek.working_minutes_wednesday,
      thursday: currentUser.personalWorkWeek.working_minutes_thursday,
      friday: currentUser.personalWorkWeek.working_minutes_friday,
      saturday: currentUser.personalWorkWeek.working_minutes_saturday,
      sunday: currentUser.personalWorkWeek.working_minutes_sunday,
    });
    await setChange(false);
    await setLocalLoading(false);
  };

  return (
    <>
      <BoxHeader title={"Arbeitswoche einstellen"} />
      <ContentBox>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            <Grid item sx={{ marginTop: "5px" }}>
              <Grid container spacing={3}>
                {/* {days.map((day) => (
                  <WorkDayTextField
                    key={day}
                    label={day}
                    name={day.toLowerCase()}
                    handleChange={handleChange}
                    required
                    values={values}
                    workMinutes={workMinutes}
                  />
                ))} */}
                <Grid item xl={1.7} md={3} sm={4} xs={6} style={{ zIndex: 0 }}>
                  <TextField
                    fullWidth
                    label="Montag"
                    name="monday"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.monday}
                    variant="outlined"
                  >
                    {workMinutes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xl={1.7} md={3} sm={4} xs={6} style={{ zIndex: 0 }}>
                  <TextField
                    fullWidth
                    label="Dienstag"
                    name="tuesday"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.tuesday}
                    variant="outlined"
                  >
                    {workMinutes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xl={1.7} md={3} sm={4} xs={6} style={{ zIndex: 0 }}>
                  <TextField
                    fullWidth
                    label="Mittwoch"
                    name="wednesday"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.wednesday}
                    variant="outlined"
                  >
                    {workMinutes.map((option) => (
                      <option key={option.value} value={option.value} >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xl={1.7} md={3} sm={4} xs={6} style={{ zIndex: 0 }}>
                  <TextField
                    fullWidth
                    label="Donnerstag"
                    name="thursday"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.thursday}
                    variant="outlined"
                  >
                    {workMinutes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xl={1.7} md={3} sm={4} xs={6} style={{ zIndex: 0 }}>
                  <TextField
                    fullWidth
                    label="Freitag"
                    name="friday"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.friday}
                    variant="outlined"
                  >
                    {workMinutes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xl={1.7} md={3} sm={4} xs={6} style={{ zIndex: 0 }}>
                  <TextField
                    fullWidth
                    label="Samstag"
                    name="saturday"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.saturday}
                    variant="outlined"
                  >
                    {workMinutes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xl={1.7} md={3} sm={4} xs={6} style={{ zIndex: 0 }}>
                  <TextField
                    fullWidth
                    label="Sonntag"
                    name="sunday"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.sunday}
                    variant="outlined"
                  >
                    {workMinutes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ marginTop: "0.75em" }}>
              <div style={{ visibility: !change ? "hidden" : "visible" }}>
                <ButtonController
                  localLoading={localLoading}
                  disabled={!change}
                  save={updateHandler}
                  clear={clearHandler}
                  saveText={"speichern"}
                  clearText={"verwerfen"}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </ContentBox>
    </>
  );
}

export default memo(WorkWeekDetail);
