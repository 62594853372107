import moment from 'moment';
import { getAssignmentsAfter } from '../../../../../_3HelperFunctions/_AssignmentFunctions/getAssignmentsAfter';
import { filterList2FromList1 } from '../../../../../_3HelperFunctions/_AssignmentFunctions/filterList2FromList1';

export const processOtherAssignments = async (schoolYearData, relevantUserAssignments, referenceDate, showExams, showHomeworks, showPersonals, statusSelection) => {
    if (schoolYearData?.userAssignments) {
        const sunday = moment(referenceDate).endOf("isoWeek");
        const alreadyProcessedExtraDates = relevantUserAssignments.additional.extraDates //have alreay been dragged into the week

        let futureAssignments = await getAssignmentsAfter(
            schoolYearData.userAssignments,
            sunday, 
            showExams,
            showHomeworks,
            showPersonals
        );
        //filter already processed
        return filterList2FromList1(futureAssignments, alreadyProcessedExtraDates)
    } else {
        return [];
    }
};
