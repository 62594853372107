import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

export const ClassesOverview = () => {
  const theme = useTheme();
  const {
    userCourses,
    selectedCourse,
    setSelectedCourse,
    setOldSelectedCourse,
  } = useAppContext();

  useEffect(() => {
    // console.log(selectedCourse);
  }, [selectedCourse]);

  const handleChange = async (course) => {
    // console.log(course);
    await setOldSelectedCourse(selectedCourse);
    await setSelectedCourse(course);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          overflow: "auto",
        }}
      >
        {selectedCourse !== undefined &&
        userCourses !== undefined &&
        userCourses.length !== 0 ? (
          userCourses.map((course, i) => (
            <div key={i}>
              <Typography
                alignm="center"
                color="textSecondary"
                variant={course.id === selectedCourse.id ? "button" : "caption"}
                style={{
                  color:
                    course.id === selectedCourse.id
                      ? theme.palette.levels.lightGreen
                      : theme.palette.levels.green,
                }}
                onClick={() => handleChange(course)}
                sx={{ cursor: "pointer" }}
              >
                &nbsp;{course.name}&nbsp;
              </Typography>
              {i !== userCourses.length - 1 && (
                <Typography
                  alignm="center"
                  color="primary"
                  variant={
                    course.id === selectedCourse.id ? "button" : "caption"
                  }
                >
                  |
                </Typography>
              )}
            </div>
          ))
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};
