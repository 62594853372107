import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { memo } from "react";

const WeekNavigation = ({ title, disabled, onClick, arrowLeft }) => (
<>
  <Tooltip title={title} placement="bottom-start" arrow enterDelay={500}>
    <span>
      <IconButton
        disabled={disabled}
        aria-label={title}
        color={"primary"}
        style={{color: disabled ? 'transparent' : ''}}
        onClick={onClick}
      >
        {arrowLeft ? (
          <KeyboardArrowLeft fontSize="large" />
        ) : (
          <KeyboardArrowRight fontSize="large" />
        )}
      </IconButton>
    </span>
  </Tooltip>
</>
);

export default memo(WeekNavigation);
