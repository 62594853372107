import userService from "../../_6Services/user.service";

export const refetchSchoolYearData = async (currentUser, selectedCourse) => {
  // console.log(selectedCourse)
  try {
    const response = await userService.getUserInfo("schoolyeardata", currentUser.id, selectedCourse?.id);
    return response.data;
  } catch (error) {
    console.error("Error fetching school year data:", error);
    throw error; // Re-throw the error to propagate it up
  }
};
