import { Grid } from "@material-ui/core";
import { memo } from "react";
import DraggableTrashContainer from "../DraggableTrashContainer";
import TransitionsSnackbar from "../TransitionsSnackbar";
import SnackbarTaskCompleted from "../../../5_Snackbars/SnackbarTaskCompleted";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";

function PriorityDragGridBottom({open, setOpen, openSnackbar, setOpenSnackbar}) {
  const {isMobile} = useAppContext()
  return (
    <Grid item xs={12} style={{width: "100%", marginTop:isMobile?"-1em":"" }}>
      <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
        <Grid item>
          <DraggableTrashContainer prefix={"deleteBin"} />
          <TransitionsSnackbar open={open} setOpen={setOpen} title={"toDo gelöscht"}/>

          <SnackbarTaskCompleted open={openSnackbar} setOpen={setOpenSnackbar} />
        </Grid>
      </Grid>
    </Grid>

  );
}

export default memo(PriorityDragGridBottom);
