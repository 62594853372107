import React, { memo } from "react";
import MessageBox from "../1_Boxes/MessageBox";
import SaveButton from "../../_4GeneralHelpers/2_Buttons/SaveButton";

function SaveButtonPart({ change, loadingCircle, saveColor, message, onSubmit }) {

  return (
    < div
      style={{
        marginTop: "1em",
        display: "flex", // Set display to "flex"
        justifyContent: "space-between", // Set justifyContent to "space-between"
      }
      }
    >
      <div
        style={{
          visibility: !change ? "hidden" : "visible",
          marginRight: "10px", // Adjust the value to add space
        }}
      >
        <SaveButton
          loading={loadingCircle}
          saveColor={saveColor}
          save={onSubmit}
          saveText={"speichern"}
        />
      </div>

      {/* Feedback Message */}
      <div>
        {message && <MessageBox message={message} saveColor={saveColor} />}
      </div>
    </div >

  );
}

export default memo(SaveButtonPart);
