import { memo } from "react";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import { LinearProgress, Grid } from "@mui/material";
import { SemesterDataContext } from "../../_8HusibuechContexts/semesterDataContext";
import SemesterTable from "./SemesterTable";
import CircularProgressWithoutLabel from "../0Navigation/3Loading/CircularProgressWithoutLabel";
import { useState } from "react";
import SnackbarTaskCompleted from "../../_4GeneralHelpers/5_Snackbars/SnackbarTaskCompleted";
import { DashboardContext } from "../../_8HusibuechContexts/dashboardContext";

function SemesterView() {
  const {
    currentSemesterStartDate,
    setCurrentSemesterStartDate,
  } = useAppContext();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // console.log(loading)

  return (
        <>
          <DashboardContext.Provider value={{      
                openSnackbar,
                setOpenSnackbar,
              }}>
            <SemesterDataContext.Provider
              value={{
                // currentSemesterStartDate: currentSemesterStartDate,
                // setCurrentSemesterStartDate: setCurrentSemesterStartDate,
                
              }}
            >
              <SemesterTable />
              <SnackbarTaskCompleted
                open={openSnackbar}
                setOpen={setOpenSnackbar}
              />
            </SemesterDataContext.Provider>
          </DashboardContext.Provider>
        </>
  );
}
export default memo(SemesterView);
