import { Grid, Typography } from "@mui/material";
import React, { memo } from "react";
import PrioritySeverityPill from "../../../../../_4GeneralHelpers/3_SeverityPills/PrioritySeverityPill";

function PriorityBox({
  assignment,
  previewAssignment,
  setPreviewAssignment,
  changesCount,
  setChangesCount,
  priority,
  setPriority,
  personalView,
}) {
  //this only changes the preview, not the assignment database!
  const changePriority = (i) => {
    let changesCountCopy = changesCount;
    if (parseInt(i) === assignment.priority) {
      //we have gone back to default
      setChangesCount(changesCountCopy - 1);
    } else if (assignment.priority !== priority) {
      // do noting
    } else {
      // new change
      setChangesCount(changesCountCopy + 1);
    }
    let previewAssignmentCopy = previewAssignment;
    previewAssignmentCopy.priority = i;
    setPreviewAssignment(previewAssignmentCopy);
    setPriority(i);
  };
  return (
    <>
      {/* Priorität */}
      <Grid container direction="row">
        <Grid item xs={personalView ? 7 : 5} sx={{ marginLeft: "0.5em" }}>
          <Typography variant="body2" color="primary" sx={{ width: "50%" }}>
            {personalView?"MyPriority:": "Priorität:"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          // sx={{background: "green"}}
        >
          <div style={{ 
            marginTop: "-1px", 
            marginLeft: !personalView ? "30px":"14px",
          }}>
            <PrioritySeverityPill
              assignment={previewAssignment}
              preview={"preview"}
              changePriority={changePriority}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default memo(PriorityBox);
