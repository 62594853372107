import { memo } from "react";

import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";

function CourseSelection({ zusatzFaecher, handleChange, selections, options }) {
  const theme = useTheme();
  // console.log(selections)
  return (
    <Grid
      item
      // md={5}
      xs={8}
    // style={{
    //   backgroundColor: "orange", // Set your desired default color
    // }}
    >
      <InputLabel id="demo-multiple-name-label">Meine Kurse</InputLabel>
      <Select
        sx={{
          minWidth: "50%", // Adjust the minWidth as needed
          maxWidth: "100%",
          // display: 'flex',
          flexWrap: "wrap", // Add this line to allow multiple lines
          // background: "blue"
        }}
        displayEmpty
        labelId="multiple-name-label"
        required
        multiple
        onChange={handleChange}
        input={<OutlinedInput label="Zusatfaecher" />}
        value={zusatzFaecher}
        renderValue={(selected) => {
          if (selected?.length > 0) { // we have a newly selected course
            // console.log(selected)
            var courses = selected;
            if (selections?.length) {
              courses = selections.concat(selected);
              var normalCourses = 
              courses
              .filter((course) => !course.mixed_course)
              .sort((a, b) => a.name.localeCompare(b.name))
              
              var mixedCourses =
                courses
                  .filter((course) => course.mixed_course)
                  .sort((a, b) => a.name.localeCompare(b.name))
              courses = normalCourses.concat(mixedCourses)
            }
            return (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {courses.map((kurs, index) => (
                  <Typography
                    key={index}
                    style={{
                      marginBottom: "6px",
                      marginRight: "6px", // Add margin between items
                    }}
                  >
                    {`${kurs.name}${kurs.tutor_abbreviation
                        ? ` (${kurs.tutor_abbreviation})`
                        : ""
                      }${index !== courses.length - 1 ? `, ` : ""}`}
                  </Typography>
                ))}
              </div>
            );
          } else if (selections?.length > 0) {
            var courses = selections;
            var normalCourses = 
              courses
              .filter((course) => !course.mixed_course)
              .sort((a, b) => a.name.localeCompare(b.name))
              
              var mixedCourses =
                courses
                  .filter((course) => course.mixed_course)
                  .sort((a, b) => a.name.localeCompare(b.name))
              courses = normalCourses.concat(mixedCourses)

            return (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {courses.map((kurs, index) => (
                  <Typography
                    key={index}
                    style={{
                      marginBottom: "6px",
                      marginRight: "6px", // Add margin between items
                    }}
                  >
                    {`${kurs.name}${kurs.tutor_abbreviation
                        ? ` (${kurs.tutor_abbreviation})`
                        : ""
                      }${index !== courses.length - 1 ? `, ` : ""}`}
                  </Typography>
                ))}
              </div>
            );
          } else {
            return <em>Wähle sorgfältig aus</em>;
          }
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left", // Align to the left
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left", // Align to the left
          },
          getcontentanchorel: null,
          PaperProps: {
            style: {
              maxHeight: "175px", // Set the maximum height as needed
              overflowY: "auto", // Enable vertical scrolling
            },
          },
        }}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value="">
          <em>Optionen</em>
        </MenuItem>
        { selections?.length > 0 &&
          selections.map((kurs) => (
            <MenuItem
              disabled
              value=""
              key={kurs.id}
              style={{
                color: theme.palette.levels.green,
                fontWeight: "bold",
              }}
            >
              <em>{`${kurs.name}${kurs.tutor_abbreviation ? ` (${kurs.tutor_abbreviation})` : ""
                }`}</em>
            </MenuItem>
          ))}
        {options?.length > 0 &&
          options.map((kurs) => (
            <MenuItem
              key={kurs.id}
              value={kurs}
              style={{
                backgroundColor:
                  zusatzFaecher && zusatzFaecher.includes(kurs)
                    ? theme.palette.levels.lightGreen
                    : "transparent",
              }}
            >
              {`${kurs.name}${kurs.tutor_abbreviation ? ` (${kurs.tutor_abbreviation})` : ""
                }`}
            </MenuItem>
          ))}
      </Select>
    </Grid>
  );
}
export default memo(CourseSelection);
