import axios from "axios";
import { SERVER_URL } from "../_2Deployment/constants";
import authHeader from "./auth-header";
import moment from "moment";

class AssignmentService {
  async patchAssignment(assignmentJson, origin, currentUser, isAStudent) {
    // console.log(assignmentJson, currentUser)
    // Hack to prevent error -> to be fixed
    if (origin === "retrospectionView") {
      assignmentJson.status = 4;
    }
    if (assignmentJson.type === "personal") {
      assignmentJson.suggested_minutes = assignmentJson.my_minutes;
      assignmentJson.suggested_days = assignmentJson.my_days;
    }
    // console.log("ass", assignmentJson.real_edit_date)
    assignmentJson.sourceTool = origin;

    //fix dateTime issues:
    if (
      assignmentJson.type === "personal" ||
      !isAStudent
    ) {
      assignmentJson.due_date = moment(assignmentJson.due_date).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      assignmentJson.real_edit_date = moment(
        assignmentJson.real_edit_date
      ).format("YYYY-MM-DD HH:mm:ss");
    }

    assignmentJson.real_edit_date_user = moment(
      assignmentJson.real_edit_date_user
    ).format("YYYY-MM-DD HH:mm:ss");

    if (assignmentJson.due_date) {
      assignmentJson.due_date = moment(
        assignmentJson.due_date
      ).format("YYYY-MM-DD HH:mm:ss")
    }

    // console.log("we update this:")
    // console.log("before sending: ", assignmentJson.real_edit_date, assignmentJson.real_edit_date_user)
    // console.log("json, ", assignmentJson)
    try {
      const response = await axios.patch(
        SERVER_URL +
        "assignments/update/" +
        assignmentJson.type +
        "/" +
        currentUser.id,
        assignmentJson,
        {
          headers: authHeader(),
        }
      );
      // console.log("res, ", response.data.assignment)
      return response;      //retrieve with response.data.assignment!;

    } catch (error) {
      console.log("We had a problem patching the exam");
      return error;
    }
  }

  deleteAssignment(assignment) {
    try {
      return axios.get(SERVER_URL + "assignments/kill/" + assignment.assignment_id, {
        headers: authHeader(),
      });
    } catch (error) {
      // console.log("We had a problem deleting the assignment");
      return error;
    }
  }

  createNewAssignment = async (assignmentJson) => {
    try {
      // console.log(assignmentJson)
      const response = await axios.post(
        SERVER_URL + "assignments/createassignment",
        assignmentJson,
        {
          headers: authHeader(),
        }
      );

      return response;
    } catch (error) {
      console.error("Error creating assignment:", error);
      throw error; // Rethrow the error to propagate it to the calling code
    }
  };

  getCoreCourseAssignments(coreCourseId) {
    // console.log("we called with this: " + coreCourseId)
    try {
      return axios.get(
        SERVER_URL + "corecourses/allassignments?corecourseid=" + coreCourseId,
        {
          headers: authHeader(),
        }
      );
    } catch (e) {
      // console.log("We had a problem with the database update: ");
      return e;
    }
  }

  uploadAssignments = async (assignmentJson) => {
    // console.log("json,", assignmentJson)
    try {
      const response = await axios.post(
        SERVER_URL + "assignments/upload",
        assignmentJson,
        {
          headers: authHeader(),
        }
      );
      // console.log(response)
      return response;
    } catch (error) {
      console.error("Error creating assignment:", error);
      throw error; // Rethrow the error to propagate it to the calling code
    }
  };
}

const assignmentServiceInstance = new AssignmentService();
export default assignmentServiceInstance;
