import { useTheme } from "@mui/styles";
import SemesterWeekPillDetail from "./SemesterWeekPillDetail";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

export default function SemesterWeekPill({ assignment, handleClick, origin }) {
  // console.log("I got called on: ", origin, assignment)
  const { personalView } = useAppContext()
  // console.log("pers...", personalView)

  const theme = useTheme();

  const clickFunction = (event) => {
    let eventCopy = event
    eventCopy.origin = "assignment"
    handleClick(eventCopy, assignment);

  };

  const getColor = (assignment) => {
    if (personalView) {
      switch (assignment.status) {
        case 4:
          return theme.palette.levels.green;
        case 3:
          return theme.palette.levels.lightGreen;
        case 2:
          return theme.palette.levels.yellow;
        case 1:
          return theme.palette.levels.orange;
        default:
          return theme.palette.levels.red;
      }
    } else {
      const keyValue = assignment.suggested_minutes
      switch (true) {
        case keyValue <= 30:
          return theme.palette.levels.green;
        case keyValue <= 60:
          return theme.palette.levels.lightGreen;
        case keyValue <= 90:
          return theme.palette.levels.yellow;
        case keyValue <= 120:
          return theme.palette.levels.orange;
        case keyValue <=180:
          return theme.palette.levels.red;
        default:
          return theme.palette.levels.blue;
      }
    }
  }
return (
  <>
    <SemesterWeekPillDetail
      onClick={clickFunction}
      assignment={assignment}
      origin={origin}
      sx={{
        cursor: "pointer",
        backgroundColor: getColor(assignment)

      }}
    >
      <div>{assignment.tag} </div>
    </SemesterWeekPillDetail>
  </>
);
}
