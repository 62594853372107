import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { FilterBox } from "../../../4DashboardPage/DashboardHelpers/FilterBox";
import { DateOverviewBox } from "../../../4DashboardPage/DashboardHelpers/DateOverviewBox";
import { CourseOverviewBox } from "../../../4DashboardPage/DashboardHelpers/CourseOverviewBox";
import { ClassOverviewBox } from "./ClassOverviewBox";
import { ProfileTitle } from "../../../3ProfilePage/ProfileHelpers/ProfileTitle";
import { ThemeSwitchBox } from "../../../../_7HusibuechTheme/themeSwitch/ThemeSwitchBox";
import { AssignmentStats } from "./AssignmentStats";
import { NKTitle } from "../../../5Nachkontrolle/_NachKontrolleHelpers/NKTitle";
import { AdminTitle } from "../../../6AdminPage/_RegisterHelpers/AdminTitle";
import { useEffect, useState } from "react";
import { UploadTitle } from "../../../8UploadPage/_UploadHelpers/UploadTitle";

export const RibbonDetail = ({ type }) => {
  // console.log("type,", type)
  const { isATeacher, isAStudent, isALeader } = useAppContext();
  const [flexValue, setFlexValue] = useState(type !== "nachkontrolle" ? "33%" : "50%");

  useEffect(() => {
    const updateFlexValue = () => {
      const screenWidth = window.innerWidth;

      if ((type === "dashboard" || type === "examView" || type === "nachkontrolle" || type === "profile") && screenWidth <= 520) {
        setFlexValue("50%");
      } else {
        setFlexValue(type !== "nachkontrolle" ? "33%" : "50%");
      }
    };

    updateFlexValue(); // Call the update function on mount

    window.addEventListener("resize", updateFlexValue); // Event listener for window resize

    return () => {
      window.removeEventListener("resize", updateFlexValue); // Clean up the event listener on component unmount
    };
  }, [type]);

  // useEffect(() => {
  //   console.log(flexValue, type);
  // }, [flexValue]);
  return (
    <div
      style={{
        height: "6em",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        // backgroundColor: "red",
        maxWidth: "90%", // Set maximum width to 90% of the viewport width
        overflowX: "hidden", // Hide horizontal overflow
        overflowY: "hidden",
      }}
    >
      {/* LEFT item */}
      <div
        style={{
          flex: `0 0 ${flexValue}`,
          justifyContent: "center",
          // backgroundColor: "red",
        }}
      >
        {type === "dashboard" ? (
          <DateOverviewBox />
        ) : type === "examView" ? (
          <ClassOverviewBox />
        ) : type === "nachkontrolle" ? (
          <NKTitle />
        ) : type === "admin" ? (
          <AdminTitle />
        ) : type === "profile" ? (
          <ProfileTitle />
        ) : <UploadTitle />
        }
      </div>

      {/* CENTER item */}
      {((isAStudent && (type === "dashboard" || type === "nachkontrolle" || type === "examView" || type === "profile")) || (isALeader && (type === "examView" || type === "dashboard" || type === "profile"))) &&
        <div
          role="center-item"
          style={{
            height: "100%",  // Set height to 100%
            flex: `0 0 ${flexValue}`,
            overflow: "auto",
            display: type === "nachkontrolle" ? "none" : "flex",
            justifyContent: "center",
            WebkitOverflowScrolling: "touch",
            "&::WebkitScrollbar": {
              width: "0 !important",
              height: "0 !important",
            },
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            marginInline: "-17px", // Adjust this value to hide the x-axis scrollbar
            // backgroundColor: "orange",
          }}
        >
          {isAStudent && type === "dashboard" ? (
            <AssignmentStats />
            // <>hi</>
          ) : isALeader && (type === "examView" || type === "dashboard") ? (
            <CourseOverviewBox />
          ) : isAStudent && (type === "nachkontrolle" || type === "examView") ? (
            <></>
          ) : type === "profile" ? (
            <ThemeSwitchBox />
          ) : (
            <></>
          )}
        </div>
      }

      {/* RIGHT item */}
      {(type === "dashboard" || type === "nachkontrolle" || (type === "examView" && isATeacher)) &&
        <div
          role="right-item"
          style={{
            flex: `0 0 ${flexValue}`,
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            scrollbarWidth: "thin",
            scrollbarColor: "transparent transparent",
            // backgroundColor: "yellow",
          }}
        >
          {(type === "dashboard" || type === "nachkontrolle" || (type === "examView" && isATeacher)) && (
            <FilterBox reduced={type === "nachkontrolle"} toggleOnly={type === "examView"} />
          )}
        </div>
      }

      {/* Media query to hide the items on smaller screens */}
      <style>
        {`
          @media (max-width: 520px) {
            div[role="right-item"] {
              display: ${type === "dashboard" && isATeacher ? "none" : "flex"};
            },
            div[role="center-item"] {
              display: ${type === "nachkontrolle" || (isAStudent && type === "dashboard") ? "none" : "flex"};
            },

          }
        `}
      </style>
    </div>
  );
};
