import { useState } from "react";
import { Popover, Tooltip } from "@mui/material";
import { QueryStats } from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import StyledTooltip from "../../../../_4GeneralHelpers/2_Tooltips/StyledTooltip";
import StatisticsPopUpView from "../../../../_4GeneralHelpers/3_SeverityPills/StatisticsPopUpView";


export default function AssignmentDistributionStats({ assignment }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  //for popover functionality

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <StyledTooltip title={"Statistik ansehen"} placement="bottom-start" arrow sx={{cursor:"pointer"}}>
        <QueryStats
          onClick={handleClick}
          style={{ color: theme.palette.levels.gray }}
        />
      </StyledTooltip>

      {/* For popup Functionality */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <StatisticsPopUpView
          assignment={assignment}
        />
      </Popover>
    </>
  );
}
