import { useState } from "react";
import Popover from "@mui/material/Popover";
import PriorityPopUpView from "./PriorityPopUpView";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import { saveAssignmentChanges } from "../../_3HelperFunctions/_AssignmentFunctions/saveAssignmentChanges";
import { updateDataSet } from "../../_3HelperFunctions/_LocalUpdates/updateDataSet";
import HusibuechCircle from "./_HusibuechCircle";

export default function PrioritySeverityPill({
  assignment,
  preview,
  changePriority,
}) {
  const { currentUser, isALeader, schoolYearData, setUnresolvedSchoolYearData } =
    useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (keyValue, event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changePriorityCloseAndSave = async (i) => {
    try {
      if (preview !== "preview") {
        const updateValues = [{ property: "priority", value: i }]
        handleClose();
        const responseAssignment = await saveAssignmentChanges(assignment, updateValues, "priorityPill", //origin
          currentUser);
        const updatedSchoolYearData = await updateDataSet(schoolYearData, responseAssignment, isALeader, "priorityPill");
        setUnresolvedSchoolYearData(updatedSchoolYearData)

      } else {
        changePriority(i);
        handleClose();
      }
    } catch (error) {
      console.log(error)
      alert("Es gab ein Problem im Update");
      handleClose();
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <HusibuechCircle
        type={"priority"}
        keyValue={assignment.priority}
        onClick={handleClick}
        fill={false}
        select={"own"}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <PriorityPopUpView
          changePriority={changePriorityCloseAndSave}
          assignment={assignment}
        />
      </Popover>
    </>
  );
}
