import { Grid, Typography } from "@mui/material";
import { ClassesOverview } from "../../0_PageTemplate/2_PageRibbon/_RibbonHelpers/classesOverview";

export const CourseOverviewBox = () => {
  return (
    // This is the date overview item
    <Grid container direction="column" alignItems="center">
      <Typography variant="h6" color="primary">
        Kursübersicht
      </Typography>
      <ClassesOverview />
    </Grid>
  );
};
