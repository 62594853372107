import { Typography } from "@mui/material";

export const DistributionText = ({ height, width, hasStudents }) => {
    // console.log(hasStudents)
    return (
        <Typography
            sx={{
                position: "relative",
                width: `${Math.min(width, height)}px`,
                height: `${Math.min(width, height)}px`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "-0.5em",
                textAlign: "center",
            }}
            color={
                "levels.green"
            }
            variant="h4"
            style={{
                fontSize: `${Math.min(width, height) / 5}px`,
                // backgroundColor: "yellow",
            }}
        >
            <>
                <Typography
                    variant="h5"
                    component="span"
                    style={{ fontSize: "inherit" }}
                >
                    {hasStudents ? "Status" : "Keine"}
                </Typography>
                <Typography
                    variant="h6"
                    component="span"
                    style={{ fontSize: `${Math.min(width, height) / 10}px` }}
                >
                    {hasStudents ? "der" : ""}

                </Typography>
                <Typography
                    variant="h5"
                    component="span"
                    style={{ fontSize: "inherit", marginTop: "-0.3em" }}
                >
                    Lernenden
                </Typography>
            </>

        </Typography>
    );
};
