export const formValidation = (assignmentName, countingFactor, myDays, myMinutes) => {
    let assignmentNameErr = {};
    let countingFactorErr = {};
    let availablePrepDaysErr = {};
    let workloadErr = {};
    let isValid = true;
  
    if (assignmentName === "") {
      assignmentNameErr.assignmentNameEmpty = "Namen eingegeben!";
      isValid = false;
    }
  
    if (!Number.isInteger(countingFactor * 100)) {
      countingFactorErr.countingFactorNotANumber = "Bitte eine Zahl eingeben!";
      isValid = false;
    }
  
    if (Number.isInteger(countingFactor) && countingFactor >= 10) {
      countingFactorErr.countingFactorTooBig = "Bitte Zahl < 10 eingeben oder Teilassignments erfassen!";
      isValid = false;
    }
  
    if (!Number.isInteger(myDays * 10)) {
      availablePrepDaysErr.availablePrepDaysNotANumber = "Bitte Zahl eingeben!";
      isValid = false;
    }
  
    if (Number.isInteger(myDays) && myDays >= 120) {
      availablePrepDaysErr.availablePrepDaysTooBig = "Bitte Zahl < 120 eingeben oder Teilassignments erfassen!";
      isValid = false;
    }
  
    if (!Number.isInteger(myMinutes * 1)) {
      workloadErr.workloadNotANumber = "Bitte eine ganze Zahl eingeben!";
      isValid = false;
    }
  
    if (Number.isInteger(myMinutes) && myMinutes >= 1000) {
      workloadErr.workloadTooBig = "Bitte Zahl < 1000 eingeben oder Teilassignments erfassen!";
      isValid = false;
    }
  
    return { isValid, assignmentNameErr, countingFactorErr, availablePrepDaysErr, workloadErr };
  };
  