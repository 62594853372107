import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import CourseBox from "../CardElements/CourseBox";
import TypeBox from "../CardElements/TypeBox";
//L for leader
function EditLineTwoL({ previewAssignment, assignment, changesCount, setChangesCount, selectedAssignmentCourse, setSelectedAssignmentCourse, type, setType, restricted }) {
    return (
        <Grid item style={{ marginTop: "0.75em"}}>
        <Grid
          container
          justifyContent="flex-start"
          alignItems={"center"}
          alignContent={"center"}
          direction="row"
          spacing={3}
          
        // sx={{ marginTop: "0.75em", marginBottom:"0.75em" }}
        // sx={{ width: "100%", background: "orange" }}
        >
          {/* Assignment Kurs */}
          {type !== "personal" && (
            <Grid item xs={4}>
              <CourseBox
                restricted={restricted}
                assignment={assignment}
                selectedAssignmentCourse={selectedAssignmentCourse}
                setSelectedAssignmentCourse={setSelectedAssignmentCourse}
                changesCount={changesCount}
                setChangesCount={setChangesCount}
              />
            </Grid>
          )}
          {/* Assignment Type */}
          <Grid
            item
            xs={6}
            sx={{
              marginLeft: !restricted && type !== "personal" ? "0.9em" : "",
              //  background: "orange"
            }}
          >
            <TypeBox
              restricted={restricted}
              assignment={assignment}
              previewAssignment={previewAssignment}
              type={type}
              setType={setType}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
            />
          </Grid>
        </Grid>
      </Grid>

    );
}

export default memo(EditLineTwoL);
