async function filterTasksByType(assignments, type, getThisTypeOnly) {
  const filteredArray = assignments.filter((assignment) => {
    // Filter by type
    if (getThisTypeOnly && assignment.type !== type) {
      return false;
    } else if (!getThisTypeOnly && assignment.type === type) {
      //return everything but the type
      // console.log("second", assignment)

      return false;
    }

    // If none of the filters apply, keep the assignment
    return true;
  });

  return filteredArray;
}

exports.filterTasksByType = filterTasksByType;
