import styled from "@emotion/styled";
import { tooltipClasses } from "@mui/material";
import { Tooltip } from "@mui/material";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    top: -10,
    maxWidth: 130,
    maxHeight: 98,
    overflowY: "auto",
      overflowX: "auto", // Hide x-axis scrollbar
      "&::-webkit-scrollbar": {
        width: "0px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "your-desired-color", // Set your desired color
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent", // or any background color
      },
  },
});

export default CustomWidthTooltip;
