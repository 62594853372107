import { memo, useState } from "react";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib.js";
import { getBoxHeaderTitle } from "../../../../_3HelperFunctions/_BoxHeader/getBoxHeaderTitle.js";
import MiddleBox from "../../../../_4GeneralHelpers/1_Boxes/MiddleBox.js";
import BoxHeader from "../../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader.js";
import ReactCardFlip from "react-card-flip";
import DeadlineAssignmentsList from "./DeadlineAssignmentsList.js";

function DeadlineAssignmentsTFlip({single}) {
  const {
    isAStudent,
    isATeacher,
    isAnAdmin,
    selectedCourse,
    showCourseAssignmentOnly,
  } = useAppContext();
  const [teacherFlip, setTeacherFlip] = useState(true);

  const boxHeaderTitle = getBoxHeaderTitle(
    isAStudent,
    isATeacher,
    isAnAdmin,
    selectedCourse,
    teacherFlip?"coreCourse":"additional",
    showCourseAssignmentOnly
  );
 
  // console.log("teacherFlip, ", teacherFlip)

  return (
    <ReactCardFlip isFlipped={teacherFlip} flipDirection="horizontal">
      <>
        <BoxHeader
          title={boxHeaderTitle}
          options={single?[]:["teacherFlip"]}
          flipTeacherAssignment={teacherFlip}
          setFlipTeacherAssignment={setTeacherFlip} />
        <MiddleBox>
          <DeadlineAssignmentsList
            type={"additional"}
            single={single}
          />
        </MiddleBox >
      </>
      <>
        <BoxHeader
          title={boxHeaderTitle}
          options={single?["courseView"]:["courseView", "teacherFlip"]}
          flipTeacherAssignment={teacherFlip}
          setFlipTeacherAssignment={setTeacherFlip} />
        <MiddleBox>
          <DeadlineAssignmentsList
            type={"class"}
          />
        </MiddleBox>
      </>
    </ReactCardFlip>
  );
}

export default memo(DeadlineAssignmentsTFlip);
