async function dataDoesNotExistsCheck(newAssignments, oldAssignments) {
   //there is no data and has not been any, ergo no change
  // console.log(newAssignments.length)
  // console.log(oldAssignments.length)
  
  return (newAssignments[0] === undefined && oldAssignments[0] === undefined) ||
    (newAssignments.length === 0 && oldAssignments.length === 0)
  
}
exports.dataDoesNotExistsCheck = dataDoesNotExistsCheck;
