import moment from "moment";
import { filterUniqueAssignments } from "../_3HelperFunctions/_AssignmentFunctions/filterUniqueAssignments";
import { filterTasksByType } from "../_3HelperFunctions/_AssignmentFunctions/filterTasksByType";

function filterByWeek(arr, monday, plus) {
  return arr.filter((assignment) =>
    moment(assignment.due_date).isBetween(
      moment(moment(monday).add(plus, "days").startOf("day")),
      moment(
        moment(monday)
          .add(plus + 1, "days")
          .startOf("day")
      ),
      "days",
      "[)"
    )
  );
}

class SemesterService {
  getStartDateOfSemester(schoolYearData, date) {
    if (
      moment(date).isBetween(
        moment(schoolYearData.datesFirstSemester[0]),
        moment(schoolYearData.datesSecondSemester[1]),
        "days",
        "[]"
      )
    ) {
      if (
        moment(date).isBetween(
          moment(schoolYearData.datesFirstSemester[0]),
          moment(schoolYearData.datesFirstSemester[1]),
          "days",
          "[]"
        )
      ) {
        return moment(schoolYearData.datesFirstSemester[0]).format(
          "YYYY-MM-DD"
        );
      } else {
        return moment(schoolYearData.datesSecondSemester[0]).format(
          "YYYY-MM-DD"
        );
      }
    } else {
      return false;
    }
  }

  getSemesterName(schoolYearData, date) {
    let name;
    if (
      moment(date).isBetween(
        moment(schoolYearData.datesFirstSemester[0]),
        moment(schoolYearData.datesFirstSemester[1]),
        "days",
        "[]"
      )
    ) {
      name = schoolYearData.nameFirstSemester;
    } else {
      name = schoolYearData.nameSecondSemester;
    }
    return name;
  }

  async getSemesterData(schoolYearData, semesterStartDate, selectedCourse, currentUser, personal, viewCourseOnly) {
    // console.log("Pers, ", personal)
    const semesterData = [];
    let mondays;

    if (semesterStartDate === schoolYearData.datesFirstSemester[0]) {
      //we are in the first semester
      mondays = schoolYearData.mondaysFirstSemester;
    } else {
      // we are in the second semester
      mondays = schoolYearData.mondaysSecondSemester;
    }
    let relevantUserAssignments
    if (!personal) {//teacher looking at class
      relevantUserAssignments = await filterTasksByType(await filterUniqueAssignments(
        schoolYearData.classAssignments,
        "assignment_id"
      ), "exam", true)
      // Filter the array based on the specified conditions
      if (currentUser.roles.includes("Teacher")) {
        if (viewCourseOnly) {
          relevantUserAssignments = relevantUserAssignments.filter((assignment) => {
            return (assignment.user_id !== currentUser.id && assignment.course_id === selectedCourse.id) || assignment.course_id === selectedCourse.id;
          });

        } else {
          relevantUserAssignments = relevantUserAssignments.filter((assignment) => {
            return (assignment.user_id !== currentUser.id && assignment.core_course_id === selectedCourse.core_course_id) || assignment.core_course_id === selectedCourse.core_course_id;
          });
        }
      }
    } else { //student or teacher wanting personal info
      relevantUserAssignments = schoolYearData.userAssignments.filter((assignment) => assignment.type === "exam")
    }

    // console.log("filteredExams: ", relevantUserAssignments, selectedCourse)

    let _id;
    let _calendarWeek;
    let _monday;
    let _mondayAssignments;
    let _tuesdayAssignments;
    let _wednesdayAssignments;
    let _thursdayAssignments;
    let _fridayAssignments;
    let _saturdayAssignments;
    let _sundayAssignments;

    for (let i = 0; i < mondays.length; i++) {
      // console.log(mondays[i])
      _id = i + 1;
      _monday = mondays[i];
      _calendarWeek = moment(_monday).isoWeek();
      _mondayAssignments = filterByWeek(
        relevantUserAssignments,
        _monday,
        0
      );
      _tuesdayAssignments = filterByWeek(
        relevantUserAssignments,
        _monday,
        1
      );
      _wednesdayAssignments = filterByWeek(
        relevantUserAssignments,
        _monday,
        2
      );
      _thursdayAssignments = filterByWeek(
        relevantUserAssignments,
        _monday,
        3
      );
      _fridayAssignments = filterByWeek(
        relevantUserAssignments,
        _monday,
        4
      );
      _saturdayAssignments = filterByWeek(
        relevantUserAssignments,
        _monday,
        5
      );
      _sundayAssignments = filterByWeek(
        relevantUserAssignments,
        _monday,
        6
      );
      semesterData.push({
        id: _id,
        calendarWeek: _calendarWeek,
        monday: _monday,
        mondayAssignment: _mondayAssignments,
        tuesdayAssignment: _tuesdayAssignments,
        wednesdayAssignment: _wednesdayAssignments,
        thursdayAssignment: _thursdayAssignments,
        fridayAssignment: _fridayAssignments,
        saturdayAssignment: _saturdayAssignments,
        sundayAssignment: _sundayAssignments,
      });
    }
    // console.log(semesterData)
    return semesterData;
  }
}

const semesterServiceInstance = new SemesterService();
export default semesterServiceInstance;
