import React, { memo } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
import { useTheme } from "@mui/styles";

const SemesterWeekDateCell = ({ semesterWeek, handleClick, special }) => {
  const theme = useTheme();
  const clickFunction = (event) => {
    console.log(event)
    handleClick(event, moment(semesterWeek.monday).format("YYYY-MM-DD"));
  };
  return (
    <TableCell
      key={semesterWeek.monday}
      style={{ color: theme.palette.text.primary, backgroundColor: special && theme.palette.background.default }}
      onClick={clickFunction}
    >
      {moment(semesterWeek.monday).format("DD.MM")}
    </TableCell>
  );
};

export default memo(SemesterWeekDateCell);