import { Typography } from "@mui/material";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";

export const DoghnutText = ({ height, width }) => {
  const { thisWeekMinutesTotal, thisWeekMinutesDone, percentageDone } =
    useAppContext();
  // console.log(thisWeekMinutesTotal, thisWeekMinutesDone, percentageDone)
  return (
    <Typography
      sx={{
        position: "relative",
        width: `${Math.min(width, height)}px`,
        height: `${Math.min(width, height)}px`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "-0.5em",
        textAlign: "center",
      }}
      color={
        thisWeekMinutesTotal > 0 && percentageDone < 10
          ? "levels.red"
          : thisWeekMinutesTotal > 0 && percentageDone < 33
          ? "levels.orange"
          : thisWeekMinutesTotal > 0 && percentageDone < 66
          ? "levels.yellow"
          : thisWeekMinutesTotal > 0 && percentageDone < 90
          ? "levels.lightGreen"
          : "levels.green"
      }
      variant="h4"
      style={{
        fontSize: `${Math.min(width, height) / 5}px`,
        // backgroundColor: "yellow",
      }}
    >
      {thisWeekMinutesTotal !== thisWeekMinutesDone ? (
        <>
          <Typography
            variant="h5"
            component="span"
            style={{ fontSize: "inherit" }}
          >
            {thisWeekMinutesDone}
          </Typography>
          <Typography
            variant="h6"
            component="span"
            style={{ fontSize: `${Math.min(width, height) / 15}px` }}
          >
            von
          </Typography>
          <Typography
            variant="h5"
            component="span"
            style={{ fontSize: "inherit" }}
          >
            {thisWeekMinutesTotal}
          </Typography>
          <Typography
            variant="h6"
            component="span"
            style={{ fontSize: `${Math.min(width, height) / 15}px` }}
          >
            Minuten erledigt
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant="h5"
            component="span"
            style={{
              marginTop: "0.5em",
              fontSize: `${Math.min(width, height) / 10}px`,
            }}
          >
            Alles erledigt
          </Typography>
          <Typography
            variant="h6"
            component="span"
            style={{ fontSize: `${Math.min(width, height) / 12}px` }}
          >
            für diese Woche
          </Typography>
        </>
      )}
    </Typography>
  );
};
