import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import { IconButton, Grid } from "@mui/material";
import {
  Dashboard,
  CalendarViewWeek,
  FactCheck,
  Preview,
} from "@mui/icons-material";
import StyledTooltip from "../../../_4GeneralHelpers/2_Tooltips/StyledTooltip";

function MenuTopBarNavigation() {
  const {
    currentUser,
    isAuthenticated,
    currentPage,
    setCurrentPage,
    openToDoList,
    setOpenToDoList,
    loading,
    schoolYearLoading
  } = useAppContext();
  //for slider
  const navigate = useNavigate();

  const goToDashboard = () => {
    setCurrentPage("dashboard");
    navigate("/");
  };

  const goToSemesterView = () => {
    setCurrentPage("semesterview");
    navigate("/semesterplan");
  };

  const goToNachkontrolle = () => {
    setCurrentPage("nachkontrolle");
    navigate("/nachkontrolle");
  };

  /**
   * for slider:
   */
  const toggleSlider = () => {
    setOpenToDoList(!openToDoList);
  };

  return (
    <div className="nav-left">
      {isAuthenticated && currentUser && (
        <>
          <Grid
            container
            direction="row"
            spacing={0}
            justifyContent="start"
            alignItems="flex-start"
            display={"flex"}
          >
            {/* ToDo List */}
            <Grid item>
              <StyledTooltip title="ToDo Liste" placement="bottom-start" arrow>
                <span>
                  <IconButton
                    size="medium"
                    color={openToDoList ? "inherit" : "primaryLight"}
                    aria-label="Semesterplan"
                    sx={{
                      mr: 0,
                      display: {
                        xs: "block",
                      },
                    }}
                    onClick={toggleSlider}
                  >
                    <FactCheck />
                  </IconButton>
                </span>
              </StyledTooltip>
            </Grid>

            {/* Dashboard */}
            <Grid item
            // sx={{background: "orange",}}
            >
              <StyledTooltip title="Dashboard" placement="bottom-start" arrow>
                <IconButton
                  size="medium"
                  color={
                    currentPage == "dashboard" ? "inherit" : "primaryLight"
                  }
                  aria-label="Dashboard"
                  sx={{
                    mr: 0,
                    display: {
                      xs: "none",
                      sm: "block",
                    },
                  }}
                  onClick={goToDashboard}
                >
                  <Dashboard />
                </IconButton>
              </StyledTooltip>
            </Grid>

            {/* Semesterview */}
            <Grid item
            // sx={{background: "orange",}}
            >
              <StyledTooltip
                title="Prüfungsplan"
                placement="bottom-start"
                arrow
              >
                <span>
                  <IconButton
                    disabled={schoolYearLoading}
                    size="medium"
                    color={
                      currentPage == "semesterview" ? "inherit" : "primaryLight"
                    }
                    aria-label="Semesterplan"
                    sx={{
                      mr: 0,
                      display: {
                        xs: "block",
                      },
                    }}
                    onClick={goToSemesterView}
                  >
                    <CalendarViewWeek />
                  </IconButton>
                </span>
              </StyledTooltip>
            </Grid>
            {/* Nachkontrolle */}
            <Grid item
            // sx={{background: "blue",}}
            >
              <StyledTooltip
                title="Nachkontrolle"
                placement="bottom-start"
                arrow
              >
                <span>
                  <IconButton
                    disabled={schoolYearLoading}
                    size="medium"
                    color={
                      currentPage == "nachkontrolle"
                        ? "inherit"
                        : "primaryLight"
                    }
                    aria-label="Nachkontrolle"
                    sx={{
                      mr: 0,
                      display: {
                        xs: "block"
                      },
                    }}
                    onClick={goToNachkontrolle}
                  >
                    <Preview />
                  </IconButton>
                </span>
              </StyledTooltip>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}
export default memo(MenuTopBarNavigation);
