import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import StyledTooltip from "../../../_4GeneralHelpers/2_Tooltips/StyledTooltip";
import { useTheme } from "@mui/system";

const SeverityPillRoot = styled("span")(({ theme, ownerState }) => {
    const backgroundColor = theme.palette[ownerState.color].main;
    const color = theme.palette[ownerState.color].contrastText;

    return {
        alignItems: "center",
        backgroundColor,
        borderRadius: 12,
        color,
        cursor: "pointer",
        display: "inline-flex",
        flexGrow: 0,
        flexShrink: 0,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 2,
        fontWeight: 600,
        justifyContent: "center",
        letterSpacing: 0.5,
        minWidth: 20,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        textTransform: "uppercase",
        whiteSpace: "nowrap",
    };
});

const getTooltipContent = (props, theme) => {
    const { origin, assignment } = props;

    if (origin === "semesterTable") {
        return (
            <div style={{ whiteSpace: "pre-line" }} key={assignment.assignment_id}>
                <span style={{ fontWeight: "bold", fontSize: theme.typography.pxToRem(16), color: theme.palette.primary.dark }}>{assignment.name}</span>
                {"\nLehrervorschlag: " + assignment.suggested_minutes + " min." +
                    "\nVorbereitung: " + assignment.suggested_days + (assignment.my_days === 1 ? " Tag" : " Tage") +
                    "\nBereits durchschnittlich erledigt: " + assignment.my_minutes_done +
                    "\nDurchschnittlich erwarteter Aufwand: " + assignment.my_minutes}
            </div>
        );
    }
};

const SemesterWeekPillDetail = ({ color = "primary", children, ...other }) => {
    const theme = useTheme();
    const ownerState = { color };

    return (
        <StyledTooltip
            title={getTooltipContent(other, theme)}
            placement="bottom-start"
            arrow
            sx={{ cursor: "null" }}

        >
            <SeverityPillRoot ownerState={ownerState} {...other}>
                {children}
            </SeverityPillRoot>
        </StyledTooltip>
    );
};

SemesterWeekPillDetail.propTypes = {
    children: PropTypes.node,
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "error",
        "info",
        "warning",
        "success",
    ]),
};

export default SemesterWeekPillDetail;