import {
  Avatar,
  Box,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import authService from "../../../_6Services/auth.service";
import imageService from "../../../_6Services/image.service";
import { useTheme } from "@mui/styles";
import CircularStatic from "../../0Navigation/3Loading/CircularProgressWithLabel";
import { Input } from "@mui/material";
import { IconButton } from "@mui/material";
import ButtonController from "./ButtonController";
import ContentBox from "../../../_4GeneralHelpers/1_Boxes/ContentBox";

function AccountProfileContent() {
  const theme = useTheme();
  const { currentUser, setCurrentUser } = useAppContext();
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [roleNames, setRoleNames] = useState("");
  //for resetting file inupt value
  // const ref = useRef();
  //Some links on Picture setting:
  //https://www.w3docs.com/snippets/html/how-to-display-base64-images-in-html.html
  //https://stackoverflow.com/questions/42395034/how-to-display-binary-data-as-image-in-react
  const imageSelectedHandler = (e) => {
    let file = e.target.files[0];
    let imageData = new FormData();
    imageData.append("image", file);

    setImageData(imageData);
    setImagePreview(URL.createObjectURL(file));
    setFile(file);
  };
  const fileUploadHandler = () => {
    setLocalLoading(true);
    var editedUser = currentUser;
    try {
      imageService
        .upload(currentUser.id, file)
        .then(
          (response) =>
            (editedUser.avatar = response) +
            authService.editCurrentUser(editedUser) +
            // console.log("we trigger this...5")+
            setCurrentUser(editedUser)
        )
        .then(() => fileClearHandler())
        .then(() => setLocalLoading(false));
    } catch (err) {
      console.error(err);
      setLocalLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      var out = [];
      currentUser.roles.forEach((role) => out.push(role));
      setRoleNames(out.join(", "));
    }
  }, [currentUser]);

  const fileClearHandler = () => {
    try {
      setFile(null);
      setImagePreview(null);
      setImageData(null);
      // ref.current.value = "";
    } catch {
      setLocalLoading(false);
    }
  };

  return (
    <ContentBox>
      <Grid container spacing={3}>
        <Grid item>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {!localLoading ? (
              <IconButton
                component="label"
                style={{ backgroundColor: "transparent" }}
                variant={"raised"}
              >
                <Avatar
                  src={`data:image/jpeg;base64,${currentUser.avatar}`}
                  sx={{
                    height: 64,
                    mb: 2,
                    width: 64,
                  }}
                />
                <Input
                  id="img"
                  type="file"
                  accept="image/png, image/jpeg"
                  autoFocus={true}
                  disableUnderline={true}
                  onChange={imageSelectedHandler}
                  hidden
                />
              </IconButton>
            ) : (
              <>
                <CircularStatic />
                <br />
              </>
            )}

            <Typography
              color={theme.palette.text.blue}
              noWrap={true}
              gutterBottom
              variant="h5"
            >
              {currentUser.userNickName
                ? currentUser.userNickName
                : currentUser.firstName + " " + currentUser.lastName}
            </Typography>

            <Typography
              color={theme.palette.text.blue}
              noWrap={true}
              gutterBottom
              variant="h7"
            >
              (Funktion: {roleNames} )
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          {!localLoading && (
            <>
              <CardMedia
                component="img"
                image={imagePreview !== null ? imagePreview : null}
                sx={{
                  maxHeight: 120,
                  width: "95%", // Allow it to take up the available space
                  marginBottom: "1rem",
                }}
                variant="outlined"
              />
              {file && (
                <ButtonController
                  localLoading={localLoading}
                  disabled={localLoading || file === null}
                  save={fileUploadHandler}
                  clear={fileClearHandler}
                  saveText={"speichern"}
                  clearText={"verwerfen"}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
      {/* </Box> */}
    </ContentBox>
  );
}
export default memo(AccountProfileContent);
