import { createTheme, responsiveFontSizes } from "@mui/material";

let husibuechTheme = createTheme({
  menuTopBar: {
    "&:hover": {
      backgroundColor: "green",
    },
  },
  palette: {
    type: "light",
    primary: {
      main: "#38679c",
      light: "#6b95cd",
      dark: "#003d6d",
    },
    primaryLight: {
      main: "#6b95cd",
    },
    secondary: {
      main: "#38999c",
      light: "#6ecacd",
      dark: "#006a6e",
    },
    tertiary: {
      main: "#f44336",
      light: "#ff7961",
      dark: "#ba000d",
    },
    background: {
      default: "#f8f8f8",
      paper: "#E8EAF6",
      paperWhite: "#ffffff",
      special: "#e8f5e9",
      red: "#e53935",
      black: "#333333",
    },
    levels: {
      green: "#2e7d32",
      lightGreen: "#c0ca33",
      yellow: "#fdd835",
      orange: "#fb8c00",
      red: "#e53935",
      blue: "#38679c",
      lightBlue: "#add8e6",
      white: "#FFFFFF",
    },
    text: {
      green: "#2e7d32",
      lightGreen: "#c0ca33",
      yellow: "#fdd835",
      orange: "#fb8c00",
      red: "#e53935",
      blue: "#38679c",
      lightBlue: "#add8e6",
      white: "#FFFFFF",
      primary: "#000000",
      secondary: "#38679c",
    },
    icon: {
      primary: "#38679c",
      secondary: "#7bb4da",
    },
    success: {
      main: "#389c6d",
    },
    info: {
      main: "#3b389c",
    },
    error: {
      main: "#9c3b38",
    },
    warning: {
      main: "#9c3899",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      xss: 200,
      xsss: 400,
      ss: 600,
      sm: 800,
      mds: 960,
      md: 1000,
      lg: 1400,
      xl: 1800,
      xxl: 2200,
      xxxl: 2600,
    },
  },
  typography: {
    fontFamily: "Oswald",
    // fontSize: {
    //     xl: 35,
    //     lg: 30,
    //     md: 20,
    //     sm: 15,
    //     xs: 10
    // },
    fontSize: 14,
    fontWeightBold: 800,
    fontWeightMedium: 500,
    h1: {
      fontSize: "3rem",
      lineHeight: 1.25,
      fontWeight: 400,
    },
    h2: {
      fontSize: "2.5rem",
      lineHeight: 1.25,
      fontWeight: 400,
    },
    h3: {
      fontSize: "2rem",
      lineHeight: 1.25,
      fontWeight: 600,
    },
    h4: {
      fontSize: "2rem",
      lineHeight: 1.25,
      fontWeight: 400,
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: 1.25,
    },
    h6: {
      fontSize: "1.5rem",
      fontWeight: 400,
      lineHeight: 1.25,
    },
    subtitle1: {
      fontSize: "1.2rem",
      lineHeight: 1,
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: "1.2rem",
      fontWeight: 400,
      lineHeight: 1,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.2,
    },
    body2: {
      fontSize: "1rem",
      lineHeight: 1.2,
    },
    button: {
      fontWeight: 500,
      lineHeight: 1.5,
    },
    caption: {
      fontSize: "0.8rem",
      lineHeight: 1.2,
    },
    overline: {
      fontSize: "0.7rem",
      lineHeight: 1.5,
    },
  },
  spacing: 8,
  overrides: {
    MuiAppBar: {
      fontSize: 10,
      colorInherit: {
        backgroundColor: "#689f38",
        color: "#000",
      },
    },
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: 8,
      },
      switchBase: {
        padding: 1,
        "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
          transform: "translateX(16px)",
          color: "#fff",
          "& + $track": {
            opacity: 1,
            border: "none",
          },
        },
      },
      thumb: {
        width: 24,
        height: 24,
      },
      track: {
        borderRadius: 13,
        border: "1px solid #bdbdbd",
        backgroundColor: "#fafafa",
        opacity: 1,
        transition:
          "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      },
    },
  },
  props: {
    MuiButton: {
      size: "small",
    },
    MuiButtonGroup: {
      size: "small",
    },
    MuiCheckbox: {
      size: "small",
    },
    MuiFab: {
      size: "small",
    },
    MuiFormControl: {
      margin: "dense",
      size: "small",
    },
    MuiFormHelperText: {
      margin: "dense",
    },
    MuiIconButton: {
      size: "small",
    },
    MuiInputBase: {
      margin: "dense",
    },
    MuiInputLabel: {
      margin: "dense",
    },
    MuiRadio: {
      size: "small",
    },
    MuiSwitch: {
      size: "small",
    },
    MuiTextField: {
      margin: "dense",
      size: "small",
    },
    MuiTooltip: {
      arrow: true,
    },
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: "small",
    },
  },
  shape: {
    borderRadius: 4,
  },
});
husibuechTheme = responsiveFontSizes(husibuechTheme, {
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
  factor: 3,
});
export default husibuechTheme;

// unused

// type: 'light',
// primary: {
//     main: '#0199a7',
// },
// secondary: {
//     main: '#ffb300',
// },
// background: {
//     default: '#e3eff3',
// },
// error: {
//     main: '#a70f01',
// },
// success: {
//     main: '#01a762',
// },
// warning: {
//     main: '#e04a3b',
// },
// info: {
//     main: '#0146a7',
// },
