export const createSubjectTag = (inputName) => {
    const parts = inputName.split('-');
    const subjectTag = parts[0].trim(); // Trim whitespace at the end

    return subjectTag;
};

export const createCourseName = (inputName) => {
    const teacherTag = createTeacherName(inputName);
    // Remove the teacher tag from the input name
    let courseName = inputName.replace(teacherTag, '');

    // Remove leading and trailing hyphens or spaces
    courseName = courseName.replace(/^-+|-+$/g, '').trim();
    return courseName;
};

export const createCoreCourseName = (inputName) => {
    const courseTag = createCourseName(inputName); // gets EN-G22a
    const coreCourseName = createTeacherName(courseTag) // gets G22a

    return coreCourseName;
};

export const createTeacherName = (inputName) => {
    const parts = inputName.split('-');
    const lastPart = parts[parts.length - 1];

    // Remove unwanted characters
    const teacherTag = lastPart.replace(/\s/g, '').replace(/\(/g, '').replace(/\)/g, '');

    return teacherTag;
};
