import { Grid, ListItemAvatar } from "@mui/material";
import React, { memo } from "react";
import { useTheme } from "@mui/styles";
import AssignmentAvatar from "../../DeadlineBoxes/AssignmentAvatar";

function AvatarBox({ type, status }) {
  const theme = useTheme();

  return (
    <>
      <Grid item xs={1}>
        <ListItemAvatar
          sx={{
            backgroundColor:
              status === 4
                ? theme.palette.levels.green
                : status === 3
                ? theme.palette.levels.lightGreen
                : status === 2
                ? theme.palette.levels.yellow
                : status === 1
                ? theme.palette.levels.orange
                : theme.palette.levels.red,
            height: "30px",
            width: "30px",
            // marginRight: 3,
            // marginLeft: -2,
            borderRadius: 3,
          }}
        >
          <AssignmentAvatar
            type={type}
            width={25}
            marginTop={0}
            marginLeft={0.75}
          />
        </ListItemAvatar>
      </Grid>
    </>
  );
}

export default memo(AvatarBox);
