import React, { memo } from "react";
import {
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import StyledTooltip from "../../../_4GeneralHelpers/2_Tooltips/StyledTooltip";
import { useTheme } from "@mui/styles";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

const SemesterTableHead = () => {
  const {appInDarkMode} = useAppContext()
  const theme = useTheme();

  return (
    <TableHead >
      <TableRow sx={{}}>
        <StyledTooltip title={"Semesterwoche"} placement="bottom-start" arrow>
          <TableCell style={{ width: "3%", backgroundColor: appInDarkMode?"":theme.palette.primary.main }}>SW</TableCell>
        </StyledTooltip>
        <TableCell
          style={{
            width: "6%",
            color: theme.palette.text.primary,
            backgroundColor: appInDarkMode?"":theme.palette.primary.main
          }}
        >
          Datum
        </TableCell>
        <TableCell
          style={{
            width: "13%",
            color: theme.palette.text.primary,
            backgroundColor: appInDarkMode?"":theme.palette.primary.main
          }}
        >
          Mo
        </TableCell>
        <TableCell
          style={{
            width: "13%",
            color: theme.palette.text.primary,
            backgroundColor: appInDarkMode?"":theme.palette.primary.main
          }}
        >
          Di
        </TableCell>
        <TableCell
          style={{
            width: "13%",
            color: theme.palette.text.primary,
            backgroundColor: appInDarkMode?"":theme.palette.primary.main
          }}
        >
          Mi
        </TableCell>
        <TableCell
          style={{
            width: "13%",
            color: theme.palette.text.primary,
            backgroundColor: appInDarkMode?"":theme.palette.primary.main
          }}
        >
          Do
        </TableCell>
        <TableCell
          style={{
            width: "13%",
            color: theme.palette.text.primary,
            backgroundColor: appInDarkMode?"":theme.palette.primary.main
          }}
        >
          Fr
        </TableCell>
        <TableCell
          style={{
            width: "13%",
            color: theme.palette.text.primary,
            backgroundColor: appInDarkMode?"":theme.palette.primary.main
          }}
        >
          Sa
        </TableCell>
        <TableCell
          style={{
            width: "13%",
            color: theme.palette.text.primary,
            backgroundColor: appInDarkMode?"":theme.palette.primary.main
          }}
        >
          So
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default memo(SemesterTableHead);
