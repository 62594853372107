import React, { memo, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useTheme } from "@mui/styles";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import userService from "../../../_6Services/user.service";
import appService from "../../../_6Services/app.service";

// function createData(id, name, subject, tutor) {
function createData(id, name, subject) {
  return {
    id,
    name,
    subject,
    // tutor,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const DEFAULT_ORDER = "asc";
const DEFAULT_ORDER_BY = "name";
const DEFAULT_ROWS_PER_PAGE = 25;

function EnhancedTableHead(props) {
  const { isAStudent } = useAppContext();
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (newOrderBy) => (event) => {
    onRequestSort(event, newOrderBy);
  };

  const headCellsUser = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Kursname",
    },
    {
      id: "subject",
      numeric: false,
      disablePadding: false,
      label: `${isAStudent ? "Fach" : "Funktion"}`,
    },
    // {
    //   id: "tutor",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Lehrer",
    // },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCellsUser.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={null}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const theme = useTheme();
  const {
    currentUser,
    isAStudent,
    myCoreCourses,
    setMyCoreCourses,
    setHusibuechCoreCourses,
    setSchoolYearLoading,
    setUpdateCurrentUserData
  } = useAppContext();

  const deleteKlasse = () => {
    setTimeout(async () => {
      var answer = window.confirm(
        `${numSelected > 1
          ? "Wirklich aus all diesen Klassen austreten?"
          : "Wirklich aus dieser Klasse austreten?"
        }`
      );
      if (!answer) {
        alert("Keine Änderung vorgenommen!");
      } else {
        await removeKlasse();
      }
    }, 300);
  };
  //prepare backend data
  const removeKlasse = async () => {
    var klassen = [];
    // console.log(props.selectedCourses);

    await props.selectedCourses.forEach((id) => {
      // console.log(userCourses.filter((course) => course.id === id));
      klassen.push(
        myCoreCourses?.filter((coreCourse) => coreCourse.id === id)[0]
      );
      // const selectedIndex = selectedUsers.indexOf(id);
      // coursesCopy = coursesCopy.filter((course) => course.id !== id);
      // //delete the user from system
    });

    // console.log(klassen);

    try {
      const response = await userService.delete(
        "corecourse",
        klassen,
        currentUser.id
      );
      // console.log(response.data) //returns the deleted course!!!

      const selectedIndex = props.selectedCourses.indexOf(response?.data?.id);
      let newSelected = [];
      if (selectedIndex && selectedIndex === 0) {
        newSelected = newSelected.concat(props.selectedCourses.slice(1));
      } else if (selectedIndex === props.selectedCourses.length - 1) {//slice last
        newSelected = newSelected.concat(props.selectedCourses.slice(0, -1));
      } else if (selectedIndex > 0) {//slice from index
        newSelected = newSelected.concat(
          props.selectedCourses.slice(0, selectedIndex),
          props.selectedCourses.slice(selectedIndex + 1)
        );
      }
      setMyCoreCourses(newSelected);
      props.setSelectedCourses(newSelected);

      //deal with teachers who remove themselves from courses in which they were tutors
      try {
        const response2 = await appService.get("core_course");
        // console.log("response2: ,", response2)
        setHusibuechCoreCourses(response2.data?response2.data:[]);
      } catch (err) {
        console.error(err);
      }
      await setUpdateCurrentUserData(true)
      setTimeout(async () => {
        await setSchoolYearLoading(true);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Toolbar
      sx={{
        marginTop: "-1.5em",
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
        color="primary"
      >
        {isAStudent
          ? "Deine erfasste Klasse (Stammklasse)"
          : "Ihre erfasste Klasse (Klassenlehrperson)"}
      </Typography>

      {numSelected > 0 ? (
        <Tooltip
          title={
            numSelected > 1
              ? "Mich aus diesen Klassen löschen"
              : "Mich aus dieser Klasse löschen"
          }
        >
          <IconButton onClick={deleteKlasse}>
            <DeleteIcon style={{ color: theme.palette.levels.lightGreen }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon style={{ color: theme.palette.levels.primary }} />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function CoreCoursesOverviewTable() {
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = useState(DEFAULT_ORDER_BY);
  const [dense] = useState(true);
  const [visibleRows, setVisibleRows] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [paddingHeight, setPaddingHeight] = useState(0);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const { myCoreCourses, currentUser, isAStudent } = useAppContext();

  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (myCoreCourses) {
      let array = [];
      /**
       * Push CoreCourse
       */
      myCoreCourses.forEach(function (coreCourse) {
        // console.log(course);
        array.push(
          // createData(course.id, course.name, course.subject.name, course.tutor)
          createData(
            coreCourse.id,
            coreCourse.name,
            isAStudent
              ? "Stammklasse"
              : "Klassenlehrperson"
          )
        );
      });

      setRows(array);
    }
  }, [myCoreCourses, currentUser]);

  // useEffect(() => {
  //   console.log(selectedCourses)
  //   console.log(userCourses.filter(course => course.id === selectedCourses[0]))

  // }, [selectedCourses]);

  useEffect(() => {
    if (rows.length) {
      setVisibleRows(rows.length > 10 ? rows.slice(0, 10) : rows);
    }
  }, [rows]);

  const handleRequestSort = useCallback(
    (event, newOrderBy) => {
      const isAsc = orderBy === newOrderBy && order === "asc";
      const toggledOrder = isAsc ? "desc" : "asc";
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);

      const sortedRows = stableSort(
        rows,
        getComparator(toggledOrder, newOrderBy)
      );
      const updatedRows = sortedRows.slice(
        rowsPerPage,
        rowsPerPage + rowsPerPage
      );

      setVisibleRows(updatedRows);
    },
    [order, orderBy, rowsPerPage, rows]
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelectedCourses(newSelected);
      return;
    }
    setSelectedCourses([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selectedCourses.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) { //add course
      newSelected = newSelected.concat(selectedCourses, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCourses.slice(1));
    } else if (selectedIndex === selectedCourses.length - 1) {//slice last
      newSelected = newSelected.concat(selectedCourses.slice(0, -1));
    } else if (selectedIndex > 0) {//slice from index
      newSelected = newSelected.concat(
        selectedCourses.slice(0, selectedIndex),
        selectedCourses.slice(selectedIndex + 1)
      );
    }
    setSelectedCourses(newSelected);
  };


  const isSelected = (id) => selectedCourses.indexOf(id) !== -1;

  return (
    <>
      <Paper sx={{ width: "100%", mt: "0.5em", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selectedCourses.length}
          selectedCourses={selectedCourses}
          setSelectedCourses={setSelectedCourses}
        />
        <Box mb={"1rem"} ml={"2rem"} mr={"2rem"}>
          <TableContainer>
            <Table
              // sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selectedCourses.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows && visibleRows !== null && visibleRows.length
                  ? visibleRows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.subject}</TableCell>
                        {/* <TableCell align="left">{row.tutor}</TableCell> */}
                      </TableRow>
                    );
                  })
                  : null}
                {paddingHeight > 0 && (
                  <TableRow
                    style={{
                      height: paddingHeight,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </>
  );
}
export default memo(CoreCoursesOverviewTable);
