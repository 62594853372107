import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  DataGrid,
  deDE,
  GridActionsCellItem,
  GridRowModes,
  GridRowEditStopReasons,
  GridToolbarContainer,
  GridColumnMenu,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../../_8HusibuechContexts/contextLib';
import { styled, useTheme } from '@mui/styles';
import { saveAssignmentChanges } from '../../../_3HelperFunctions/_AssignmentFunctions/saveAssignmentChanges';
import { updateDataSet } from '../../../_3HelperFunctions/_LocalUpdates/updateDataSet';

const StyledDataGrid = styled(DataGrid)(({ appindarkmode, theme }) => ({
  "& .MuiDataGrid-sortIcon": {
    opacity: 1,
    color: appindarkmode === "true" ? theme.palette.levels.white : theme.palette.levels.dark
  },
  "& .MuiDataGrid-filterIcon": {
    opacity: 1,
    color: appindarkmode === "true" ? theme.palette.levels.white : theme.palette.levels.dark
  },
  "& .MuiDataGrid-menuIconButton": {
    opacity: 1,
    color: appindarkmode === "true" ? theme.palette.levels.white : theme.palette.levels.dark
  },
}));

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        // columnMenuColumnsItem: null,
      }}
      slotProps={{
        // Swap positions of filter and sort items
        columnMenuFilterItem: {
          displayOrder: 0, // Previously `10`
        },
        columnMenuSortItem: {
          displayOrder: 10, // Previously `0`
        },
      }}
    />
  );
}

const UnfinishedDataGrid = ({ data }) => {
  const _ = require('lodash');
  const theme = useTheme()
  const { currentUser, isALeader, isAStudent, appInDarkMode, setUnresolvedSchoolYearData, schoolYearData } = useAppContext()
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  useEffect(() => {
    // Map the data and add a unique 'id' property
    const mappedRows = data.map((row) => ({
      ...row,
      id: row.assignment_id,
      due_date: new Date(row.due_date), // Convert due_date to Date object
      type: row.type === "exam" ? "Prüfung" : row.type === "homework" ? "Hausaufgabe" : "Personal",
      abweichung: row.my_minutes - row.suggested_minutes === 0
        ? "-"
        : (row.my_minutes - row.suggested_minutes > 0
          ? "+"
          : "") +
        (row.my_minutes - row.suggested_minutes) +
        "min (" +
        Math.round(
          Math.abs(
            ((row.my_minutes - row.suggested_minutes) /
              row.suggested_minutes) *
            100
          )
        ) +
        "%)"
    }));
    setRows(mappedRows);
  }, [data]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id, props) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    // console.log(rows.filter((row)=> row.assignment_id === id)[0])

  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow) => {
    try {
      let schoolYearDataCopy = _.cloneDeep(schoolYearData);
      let assignment = rows.filter((row) => row.assignment_id === newRow.assignment_id)[0]
      assignment.type = assignment.type === "Prüfung" ? "exam" : assignment.type === "Hausaufgabe" ? "homework" : "personal"
      const percentageNew = newRow.my_minutes_done / newRow.my_minutes;
      //100% done == done:
      const theoreticalStatus =
        percentageNew === 0
          ? 0
          : percentageNew < 1 / 3
            ? 1
            : percentageNew < 2 / 3
              ? 2
              : percentageNew < 3 / 3
                ? 3
                : 4;
      const updateValues = [
        { property: "status", value: theoreticalStatus },
        { property: "my_minutes", value: newRow.my_minutes > newRow.my_minutes_done ? newRow.my_minutes : newRow.my_minutes_done },
        { property: "my_minutes_done", value: newRow.my_minutes_done },
      ]
      // console.log(updateValues)
      //connect to db
      const responseAssignment = await saveAssignmentChanges(
        assignment,
        updateValues,
        "update",
        currentUser
      );
      // console.log(responseAssignment)
      //local update
      const updatedSchoolYearData = await updateDataSet(schoolYearDataCopy, responseAssignment, isALeader, "nachkontrolle")
      await setUnresolvedSchoolYearData(updatedSchoolYearData);

    } catch (error) {
      console.log(error)
    }
    // Implement the logic based on your data structure
    // Update the row in your data array and return the updated row
    return newRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      align: 'center',
      headerName: 'Ändern',
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
      minWidth: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: 'type',
      headerName: 'Typ',
      headerClassName: 'super-app-theme--header',
      flex: 0.4,
      minWidth: 100,
      editable: false,
      type: 'singleSelect',
      valueOptions: ['Prüfung', 'Hausaufgabe', 'Personal'],
    },
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: 'super-app-theme--header',
      flex: 0.8,
      minWidth: 160,
      // width: 180,
      editable: false
    },
    {
      field: 'course_name',
      headerName: 'Kurs',
      headerClassName: 'super-app-theme--header',
      flex: 0.3,
      minWidth: 80,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'due_date',
      headerName: 'Datum',
      headerClassName: 'super-app-theme--header',
      type: 'date',
      flex: 0.4,
      // minWidth: 100,
      editable: false,
    },
    {
      field: 'suggested_minutes',
      headerName: 'Vorschlag',
      headerClassName: 'super-app-theme--header',
      align: 'center',
      editable: false,
      type: 'number',
      flex: 0.4,
      // minWidth: 100,
      description: 'Von der Lehrperson vorgeschlagener Zeitaufwand. Ausser natürlich bei den persönlichen Aufgaben',

    },
    {
      field: 'my_minutes',
      headerName: isAStudent ? 'Dein Aufwand' : 'Ihr Aufwand',
      headerClassName: 'super-app-theme--header',
      align: 'center',
      editable: true,
      type: 'number',
      flex: 0.4,
      // minWidth: 100,
      description: "Von dir persönlich eingeplanter Zeitaufwand."

    },
    {
      field: 'my_minutes_done',
      headerName: 'Bereits erledigt',
      headerClassName: 'super-app-theme--header',
      align: 'center',
      editable: true,
      type: 'number',
      flex: 0.4,
      // minWidth: 100,
    },
    {
      field: 'abweichung',
      headerName: 'Abweichung',
      headerClassName: 'super-app-theme--header',
      align: 'left',
      editable: false,
      flex: 0.5,
      minWidth: 120,
      description: "Deine Abweichung vom Vorgebenenen.\n\"+\" bedeutet, dass du mehr Zeit eingeplant hast als vorgegeben.\n\"-\" bedeutet, dass du weniger Zeit eingeplant hast als vorgegeben."
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        maxWidth: '100%',
        '& .actions': {
          color: theme.palette.text.secondary,
        },
        '& .textPrimary': {
          color: theme.palette.text.primary,
        },
        '& .super-app-theme--header': {
          backgroundColor: appInDarkMode ? theme.palette.background.black : theme.palette.primary.main,
          // paddingBottom: "1.5em"
        },
      }}
    >
      <StyledDataGrid localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        // autoHeight
        rows={rows}  // Use the provided data prop
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        initialState={{
          ...data.initialState,
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 20, 50, 100]}
        slots={{
          toolbar: EditToolbar,
          columnMenu: CustomColumnMenu
        }}
        slotProps={{
          toolbar: { setRowModesModel },
        }}
        sx={{
          width: "100%",
          boxShadow: 2,
          border: 2,
          borderColor: 'transparent',
          '& .MuiDataGrid-cell:hover': {
            color: theme.palette.primary.light,
          },
          '& .MuiDataGrid-row:hover': {
            color: theme.palette.primary.main,
          },
        }}
        appindarkmode={appInDarkMode.toString()}
        theme={theme}
      />
    </Box>
  );
};

const EditToolbar = (props) => {
  const { setRowModesModel } = props;

  // const handleClick = () => {
  //   // Handle the logic to add a new row
  // };

  return (
    <GridToolbarContainer>
      {/* <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button> */}
    </GridToolbarContainer>
  );
};

export default UnfinishedDataGrid;
