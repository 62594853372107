// DraggableWorkboardColumn.js
import React, { useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";
import { Typography, IconButton } from "@mui/material";
import { VisibilityOff } from "@mui/icons-material";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";
import { memo } from "react";
import {
  ColumnHeader,
  TaskList,
  DroppableStyles,
} from "./DraggableWorkboardStyles";
import DraggableWorkboardAssignment from "./DraggableWorkboardAssignment";
import { useTheme } from "@mui/styles";
import CustomWidthTooltip from "../../../../../_4GeneralHelpers/2_Tooltips/CustomWidthTooltip";
import longText from "./LongText";

function DraggableWorkboardColumn({ prefix, elements, setOpenSnackbar }) {
  const { showDetail, setShowDetail, statusList, appInDarkMode } = useAppContext();
  const theme = useTheme()
  const toggleShowDetail = () => {
    setShowDetail(!showDetail);
  };
  // useEffect(() => {
  //   console.log("prefDark:", appInDarkMode)
  // }, [appInDarkMode])

  return (
    <DroppableStyles color={theme.palette.background.default}
    >
      <ColumnHeader>{prefix}</ColumnHeader>
      <Droppable droppableId={`${prefix}`}>
        {(provided, snapshot) => (
          <TaskList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
            color={appInDarkMode ? theme.palette.background.default : "#ffffff"}
            dragColor={appInDarkMode ? theme.palette.background.special : theme.palette.background.paper}
          >
            {elements === undefined ? (
              <div>error</div>
            ) : prefix !== "spätere Arbeiten" &&
              elements?.length !== 0 ? (
              elements.map((assignment, index) => (
                <DraggableWorkboardAssignment
                  id={assignment.assignment_id}
                  key={assignment.assignment_id}
                  assignment={assignment}
                  index={index}
                  setOpenSnackbar={setOpenSnackbar}
                />
              ))
            ) : prefix === statusList[0] && elements.length === 0 ? (
              <Typography color="textSecondary" variant="caption">
                Super, alles läuft diese Woche!
              </Typography>
            ) : prefix !== "spätere Arbeiten" && elements ? (
              <Typography color="textSecondary" variant="caption">
                komm zu mir!
              </Typography>
            ) : prefix === "spätere Arbeiten" &&
              showDetail &&
              elements?.length > 0 ? (
              elements.map((assignment, index) => (
                <DraggableWorkboardAssignment
                  id={assignment.assignment_id}
                  key={assignment.assignment_id}
                  assignment={assignment}
                  index={index}
                  setOpenSnackbar={setOpenSnackbar}
                />
              ))
            ) : prefix === "spätere Arbeiten" &&
              !showDetail &&
              elements.length !== 0 ? (
              <CustomWidthTooltip title={longText} placement="bottom" arrow>
                <IconButton
                  aria-label="detail ansicht"
                  onClick={() => {
                    toggleShowDetail();
                  }}
                >
                  <VisibilityOff style={{ color: "#7bb4da" }} />
                </IconButton>
              </CustomWidthTooltip>
            ) : prefix === "spätere Arbeiten" && elements.length === 0 ? (
              <Typography color="textSecondary" variant="caption">
                keine mehr!
              </Typography>
            ) : (
              <></>
            )}

            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </DroppableStyles>
  );
}

export default memo(DraggableWorkboardColumn);
