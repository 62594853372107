import { memo, useState } from "react";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib.js";
import { getBoxHeaderOptions } from "../../../../_3HelperFunctions/_BoxHeader/getBoxHeaderOptions.js";
import { getBoxHeaderTitle } from "../../../../_3HelperFunctions/_BoxHeader/getBoxHeaderTitle.js";
import DeadlineAssignmentsList from "./DeadlineAssignmentsList.js";
import MiddleBox from "../../../../_4GeneralHelpers/1_Boxes/MiddleBox.js";
import BoxHeader from "../../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader.js";
import DeadlineAssignmentsTFlip from "./DeadlineAssignmentsTFlip.js";

function DeadlineAssignments({ type, flipAssignment, setFlipAssignment, single }) {
  const {
    isAStudent,
    isATeacher,
    isAnAdmin,
    isALeader,
    currentUser,
    selectedCourse,
    showCourseAssignmentOnly,
  } = useAppContext();
  const [teacherFlip, setTeacherFlip] = useState(true);


  // console.log("type, ", type)
  const shouldRenderPersonalList =
    (isALeader && type === "personal") ||
    (isAStudent && type === "studentView") ||
    (currentUser && type === "personalEmpty");
  // console.log("pers", shouldRenderPersonalList)

  const shouldRenderCoreCourseList = isALeader && type === "coreCourse";

  const boxHeaderTitle = getBoxHeaderTitle(
    isAStudent,
    isATeacher,
    isAnAdmin,
    selectedCourse,
    type,
    showCourseAssignmentOnly
  );
  const boxHeaderOptions = getBoxHeaderOptions(type, single);

  return (
    shouldRenderCoreCourseList && isALeader ?
      <DeadlineAssignmentsTFlip single={single} /> :
      <>
        <BoxHeader
          title={boxHeaderTitle}
          options={boxHeaderOptions}
          flipAssignment={flipAssignment}
          setFlipAssignment={setFlipAssignment}
          flipTeacherAssignment={teacherFlip}
          setFlipTeacherAssignment={setTeacherFlip} />
        <MiddleBox>
          <DeadlineAssignmentsList
            type={
              shouldRenderPersonalList
                ? "personal"
                : "additional"
            }
          />
        </MiddleBox>
      </>
  );
}

export default memo(DeadlineAssignments);
