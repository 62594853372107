import { Grid } from "@material-ui/core";
import { memo } from "react";
import DraggableContainer from "../DraggableContainer";


function PriorityDragBoxes({priorityLabels, elements, setOpenSnackbar}) {
  return (
    <div className="iDoContainer">
      <div className="iDoContainerHover">
        <Grid container spacing={0} alignItems="center">
          {priorityLabels.map((label, index) => (
            <Grid item key={index} xs={6}>
              <DraggableContainer
                key={index}
                elements={elements[label]}
                prefix={label}
                setOpenSnackbar={setOpenSnackbar}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default memo(PriorityDragBoxes);
