import React, { memo } from "react";
import AufwandBoxComponent from "./AufwandBoxComponent";

function AufwandBox({
  classView,
  restricted,
  previewAssignment,
  setPreviewAssignment,
  changesCount,
  setChangesCount,
  my_minutes,
  setMyMinutes,
  my_minutes_done,
  setWorkloadMinutesDone,
  suggested_minutes,
  setWorkloadMinutesTotalSugg,
  type,
  status,
  setStatus,
  workloadErr,
  personalView,
}) {
  // console.log("classView: ", classView)
  const change = (e) => {
    // console.log("...we do something")
    let changesCountCopy = changesCount;

    const valueKey = personalView ? "my_minutes" : "suggested_minutes";
    const currentValue = parseInt(e.target.value, 10);

    if (currentValue === previewAssignment[valueKey]) {
      // We have gone back to default
      // console.log("...this")
      setChangesCount(changesCountCopy - 1);
    } else if (
      previewAssignment[valueKey] !== currentValue &&
      previewAssignment[valueKey] !==
      (valueKey === "my_minutes" ? my_minutes : suggested_minutes)
    ) {
      // Do nothing
      // console.log("...nothing")
    } else {
      // New change
      // console.log("...that")
      setChangesCount(changesCountCopy + 1);
    }

    const finalValue = currentValue >= 0 ? currentValue : 0;

    if (personalView) {
      // console.log("we go here...", currentValue, finalValue)
      setMyMinutes(finalValue);
    } else {
      setWorkloadMinutesTotalSugg(finalValue);
    }

    /**
     * Impact on other variables
     */
    // Consider potential workload minutes done overflow
    let finalMinutesDone = Math.min(my_minutes_done, finalValue);
    setWorkloadMinutesDone(finalMinutesDone);

    // Then consider the set status based on these new values
    let previewAssignmentCopy = { ...previewAssignment };
    let statusCopy = status;

    // Consider these boundaries
    const percentageNew = finalMinutesDone / finalValue;
    const theoreticalStatus =
      percentageNew === 0
        ? 0
        : percentageNew < 1 / 3
          ? 1
          : percentageNew < 2 / 3
            ? 2
            : percentageNew < 3 / 3
              ? 3
              : 4;

    if (statusCopy !== theoreticalStatus) {
      previewAssignmentCopy.status = theoreticalStatus;
      setPreviewAssignment(previewAssignmentCopy);
      setStatus(theoreticalStatus);
    }
  };

  return (
    <>
      {/* Aufwand */}
      <AufwandBoxComponent
        classView={classView}
        restricted={restricted}
        my_minutes={my_minutes}
        suggested_minutes={suggested_minutes}
        type={type}
        workloadErr={workloadErr}
        personalView={personalView}
        change={change}
      />
    </>
  );
}

export default memo(AufwandBox);
