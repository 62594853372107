import React, {memo } from "react";
import MainContentGrid from "../../../_4GeneralHelpers/1_Boxes/MainContentGrid";
import { Item } from "./Item";
import { Grid } from "@mui/material";
import { Workboard } from "../DashboardParts/Workboard/Workboard";
import "./DashboardContentMobile.css";
import DashboardCarousel from "./DashboardCarousel";
import DashboardCarousel2 from "./DashboardCarousel2";


function DashboardContentMobile() {

  return (
    <MainContentGrid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item
            elevation={5}
            sx={{ padding: { xs: 1, ss: 2, lg: 3 } }}
          >
            <DashboardCarousel type={"dashboard"} />
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item
            elevation={5}
            sx={{ padding: { xs: 1, ss: 2, lg: 3 } }}
          >
            <DashboardCarousel2 />
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Item
            elevation={5}
            sx={{ padding: { xs: 1, ss: 2, lg: 3 } }}
          >
            <Workboard />
          </Item>
        </Grid>
      </Grid>
    </MainContentGrid >
  );
}

export default memo(DashboardContentMobile);
