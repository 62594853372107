import React, { memo } from "react";
import { useTheme } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";

function AssignmentDeleteButton({ loading, saveColor, deleteFunction, type }) {
  const theme = useTheme();

  return (
    <LoadingButton
      disabled={loading}
      color={saveColor}
      // fullWidth
      style={{
        backgroundColor: theme.palette.levels.red,
        paddingLeft: "-1em",
      }}
      type="submit"
      variant="contained"
      className="button-block"
      onClick={deleteFunction}
      //this is for the disabled style:
      sx={{
        "&.Mui-disabled": {
          background: theme.palette.primary.main,
          color: "#c0c0c0",
        },
      }}
    >
      {loading && (
        <div
          style={{
            visibility: loading ? "visible" : "visible",
            marginLeft: "-0.8em",
            marginRight: "0.8em",
          }}
        >
          <span className="spinner-border spinner-border-sm"></span>
        </div>
      )}
      <div
        style={{
          marginRight: "-0.4em",
          marginLeft: "-0.4em",
        }}
      >
        <span>
          {type === "exam"
            ? "Prüfung "
            : type === "homework"
            ? "Hausaufgabe "
            : type === "personal"? "Personal ":""}
          löschen
        </span>
      </div>
    </LoadingButton>
  );
}

export default memo(AssignmentDeleteButton);
