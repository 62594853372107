import { CheckCircle, CheckCircleOutline, Error, ErrorOutline } from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import { useState } from "react";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import StyledTooltip from "../../_4GeneralHelpers/2_Tooltips/StyledTooltip";

export default function HusibuechCircle({
  type,
  keyValue,
  fill,
  onClick,
  select,
}) {
  const { priorityList, statusList } = useAppContext();
  const theme = useTheme();
  const [isFilled, setIsFilled] = useState(fill);

  const getColor = (keyValue) => {
    switch (keyValue) {
      case 4:
        return theme.palette.levels.green;
      case 3:
        return theme.palette.levels.lightGreen;
      case 2:
        return theme.palette.levels.yellow;
      case 1:
        return theme.palette.levels.orange;
      default:
        return theme.palette.levels.red;
    }
  };

  const changeStatus = (event) => {
    // console.log("event.currentTarget:", event.currentTarget);
    event.stopPropagation(); // Prevent the event from propagating to the parent elements
    setIsFilled(!isFilled);
    onClick(keyValue);
  };

  return (
    <StyledTooltip
      title={type === "status" ? statusList[keyValue] : priorityList[keyValue]}
      placement="bottom-start"
      arrow
    >
      {type === "status" && !isFilled ? (
        <CheckCircleOutline
          sx={{ height: 22, width: 22, marginBottom: 0.4, cursor: "pointer" }}
          style={{ color: getColor(keyValue) }}
          onClick={(event) => (select === "status" ? changeStatus(event) : onClick(keyValue, event))}
        />
      ) : type === "status" && isFilled ? (
        <CheckCircle
          sx={{ height: 22, width: 22, marginBottom: 0.4, cursor: "pointer" }}
          style={{ color: getColor(keyValue) }}
          onClick={(event) => (select === "status" ? changeStatus(event) : onClick(keyValue, event))}
        />
      ) : type === "priority" && !isFilled ? (
        <ErrorOutline
          sx={{ height: 22, width: 22, marginBottom: 0.4, cursor: "pointer" }}
          style={{ color: getColor(keyValue) }}
          onClick={(event) => (select === "priority" ? changeStatus(event) : onClick(keyValue, event))}
        />
      ) : type === "priority" && isFilled ? (
        <Error
          sx={{ height: 22, width: 22, marginBottom: 0.4, cursor: "pointer" }}
          style={{ color: getColor(keyValue) }}
          onClick={(event) => (select === "priority" ? changeStatus(event) : onClick(keyValue, event))}
        />
      ) : (
        <></>
      )}
    </StyledTooltip>
  );
}
