import React, { memo } from "react";
import CountingFactorBoxComponent from "./CountingFactorBoxComponent";

function CountingFactorBox({
  restricted,
  previewAssignment,
  countingFactor,
  setCountingFactor,
  changesCount,
  setChangesCount,
  countingFactorErr,
}) {
  // console.log("fact...", countingFactor)
  const change = (e) => {
    if (e.target.value > 0 && e.target.value < 5) {
      let changesCountCopy = changesCount;

      if (parseFloat(e.target.value) === previewAssignment.counting_factor) {
        //we have gone back to default
        // console.log("default");
        // console.log(previewAssignmentcounting_factor);
        setChangesCount(changesCountCopy - 1);
      } else if (
        parseFloat(countingFactor) !== previewAssignment.counting_factor
      ) {
        // do noting
        // console.log(previewAssignmentcounting_factor);
        // console.log(countingFactor);
        // console.log("do noting");
      } else {
        // new change
        // console.log("new change");
        setChangesCount(changesCountCopy + 1);
      }
      if (parseFloat(e.target.value) < 0) {
        setCountingFactor(0);
      } else if (parseFloat(e.target.value) > 5) {
        setCountingFactor(5);
      }
      else {
        setCountingFactor(parseFloat(e.target.value));
      }

    }
  };
  return (
    <>
      {countingFactor && <CountingFactorBoxComponent
        restricted={restricted}
        countingFactor={countingFactor}
        countingFactorErr={countingFactorErr}
        change={change}
      />}
    </>
  );
}

export default memo(CountingFactorBox);
