import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import "./MenutBottomBar.css";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import { BottomNavigation, BottomNavigationAction, Paper, useMediaQuery } from "@mui/material";
import {
  Dashboard,
  // Notifications,
  CalendarViewWeek,
  FactCheck,
  Preview,
  Settings,
  UploadFile
} from "@mui/icons-material";
import { useTheme } from "@mui/styles";

function MenutBottomBar() {
  const {
    isAnAdmin,
    isAuthenticated,
    currentPage,
    setCurrentPage,
    setOpenToDoList,
    openToDoList,
  } = useAppContext();
  const navigate = useNavigate();
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down("xsss"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));


  const toggleSlider = () => {
    setOpenToDoList(!openToDoList);
  };
  const changePage = (event, newValue) => {
    if (newValue !== "iDoList") {
      setCurrentPage(newValue);
      if (newValue === "dashboard") {
        navigate("/");
        // } else if (newValue == "weekview") {
        //   navigate("/wochenplan")
      } else if (newValue === "semesterview") {
        navigate("/semesterplan");
      } else if (newValue === "nachkontrolle") {
        navigate("/nachkontrolle");
      } else if (newValue === "settings") {
        navigate("/einstellungen");
      } else if (newValue === "upload") {
        navigate("/upload")
      }
    } else {
      toggleSlider();
    }
  };

  return (
    <>
      {isAuthenticated && (
        <Paper
          sx={{
            paddingTop: 2,
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: "center",
            overflowX: "auto", // Allow horizontal scrolling
            ...(isMobile && isAnAdmin && { paddingLeft: "6.5em" }), // Apply padding only on smartphones
            ...(isSmallScreen && isAnAdmin && { paddingLeft: "5em" }), // Apply padding only on small screens
          }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            // position="fixed"
            // className="carousel-container" // Add this class
            sx={{
              height: "4em",
              marginTop: "-1em",
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // flexWrap: "nowrap",
              display: {
                sm: "none"
              },
            }}
            value={currentPage}
            onChange={changePage}
          >

            <BottomNavigationAction
              value="dashboard"
              label="Dashboard"
              icon={<Dashboard color={currentPage === "dashboard" && !openToDoList ? "primary" : "primaryLight"} />}
            />
            <BottomNavigationAction
              value="semesterview"
              label="Prüfungsplan"
              icon={<CalendarViewWeek color={currentPage === "semesterview" && !openToDoList ? "primary" : "primaryLight"} />}
            />
            <BottomNavigationAction
              value="iDoList"
              label="iDo Liste"
              icon={<FactCheck color={openToDoList ? "primary" : "primaryLight"} />}
            // sx={{ backgroundColor: "red" }}
            />
            <BottomNavigationAction
              value="nachkontrolle"
              label="Nachkontrolle"
              icon={<Preview color={currentPage === "nachkontrolle" && !openToDoList ? "primary" : "primaryLight"} />}
            />
            {isAnAdmin && (
              <BottomNavigationAction
                value="settings"
                label="Einstellungen"
                icon={<Settings color={currentPage === "settings" && !openToDoList ? "primary" : "primaryLight"} />}
              />
            )}
            {isAnAdmin && (
              <BottomNavigationAction
                value="upload"
                label="Import"
                icon={<UploadFile color={currentPage === "upload" && !openToDoList ? "primary" : "primaryLight"} />}
              />
            )}
          </BottomNavigation>
        </Paper>
      )}
    </>
  );
}
export default memo(MenutBottomBar);
