import { Box, Paper, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import DoghnutBox from "../../../../_4GeneralHelpers/1_Boxes/DoghnutBox";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { calculateMinutes } from "./_DoghnutHelpers/calculateMintues";
import { calculateMinutesDone } from "./_DoghnutHelpers/calculateMintuesDone";
import { DoghnutText } from "./_DoghnutHelpers/DoghnutText";
import BoxHeader from "../../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";
import { PieChart } from '@mui/x-charts/PieChart';
const { arraysAreEqual } = require("../../../../_3HelperFunctions/_AppHelpers/arraysAreEqual");


export const StatusPriorityPie = ({ parameter, flipCard }) => {
  const theme = useTheme();
  const {
    isATeacher,
    statusList,
    priorityList,
    setStopDoughnutChartReRender,
    stopReRenderProcess,
    weekStatusCount,
    setWeekStatusCount,
    weekPriorityCount,
    setWeekPriorityCount,
    setThisWeeksMinutesTotal,
    setThisWeeksMinutesDone,
    setPercentageDone,
    appInDarkMode,
    relevantUserAssignments,
    verifyDoughnutChart,
    setVerifyDoughnutChart,
  } = useAppContext();
  const [calibratingDoghnut, setCalibratingDoghnut] = useState(false);
  const [size, setSize] = useState({
    width: 400,
    height: 200,
  });
  const [data, setData] = useState(
    parameter === "status" ? [
      { value: 0, label: statusList[0] },
      { value: 0, label: statusList[1] },
      { value: 0, label: statusList[2] },
      { value: 0, label: statusList[3] },
      { value: 1, label: statusList[4] }
    ] :
      [
        { value: 0, label: priorityList[0] },
        { value: 0, label: priorityList[1] },
        { value: 0, label: priorityList[2] },
        { value: 0, label: priorityList[3] }
      ]
  )

  /**
   * Locks
   */
  useEffect(() => {
    // console.log("general: ", setStopDoughnutChartReRender, "verify: ", verifyDoughnutChart, "calibrate: ", calibratingDoghnut)
    if (verifyDoughnutChart !== undefined && !verifyDoughnutChart && !calibratingDoghnut) {
      // console.log("freeze process is over");
      setStopDoughnutChartReRender(false);
    }
  }, [verifyDoughnutChart, calibratingDoghnut]);

  //unlock calibration
  useEffect(() => {
    const unlock = async () => {
      // console.log("We are allowed to calculate the doghnut");
      await setCalibratingDoghnut(true);
    };
    if (verifyDoughnutChart) {
      unlock();
    }
  }, [verifyDoughnutChart]);

  useEffect(() => {
    // console.log(calibratingDoghnut, relevantUserAssignments)
    if (calibratingDoghnut && relevantUserAssignments) {
      // console.log("we go here");
      calibrateDoghnutData(); //sets calibratingWorkload to false once process is finished
    }
  }, [calibratingDoghnut, relevantUserAssignments]);

  const calibrateDoghnutData = async () => {
    await setVerifyDoughnutChart(false); // Change the locks
    // console.log(relevantUserAssignments)

    let relevantWeekAssignmentCopy = relevantUserAssignments;
    //put the pipes together
    let allRelevantAssignments = relevantWeekAssignmentCopy.thisWeek.concat(
      relevantUserAssignments.additional.daysToPrepare,
      relevantUserAssignments.additional.extraDates
    );
    //remove possible undefined values
    allRelevantAssignments = allRelevantAssignments.filter(function (assignment) {
      return assignment !== undefined;
    });

    // console.log("allrel", allRelevantAssignments)
    const minutesTotal =
      Math.floor(allRelevantAssignments.length > 0
        ? await calculateMinutes(allRelevantAssignments)
        : 0)
    const minutesDone =
      Math.floor(allRelevantAssignments.length > 0
        ? await calculateMinutesDone(allRelevantAssignments)
        : 0)

    const percentageDone = minutesTotal > 0 ? Math.floor((minutesDone / minutesTotal) * 100) : 0
    // console.log("percentage", percentageDone, "total: ", minutesTotal, "done: , ", minutesDone)
    if (parameter === "status") {
      // console.log(minutesTotal, minutesDone, percentageDone)
      await setThisWeeksMinutesTotal(minutesTotal);
      await setThisWeeksMinutesDone(minutesDone);
      await setPercentageDone(percentageDone);
    }

    //set counters depending on parameter that is passed to the component
    if (parameter === "status") {
      // calculate color pattern
      if (allRelevantAssignments.length !== 0) {
        const currentCount = [weekStatusCount];
        var newCount = [0, 0, 0, 0, 0];

        allRelevantAssignments.forEach((assignment) => {
          let i = assignment.status;
          newCount[i] = newCount[i] + assignment.my_minutes_done;
          if (assignment.status !== 0) {
            //add the "undone minutes to status 0"
            newCount[0] = newCount[0] + assignment.my_minutes - assignment.my_minutes_done
          }
          else { // status === 0
            newCount[0] = newCount[0] + assignment.my_minutes
          }
        });
        // console.log("counter, ", newCount)
        if (
          !arraysAreEqual(newCount, [0, 0, 0, 0, 0]) &&
          !arraysAreEqual(newCount, currentCount)
        ) {
          setWeekStatusCount(newCount);
        }
        else {
          //catch empty cases for minutes
          setWeekStatusCount([1, 0, 0, 0, 0]);
        }
      } else {
        //there are no assignments
        setWeekStatusCount([0, 0, 0, 0, 1]);
      }
    }

    if (parameter === "priority") {
      //calculate priority colors
      if (allRelevantAssignments.length !== 0) {
        const currentCount = [weekPriorityCount];
        var newCount = [0, 0, 0, 0];

        allRelevantAssignments.forEach((assignment) => {
          let i = assignment.priority;
          newCount[i] = newCount[i] + 1;
        });

        if (
          !arraysAreEqual(newCount, [0, 0, 0, 0, 0]) &&
          !arraysAreEqual(newCount, currentCount)
        ) {
          setWeekPriorityCount(newCount);
        }
      } else {
        //there are no assignments
        setWeekPriorityCount([1, 0, 0, 0]);
      }
    }

    await setCalibratingDoghnut(false);
  };
  /**
   * set the weekdata for the current week
   * once all the relevant elements have been calculated
   */
  useEffect(() => {
    // [
    //   { value: 5, label: 'A' },
    //   { value: 10, label: 'B' },
    //   { value: 15, label: 'C' },
    //   { value: 20, label: 'D' },
    // ]
    const currentData = !stopReRenderProcess && parameter === "status"
      ? weekStatusCount
      : !stopReRenderProcess && parameter === "priority"
        ? weekPriorityCount
        : parameter === "status"
          ? [0, 0, 0, 0, 1]
          : [0, 0, 0, 1]

    const currentLabels = parameter === "status" ? statusList : priorityList

    let result = []


    for (let i = 0; i < currentLabels.length; i++) {
      result.push({
        value: currentData[i],
        label: currentLabels[i],
      });
    }
    setData(
      result
    )
  }, [stopReRenderProcess]);

  //this is for the layout of the doghnut. Pass the values on to the child components
  const containerRef = useRef(null);

  useEffect(() => {
    const adjustFontSize = async () => {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight; // Use clientHeight
      await setSize({
        width: containerHeight >= containerWidth ? containerWidth : containerHeight,
        height: containerHeight >= containerWidth ? containerWidth : containerHeight,
      })
      // console.log(containerHeight);
    };

    // Call the adjustFontSize function initially and whenever the window is resized
    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", adjustFontSize);
    };
  }, []);

  // console.log(dataCurrentWeek)
  const CustomPieToolTip = (props) => {
    const { itemData, series } = props;
    // console.log(itemData, series);
    return (
      <Paper sx={{ padding: 1, marginTop: "-0.5em", marginLeft: "0.75em", marginRight: "1em" }}>
        {parameter === "status" ?
          <div style={{ color: theme.palette.background.white, marginBottom: "0.4em" }}>Status diese Woche:</div> :
          <div style={{ color: theme.palette.background.white, marginBottom: "0.4em" }}>Prioritäten diese Woche:</div>
        }
        <div style={{ display: 'flex' }}>
          <div style={{ color: series.data[itemData.dataIndex].color }}>
            {/* Checkbox with background color */}
            <span
              style={{
                display: 'inline-block',
                width: '0.7em',  // Adjust the width as needed
                height: '0.7em', // Adjust the height as needed
                backgroundColor: series.data[itemData.dataIndex].color,
                marginBottom: "0.1em",
                marginRight: '0.3em', // Adjust the spacing between checkbox and text
                border: '0.075em solid white', // Add a white border
                borderRadius: '1px', // Optional: add border radius for a rounded appearance
              }}
            ></span>
            {`${series.data[itemData.dataIndex].label}:`}&nbsp;</div>
          {parameter === "status" ?
            <div style={{ color: series.data[itemData.dataIndex].color }}>{series.data[itemData.dataIndex].value}&nbsp;min</div> :
            <div style={{ color: series.data[itemData.dataIndex].color }}>{series.data[itemData.dataIndex].value}&nbsp;{series.data[itemData.dataIndex].value === 1 ? "Assignment" : "Assignments"}</div>
          }
        </div>
      </Paper>
    );
  };

  return (
    <>
      <BoxHeader
        title={
          parameter === "status"
            ? `Status`
            : parameter === "priority"
              ? "Prioritäten"
              : ""
        }
        flipCard={flipCard}
        options={
          // isATeacher
          //   ? ["courseView", "flip"]
          //   : 
          ["flip"]
        }
      />
      {/* parent box */}
      <DoghnutBox>
        <Box
          ref={containerRef}
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            // backgroundColor: "red",
          }}
        >

          {/* Text component */}
          {!calibratingDoghnut &&
            <>
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  zIndex: 1,
                  backgroundColor: "transparent",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!calibratingDoghnut && <DoghnutText width={size.width * 0.8} height={size.height * 0.8} />}
              </div>

              {/* Chart component */}
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  zIndex: 2,
                  backgroundColor: "transparent",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PieChart
                  margin={{ top: -10, right: 15, left: 15 }}
                  tooltip={{ trigger: "item", itemContent: CustomPieToolTip }}
                  colors={parameter === "status"
                    ? [
                      theme.palette.levels.red,
                      theme.palette.levels.orange,
                      theme.palette.levels.yellow,
                      theme.palette.levels.lightGreen,
                      theme.palette.levels.green,
                    ]
                    : [
                      theme.palette.levels.green,
                      theme.palette.levels.lightGreen,
                      theme.palette.levels.orange,
                      theme.palette.levels.red,
                    ]}
                  series={[{
                    data,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { opacity: 0.5 },
                    highlighted: { innerRadius: size.height * 0.37 + 7, additionalRadius: +7 },
                    innerRadius: size.height * 0.37,
                    valueFormatter: (element) =>
                      `${element.value}min`,
                  }]}
                  slotProps={{
                    legend: {
                      hidden: true,
                      direction: 'row',
                      position: { vertical: 'bottom', horizontal: 'left' },
                      padding: 0,
                    },
                  }}
                  {...size}
                />
              </div>
            </>
          }
        </Box>
      </DoghnutBox>
    </>
  );
};
