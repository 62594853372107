import React, { memo, useEffect, useState } from "react";

import {
  Button,
  TextField,
  Grid,
  Typography,
  LinearProgress,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import appService from "../../../_6Services/app.service";
import { useTheme } from "@mui/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RegisterTeacherAdmin() {
  const { husibuechRoles, husibuechCourses, husibuechUsers, setHusibuechUsers, husibuechCoreCourses } =
    useAppContext();
  const theme = useTheme();

  const [firstName, setFirstName] = useState("");
  const [userFirstNameErr, setUserFirstNameErr] = useState({});

  const [lastName, setLastName] = useState("");
  const [userLastNameErr, setUserLastNameErr] = useState({});

  const [abbreviation, setAbbreviation] = useState("");
  const [userAbbreviationErr, setUserAbbreviationErr] = useState({});

  const [userName, setUserName] = useState("");
  const [userNameErr, setUserNameErr] = useState({});

  const [userPassword, setUserPassword] = useState("");
  const [userPasswordErr, setUserPasswordErr] = useState("");

  const [userPasswordConfirmation, setUserPasswordConfirmation] = useState("");
  const [userPasswordConfirmationErr, setUserPasswordConfirmationErr] =
    useState({});

  const [klasse, setKlasse] = useState(undefined);
  const [userKlasseErr] = useState({});

  const [courses, setCourses] = useState([]);
  const [userCoursesErr, setUserCoursesErr] = useState({});

  const [roles, setRoles] = useState([]);

  const [localLoading, setLocalLoading] = useState(false);
  const [showVerwerfen, setShowVerwerfen] = useState(false);
  const [success, setSuccess] = useState(false);

  const [message, setMessage] = useState("");

  const [husibuechCoreCoursesContains, setHusibuechCoreCoursesContains] =
    useState(false);

  const [husibuechRolesContains, setHusibuechRolesContains] = useState(false);

  // useEffect(() => {
  //   console.log("We noticed a change")
  //   console.log(husibuechCourses)
  // }, [husibuechCourses]);

  useEffect(() => {
    if (husibuechCoreCourses && husibuechCoreCourses.length > 0) {
      setHusibuechCoreCoursesContains(true);
    } else {
      setHusibuechCoreCoursesContains(false);
    }
  }, [husibuechCoreCourses]);

  useEffect(() => {
    if (husibuechRoles && husibuechRoles.length >= 1) {
      setHusibuechRolesContains(true);
    } else {
      setHusibuechRolesContains(false);
    }
  }, [husibuechRoles]);

  const handleChangeKlasse = (e) => {
    setShowVerwerfen(true);
    setKlasse(e.target.value);
  };

  const handleChangeCourses = (e) => {
    setShowVerwerfen(true);
    const {
      target: { value },
    } = e;
    setCourses(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.name.split(",") : value
    );
  };

  const handleChangeRoles = (e) => {
    setShowVerwerfen(true);
    const {
      target: { value },
    } = e;
    setRoles(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.name.split(",") : value
    );
  };

  const onChangeUserName = (e) => {
    setShowVerwerfen(true);
    if (e.target.value.length < 40) {
      setUserNameErr({});
    }
    setUserName(e.target.value);
  };
  const onChangePassword = (e) => {
    setShowVerwerfen(true);
    setUserPasswordConfirmationErr({});
    setUserPassword(e.target.value);
  };
  const onChangePasswordConfirmation = (e) => {
    setShowVerwerfen(true);
    setUserPasswordConfirmationErr({});
    setUserPasswordConfirmation(e.target.value);
  };

  const onChangeFirstName = (e) => {
    setShowVerwerfen(true);
    if (e.target.value.length < 30) {
      setUserFirstNameErr({});
    }
    setFirstName(e.target.value);
  };
  const onChangeLastName = (e) => {
    setShowVerwerfen(true);
    if (e.target.value.length < 30) {
      setUserLastNameErr({});
    }
    setLastName(e.target.value);
  };
  const onChangeAbbreviation = (e) => {
    setShowVerwerfen(true);
    if (e.target.value.length < 30) {
      setUserAbbreviationErr({});
    }
    setAbbreviation(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      handleRegister();
    }
  };

  const formValidation = () => {
    let userNameErr = {};
    let userPasswordErr = {};
    let userFirstNameErr = {};
    let userLastNameErr = {};
    let userAbbreviationErr = {};
    let userCoursesErr = {};
    let isValid = true;

    //design the desired rules for form completion here:
    if (userName === "") {
      userNameErr.userNameEmpty = "E-Mail eingegeben!";
      isValid = false;
    }
    if (!userName.includes("@")) {
      userNameErr.userName = "E-mail muss eine Mailadresse sein";
      isValid = false;
    }
    if (userName.length >= 40) {
      userNameErr.userNameTooLong = "E-Mail zu lange!";
      isValid = false;
    }

    if (userPassword === "") {
      userPasswordErr.userPasswordEmpty = "Passwort eingegeben!";
      isValid = false;
    }

    if (userPassword !== userPasswordConfirmation) {
      userPasswordConfirmationErr.userPasswordNotConfirmed =
        "Passwörter nicht identisch!";
      isValid = false;
    }
    if (firstName === "") {
      userFirstNameErr.firstNameEmpty = "Vornamen eingegeben!";
      isValid = false;
    }
    if (firstName.length >= 30) {
      userFirstNameErr.firstNameTooLong = "Vorname zu lange!";
      isValid = false;
    }

    if (lastName === "") {
      userLastNameErr.lastNameEmpty = "Nachnamen eingegeben!";
      isValid = false;
    }
    if (lastName.length >= 30) {
      userLastNameErr.lastNameTooLong = "Nachname zu lange!";
      isValid = false;
    }
    if (abbreviation.length >= 30) {
      userAbbreviationErr.abbreviationTooLong = "UserName zu lange!";
      isValid = false;
    }
    //TODO:
    // if (courses.length <= 0) {
    //   userCoursesErr.userCoursesEmpty = "Kurse auswählen!";
    //   isValid = false;
    // }
    setUserNameErr(userNameErr);
    setUserPasswordErr(userPasswordErr);
    setUserPasswordConfirmationErr(userPasswordConfirmationErr);
    setUserFirstNameErr(userFirstNameErr);
    setUserLastNameErr(userLastNameErr);
    setUserAbbreviationErr(userAbbreviationErr);
    setUserCoursesErr(userCoursesErr);

    return isValid;
  };

  const handleRegister = async () => {
    setMessage("");
    setLocalLoading(true);

    const newUser = {
      email: userName,
      password: userPassword,
      firstName: firstName,
      lastName: lastName,
      abbreviation: abbreviation.length > 0 ? abbreviation : null,
      coreCourse: klasse?.id ? klasse : null,
      courses: courses.length > 0 ? courses : null,
      roles: roles.length > 0 ? roles : null,
    };

    try {
      const response = await appService
        .registerUserThroughAdmin(newUser)
      let users = [...husibuechUsers]
      users.push(response.data)
      setHusibuechUsers(users)
      setSuccess(true)
      setMessage("Update erfolgreich")
      setLocalLoading(false)
      setTimeout(() => {
        setUserName("")
        setFirstName("")
        setLastName("")
        setAbbreviation("")
        setUserPassword("")
        setUserPasswordConfirmation("")
        setKlasse(undefined)
        setCourses([])
        setRoles([])
        setTimeout(() => {
          setSuccess(false);
          setMessage("");
          // ??? setErrorsBack
          setShowVerwerfen(false);
        }, 2500)
      }, 1000)
    } catch (error) {
      setLocalLoading(false);
      setMessage(
        error.response.data.message
          ? error.response.data.message
          : "ungültige Angaben"
      );
      setTimeout(() => {
        setMessage("");
        // ??? setErrorsBack
        setShowVerwerfen(false);
      }, 4000);
    }
  };
  // useEffect(()=>{
  //   console.log("user...", husibuechUsers)
  // }, [husibuechUsers])

  const clearAll = () => {
    setShowVerwerfen(false);
    setFirstName("");
    setLastName("");
    setAbbreviation("");
    setUserName("");
    setUserPassword("");
    setUserPasswordConfirmation("");
    setMessage("");
    setUserNameErr({});
    setUserFirstNameErr({});
    setUserLastNameErr({});
    setUserPasswordErr({});
    setUserPasswordConfirmationErr({});
    setKlasse(undefined);
    setCourses([]);
  };

  return (
    <Card>
      <CardHeader
        title="Neuen User"
        subheader="registrieren"
        subheaderTypographyProps={{ color: theme.palette.levels.white }}
        sx={{
          color: theme.palette.levels.white,
          backgroundColor: theme.palette.levels.blue,
        }}
      />
      <Divider />
      <CardContent>
        {/* Box container */}

        {/* Form item */}
        <form id="login" onSubmit={onSubmit}>
          <Grid
            container
            direction="column"
            spacing={3}
            justifyContent="center"
          >
            <Grid
              container
              direction="row"
              spacing={3}
              paddingLeft={3}
              paddingTop={3}
            // justifyContent="space-between"
            >
              <Grid item>
                <Grid item>
                  <TextField
                    type="email"
                    placeholder="E-Mail *"
                    fullWidth
                    name="username"
                    variant="outlined"
                    required
                    autoFocus={false}
                    value={userName}
                    onChange={onChangeUserName}
                    autoComplete="username"
                  />
                </Grid>
                {Object.keys(userNameErr).map((key) => {
                  return (
                    <Grid item className="alert alert-danger" role="alert">
                      {" "}
                      {userNameErr[key]}
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item>
                <Grid item>
                  <TextField
                    type="password"
                    autoComplete="current-password"
                    placeholder="Passwort *"
                    fullWidth
                    name="password"
                    variant="outlined"
                    required
                    value={userPassword}
                    onChange={onChangePassword}
                  />
                </Grid>
                {Object.keys(userPasswordErr).map((key) => {
                  return (
                    <Grid item className="alert alert-danger" role="alert">
                      {" "}
                      {userPasswordErr[key]}
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item>
                <Grid item>
                  <TextField
                    type="password"
                    autoComplete="current-password"
                    placeholder="Passwort bestätigen *"
                    fullWidth
                    name="password"
                    variant="outlined"
                    required
                    value={userPasswordConfirmation}
                    onChange={onChangePasswordConfirmation}
                  />
                </Grid>
                {Object.keys(userPasswordConfirmationErr).map((key) => {
                  return (
                    <Grid item className="alert alert-danger" role="alert">
                      {" "}
                      {userPasswordConfirmationErr[key]}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              spacing={3}
              paddingLeft={3}
              paddingTop={3}
            // justifyContent="space-between"
            >
              <Grid item>
                <Grid item>
                  <TextField
                    placeholder="Vorname *"
                    fullWidth
                    name="firstName"
                    variant="outlined"
                    required
                    autoFocus={false}
                    value={firstName}
                    onChange={onChangeFirstName}
                  />
                </Grid>
                {Object.keys(userFirstNameErr).map((key) => {
                  return (
                    <Grid item className="alert alert-danger" role="alert">
                      {" "}
                      {userFirstNameErr[key]}
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item>
                <Grid item>
                  <TextField
                    placeholder="Nachname *"
                    fullWidth
                    name="lastName"
                    variant="outlined"
                    required
                    autoFocus={false}
                    value={lastName}
                    onChange={onChangeLastName}
                  />
                </Grid>
                {Object.keys(userLastNameErr).map((key) => {
                  return (
                    <Grid item className="alert alert-danger" role="alert">
                      {" "}
                      {userLastNameErr[key]}
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item>
                <Grid item>
                  <TextField
                    placeholder="Kürzel (für LP)"
                    fullWidth
                    name="abbreviation"
                    variant="outlined"
                    autoFocus={false}
                    value={abbreviation}
                    onChange={onChangeAbbreviation}
                  />
                </Grid>
                {Object.keys(userAbbreviationErr).map((key) => {
                  return (
                    <Grid item className="alert alert-danger" role="alert">
                      {" "}
                      {userAbbreviationErr[key]}
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              spacing={3}
              paddingLeft={3}
              paddingTop={3}
            // justifyContent="space-between"
            >
              <Grid item>
                <InputLabel id="demo-multiple-name-label">
                  Klasse/Klassenlehrer?
                </InputLabel>
                <Select
                  displayEmpty
                  onChange={handleChangeKlasse}
                  input={<OutlinedInput />}
                  value={klasse ? klasse : ``}
                  renderValue={(selected) => {
                    return !selected ? <em>Wähle aus</em> : selected.name;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>wähle aus</em>
                  </MenuItem>
                  {husibuechCoreCourses &&
                    husibuechCoreCoursesContains &&
                    husibuechCoreCourses.map((klasse) => (
                      <MenuItem key={klasse.id} value={klasse}>
                        {klasse.name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              {Object.keys(userKlasseErr).map((key) => {
                return (
                  <Grid item className="alert alert-danger" role="alert">
                    {" "}
                    {userKlasseErr[key]}
                  </Grid>
                );
              })}
              <Grid item>
                <InputLabel id="demo-multiple-name-label">
                  Mischklassen / Kurse einer neuen LP!
                </InputLabel>
                <Select
                  componentsProps={{
                    listbox: {
                      sx: { backgroundColor: "#000" },
                    },
                  }}
                  displayEmpty
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={courses}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Bitte sorgfältig auswählen</em>;
                    }
                    var out = [];
                    selected.forEach((kurs) => out.push(kurs.name));
                    return out.join(", ");
                  }}
                  onChange={handleChangeCourses}
                  input={<OutlinedInput label="Courses" />}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value="">
                    <em>Optionen</em>
                  </MenuItem>
                  {husibuechCourses &&
                    husibuechCourses.length &&
                    husibuechCourses.map((kurs, i) => (
                      <MenuItem key={i} value={kurs}>
                        {kurs.name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              {Object.keys(userCoursesErr).map((key) => {
                return (
                  <Grid item className="alert alert-danger" role="alert">
                    {userCoursesErr[key]}
                  </Grid>
                );
              })}
              <Grid item>
                <InputLabel id="demo-multiple-name-label">
                  Rollen des Users
                </InputLabel>
                <Select
                  componentsProps={{
                    listbox: {
                      sx: { backgroundColor: "#000" },
                    },
                  }}
                  displayEmpty
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple
                  value={roles}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Bitte sorgfältig auswählen</em>;
                    }
                    var out = [];
                    selected.forEach((role) => out.push(role.name));
                    return out.join(", ");
                  }}
                  onChange={handleChangeRoles}
                  input={<OutlinedInput label="Courses" />}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value="">
                    <em>Optionen</em>
                  </MenuItem>
                  {husibuechRoles &&
                    husibuechRolesContains &&
                    husibuechRoles.map((role) => (
                      <MenuItem key={role.id} value={role}>
                        {role.name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{ justifyContent: "space-between" }}
              paddingLeft={3}
              paddingTop={1}
            >
              <Grid item>
                {!localLoading && (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="button-block"
                    disabled={localLoading}
                  >
                    Registrieren
                  </Button>
                )}
                {localLoading && <LinearProgress />}
              </Grid>
              <Grid item>
                {!localLoading && showVerwerfen && (
                  <Button
                    variant="contained"
                    color="error"
                    className="button-block"
                    disabled={localLoading}
                    onClick={clearAll}
                  >
                    verwerfen
                  </Button>
                )}
              </Grid>
            </Grid>
            {message && success && (
              <Grid item>
                <Typography className="alert alert-success" role="alert">
                  {message}
                </Typography>
              </Grid>
            )}
            {message && !success && (
              <Grid item>
                <Typography className="alert alert-danger" role="alert">
                  {message}
                </Typography>
              </Grid>
            )}
          </Grid>
        </form>
      </CardContent>
      <Divider />
    </Card>
  );
}
export default memo(RegisterTeacherAdmin);
