async function sortTasksByDueDateAndPriority(array) {
  var sortedFilteredArray = await array.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    var c = new Date(a.due_date);
    var d = new Date(b.due_date);
    var e = a.priority;
    var f = b.priority;
    var g = a.ranking 
    var h = b.ranking

    //multiple key order:
    return c - d || f - e || g-h || b.assignment_id - a.assignment_id;
  });
  return sortedFilteredArray;
}
exports.sortTasksByDueDateAndPriority = sortTasksByDueDateAndPriority;
