import { memo } from "react";
import { useTheme } from "@mui/styles";
import { Grid } from "@mui/material";
import ProjectLinkBox from "./ProjectLinkBox";
import UserQuestion from "./UserQuestion";
import MyButton from "./MyButton";

function AppStarter({ flipCard, loginPage }) {
  const theme = useTheme();
  const userQuestionText = loginPage
    ? "Noch nicht registriert?": "Bereits registriert?";
  const buttonText = loginPage ? "Sign up!": "Log in!";

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      style={{
        height: "100%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.light,
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
      }}
      sx={{
        alignItems: {
          xs: "center",
          ss: "center",
          sm: "center",
          md: "center",
          lg: "center",
          xl: "center",
        },
      }}
    >
      <UserQuestion text={userQuestionText} />
      <Grid
        style={{
          justifyContent: "start",
          alignItems: "start",
        }}
      >
        <MyButton job={flipCard} text={buttonText} />
      </Grid>

      <ProjectLinkBox />
    </Grid>
  );
}

export default memo(AppStarter);
