const loginFormValidation = (email, userPassword) => {
  const errors = {
    email: [],
    userPassword: [],
  };

  // Design the desired rules for form completion here:
  if (email.trim() === "") {
    errors.email.push("Username eingeben!");
  } else if (!email.includes("@")) {
    errors.email.push("Username muss eine Mailadresse sein");
  }

  if (userPassword.trim() === "") {
    errors.userPassword.push("Passwort eingeben!");
  }

  const isValid = Object.values(errors).every(
    (errorList) => errorList.length === 0
  );
  return {
    isValid,
    errors
  };
};

export default loginFormValidation;
