import { memo, useEffect, useState } from "react";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import appService from "../../../_6Services/app.service";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RegisterExamType() {
  const { husibuechExamTypes, setHusibuechExamTypes } = useAppContext();
  const [husibuechExamTypesContains, setHusibuechExamTypesContains] =
    useState(false);

  const [change, setChange] = useState(false);
  const [success, setSuccess] = useState(false);

  const [localLoading, setLocalLoading] = useState(false);

  const [examTypeName, setExamTypeName] = useState("");
  const [examTypeNameErr, setExamTypeNameErr] = useState({});

  const [suggested_minutes, setWorkloadMinutesTotalSugg] = useState(60);
  const [workloadMinutesTotalSuggErr, setWorkloadMinutesTotalSuggErr] =
    useState({});

  const [suggested_days, setDaysToPrepareSugg] = useState(7);
  const [daysToPrepareSuggErr, setDaysToPrepareSuggErr] = useState({});

  const [message, setMessage] = useState("");

  const theme = useTheme();

  useEffect(() => {
    if (husibuechExamTypes && husibuechExamTypes.length >= 1) {
      setHusibuechExamTypesContains(true);
    } else {
      setHusibuechExamTypesContains(false);
    }
  }, [husibuechExamTypes]);

  const onChangeExamTypeName = (e) => {
    setExamTypeNameErr({});
    setExamTypeName(e.target.value);
    setChange(e.target.value.length ? true : false);
  };

  const onChangeWorkloadMinutesTotalSugg = (e) => {
    setWorkloadMinutesTotalSuggErr({});
    if (!isNaN(e.target.value)) {
      setWorkloadMinutesTotalSugg(Number(e.target.value));
    }
  };

  const onChangeDaysToPrepareSugg = (e) => {
    setDaysToPrepareSuggErr({});
    if (!isNaN(e.target.value)) {
      setDaysToPrepareSugg(Number(e.target.value));
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      registerExamType();
    }
  };

  const formValidation = () => {
    let workloadMinutesTotalSuggErr = {};
    let daysToPrepareSuggErr = {};
    let examTypeNameErr = {};
    let isValid = true;

    //design the desired rules for form completion here:

    if (
      husibuechExamTypes &&
      husibuechExamTypes.find((examType) => examType.name === examTypeName)
    ) {
      examTypeNameErr.notUnique = "Prüfungstypname bereits verwendet!";
      isValid = false;
    }
    //allow for 1000 minutes
    if (suggested_days > 28) {
      daysToPrepareSuggErr.timeFrameTooBig =
        "Bitte Zahl bis 28 Tage eingeben oder Teilassignments erfassen!";
      isValid = false;
    }

    //allow for 1000 minutes
    if (suggested_minutes >= 1000) {
      workloadMinutesTotalSuggErr.workloadTooBig =
        "Bitte Zahl < 1000 eingeben oder Teilassignments erfassen!";
      isValid = false;
    }
    setExamTypeNameErr(examTypeNameErr);
    setWorkloadMinutesTotalSuggErr(workloadMinutesTotalSuggErr);
    setDaysToPrepareSuggErr(daysToPrepareSuggErr);

    return isValid;
  };
  const updateLocalSystemData = (newExamType) => {
    let updatedSystemData = husibuechExamTypes;

    updatedSystemData = updatedSystemData.concat(newExamType);
    setHusibuechExamTypes(updatedSystemData);
  };
  const registerExamType = async () => {
    setLocalLoading(true);
    const newExamType = {
      name: examTypeName,
      suggested_minutes: suggested_minutes,
      suggested_days: suggested_days,
    };
    try {
      const response = await appService.registerNewExamType(newExamType)

      updateLocalSystemData(response.data)
      setSuccess(true)
      setMessage("Update erfolgreich")
      setLocalLoading(false)
      setTimeout(() => {
        setExamTypeName("")
        setWorkloadMinutesTotalSugg(60)
        setDaysToPrepareSugg(7)
        setSuccess(false);
        setMessage("");
        setExamTypeNameErr({});
        setWorkloadMinutesTotalSuggErr({});
        setDaysToPrepareSuggErr({});
        setChange(false);
      }, 2500)
    } catch (error) {
      console.log(error)
      setWorkloadMinutesTotalSugg(
        !suggested_minutes ? 60 : suggested_minutes
      );
      setDaysToPrepareSugg(
        !suggested_days ? 7 : suggested_days
      );
      setMessage(
        error.response && error.response.data.message
          ? error.response.data.message
          : "ungültige Angaben"
      );
      setLocalLoading(false);

      setTimeout(() => {
        setMessage("");
        setExamTypeNameErr({});
        setWorkloadMinutesTotalSuggErr({});
        setDaysToPrepareSuggErr({});
        setChange(false);
        setLocalLoading(false);
      }, 4000);
    }
  };

  return (
    <Card>
      <CardHeader
        title="Neuen Prüfungstyp"
        subheader="hinzufügen"
        subheaderTypographyProps={{ color: theme.palette.levels.white }}
        sx={{
          color: theme.palette.levels.white,
          backgroundColor: theme.palette.levels.blue,
        }}
      />
      <Divider />
      <CardContent>
        {/* Box container */}
        <Grid
          container
          direction="row"
          spacing={3}
          justifyContent="space-between"
        >
          {/* Form item */}
          <Grid item md={6} xs={12}>
            <form id="registerExamType" onSubmit={onSubmit}>
              {/* Form container including the button */}
              <Grid
                container
                direction="column"
                spacing={3}
                justifyContent="center"
              >
                <Grid item md={6} xs={12}>
                  <InputLabel id="demo-multiple-name-label">
                    Name für neuen Prüfungstyp *
                  </InputLabel>
                  <TextField
                    type="name"
                    placeholder="Name *"
                    fullWidth
                    name="name"
                    variant="outlined"
                    required
                    autoFocus={false}
                    value={examTypeName}
                    onChange={onChangeExamTypeName}
                  />
                </Grid>
                {Object.keys(examTypeNameErr).map((key) => {
                  return (
                    <Grid
                      item
                      marginLeft={3}
                      md={6}
                      xs={12}
                      className="alert alert-danger"
                      role="alert"
                    >
                      {examTypeNameErr[key]}
                    </Grid>
                  );
                })}
                <Grid item md={6} xs={12}>
                  <Grid item md={6} xs={12}>
                    <InputLabel id="demo-multiple-name-label">
                      Zeitaufwand (in Min) *
                    </InputLabel>
                    <TextField
                      id="workloadMinutes"
                      type="number"
                      required
                      size="small"
                      value={suggested_minutes}
                      onChange={onChangeWorkloadMinutesTotalSugg}
                    // helperText="in Minuten"
                    />
                    {Object.keys(workloadMinutesTotalSuggErr).map((key) => {
                      return (
                        <Grid
                          item
                          marginLeft={3}
                          md={12}
                          xs={12}
                          className="alert alert-danger"
                          role="alert"
                        >
                          {workloadMinutesTotalSuggErr[key]}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid item md={6} xs={12}>
                    <InputLabel id="demo-multiple-name-label">
                      Vorbereitungszeit (Tage) *
                    </InputLabel>
                    <TextField
                      id="DaysSugg"
                      type="number"
                      required
                      size="small"
                      value={suggested_days}
                      onChange={onChangeDaysToPrepareSugg}
                    />
                    {Object.keys(daysToPrepareSuggErr).map((key) => {
                      return (
                        <Grid
                          item
                          marginLeft={3}
                          md={12}
                          xs={12}
                          className="alert alert-danger"
                          role="alert"
                        >
                          {daysToPrepareSuggErr[key]}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>

                <Grid item md={6} xs={12}>
                  {!localLoading && change && (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="button-block"
                      disabled={localLoading}
                    >
                      Registrieren
                    </Button>
                  )}
                  {localLoading && <LinearProgress />}
                </Grid>
                {message && success && (
                  <Grid item>
                    <Typography className="alert alert-success" role="alert">
                      {message}
                    </Typography>
                  </Grid>
                )}
                {message && !success && (
                  <Grid item>
                    <Typography className="alert alert-danger" role="alert">
                      {message}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </form>
          </Grid>
          {/* Stystem information item */}
          <Grid item md={6} xs={12}>
            <InputLabel id="demo-multiple-name-label">
              Bekannte Typen
            </InputLabel>
            <Select
              displayEmpty
              onChange={null}
              input={<OutlinedInput />}
              value={``}
              renderValue={(selected) => {
                return <em>bereits im System vorhanden</em>;
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem disabled value="">
                <em>bereits erfasst</em>
              </MenuItem>
              {husibuechExamTypes &&
                husibuechExamTypesContains &&
                husibuechExamTypes.map((examType, i) => (
                  <MenuItem key={i} value={examType}>
                    {examType.name} (Zeit: {examType.suggested_minutes},
                    Tage: {examType.suggested_days})
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  );
}
export default memo(RegisterExamType);
