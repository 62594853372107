// deadlineUtils.js

import { sortTasksByDueDateAndPriority } from "../../../../../_3HelperFunctions/_AssignmentFunctions/sortTasksByDueDateAndPriority";

export const calculateAdditionalAssignments = async (
  relevantUserAssignments,
  setAdditionalAssignments
) => {
  const daysToPrepare = relevantUserAssignments?.additional?.daysToPrepare || [];
  const extraDates = relevantUserAssignments?.additional?.extraDates || [];

  const sortedAssignments = await sortTasksByDueDateAndPriority(
    daysToPrepare.concat(extraDates)
  );
  // console.log("additional: ", sortedAssignments)
  await setAdditionalAssignments(sortedAssignments);
};