import React, { memo } from "react";
import AccountProfileContent from "./AccountProfileContent";
import BoxHeader from "../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";

function AccountProfile() {
  return (
    <>
      <BoxHeader title={`Aktueller User`} />
      {/* AccountProfileContent component for rendering user profile content */}
      <AccountProfileContent />
    </>
  );
}

export default memo(AccountProfile);
