import { Droppable } from "react-beautiful-dnd";
import React from "react";
import styled from "styled-components";
import { memo } from "react";
import { Grid } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useTheme } from "@mui/styles";

const TaskList = styled.div`
  // padding-left: 6px;
  border-radius: 6px;
  transition: background-color 0.2s ease;
  background-color: ${(props) =>
    props.isDraggingOver ? "#38679c" : "#38679c"};
  // #d4d4d4
  display: flex;
  flex-direction: column;
  width: 340px;
  height: 60px;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: hidden; /* Add vertical scrollbar when necessary*/
`;

const DroppableStyles = styled.div`
  padding: 10px;
  // border-radius: 6px;
  background-color: "#d4d4d4";
  // #40649c
  flex-grow: 1;
`;

//the columns of the to do list:
function DraggableTrashContainer({ prefix }) {
  const theme = useTheme();
  // console.log(elements);

  return (
    <DroppableStyles>
      <Droppable droppableId={`${prefix}`}>
        {(provided, snapshot) => (
          <TaskList
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
          >
            <Grid container alignItems="center" direction="column">
              <Grid item>
                {snapshot.isDraggingOver ? (
                  <Delete
                    sx={{
                      height: 55,
                      width: 55,
                      // marginLeft: 0.7,
                      // marginRight: -0.5,
                      marginTop: 0.6,
                      // marginBottom: 0.4,
                    }}
                    style={{
                      color: theme.palette.levels.red,
                    }}
                  />
                ) : (
                  <Delete
                    sx={{
                      height: 30,
                      width: 30,
                      // marginLeft: 0.7,
                      // marginRight: -0.5,
                      marginTop: 1.6,
                      // marginBottom: 0.4,
                    }}
                    style={{
                      color: theme.palette.levels.gray,
                    }}
                  />
                )}
              </Grid>
            </Grid>

            {provided.placeholder}
          </TaskList>
        )}
      </Droppable>
    </DroppableStyles>
  );
}

export default memo(DraggableTrashContainer);
