import { useState } from "react";
import { AddLink } from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import StyledTooltip from "../2_Tooltips/StyledTooltip";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import { saveAssignmentChanges } from "../../_3HelperFunctions/_AssignmentFunctions/saveAssignmentChanges";
import { updateDataSet } from "../../_3HelperFunctions/_LocalUpdates/updateDataSet";
import TransitionsSnackbar from "../7_ToDos/ToDoSideBar/TransitionsSnackbar";

export default function PickUpToDoButton({ assignment }) {
  const theme = useTheme();
  const {
    currentUser,
    isALeader,
    schoolYearData,
    setUnresolvedSchoolYearData } = useAppContext();
  const [open, setOpen] = useState(false);
  

  const addNewToDo = async () => {
    const updateValues = [{ property: "is_a_to_do", value: true }]
    const responseAssignment = await saveAssignmentChanges(assignment, updateValues, "pickUpButton", //origin
      currentUser);
    const updatedSchoolYearData = await updateDataSet(schoolYearData, responseAssignment, isALeader, "pickUpButton");
    setUnresolvedSchoolYearData(updatedSchoolYearData)
    setOpen(true);
  };

  return (
    <>

      {!assignment.is_a_to_do && (
        <StyledTooltip
          title={"In die ToDo Liste aufnehmen"}
          placement="bottom-start"
          arrow
        >
          <AddLink
            onClick={addNewToDo}
            edge="end"
            style={{
              color: theme.palette.levels.gray,
              marginLeft: "0.2rem",
              cursor: "pointer"
            }}
          />
        </StyledTooltip>
      )}
      {assignment.is_a_to_do && (
        <TransitionsSnackbar open={open} setOpen={setOpen} title={"toDo hinzugefügt"} />
      )}
    </>
  );
}
