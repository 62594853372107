import React from "react";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import MiddleBox from "../../../../_4GeneralHelpers/1_Boxes/MiddleBox";
import BoxHeader from "../../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";
import DeadlineAssignmentsList from "../DeadlineBoxes/DeadlineAssignmentsList";
import { createTeacherName } from "../../../../_3HelperFunctions/_NameHelpers/nameHelpers";

const RecentlyAdded = () => {
  const {
    isALeader,
    selectedCourse,
  } = useAppContext();

  return (
    <>
      <BoxHeader
        title={
          isALeader && selectedCourse
            ? `Kürzlich der ${selectedCourse?.name ? createTeacherName(selectedCourse.name) : "Klasse"} hinzugefügt`
            : "Neue Aufgaben"
        }
      />
      <MiddleBox>
        <DeadlineAssignmentsList
          type={"topSix"}
        // assignments={memoizedTopSix}
        />
      </MiddleBox>
    </>
  );
};

export default RecentlyAdded;
