import { memo } from "react";
import NachkontrolleContent from "./_NachKontrolleHelpers/NachkontrolleContent";
import { PageTemplate } from "../0_PageTemplate/PageTemplate";

function Nachkontrolle() {

  return (
    <div className="content" sx={{ clear: "left" }}>
      <PageTemplate type={"nachkontrolle"}>
        <NachkontrolleContent />
      </PageTemplate>
    </div>
  );
}

export default memo(Nachkontrolle);
