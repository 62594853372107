import { schoolyearDataHasChanged } from "../_SchoolYearDataHelper/schoolyearDataHasChanged";

export const shouldUpdateLocalData = async (
  data,
  schoolYearData,
  currentUser
) => {
  if (data === undefined || data === null) {
    //we have no data
    return false;
  } else if (schoolYearData === undefined || schoolYearData === null) {
    //this is the first data we get
    return true;
  } else {
    //let's see if we have data changes excluding the filters
    return await schoolyearDataHasChanged(data, schoolYearData, currentUser)
  }
}

