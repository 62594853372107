import React, { memo } from "react";
import { TableCell } from "@mui/material";
import moment from "moment";
import SemesterWeekPill from "./SemesterWeekPill";
import { useTheme } from "@mui/styles";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import StyledTooltip from "../../../_4GeneralHelpers/2_Tooltips/StyledTooltip";

const SemesterWeekContent = ({ semesterWeek, handleClick }) => {
  const { holidays, appInDarkMode } = useAppContext();
  const theme = useTheme();

  const isDayAHoliday = (index) => {
    const startDate = moment(semesterWeek.monday).add(index, "days");

    return holidays?.some((x) =>
      startDate.isSameOrAfter(moment(x.start_date)) &&
      startDate.isSameOrBefore(moment(x.end_date))
    );
  };

  const renderCell = (day, index) => {
    const isHoliday = isDayAHoliday(index);
    if (isHoliday) {
    }

    if (isHoliday) {
      let holidayEndIndex = index;

      // Find the end index of the consecutive holiday period
      while (
        holidayEndIndex < 6 &&
        isDayAHoliday(holidayEndIndex + 1)
      ) {
        holidayEndIndex++;
      }
      const holiday = holidays.find((x) =>
        moment(semesterWeek.monday).add(index, "days").isSameOrAfter(moment(x.start_date)) &&
        moment(semesterWeek.monday).add(index, "days").isSameOrBefore(moment(x.end_date))
      );

      const colSpan = holidayEndIndex - index + 1;
      mappedDays = mappedDays + colSpan
      // console.log("week: ", semesterWeek.monday, ":we've map internally: ", mappedDays)


      return (
        <TableCell
          key={index}
          colSpan={colSpan}
          align="center"
          style={{ backgroundColor: appInDarkMode?theme.palette.background.default:theme.palette.primary.light }}
        >
          <StyledTooltip
            title={`Von: ${moment(holiday.start_date).format(
              "DD-MM-YYYY"
            )} bis: ${moment(holiday.end_date).format("DD-MM-YYYY")}`}
            arrow
          >
            <div>{holiday.name}</div>
          </StyledTooltip>
        </TableCell>
      );
    } else {
      mappedDays++
      return (
        <TableCell
          key={index}
          style={{ color: theme.palette.text.primary }}
          onClick={(event) =>
            handleClick(
              event,
              moment(semesterWeek.monday).add(index, "days").format("YYYY-MM-DD")
            )
          }
        >
          {semesterWeek[`${day}Assignment`].length !== 0 && (
            semesterWeek[`${day}Assignment`].map((exam, i) => (
              <SemesterWeekPill
                key={i}
                assignment={exam}
                handleClick={handleClick}
                origin={"semesterTable"}
              />
            ))
          )}
        </TableCell>
      );
    }
  };

  const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  let mappedDays = 0;
  return daysOfWeek.map((day, index) => {
    if (mappedDays >= 7) {
      return null;  // Skip remaining days if they are already covered by holidays
    } else {
      return renderCell(daysOfWeek[mappedDays], mappedDays);
    }
  });
};

export default memo(SemesterWeekContent);
