import React, { memo } from "react";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import MenuTopBarTitleUnauthorized from "./MenuTopBarTitleUnauthorized";
import MenuTopBarAuthorized from "./MenuTopBarAuthorized";
import AppBarBox from "../../../_4GeneralHelpers/1_Boxes/AppBarBox";

function MenuTopBar() {
  const { currentUser, isAuthenticated } = useAppContext();

  return (
    <div className="content" sx={{ clear: "left" }}>
      <AppBarBox>
        {isAuthenticated && currentUser ? (
          <MenuTopBarAuthorized />
        ) : (
          <MenuTopBarTitleUnauthorized />
        )}
      </AppBarBox>
    </div>
  );
}
export default memo(MenuTopBar);
