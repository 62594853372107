import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";

export const DoghnutChart = ({ dataCurrentWeek, options, height, width }) => {
  return (
    <div
      style={{
        position: "100%",
        width: `${Math.min(width, height)}px`, // Set the width to the minimum dimension
        height: `${Math.min(width, height)}px`, // Set the height to the minimum dimension
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: "blue",
      }}
    >
      <Doughnut data={dataCurrentWeek} options={options} />
    </div>
  );
};
