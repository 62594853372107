import { memo, useEffect, useState } from "react";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import appService from "../../../_6Services/app.service";
import moment from "moment";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RegisterHoliday() {
  const { husibuechSemesters, husibuechHolidays, sethusibuechHolidays } =
    useAppContext();
  const [husibuechHolidaysContains, setHusibuechHolidaysContains] =
    useState(false);
  const [change, setChange] = useState(false);
  const [success, setSuccess] = useState(false);

  const [localLoading, setLocalLoading] = useState(false);

  const [holidayName, setHolidayName] = useState("");
  const [holidayNameErr] = useState({});
  const [startDate, setStartDate] = useState("");
  const [startDateErr, setStartDateErr] = useState({});
  const [endDate, setEndDate] = useState("");
  const [endDateErr, setEndDateErr] = useState({});
  const [semester, setSemester] = useState(undefined);
  const [semesterErr, setSemesterErr] = useState({});

  const [message, setMessage] = useState("");

  const theme = useTheme();

  useEffect(() => {
    if (husibuechHolidays && husibuechHolidays.length >= 1) {
      setHusibuechHolidaysContains(true);
    } else {
      setHusibuechHolidaysContains(false);
    }
  }, [husibuechHolidays]);

  const onChangeHolidayName = (e) => {
    setHolidayName(e.target.value);
  };

  const onChangeStartDate = (e) => {
    setSemesterErr({});
    setStartDateErr({});
    setStartDate(e.target.value);
    setChange(e.target.value.length ? true : false);

  };

  const onChangeEndDate = (e) => {
    setSemesterErr({});
    setEndDateErr({});
    setEndDate(e.target.value);
    setChange(e.target.value.length ? true : false);
  };

  const onChangeSemester = (e) => {
    setSemesterErr({});
    setSemester(e.target.value);
    setChange(e.target.value.length ? true : false);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      registerHoliday();
    } else {
      // setStartDate("");
      // setEndDate("");
      setTimeout(() => {
        // go back to normal
        setMessage("");
        setChange(false);
      }, 4000);
    }
  };

  const formValidation = () => {
    let startDateErr = {};
    let endDateErr = {};
    let semesterErr = {};

    let isValid = true;

    //design the desired rules for form completion here:

    if (!moment(startDate)) {
      startDateErr.noStartDate =
        "Bitte Anfangsdatum mit Format YYYY-MM-DD eingeben!";
      isValid = false;
    }

    if (!moment(endDate)) {
      endDateErr.noStartDate = "Bitte Enddatum mit Format YYYY-MM-DD eingeben!";
      isValid = false;
    }

    if (!moment(endDate).isAfter(moment(startDate))) {
      endDateErr.dateConflict = "Enddatum liegt for Startdatum!";
      isValid = false;
    }
    if (husibuechSemesters) {
      let possibleSemesters = husibuechSemesters.filter((semester) =>
        moment(semester.start).isBefore(moment(startDate))
      );
      console.log(possibleSemesters);
      if (!possibleSemesters.length) {
        semesterErr.dateConflict = "Startdatum liegt in keinem Semester!";
        isValid = false;
      } else {
        console.log(possibleSemesters[possibleSemesters.length - 1]);
        setSemester(possibleSemesters[possibleSemesters.length - 1]);
      }
    } else {
      semesterErr.notFound = "keine Semester!";
      isValid = false;
    }

    setStartDateErr(startDateErr);
    setEndDateErr(endDateErr);
    setSemesterErr(semesterErr);

    return isValid;
  };
  const updateLocalSystemData = (newHoliday) => {
    let updatedSystemData = husibuechHolidays;
    updatedSystemData = updatedSystemData.concat(newHoliday);
    sethusibuechHolidays(updatedSystemData);
  };
  // console.log("target Semester", husibuechSemesters);

  const registerHoliday = () => {
    setLocalLoading(true);
    let possibleSemesters = husibuechSemesters.filter((semester) =>
      moment(semester.start).isBefore(moment(startDate))
    );
    let targetSemester = possibleSemesters[possibleSemesters.length - 1];
    const newHoliday = {
      name: holidayName,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      semesterId: targetSemester.id,
      semester: {
        name: targetSemester.name,
        start_date: moment(targetSemester.start).format("YYYY-MM-DD"),
        end_date: moment(targetSemester.end).format("YYYY-MM-DD"),
      },
    };
    // console.log(newHoliday)
    appService
      .registerNewHoliday(newHoliday)
      .then(
        (response) =>
          updateLocalSystemData(response.data) +
          setLocalLoading(false) +
          setSuccess(true) +
          setMessage("Update erfolgreich") +
          setHolidayName("") +
          setStartDate("") +
          setEndDate("") +
          setSemester(undefined) +
          setTimeout(() => {
            setSuccess(false);
            setMessage("");
            setStartDateErr({});
            setEndDateErr({});
            setSemesterErr({});
            setChange(false);
          }, 2500)
      )
      .catch((error) => {
        setLocalLoading(false);
        setMessage(
          error.response.data.message
            ? error.response.data.message
            : "ungültige Angaben"
        );
        setTimeout(() => {
          setMessage("");
          setStartDateErr({});
          setEndDateErr({});
          setSemesterErr({});
          setChange(false);
        }, 4000);
      });
  };

  return (
    <Card>
      <CardHeader
        title="Neue Ferien"
        subheader="hinzufügen"
        subheaderTypographyProps={{ color: theme.palette.levels.white }}
        sx={{
          color: theme.palette.levels.white,
          backgroundColor: theme.palette.levels.blue,
        }}
      />
      <Divider />
      <CardContent>
        {/* Box container */}
        <Grid
          container
          direction="row"
          spacing={3}
          justifyContent="space-between"
        >
          {/* Form item */}
          <Grid item md={6} xs={12}>
            <form id="registerHoliday" onSubmit={onSubmit}>
              {/* Form container including the button */}
              <Grid
                container
                direction="column"
                spacing={3}
                justifyContent="center"
              >
                <Grid item md={6} xs={12}>
                  <InputLabel id="holidayName">
                    Name für neue Ferien *
                  </InputLabel>
                  <TextField
                    type="name"
                    placeholder="Name *"
                    fullWidth
                    name="name"
                    variant="outlined"
                    required
                    autoFocus={false}
                    value={holidayName}
                    onChange={onChangeHolidayName}
                  />
                </Grid>
                {Object.keys(holidayNameErr).map((key) => {
                  return (
                    <Grid
                      item
                      md={6}
                      xs={12}
                      className="alert alert-danger"
                      role="alert"
                    >
                      {holidayNameErr[key]}
                    </Grid>
                  );
                })}
                <Grid item md={6} xs={12}>
                  <Grid item md={6} xs={12}>
                    <InputLabel id="holidayStartDate">
                      Anfangsdatum *
                    </InputLabel>
                    <TextField
                      type="startDate"
                      placeholder="YYYY-MM-DD"
                      fullWidth
                      name="startDate"
                      variant="outlined"
                      required
                      autoFocus={false}
                      value={startDate}
                      onChange={onChangeStartDate}
                    />
                  </Grid>
                  {Object.keys(startDateErr).map((key) => {
                    return (
                      <Grid
                        item
                        md={6}
                        xs={12}
                        className="alert alert-danger"
                        role="alert"
                      >
                        {startDateErr[key]}
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid item md={6} xs={12}>
                    <InputLabel id="holidayEndDate">Enddatum *</InputLabel>
                    <TextField
                      type="endDate"
                      placeholder="YYYY-MM-DD"
                      fullWidth
                      name="endDate"
                      variant="outlined"
                      required
                      autoFocus={false}
                      value={endDate}
                      onChange={onChangeEndDate}
                    />
                  </Grid>
                  {Object.keys(endDateErr).map((key) => {
                    return (
                      <Grid
                        item
                        md={6}
                        xs={12}
                        className="alert alert-danger"
                        role="alert"
                      >
                        {endDateErr[key]}
                      </Grid>
                    );
                  })}
                </Grid>
                {semester && (
                  <Grid item md={6} xs={12}>
                    <InputLabel id="selectedSemester">Semester</InputLabel>
                    <Select
                      displayEmpty
                      onChange={onChangeSemester}
                      input={<OutlinedInput />}
                      value={semester ? semester : ``}
                      renderValue={(selected) => {
                        return <>{semester ? semester.name : "zu bestimmen"}</>;
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {husibuechSemesters &&
                        husibuechSemesters.map((semester, i) => (
                          <MenuItem key={i} value={semester}>
                            {semester.name +
                              ": " +
                              moment(semester.start).format("DD.MM.yyyy") +
                              " - " +
                              moment(semester.end).format("DD.MM.yyyy")}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                )}
                {Object.keys(semesterErr).map((key) => {
                  return (
                    <Grid
                      item
                      md={6}
                      xs={12}
                      className="alert alert-danger"
                      role="alert"
                    >
                      {semesterErr[key]}
                    </Grid>
                  );
                })}

                <Grid item md={6} xs={12}>
                  {!localLoading && change && (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="button-block"
                      disabled={localLoading}
                    >
                      Erfassen
                    </Button>
                  )}
                  {localLoading && <LinearProgress />}
                </Grid>
                {message && success && (
                  <Grid item>
                    <Typography className="alert alert-success" role="alert">
                      {message}
                    </Typography>
                  </Grid>
                )}
                {message && !success && (
                  <Grid item>
                    <Typography className="alert alert-danger" role="alert">
                      {message}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </form>
          </Grid>
          {/* Stystem information item */}
          <Grid item md={6} xs={12}>
            <InputLabel id="systemHolidays">Ferien *</InputLabel>
            <Select
              displayEmpty
              onChange={null}
              input={<OutlinedInput />}
              value={``}
              renderValue={(selected) => {
                return <em>bereits im System vorhanden</em>;
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem disabled value="">
                <em>bereits erfasst</em>
              </MenuItem>
              {husibuechHolidays &&
                husibuechHolidaysContains &&
                husibuechHolidays.map((holiday, i) => (
                  <MenuItem key={i} value={holiday}>
                    {holiday.name +
                      ": " +
                      moment(holiday.start_date).format("DD.MM.yyyy") +
                      " - " +
                      moment(holiday.end_date).format("DD.MM.yyyy")}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  );
}
export default memo(RegisterHoliday);
