import React, { memo } from "react";
import SaveButton from "../../../_4GeneralHelpers/2_Buttons/SaveButton";
import ClearButton from "../../../_4GeneralHelpers/2_Buttons/ClearButton";

function ButtonController({ localLoading, disabled, save, clear, saveText, clearText }) {
  return (
    <div style={{ display: "flex" }}>
      <SaveButton
        loading={localLoading}
        saveColor={"success"}
        save={save}
        saveText={saveText} 
        style={{ marginRight: "8px" }}  // Adjust the margin as needed
      />
      <ClearButton disabled={disabled} clear={clear} clearText={clearText}/>
    </div>
  );
}

export default memo(ButtonController);
