import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import CourseActivation from "./CourseActivation";

export default function ActivationForm() {
  const { showActivationForm, setShowActivationForm, isAStudent } = useAppContext();

  const handleClose = () => {
    setShowActivationForm(false);
  };

  return (
    <div>
      <Dialog
        open={showActivationForm}
        onClose={handleClose}
        // maxWidth="xs"
        // fullWidth
        sx={{
          backdropFilter: "blur(5px)",
          //other styles here
        }}
      >
        <DialogTitle>Registrierung Abschliessen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isAStudent?
            "Wähle die Angaben sorgfältig aus. Änderungen kannst du später über die Profilseite machen.":
            "Wählen Sie sorgfältig aus. Kürzel genau eingeben, damit SchulNetz Uploads funktionieren. Änderungen können später über die Profilseite gemacht werden."}
          </DialogContentText>
          <CourseActivation handleClose={handleClose}/>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
}
