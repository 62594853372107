import React, { memo, useEffect } from "react";
import { Card, CardContent } from "@mui/material";
import AssignmentClassViewOpen from "./AssignmentClassViewOpen";
import AssignmentClassViewRestricted from "./AssignmentClassViewRestricted";
import EditAssignmentStudentRestricted from "./EditAssignmentStudentRestricted";
import EditAssignmentStudentOpen from "./EditAssignmentStudentOpen";
import EditAssignmentTeacherOpen from "./EditAssignmentTeacherOpen";

function EditAssignmentPopover({
  assignment,
  handleClose,
  restricted,
  classView,
}) {
  // console.log(restricted, classView);
  useEffect(() => {
    // console.log(assignment);
  }, [assignment]);
  return (
    <Card>
      <CardContent
        sx={{
          // width: "100%",
          maxWidth: {
            xs: "300px",
          },
        }}
      >
        {!restricted && !classView ?
          <EditAssignmentTeacherOpen assignment={assignment} handleClose={handleClose} />
          : !restricted && classView ?
            <AssignmentClassViewOpen assignment={assignment} handleClose={handleClose} classView={true} restricted={false} />
            : restricted && classView ?
              <AssignmentClassViewRestricted assignment={assignment} handleClose={handleClose} classView={true} restricted={true} />
              : assignment.type !== "personal" ?
                <EditAssignmentStudentRestricted
                  assignment={assignment}
                  restricted={restricted}
                  handleClose={handleClose}
                  classView={classView}
                /> : <EditAssignmentStudentOpen
                  assignment={assignment}
                  restricted={false}
                  handleClose={handleClose}
                  classView={false}
                />}
      </CardContent>
    </Card>
  );
}

export default memo(EditAssignmentPopover);
