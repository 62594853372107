import React, { memo } from "react";
import { useTheme } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box } from "@mui/material";

function ClearButton({ disabled, clear, clearText }) {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'inline-block', mx: 1 }}>
        <LoadingButton
          disabled={disabled}
          style={{
            backgroundColor: theme.palette.levels.red,
          }}
          variant="contained"
          onClick={clear}
        >
          <span>{clearText}</span>
        </LoadingButton>
      </Box>
  );
}

export default memo(ClearButton);
