import { memo } from "react";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import HusibuechCircle from "./_HusibuechCircle";

function StatusPopUpView({ changeStatus, assignment }) {
  const { statusList } = useAppContext();

  // console.log(assignment);

  return (
    <div>
      {statusList !== null
        ? statusList.map((status, i) => (
            <HusibuechCircle
              key={i}
              type={"status"}
              keyValue={i}
              fill={assignment.status === i}
              onClick={() => changeStatus(i)}
            />
          ))
        : null}
    </div>
  );
}

export default memo(StatusPopUpView);
