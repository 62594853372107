import React, { memo, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { useTheme } from "@mui/styles";
import dayjs from "dayjs";
import StatusBox from "./CardElements/StatusBox";
import PriorityBox from "./CardElements/PriorityBox";
import AufwandBox from "./CardElements/AufwandBox";
import AufwandDoneBox from "./CardElements/AufwandDoneBox";
import { updateDataSet } from "../../../../_3HelperFunctions/_LocalUpdates/updateDataSet";
import { saveAssignmentChanges } from "../../../../_3HelperFunctions/_AssignmentFunctions/saveAssignmentChanges";
import { userConfirmDialogue } from "../../../../_3HelperFunctions/userConfirmDialogue";
import { deleteAssignmentFromDB } from "../../../../_3HelperFunctions/_AssignmentFunctions/deleteAssignmentFromDB";
import DaysToPrepareBox from "./CardElements/DaysToPrepareBox";
import DescriptionBox from "./CardElements/DescriptionBox";
import NotesBox from "./CardElements/NotesBox";
import EditLineOne from "./_EditAssignmentHelpers/EditLineOne";
import EditLineTwoL from "./_EditAssignmentHelpers/EditLineTwoL";
import EditLineThreeLPers from "./_EditAssignmentHelpers/EditLineThreeLPers";
import EditLineFourExams from "./_EditAssignmentHelpers/EditLineFourExams";
import EditLineFiveL from "./_EditAssignmentHelpers/EditLineFiveL";
import AssignmentEditButtons from "./PopoverContentHelpers/AssignmentEditButtons";


function EditAssignmentTeacherOpen({
  assignment,
  restricted,
  handleClose,
  classView,
}) {
  // console.log("PopUp: ", restricted);
  const {
    schoolYearData,
    setUnresolvedSchoolYearData,
    currentUser,
    isALeader,
    isAnAdmin,
    userCourses,
    husibuechExamTypes,
  } = useAppContext();
  const theme = useTheme();
  const _ = require('lodash');

  const [previewAssignment, setPreviewAssignment] = useState(assignment);
  // console.log("previewAssignment: ", previewAssignment);
  /**
   * Update Elements
   */
  const [type, setType] = useState(previewAssignment.type);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(previewAssignment.status);
  const [priority, setPriority] = useState(previewAssignment.priority);
  const [my_minutes_done, setWorkloadMinutesDone] = useState(
    parseInt(previewAssignment.my_minutes_done)
  );
  const [suggested_minutes, setWorkloadMinutesTotalSugg] = useState(
    parseInt(previewAssignment.suggested_minutes)
  );
  const [my_minutes, setMyMinutes] = useState(
    previewAssignment.my_minutes === -1
      ? previewAssignment.suggested_minutes
      : previewAssignment.my_minutes
  );
  const [suggested_days, setSuggestedDays] = useState(
    parseInt(previewAssignment.suggested_days)
  );
  const [my_days, setMyDays] = useState(parseInt(previewAssignment.my_days));
  const [assignmentName, setAssignmentName] = useState(assignment.name);
  const [dueDate, setDueDate] = useState(
    dayjs(previewAssignment.due_date).format("YYYY-MM-DD")
  );
  const [selectedAssignmentCourse, setSelectedAssignmentCourse] = useState(
    previewAssignment.course_id
      ? userCourses.filter(
        (course) => course.id === previewAssignment.course_id
      )[0]
      : userCourses[0]
  );
  const [countingFactor, setCountingFactor] = useState(
    assignment.counting_factor ? assignment.counting_factor : 0
  );
  const [assignmentExamTypeId, setAssignmentExamTypeId] = useState(
    husibuechExamTypes[0].id
  );
  const [description, setDescription] = useState(
    previewAssignment.description === undefined
      ? ""
      : previewAssignment.description
  );
  const [notes, setNotes] = useState(
    previewAssignment.notes === undefined ? "" : previewAssignment.notes
  );

  /**
   * Processing
   */
  const [changesCount, setChangesCount] = useState(0);
  const [changes, setChanges] = useState(changesCount > 0 ? true : false);
  const [loadingCircle, setLoadingCircle] = useState(false);
  const [saveColor, setSaveColor] = useState("primary");
  /**
   * Errors
   */
  const [countingFactorErr, setCountingFactorErr] = useState({});
  const [workloadErr, setWorkloadErr] = useState({});
  const [availablePrepDaysErr, setAvailablePrepDaysErr] = useState({});

  /**
   * Testing
   */
  useEffect(() => {
    changesCount > 0 ? setChanges(true) : setChanges(false);
    // console.log(type);
  }, [changesCount]);


  /**
   * SystemChanges
   */

  //save process
  const save = async () => {
    let schoolYearDataCopy = _.cloneDeep(schoolYearData);
    // console.log("originalAssignments, ", schoolYearData.classAssignments)
    try {
      setLoadingCircle(true);
      const updateValues = [
        { property: "status", value: status },
        { property: "priority", value: priority },
        { property: "name", value: assignmentName },
        { property: "type", value: type },
        type !== "personal" &&
        { property: "course_id", value: selectedAssignmentCourse.id },
        type === "exam" &&
        { property: "counting_factor", value: countingFactor },
        type === "exam" &&
        { property: "exam_type_id", value: assignmentExamTypeId },
        { property: "due_date", value: dueDate },
        !restricted && !classView &&
        { property: "suggested_minutes", value: suggested_minutes },
        !restricted && !classView &&
        { property: "suggested_days", value: suggested_days },
        { property: "my_minutes", value: my_minutes },
        { property: "my_minutes_done", value: my_minutes_done },
        { property: "my_days", value: my_days },
        { property: "description", value: description },
        { property: "notes", value: notes },
        // Add other property-value pairs as needed
      ]
      // console.log(updateValues)
      //connect to db
      const responseAssignment = await saveAssignmentChanges(
        assignment,
        updateValues,
        "update",
        currentUser
      );
      // console.log("server response: ", responseAssignment.real_edit_date, responseAssignment.real_edit_date_user)

      setSaveColor("success");
      setMessage("Änderungen gespeichert!");
      setLoadingCircle(false);


      //local update
      const updatedSchoolYearData = await updateDataSet(schoolYearDataCopy, responseAssignment, isALeader, "update")
      await setUnresolvedSchoolYearData(updatedSchoolYearData);

      // Set a timeout before resetting default values

      setTimeout(async () => {
        // console.log("we come here")
        setSaveColor("primary");
        setMessage("");
        setChanges(false);
      }, 750);
      setTimeout(async () => {
        handleClose();
      }, 1000);
    } catch (error) {
      console.log(error)
      setSaveColor("warning");
      setMessage(
        error.response?.data.message && isAnAdmin
          ? error.response.data.message
          : "Fehler beim Speichern"
      );
      setLoadingCircle(false);

      setTimeout(() => {
        setSaveColor("primary");
        setMessage("");
      }, 6000);
    }
  };

  const onClickDelete = (func) => {
    // set loading to true during submission process
    setLoadingCircle(true); //user confirmation interaction
    setTimeout(async () => {
      const answer = await userConfirmDialogue(type);
      if (!answer) {
        alert("Assignment wird beibehalten!");
        setLoadingCircle(false);
      } else {
        try { //delete the assignment!
          var responseAssignment = await deleteAssignmentFromDB(
            previewAssignment,
            currentUser
          );
          setSaveColor("success");
          setMessage("Bye bye... " + previewAssignment.name);
          setLoadingCircle(false);
          let schoolYearDataCopy = { ...schoolYearData }
          const updatedSchoolYear = await updateDataSet(
            schoolYearDataCopy,
            responseAssignment,
            isALeader,
            "delete"
          );
          setUnresolvedSchoolYearData(updatedSchoolYear);

          //set a timeout before default is reinstalled
          setTimeout(async () => {
            handleClose()
            setTimeout(async () => {
              // setOpenChangeManaged(true);
              setMessage("");
              setChanges(false);
            }, 300);
          }, 1500);


        } catch (error) {
          setSaveColor("warning");
          setMessage(
            error.response.data.message && isAnAdmin
              ? error.response.data.message
              : "Fehler beim Löschen"
          );
          setLoadingCircle(false);

          setTimeout(() => {
            setSaveColor("primary");
            setMessage("");
          }, 6000);
        }
      }
    }, 300);
  };



  return (
    <Grid
      container
      display="flex"
      justifyContent="flex-start"
      direction="column"
    // sx={{ width: "100%", background: "orange" }}
    >
      {/* 1st Line in Edit */}
      <EditLineOne
        previewAssignment={previewAssignment}
        assignment={assignment}
        changesCount={changesCount}
        setChangesCount={setChangesCount}
        assignmentName={assignmentName}
        setAssignmentName={setAssignmentName}
        type={type}
        dueDate={dueDate}
        setDueDate={setDueDate}
        status={status}
        restricted={restricted}
        classView={classView}
      />

      {/* Line 2 box */}
      <EditLineTwoL
        previewAssignment={previewAssignment}
        assignment={assignment}
        changesCount={changesCount}
        setChangesCount={setChangesCount}
        selectedAssignmentCourse={selectedAssignmentCourse}
        setSelectedAssignmentCourse={setSelectedAssignmentCourse}
        type={type}
        setType={setType}
        restricted={restricted}
      />

      {/* Line 3 for Leaders' personals */}
      {type === "personal" && (
        <EditLineThreeLPers
          previewAssignment={previewAssignment}
          setPreviewAssignment={setPreviewAssignment}
          assignment={assignment}
          changesCount={changesCount}
          setChangesCount={setChangesCount}
          selectedAssignmentCourse={selectedAssignmentCourse}
          setSelectedAssignmentCourse={setSelectedAssignmentCourse}
          type={type}
          status={status}
          setStatus={setStatus}
          priority={priority}
          setPriority={setPriority}
          my_minutes_done={my_minutes_done}
          setWorkloadMinutesDone={setWorkloadMinutesDone}
          my_minutes={my_minutes}
        />
      )}

      {/* Line 3 for Exam information */}
      {type === "exam" && (
        <EditLineFourExams
          previewAssignment={previewAssignment}
          changesCount={changesCount}
          setChangesCount={setChangesCount}
          countingFactor={countingFactor}
          setCountingFactor={setCountingFactor}
          countingFactorErr={countingFactorErr}
          setAssignmentExamTypeId={setAssignmentExamTypeId}
          husibuechExamTypes={husibuechExamTypes}
          assignmentExamTypeId={assignmentExamTypeId}
          restricted={restricted}
        />
      )}

      {/* Workload Container */}

      <EditLineFiveL
        previewAssignment={previewAssignment}
        setPreviewAssignment={setPreviewAssignment}
        assignment={assignment}
        changesCount={changesCount}
        setChangesCount={setChangesCount}
        type={type}
        dueDate={dueDate}
        setDueDate={setDueDate}
        status={status}
        setStatus={setStatus}
        my_minutes_done={my_minutes_done}
        setWorkloadMinutesDone={setWorkloadMinutesDone}
        my_minutes={my_minutes}
        setMyMinutes={setMyMinutes}
        suggested_minutes={suggested_minutes}
        setWorkloadMinutesTotalSugg={setWorkloadMinutesTotalSugg}
        workloadErr={workloadErr}
        setMyDays={setMyDays}
        my_days={my_days}
        setSuggestedDays={setSuggestedDays}
        suggested_days={suggested_days}
        availablePrepDaysErr={availablePrepDaysErr}
        restricted={restricted}
      />


      {/* Asssignment Kommentar etc. */}
      <Grid item xs={12} style={{ marginTop: "0.75em" }}>
        <Grid
          container
          justifyContent="space-between"
          direction="column"
          rowGap={2}
        // sx={{ width: "100%", background: "pink" }}
        >
          {type !== "personal" && (
            <DescriptionBox
              restricted={restricted}
              assignment={assignment}
              previewAssignment={previewAssignment}
              description={description===null?"":description}
              setDescription={setDescription}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
            />
          )}

          {/* "Persönlich" Title container */}
          {type !== "personal" && (
            <Grid item sx={{ marginLeft: "0.8em" }}>
              <Typography
                sx={
                  {
                    // mr: 1,
                  }
                }
                variant="body2"
                color={theme.palette.primary.main}
                fontWeight={"bold"}
              >
                Persönlich:
              </Typography>
            </Grid>
          )}

          {/* PersonalElements Container 1*/}
          <Grid
            container
            justifyContent="flex-start"
            direction="row"

          >
            {/* Left Container */}
            <Grid
              item
              xs={
                restricted ? 12 : !restricted && type === "personal" ? 8 : 6
              }
              sx={{ marginTop: "0.3em" }}
            >
              <NotesBox
                restricted={restricted}
                previewAssignment={previewAssignment}
                notes={notes===null?"":notes}
                setNotes={setNotes}
                changesCount={changesCount}
                setChangesCount={setChangesCount}
              />
            </Grid>

            {/* Right Container: shown in homework and exam if teacher: myStatus and myPriority */}
            {type !== "personal" && (
              <Grid
                item
                container
                xs={6}
                direction="column"
                sx={{
                  marginTop: "0.2em",
                  paddingLeft: "1.4em",
                  // backgroundColor: "red",
                }}
              >
                <Grid item sx={{ paddingLeft: "0.2em" }}>
                  <StatusBox
                    assignment={assignment}
                    previewAssignment={previewAssignment}
                    setPreviewAssignment={setPreviewAssignment}
                    changesCount={changesCount}
                    setChangesCount={setChangesCount}
                    status={status}
                    setStatus={setStatus}
                    my_minutes_done={my_minutes_done}
                    setWorkloadMinutesDone={setWorkloadMinutesDone}
                    my_minutes={my_minutes}
                    personalView={true}
                  />
                </Grid>

                {/* myPriorität */}
                <Grid
                  item
                  sx={{
                    mt: "0.4em",
                    paddingLeft: "0.2em",
                    paddingBottom: "0.5em",
                  }}
                >
                  <PriorityBox
                    assignment={assignment}
                    previewAssignment={previewAssignment}
                    setPreviewAssignment={setPreviewAssignment}
                    changesCount={changesCount}
                    setChangesCount={setChangesCount}
                    priority={priority}
                    setPriority={setPriority}
                    personalView={true}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  sx={{ paddingBottom: "0.5em" }}
                // sx={{ background: "orange" }}
                >
                  {/* Minutes Done */}
                  <AufwandDoneBox
                    restricted={restricted}
                    previewAssignment={previewAssignment}
                    setPreviewAssignment={setPreviewAssignment}
                    changesCount={changesCount}
                    setChangesCount={setChangesCount}
                    my_minutes_done={my_minutes_done}
                    setWorkloadMinutesDone={setWorkloadMinutesDone}
                    my_minutes={my_minutes}
                    setMyMinutes={setMyMinutes}
                    suggested_minutes={suggested_minutes}
                    status={status}
                    setStatus={setStatus}
                    workloadErr={workloadErr}
                    personalView={true}
                  />
                </Grid>
                <Grid item xs={3} sx={{ paddingBottom: "0.5em" }}>
                  <AufwandBox
                    restricted={restricted}
                    previewAssignment={previewAssignment}
                    setPreviewAssignment={setPreviewAssignment}
                    changesCount={changesCount}
                    setChangesCount={setChangesCount}
                    my_minutes={my_minutes}
                    setMyMinutes={setMyMinutes}
                    suggested_minutes={suggested_minutes}
                    setWorkloadMinutesTotalSugg={setWorkloadMinutesTotalSugg}
                    my_minutes_done={my_minutes_done}
                    setWorkloadMinutesDone={setWorkloadMinutesDone}
                    type={type}
                    status={status}
                    setStatus={setStatus}
                    workloadErr={workloadErr}
                    personalView={true}
                  />
                </Grid>
                <Grid item xs={3} sx={{ paddingBottom: "0.5em" }}>
                  <DaysToPrepareBox
                    restricted={restricted}
                    previewAssignment={previewAssignment}
                    changesCount={changesCount}
                    setChangesCount={setChangesCount}
                    my_days={my_days}
                    setMyDays={setMyDays}
                    suggested_days={suggested_days}
                    setSuggestedDays={setSuggestedDays}
                    availablePrepDaysErr={availablePrepDaysErr}
                    workloadErr={workloadErr}
                    type={type}
                    personalView={true}
                  />
                </Grid>
              </Grid>
            )}
            {/* Right Container: shown in personal if teacher: minutes done */}
            {type === "personal" && (
              <Grid
                item
                xs={4}
                sx={{ paddingTop: "0.5em", paddingLeft: "1.4em" }}
              // sx={{ background: "orange" }}
              >
                {/* Minutes Done */}
                <AufwandDoneBox
                  restricted={restricted}
                  previewAssignment={previewAssignment}
                  setPreviewAssignment={setPreviewAssignment}
                  changesCount={changesCount}
                  setChangesCount={setChangesCount}
                  my_minutes_done={my_minutes_done}
                  setWorkloadMinutesDone={setWorkloadMinutesDone}
                  my_minutes={my_minutes}
                  setWrokloadMinutesTotal={setMyMinutes}
                  setMyMinutes={setMyMinutes}
                  suggested_minutes={suggested_minutes}
                  status={status}
                  setStatus={setStatus}
                  workloadErr={workloadErr}
                  personalView={true}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Assignment Buttons */}
      <AssignmentEditButtons previewAssignment={previewAssignment} changes={changes} message={message} saveColor={saveColor} deleteFunction={onClickDelete} saveFunction={save} loadingCircle={loadingCircle} type={type} />
    </Grid>
  );
}

export default memo(EditAssignmentTeacherOpen);
