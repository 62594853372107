import React, { memo, useState } from "react";
import { Grid, Paper } from "@mui/material";
import ReactCardFlip from "react-card-flip";
import SignUpForm from "../SignUpForm/SignUpForm";
import LoginForm from "./LoginForm";

function Login() {
  const [seeLogin, setSeeLogin] = useState(true);
  const [seeRegister, setSeeRegister] = useState(false);

  const changeView = () => {
    setSeeLogin(!seeLogin);
    setSeeRegister(!seeRegister);
  };

  return (
    <Grid
      container
      sx={{
        width: "100%",
        marginTop: 10,
        display: "flex",
        justifyContent: "space-around",
      }}
      direction="row"
      spacing={0}
      alignItems="center"
    >
      <Paper
        variant="elevation"
        elevation={5}
        sx={{ borderRadius: 10, width: "90%", maxWidth: 940 }}
      >
        <Grid item xs={12} ss={12} sm={12} md={12} padding={3}>
          <ReactCardFlip isFlipped={seeLogin} flipDirection="horizontal">
            <SignUpForm flipCard={changeView} />
            <LoginForm flipCard={changeView} />
          </ReactCardFlip>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default memo(Login);
