import { Grid, Typography } from "@mui/material";
import React, { memo } from "react";
import StatusSeverityPill from "../../../../../_4GeneralHelpers/3_SeverityPills/StatusSeverityPill";

function StatusBox({
  assignment,
  previewAssignment,
  setPreviewAssignment,
  changesCount,
  setChangesCount,
  status,
  setStatus,
  my_minutes_done,
  setWorkloadMinutesDone,
  my_minutes,
  personalView,
}) {
  //this only changes the preview, not the assignment database!
  const changeStatus = (i) => {
    let changesCountCopy = changesCount;
    if (parseInt(i) === assignment.status) {
      //we have gone back to default
      setChangesCount(changesCountCopy - 1);
    } else if (assignment.status !== status) {
      // do noting
    } else {
      // new change
      setChangesCount(changesCountCopy + 1);
    }
    let statusCopy = status;
    let workloadMinutesDoneCopy = my_minutes_done;
    let workloadMinutesTotalCopy = my_minutes;
    let previewAssignmentCopy = previewAssignment;
    previewAssignmentCopy.status = i;
    setPreviewAssignment(previewAssignmentCopy);
    setStatus(i);

    //now also adapt the workload minutes done according to the logic
    let newStatus = i;
    //with this status we can define a "limits"
    if (i > statusCopy) {
      //we are going up...
      if (
        workloadMinutesDoneCopy <
        (workloadMinutesTotalCopy * newStatus) / 4
      ) {
        //this is the minimal level for this status
        setWorkloadMinutesDone((workloadMinutesTotalCopy * newStatus) / 4);
      }
    } else {
      //we are going down
      if (
        workloadMinutesDoneCopy < (workloadMinutesTotalCopy * newStatus) / 4 ||
        workloadMinutesDoneCopy >=
          (workloadMinutesTotalCopy * (newStatus + 1)) / 4
      ) {
        //this is the minimal level again
        setWorkloadMinutesDone((workloadMinutesTotalCopy * newStatus) / 4);
      }
    }
  };
  return (
    <>
      {/* Status */}
      <Grid
        container
        direction="row"
        sx={{
          marginTop: !personalView ? "0.3em" : "",
          marginBottom: !personalView ? "0.3em" : "",
        }}
      >
        <Grid item xs={personalView ? 7 : 5} sx={{ marginLeft: "0.5em" }}>
          <Typography color="primary" variant="body2" sx={{ width: "50%" }}>
            {!personalView ? "Status:" : "MyStatus:"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          // sx={{background: "green"}}
        >
          <div
            style={{
              marginTop: "-1px",
              marginLeft: !personalView ? "15px":"3px",
            }}
          >
            <StatusSeverityPill
              assignment={previewAssignment}
              preview={"preview"}
              changeStatus={changeStatus}
              status={status}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default memo(StatusBox);
