import { memo } from "react";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import HusibuechCircle from "./_HusibuechCircle";

function PriorityPopUpView({ changePriority, assignment }) {
  const { priorityList } = useAppContext();

  return (
    <div>
      {priorityList !== null
        ? priorityList.map((priority, i) => (
            <HusibuechCircle
              key={i}
              type={"priority"}
              keyValue={i}
              fill={assignment.priority === i}
              onClick={() => changePriority(i)}
            />
          ))
        : null}
    </div>
  );
}

export default memo(PriorityPopUpView);
