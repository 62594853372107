export function referenceDateIsDuringHoliday(holiday, referenceDate) {
    // Get the start and end dates of the holiday
    const holidayStartDate = new Date(holiday.start_date);
    const holidayEndDate = new Date(holiday.end_date);
  
    // Get the reference date as a Date object
    const currentDate = new Date(referenceDate);
  
    // Check if the reference date is after the start date and before the end date
    return currentDate > holidayStartDate && currentDate < holidayEndDate;
  }