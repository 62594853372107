import React, { memo } from "react";
import { Box } from "@mui/material";

function ContentBox({ children }) {
  return (
    <Box
      marginTop={4}
      sx={{
        height: { md: 275, lg: 275, xl: 275 },
        position: "relative",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "0px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "your-desired-color", // Set your desired color
          borderRadius: "6px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent", // or any background color
        },
      }}
      // style={{ backgroundColor: "red" }}
    >
      {children}
    </Box>
  );
}

export default memo(ContentBox);
