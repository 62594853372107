import React from "react";
import { Box } from "@mui/material";

const DoghnutBox = ({ children }) => (
  <Box
    marginTop={2}
    sx={{
      height: { xs: 300, sm: 335, xl: 332 },
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
    }}
  >
    {children}
  </Box>
);

export default DoghnutBox;
