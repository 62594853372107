import { memo, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { useNavigate } from "react-router-dom";
import loginFormValidation from "./loginFormValidation";
import FeedbackMessage from "./FeedbackMessage";
import LoginButtons from "./LoginButtons";
import LoginFields from "./LoginFields";
import authService from "../../../../_6Services/auth.service";
const {
  combineErrorLists,
} = require("../../../../_3HelperFunctions/_ErrorHandling/combineErrorLists");
const {
  getErrorMessageFromResponse,
} = require("../../../../_3HelperFunctions/_ErrorHandling/getErrorMessageFromResponse");

function LoginCard() {
  const {
    setCurrentUser,
    isAuthenticated,
    setCurrentPage,
    verificationMessage,
    setVerificationMessage,
    verificationUserName,
    setVerificationUserName,
    currentVerificationUser,
    setCurrentVerificationUser,
    verificationUserPassword,
    setVerificationUserPassword,
  } = useAppContext();
  const [email, setEmail] = useState(""); //email
  const [userPassword, setUserPassword] = useState("");
  const [localLoading, setLocalLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [combinedErrors, setCombinedErrors] = useState([]);

  useState({});

  const [msg, setMsg] = useState("");

  const onChangeEmail = (e) => {
    setMsg("");
    if (e.target.value.length < 40) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: [],
      }));
    }
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setMsg("");
    setUserPassword(e.target.value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      userPassword: [],
    }));
  };

  const submit = async () => {
    setLocalLoading(true)
    // e.preventDefault();
    const { isValid, errors } = await loginFormValidation(email, userPassword);

    if (isValid) {
      await handleLogin();
    } else {
      // console.log(isValid, errors);
      setErrors(errors);
      setLocalLoading(false)
    }
  };

  const handleLogin = async () => {
    try {
      const response = await authService.login(email, userPassword);
      // console.log(response.request?.status);
      const receivedError = response.request?.status !== 200;
      if (receivedError) {
        await setMsg(await getErrorMessageFromResponse("login", response));
        setLocalLoading(false)
      } else {
        // Success handling
        // console.log("we trigger this...4")
        await setCurrentUser(authService.getCurrentUser());
      }
    } catch (error) {
      console.error(error);
      setLocalLoading(false)
    }
  };

  let navigate = useNavigate();

  useEffect(() => {
    // console.log(currentUser?currentUser:"no current user");
    if (isAuthenticated) {
      setCurrentPage("dashboard");
      navigate("/");
      setLocalLoading(false)
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (verificationMessage) {
      setLocalLoading(true);
      setMsg(
        "Wir haben eine Verifizierungsemail geschickt an: " +
        verificationUserName
      );
      setTimeout(() => {
        setLocalLoading(false);
        setVerificationMessage(false);
        setVerificationUserName("");
        setMsg("");
      }, 10000);
    }
  }, [verificationMessage]);

  useEffect(() => {
    const verifyUser = async () => {
      try {
        await currentVerificationUser?.reload();

        if (currentVerificationUser?.emailVerified) {
          clearInterval(interval);

          await authService.verifyUser(
            currentVerificationUser.email,
            verificationUserPassword
          );
          // console.log("we trigger this...3")
          setCurrentUser(authService.getCurrentUser());
          setCurrentVerificationUser(undefined);
          setVerificationUserPassword("");
        }
      } catch (error) {
        alert(error.message);
      } finally {
        setLocalLoading(false);
        setCurrentVerificationUser(undefined);
        setVerificationUserPassword("");
      }
    };

    let interval;

    if (currentVerificationUser) {
      interval = setInterval(verifyUser, 1000);
    }

    return () => clearInterval(interval);
  }, [currentVerificationUser, verificationUserPassword]);

  useEffect(() => {
    const fixErrors = async () => {
      const newCombinedErrors = await combineErrorLists(errors);
      await setCombinedErrors(newCombinedErrors);
    };
    if (errors) {
      fixErrors();
    }
  }, [errors]);

  return (
    <Grid
      item
      xs={12}
      ss={8}
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // backgroundColor: "green",
        borderTopRightRadius: "15px",
        borderBottomRightRadius: "15px",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        style={{
          width: "100%",
          // height: "100%",
          // flex: 1,
          display: "flex",
          flexDirection: "center",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor: "orange",
        }}
      >
        <Grid
          item
          style={{
            width: "90%",
            justifyContent: "center",
            display: "flex",
            // backgroundColor: "blue",
          }}
        >
          <h1>Login</h1>
        </Grid>

        <LoginFields
          email={email}
          onChangeEmail={onChangeEmail}
          userPassword={userPassword}
          onChangePassword={onChangePassword}
        />
        <LoginButtons
          localLoading={localLoading}
          submit={submit}
          errors={combinedErrors}
        />
        <FeedbackMessage errors={combinedErrors} msg={msg} />
      </Grid>
    </Grid>
  );
}

export default memo(LoginCard);
