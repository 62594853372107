import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import AssignmentProgress from "./AssignmentProgress";

export const AssignmentStats = () => {
  const { referenceDate, showExams, showHomeworks, relevantUserAssignments, thisWeekMinutesTotal } =
    useAppContext();
  // console.log(thisWeekMinutesTotal)

  const [numOfOpenAssignments, setNumOfOpenAssignments] = useState(0);
  const [numOfFinishedAssignments, setNumOfFinishedAssignments] = useState(0);
  const [oldNumOfOpenAssignments, setOldNumOfOpenAssignments] = useState(0);
  const [leadText, setLeadText] = useState("hallo");
  const [followText, setFollowText] = useState("roman");

  useEffect(() => {
    const calculateTexts = async () => {
      // console.log("we calculate the texts");
      var nounAttribute =
        numOfOpenAssignments !== 1 && showExams
          ? " Prüfungen"
          : numOfOpenAssignments !== 1
          ? " Hausaufgaben"
          : showExams
          ? " Prüfung"
          : " Hausaufgabe";
      var primaryText = `${numOfOpenAssignments === 0 ? "Keine" : "Noch "} ${
        numOfOpenAssignments !== 0 ? numOfOpenAssignments : ""
      } ${nounAttribute}`;

      setLeadText(primaryText);
      // console.log("numoffinished:", numOfFinishedAssignments, "num of open:", numOfOpenAssignments)
      var secondaryText = `${
        numOfFinishedAssignments === 0 && numOfOpenAssignments === 0 && thisWeekMinutesTotal === 0
          ? "Carpe Diem..."
          : numOfFinishedAssignments === 0 && numOfOpenAssignments > 0
          ? "Noch alles vor dir..."
          : numOfFinishedAssignments > 0
          ? `Schon ${numOfFinishedAssignments} ${numOfFinishedAssignments===1?"Assignment":"Assignments"} geschafft!`
          : numOfFinishedAssignments === 0 && numOfOpenAssignments === 0 && thisWeekMinutesTotal > 0
          ? "Nächste Woche bahnt sich etwas an"
          : "Es liegt noch Arbeit vor dir..."
      }`;
      setFollowText(secondaryText);
    };

    calculateTexts();
  }, [
    showExams,
    showHomeworks,
    numOfOpenAssignments,
    numOfFinishedAssignments,
    thisWeekMinutesTotal
  ]);

  useEffect(() => {
    const fetchData = async () => {
      // console.log("we calculate the values");
      try {
        // Prepare assignment list
        const assignments = relevantUserAssignments?.thisWeek 

        const futureAssignments = assignments.filter((assignment) =>
          moment(assignment.due_date).isSameOrAfter(moment(new Date()))
        );

        setNumOfFinishedAssignments(
          assignments.length - futureAssignments.length
        );
        setNumOfOpenAssignments(futureAssignments.length);

        // Set assignment lists based on dates
        const oldAssignments = assignments.filter(
          (assignment) =>
            moment(assignment.due_date).isBetween(
              moment(referenceDate).startOf("isoWeek").add(-7, "days"),
              moment(referenceDate).startOf("isoWeek").add(-1, "days"),
              "days",
              "[]"
            ) // all inclusive
        );
        const oldNumberOfAssignments = oldAssignments.length;

        setOldNumOfOpenAssignments(oldNumberOfAssignments);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (relevantUserAssignments) {
      fetchData();
    }
  }, [relevantUserAssignments, referenceDate, showExams]);

  return (
    <Box
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      display="flex"
      height="100%" // Check if this height is sufficient
      width="100%"
      sx={{
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: "0px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "your-desired-color", // Set your desired color
          borderRadius: "6px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent", // or any background color
        },
      }}
    >
      <Grid
        container
        direction="column"
        spacing={0}
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Grid item>
          <Typography color="primary" variant="h4" fontWeight={"bold"}>
            {leadText}
          </Typography>
        </Grid>
        <Grid item>
          <Typography color="primary" variant="h7">
            {followText}
          </Typography>
        </Grid>
        <Grid item width="100%">
          <Grid
            container
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item width="100%">
              <AssignmentProgress week="thisWeek" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
