import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import DaysToPrepareBox from "../CardElements/DaysToPrepareBox";
import AufwandBox from "../CardElements/AufwandBox";

function LineFiveL({
  previewAssignment,
  setPreviewAssignment,
  changesCount,
  setChangesCount,
  type,
  status,
  setStatus,
  my_minutes,
  setMyMinutes,
  suggested_minutes,
  setWorkloadMinutesTotalSugg,
  my_minutes_done,
  setWorkloadMinutesDone,
  workloadErr,
  setMyDays,
  my_days,
  setSuggestedDays,
  suggested_days,
  availablePrepDaysErr,
  restricted,
}) {
  // console.log(previewAssignment)
  return (
    <Grid item style={{ marginTop: "0.75em" }}>
      <Grid
        container
        // justifyContent="space-between"
        spacing={3}
        direction="row"
        sx={{ marginBottom: "0.75em" }}
        // sx={{ width: "100%", background: "blue" }}
      >

        {/* Aufwand */}
        <Grid
          item
          xs={4}
          // sx={{ background: "orange" }}
        >
          <AufwandBox
            restricted={restricted}
            previewAssignment={previewAssignment}
            setPreviewAssignment={setPreviewAssignment}
            changesCount={changesCount}
            setChangesCount={setChangesCount}
            my_minutes={my_minutes}
            setMyMinutes={setMyMinutes}
            suggested_minutes={suggested_minutes}
            setWorkloadMinutesTotalSugg={setWorkloadMinutesTotalSugg}
            my_minutes_done={my_minutes_done}
            setWorkloadMinutesDone={setWorkloadMinutesDone}
            type={type}
            status={status}
            setStatus={setStatus}
            workloadErr={workloadErr}
            personalView={type === "personal"}
          />
        </Grid>

        {/* Vorbereitung SuS */}
        <Grid
          item
          xs={4}
          // sx={{ background: "yellow" }}
        >
          <DaysToPrepareBox
            restricted={restricted}
            previewAssignment={previewAssignment}
            changesCount={changesCount}
            setChangesCount={setChangesCount}
            my_days={my_days}
            setMyDays={setMyDays}
            suggested_days={suggested_days}
            setSuggestedDays={setSuggestedDays}
            availablePrepDaysErr={availablePrepDaysErr}
            workloadErr={workloadErr}
            type={type}
            personalView={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(LineFiveL);
