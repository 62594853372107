import { MenuItem } from "@mui/material";
import React, { memo } from "react";
import { CustomTextField } from "../../../../../_3HelperFunctions/CustomTextField";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";
import { useTheme } from "@mui/styles";

function ExamTypeBoxComponent({ restricted, examTypeId, change }) {
  const { husibuechExamTypes, appInDarkMode } = useAppContext();
  const theme = useTheme()
  return (
    <>
      <CustomTextField
        // labelId="type-lable"
        id="type"
        select
        disabled={restricted}
        onChange={change}
        value={examTypeId}
        label="Prüfungstyp"
        variant="outlined"
        InputProps={{ style: { fontSize: "0.9em" } }}
        appindarkmode={appInDarkMode.toString()}
        theme={theme}
      >
        {husibuechExamTypes.map((option, i) => (
          <MenuItem key={i} value={option.id} style={{ fontSize: "0.8rem" }}>
            {option.name}
          </MenuItem>
        ))}
      </CustomTextField>
    </>
  );
}

export default memo(ExamTypeBoxComponent);
