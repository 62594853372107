import { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import authService from "../../../_6Services/auth.service";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import VerificationResponse from "./VerificationResponse";

function EmailVerify() {
  const { setVerificationCodeStore } = useAppContext();
  const [checkingVerification, setcheckingVerificationVerification] = useState(true);
  const [validUrl, setValidUrl] = useState(false);
  const [msg, setMsg] = useState("");
  const param = useParams();

  useEffect(() => {
    if (param.id && param.code) {
      // console.log(param);

      setcheckingVerificationVerification(true);
      authService
        .verifyUser(param.id, param.code)
        .then(
          (response) =>
            // console.log(response === "User ist bereits verifiziert") +
            //returns undefined on errors
            (response ? setValidUrl(true) : setValidUrl(false)) +
            // console.log("we got here") +
            +setVerificationCodeStore("") +
            setcheckingVerificationVerification(false) +
            (response === "User ist bereits verifiziert"
              ? setMsg(response)
              : null)
        )
        .catch((error) => {
          console.log(error);
          setVerificationCodeStore("");
          setcheckingVerificationVerification(false);
        });
    }
  }, []);

  return (
    <VerificationResponse checkingVerification={checkingVerification} validUrl={validUrl} msg={msg} />
  );
}

export default memo(EmailVerify);
