import moment from "moment";

function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}
const now = moment(new Date()).startOf("day");

function getDaysWithTime(minutesLeftDays, workloadMinutesTemp, monday) {
  const now = moment(new Date()).startOf("day");
  let daysWithTime = 0;
  for (let t in minutesLeftDays) {
    let day = moment(monday).add(t, "days");
    if (
      moment(day).isSameOrAfter(now) &&
      minutesLeftDays[t] > 0 &&
      workloadMinutesTemp[t] > 0
    ) {
      daysWithTime++;
    }
  }
  return daysWithTime;
}

function isRelevantForDay(assignment, day, property) {
  //checks if
  // console.log("for assignment" + assignment.name + " we look at this day: " + day)
  // console.log("and check if it is between:" + moment(assignment.due_date)
  // .startOf("day")
  // .subtract(assignment[property], "days").format("YYYY-MM-DD") + " and " + moment(assignment.due_date).endOf("day").format("YYYY-MM-DD"))

  const isRelevant = moment(day).isBetween(
    moment(assignment.due_date)
      .startOf("day")
      .subtract(assignment[property] === -1 ? 0 : assignment[property], "days"),
    moment(assignment.due_date).endOf("day"),
    "days",
    "[]"
  );
  // console.log(isRelevant);
  return isRelevant;
}

function isExtraDateRelevantForDay(assignment, day) {
  //checks if
  // console.log("for assignment" + assignment.name + " we look at this day: " + day)
  return assignment.extra_dates.includes(day);
}

class WorkloadService {
  addNewAssignmentSuggestedMinutesPayload(payload, assignment) {
    payload +=
      assignment.suggested_days === 0
        ? assignment.suggested_minutes
        : round(assignment.suggested_minutes / assignment.suggested_days, 2);
    return payload;
  }

  calculateExtraWeeksBetweenNowAndMonday(referenceDate, extraDates) {
    let extraWeeksBetweenNowAndMonday = 0;
    const now = moment(new Date()).startOf("day");
    const monday = moment(referenceDate).startOf("isoWeek");

    for (let j in extraDates) {
      if (
        moment(extraDates[j])
          .startOf("isoWeek")
          .isSameOrAfter(moment(now).startOf("isoWeek")) &&
        moment(extraDates[j])
          .startOf("isoWeek")
          .isBefore(moment(monday).startOf("isoWeek"))
      ) {
        extraWeeksBetweenNowAndMonday++;
      }
    }

    return extraWeeksBetweenNowAndMonday;
  }
  createDictWithRelevantExtraDateAssignments(referenceDate, data) {
    const monday = moment(referenceDate).startOf("isoWeek");
    // console.log(data)
    // create an empty array with empty arrays
    let relevantExtraDateAssignmentsByDay = [[], [], [], [], [], [], []];
    // create an arry with the relevant days
    const relevantDays = [
      moment(monday).add(0, "days").format("YYYY-MM-DD"),
      moment(monday).add(1, "days").format("YYYY-MM-DD"),
      moment(monday).add(2, "days").format("YYYY-MM-DD"),
      moment(monday).add(3, "days").format("YYYY-MM-DD"),
      moment(monday).add(4, "days").format("YYYY-MM-DD"),
      moment(monday).add(5, "days").format("YYYY-MM-DD"),
      moment(monday).add(6, "days").format("YYYY-MM-DD"),
    ];
    //double for loop to check for each assignment
    //if it is relevant for any of the days
    //set a flag so that the assignment payload is copied from the day it has been found
    var assignmentIsFlagged = false;
    data.forEach(function (assignment) {
      assignmentIsFlagged = false;
      relevantDays.forEach(function (arrayDay, index) {
        //if an assignment is relevant it needs to be pushed into the dict
        if (
          assignmentIsFlagged ||
          isExtraDateRelevantForDay(assignment, relevantDays[index])
        ) {
          relevantExtraDateAssignmentsByDay[index].push(assignment);
          assignmentIsFlagged = true;
          // console.log("say push")
          // console.log(assignment)
        }
      });
    });
    return relevantExtraDateAssignmentsByDay;
  }

  createDictWithRelevantAssignments(
    referenceDate,
    relevantUserAssignments,
    property
  ) {
    const monday = moment(referenceDate).startOf("isoWeek");

    // create an empty array with empty arrays
    let relevantAssignmentsByDay = [[], [], [], [], [], [], []];
    // create an arry with the relevant days
    const relevantDays = [
      moment(monday).add(0, "days").format("YYYY-MM-DD"),
      moment(monday).add(1, "days").format("YYYY-MM-DD"),
      moment(monday).add(2, "days").format("YYYY-MM-DD"),
      moment(monday).add(3, "days").format("YYYY-MM-DD"),
      moment(monday).add(4, "days").format("YYYY-MM-DD"),
      moment(monday).add(5, "days").format("YYYY-MM-DD"),
      moment(monday).add(6, "days").format("YYYY-MM-DD"),
    ];
    // console.log(relevantDays)

    //double for loop to check for each assignment
    //if it is relevant for any of the days
    relevantUserAssignments.forEach(function (assignment) {
      relevantAssignmentsByDay.forEach(function (arrayDay, index) {
        //if an assignment is relevant it needs to be pushed into the dict
        if (isRelevantForDay(assignment, relevantDays[index], property)) {
          relevantAssignmentsByDay[index].push(assignment);
        }
        // else { console.log("we don't push")}
      });
    });

    return relevantAssignmentsByDay;
  }

  calculateExtraDatesString(assignment) {
    let extraDates;
    try {
      extraDates = assignment.extra_dates.split(",");
    } catch {
      extraDates = [];
    }
    return extraDates;
  }

  calculateDaysToPrepare(assignment, extraDaysBetweenNowAndMonday, property) {
    return (
      Math.min(
        assignment[property],
        Math.abs(moment(assignment.due_date).diff(now, "days"))
      ) + extraDaysBetweenNowAndMonday
    );
  }

  calculateExtraDaysBetweenNowAndMonday(
    extraDates,
    lastMonday,
    extraWeeksBetweenNowAndMonday
  ) {
    let extraDaysBetweenNowAndMonday = 7 * extraWeeksBetweenNowAndMonday;
    const now = moment(new Date()).startOf("day");

    for (let j in extraDates) {
      if (
        moment(extraDates[j])
          .startOf("isoWeek")
          .isSameOrAfter(moment(now).startOf("isoWeek")) &&
        moment(extraDates[j]).startOf("isoWeek").isSame(lastMonday)
      ) {
        extraDaysBetweenNowAndMonday -= Math.abs(
          moment(now).diff(lastMonday, "days")
        );
      }
    }
    return extraDaysBetweenNowAndMonday;
  }

  calculateRelevantDaysToPrepare(referenceDate, assignment) {
    const extraDatesString = assignment?.extra_dates;
    if (extraDatesString.length > 0) { //string of dates!!
      // Split the string into an array of date strings
      // const extraDates = extraDatesString.split(',')
      // Convert all date strings to moment objects
      // console.log(assignment.extra_dates)
      // const momentDates = extraDates.map(dateString => moment(dateString, 'YYYY-MM-DD'));

      // Find the index of the earliest date
      // const indexOfEarliest = momentDates.indexOf(moment.min(momentDates));
      // const earliestExtraDate = momentDates[indexOfEarliest];
      //calculate the difference between earliest extraDate and due_date
      // return moment(assignment.due_date).diff(earliestExtraDate, "days") + 1; // include the start => +1
      //calculate the difference between referenceDate and due_date may be more accurate
      return moment(assignment.due_date).diff(moment(referenceDate, 'YYYY-MM-DD'), "days") + 1; // include the start => +1

    } else {
      return 0;
    }
  }

  getExtraDateAssignmentPayload(assignment, daysToPrepare, minutesProperty) {
    let payload = 0;
    if (daysToPrepare <= 0) {
      // console.log("we look at <0");
      payload = round(
        assignment[minutesProperty] * (4 - assignment.status) * (25 / 100),
        2
      );
    } else {
      //if daysToPrepare = 1 -> the value needs to be /2, etc. => divide by x+1
      payload = round(
        (assignment[minutesProperty] * (4 - assignment.status) * (25 / 100)) /
        daysToPrepare,
        2
      );
    }
    // console.log("returned payload:");
    // console.log(payload);
    return payload;
  }
  getAssignmentPayload(assignment, daysProperty, minutesProperty) {
    let payload = 0;
    console.log(assignment);
    if (assignment[daysProperty] <= 0) {
      // console.log("we look at <0");
      payload = round(
        assignment[minutesProperty] === -1
          ? assignment.suggested_minutes
          : assignment[minutesProperty] * (4 - assignment.status) * (25 / 100),
        2
      );
    } else {
      //if daysToPrepare = 1 -> the value needs to be /2, etc. => divide by x+1
      // console.log(assignment)
      // console.log("we try this: minutes: " +assignment.my_minutes +" , days: " + assignment[daysProperty] + 1)
      payload = round(
        (assignment[minutesProperty] === -1
          ? assignment.suggested_minutes
          : assignment[minutesProperty] *
          (4 - assignment.status) *
          (25 / 100)) /
        (assignment[daysProperty] === -1 ? 0 : assignment[daysProperty] + 1),
        2
      );
    }
    // console.log("returned payload:");
    // console.log(payload);
    return payload;
  }

  getAssignmentPayloadBasedOnMinutesDone(assignment, type) {
    let payload = 0;
    let tempPayload = 0;
    // console.log(assignment);
    // calculate a class payload
    if (type === "class") {
      if (assignment.type === "personal") {
        payload = 0;
      } else if (assignment.suggested_days <= 0) {
        payload =
          assignment.suggested_minutes === -1
            ? 0
            : assignment.suggested_minutes;
      } else {
        //if daysToPrepare = 1 -> the value needs to be /2, etc. => divide by x+1
        payload = round(
          assignment.suggested_minutes === -1
            ? 0
            : assignment.suggested_minutes /
            (assignment.suggested_days === -1
              ? 1
              : assignment.suggested_days + 1),
          2
        );
      }
    } else if (type === "personal") {
      //calculate an personal payload
      if (assignment.my_days <= 0) {
        if (assignment.my_minutes_done === -1) {
          // console.log("We go min <=0 on days<=0")
          payload = assignment.my_minutes === -1 ? 0 : assignment.my_minutes;
        } else {
          //division by 0!
          // console.log("We go min >0 on days<=0")
          if (assignment.my_minutes - assignment.my_minutes_done < 0) {
            payload = 0;
          } else {
            payload = assignment.my_minutes - assignment.my_minutes_done;
          }
          // console.log(payload);
        }
      } else {
        if (assignment.my_minutes_done === -1) {
          // console.log("We go min -1 on days>0")
          payload = round(
            (assignment.my_minutes === -1 ? 0 : assignment.my_minutes) /
            (assignment.my_days + 1),
            2
          );
        } else {
          // console.log("We go min >-1 on days>0")
          // console.log(assignment)
          // console.log(assignment.my_minutes - assignment.my_minutes_done )
          // console.log(assignment.my_days)
          payload = round(
            (assignment.my_minutes - assignment.my_minutes_done < 0
              ? 0
              : assignment.my_minutes - assignment.my_minutes_done) /
            (assignment.my_days + 1),
            2
          );
        }
      }
    } else if (type === "group") {
      if (assignment && assignment.length > 0) {
        //assignment here is a group of assignments!
        assignment.forEach((groupAssignment) => {
          const suggestedMinutes =
            groupAssignment.suggested_minutes === -1
              ? 0
              : groupAssignment.suggested_minutes;
          const suggestedDays =
            groupAssignment.suggested_days === -1
              ? 1
              : groupAssignment.suggested_days + 1;

          tempPayload += round(suggestedMinutes / suggestedDays, 2);
        });
        payload = round(tempPayload / assignment.length, 2);
        // console.log("we dealt with a mixed group: ", payload)
      }
    }
    // console.log("returned payload:");
    // console.log(payload);
    return payload;
  }

  calculateLastMonday(referenceDate) {
    const monday = moment(referenceDate).startOf("isoWeek");
    return moment(monday).subtract(1, "week").startOf("isoWeek");
  }

  calculateRelevantAssignmentsByDay(referenceDate, relevantUserAssignments) {
    function isRelevantForDayFunction(assignment, day) {
      // due_date is today but daysToPrepare is 0 -> count it!
      return (
        (moment(assignment.due_date).isSame(day) &&
          assignment.suggested_days === 0) ||
        // due_date is today and status is not 4
        // (moment(assignment.due_date).isSame(moment(now).startOf('day')) && assignment.status !== 4) ||
        // DaysToPrepare
        (moment(assignment.due_date).isAfter(day) &&
          moment(assignment.due_date)
            .subtract(assignment.suggested_days, "days")
            .isSameOrBefore(day))
      );
    }
    const monday = moment(referenceDate).startOf("isoWeek");
    // Get dict of relevant assignments by day (0: monday, 6: sunday)
    let relevantAssignmentsByDay = {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    };
    for (let key in relevantAssignmentsByDay) {
      let day = moment(monday).add(key, "days");
      for (let i in relevantUserAssignments) {
        if (isRelevantForDayFunction(relevantUserAssignments[i], day)) {
          relevantAssignmentsByDay[key].push(relevantUserAssignments[i]);
        }
      }
    }
    return relevantAssignmentsByDay;
  }

  calculateMinimumMinUntilIncl(referenceDate, relevantAssignmentsByDay) {
    const monday = moment(referenceDate).startOf("isoWeek");

    let minimumMinUntilIncl = [0, 0, 0, 0, 0, 0, 0];
    if (
      moment(monday)
        .startOf("isoWeek")
        .isSameOrAfter(moment(now).startOf("isoWeek"))
    ) {
      for (let key in relevantAssignmentsByDay) {
        let day = moment(monday).add(key, "days");
        for (let i in relevantAssignmentsByDay[key]) {
          let ass = relevantAssignmentsByDay[key][i];
          let extraDates;
          try {
            extraDates = ass.extra_dates.split(",");
          } catch {
            extraDates = [];
          }
          let extraWeeksBetweenNowAndMonday = 0;
          for (let j in extraDates) {
            if (
              moment(extraDates[j])
                .startOf("isoWeek")
                .isSameOrAfter(moment(now).startOf("isoWeek")) &&
              moment(extraDates[j])
                .startOf("isoWeek")
                .isBefore(moment(monday).startOf("isoWeek"))
            ) {
              extraWeeksBetweenNowAndMonday++;
            }
          }
          let extraDaysBetweenNowAndMonday = 7 * extraWeeksBetweenNowAndMonday;
          let lastMonday = moment(monday)
            .subtract(1, "week")
            .startOf("isoWeek");
          for (let j in extraDates) {
            if (
              moment(extraDates[j])
                .startOf("isoWeek")
                .isSameOrAfter(moment(now).startOf("isoWeek")) &&
              moment(extraDates[j]).startOf("isoWeek").isSame(lastMonday)
            ) {
              extraDaysBetweenNowAndMonday -= Math.abs(
                moment(now).diff(lastMonday, "days")
              );
            }
          }
          let realStartDate = moment(ass.due_date).subtract(
            ass.suggested_days + extraDaysBetweenNowAndMonday,
            "days"
          );
          if (realStartDate.isBefore(now)) {
            realStartDate = moment(now);
          }
          if (moment(ass.due_date).isSame(moment(day).add(1, "days"))) {
            if (realStartDate.isBefore(day) && realStartDate.isBefore(monday)) {
              let daysToPrepare =
                Math.min(
                  ass.suggested_days,
                  Math.abs(moment(ass.due_date).diff(now, "days"))
                ) + extraDaysBetweenNowAndMonday;
              minimumMinUntilIncl[key] += round(
                ((ass.suggested_minutes + ass.suggested_minutes * key) /
                  daysToPrepare) *
                (4 - ass.status) *
                (25 / 100),
                2
              );
            } else {
              minimumMinUntilIncl[key] += round(
                ass.suggested_minutes * (4 - ass.status) * (25 / 100),
                2
              );
            }
          }
        }
      }
    }
    // console.log("Minimum");
    // console.log(minimumMinUntilIncl);
    // console.log("---");

    return minimumMinUntilIncl;
  }

  addRelevantExtraDatesAssignmentsToTempArray(
    relevantExtraDatesAssignments,
    originalArray,
    referenceDate,
    propertyMinutes,
    propertyDays
  ) {
    const monday = moment(referenceDate).startOf("isoWeek");

    for (let i in relevantExtraDatesAssignments) {
      let extraDates = relevantExtraDatesAssignments[i].extra_dates.split(",");
      let numExtraWeeksFromNow = 0;
      let numExtraDaysFromNow = 0;
      for (let j in extraDates) {
        if (
          moment(extraDates[j])
            .startOf("isoWeek")
            .isAfter(moment(now).startOf("isoWeek"))
        ) {
          numExtraWeeksFromNow++;
        } else if (
          moment(extraDates[j])
            .startOf("isoWeek")
            .isSame(moment(now).startOf("isoWeek"))
        ) {
          numExtraDaysFromNow =
            Math.abs(moment(now).diff(moment(now).endOf("isoWeek"), "days")) +
            1;
        }
      }
      if (
        moment(monday)
          .startOf("isoWeek")
          .isAfter(moment(now).startOf("isoWeek"))
      ) {
        for (let j in originalArray) {
          originalArray[j] = round(
            originalArray[j] +
            (relevantExtraDatesAssignments[i][propertyMinutes] *
              (4 - relevantExtraDatesAssignments[i].status) *
              (25 / 100)) /
            (numExtraWeeksFromNow * 7 +
              numExtraDaysFromNow +
              relevantExtraDatesAssignments[i][propertyDays]),
            2
          );
        }
      } else if (
        moment(monday).startOf("isoWeek").isSame(moment(now).startOf("isoWeek"))
      ) {
        for (let j = 7 - numExtraDaysFromNow; j < 7; j++) {
          originalArray[j] = round(
            originalArray[j] +
            (relevantExtraDatesAssignments[i][propertyMinutes] *
              (4 - relevantExtraDatesAssignments[i].status) *
              (25 / 100)) /
            (numExtraWeeksFromNow * 7 +
              numExtraDaysFromNow +
              relevantExtraDatesAssignments[i][propertyDays]),
            2
          );
        }
      }
    }
    return originalArray;
  }

  addRelevantExtraDatesAssignments(
    assignmentArray,
    referenceDate,
    workloadMinutesTemp,
    property
  ) {
    const monday = moment(referenceDate).startOf("isoWeek");

    for (let i in assignmentArray) {
      let extraDates = assignmentArray[i].extra_dates.split(",");
      let numExtraWeeksFromNow = 0;
      let numExtraDaysFromNow = 0;
      for (let j in extraDates) {
        if (
          moment(extraDates[j])
            .startOf("isoWeek")
            .isAfter(moment(now).startOf("isoWeek"))
        ) {
          numExtraWeeksFromNow++;
        } else if (
          moment(extraDates[j])
            .startOf("isoWeek")
            .isSame(moment(now).startOf("isoWeek"))
        ) {
          numExtraDaysFromNow =
            Math.abs(moment(now).diff(moment(now).endOf("isoWeek"), "days")) +
            1;
        }
      }
      if (
        moment(monday)
          .startOf("isoWeek")
          .isAfter(moment(now).startOf("isoWeek"))
      ) {
        for (let j in workloadMinutesTemp) {
          workloadMinutesTemp[j] = round(
            workloadMinutesTemp[j] +
            (assignmentArray[i][property] *
              (4 - assignmentArray[i].status) *
              (25 / 100)) /
            (numExtraWeeksFromNow * 7 +
              numExtraDaysFromNow +
              assignmentArray[i][property]),
            2
          );
        }
      } else if (
        moment(monday).startOf("isoWeek").isSame(moment(now).startOf("isoWeek"))
      ) {
        for (let j = 7 - numExtraDaysFromNow; j < 7; j++) {
          workloadMinutesTemp[j] = round(
            workloadMinutesTemp[j] +
            (assignmentArray[i][property] *
              (4 - assignmentArray[i].status) *
              (25 / 100)) /
            (numExtraWeeksFromNow * 7 +
              numExtraDaysFromNow +
              assignmentArray[i][property]),
            2
          );
        }
      }
    }
    return workloadMinutesTemp;
  }

  distributeOverload(
    referenceDate,
    workloadMinutesTemp,
    timeToDistribute,
    workloadMinutesTempSave
  ) {
    const monday = moment(referenceDate).startOf("isoWeek");
    let daysUntilInclSunday = 7;
    if (moment(now).isAfter(monday)) {
      daysUntilInclSunday =
        Math.abs(moment(now).diff(moment(now).endOf("isoWeek"), "days")) + 1;
    }
    let numDaysToDistribute = daysUntilInclSunday;
    for (let i = 7 - daysUntilInclSunday; i < 7; i++) {
      if (workloadMinutesTempSave[i] === 0) {
        numDaysToDistribute--;
      }
    }
    if (timeToDistribute > 1) {
      for (let i = 0; i < daysUntilInclSunday; i++) {
        if (workloadMinutesTempSave[6 - i] > 0) {
          workloadMinutesTemp[6 - i] = round(
            workloadMinutesTemp[6 - i] + timeToDistribute / numDaysToDistribute,
            2
          );
        }
      }
    }
    return workloadMinutesTemp;
  }
  //brushing up the workloadMinutesTemp
  roundWorkloadMinutesTemp(minimumMinUntilIncl, workloadMinutesTemp) {
    for (let i = 0; i < 7; i++) {
      let sumMinimum = 0;
      let sumEffective = 0;
      for (let j = 0; j < i + 1; j++) {
        sumMinimum += minimumMinUntilIncl[j];
        sumEffective += workloadMinutesTemp[j];
      }
      if (sumMinimum > sumEffective + 1) {
        let diff = round(sumMinimum - sumEffective, 2);
        workloadMinutesTemp[i] = round(workloadMinutesTemp[i] + diff, 2);
        try {
          workloadMinutesTemp[i + 1] = round(
            workloadMinutesTemp[i + 1] - diff,
            2
          );
        } catch {
          // console.log('Problem with distribution');
        }
      }
    }
    return workloadMinutesTemp;
  }
  //this is the final workloadChart Array
  calculateWorkloadChart(temporaryArray) {
    const finalArray = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ];
    for (let i = 0; i < 21; i++) {
      finalArray[i] = temporaryArray[Math.floor(i / 3)];
    }
    return finalArray;
  }

  calculateMinusLeftDays(
    personalWorkWeekData,
    suggestedWorkloadMinutesTemp,
    referenceDate
  ) {
    //this value a safety copy:
    let minutesLeftDays = personalWorkWeekData;
    const monday = moment(referenceDate).startOf("isoWeek");
    for (let t in suggestedWorkloadMinutesTemp) {
      let day = moment(monday).add(t, "days");

      if (day.isBefore(now)) {
        minutesLeftDays[t] = 0;
      } else {
        minutesLeftDays[t] = round(
          personalWorkWeekData[t] - suggestedWorkloadMinutesTemp[t],
          2
        );
      }
    }
    return minutesLeftDays;
  }

  calculateTimeToDistribute(originalArray, minimumMinUntilIncl) {
    for (let i = 0; i < 7; i++) {
      let sumMinimum = 0;
      let sumEffective = 0;
      for (let j = 0; j < i + 1; j++) {
        sumMinimum += minimumMinUntilIncl[j];
        sumEffective += originalArray[j];
      }
      if (sumMinimum > sumEffective + 1) {
        let diff = round(sumMinimum - sumEffective, 2);
        originalArray[i] = round(originalArray[i] + diff, 2);
        try {
          originalArray[i + 1] = round(originalArray[i + 1] - diff, 2);
        } catch {
          // console.log('Problem with distribution');
        }
      }
    }
    // console.log("das haben wir berechnet:")
    // console.log(originalArray)
    return originalArray;
  }

  loadWorkloadMinutesTemp(minutesLeftDays, workloadMinutesTemp, referenceDate) {
    const monday = moment(referenceDate).startOf("isoWeek");
    let timeToDistribute = 0;
    while (
      getDaysWithTime(minutesLeftDays, workloadMinutesTemp, monday) > 0 &&
      timeToDistribute > 0.05
    ) {
      for (let t in workloadMinutesTemp) {
        if (minutesLeftDays[t] > 0 && workloadMinutesTemp[t] > 0) {
          let days = getDaysWithTime(
            minutesLeftDays,
            workloadMinutesTemp,
            monday
          );
          let minutes = Math.min(
            minutesLeftDays[t],
            round(timeToDistribute / days, 2)
          );
          minutesLeftDays[t] -= minutes;
          workloadMinutesTemp[t] += minutes;
          timeToDistribute = round(timeToDistribute - minutes, 2);
        }
      }
    }

    return workloadMinutesTemp;
  }

  /**
   * calculate the Maximum level of the workload chart
   */
  calculateMaximumWorkloadMinutes(
    personalWorkWeekData,
    workloadMinutes,
    suggestedWorkloadMinutes
  ) {
    let maxMinutesDayTemp = 0;
    let maxMinutesDayWorkWeek = 0;
    let maxMinutesDayEffective = 0;
    let maxMinutesDayTheoretical = 0;

    for (let i = 0; i < personalWorkWeekData.length; i++) {
      if (personalWorkWeekData[i] > maxMinutesDayWorkWeek) {
        maxMinutesDayWorkWeek = personalWorkWeekData[i];
      }
      if (workloadMinutes[3 * i + 1] > maxMinutesDayEffective) {
        maxMinutesDayEffective = workloadMinutes[3 * i + 1];
      }
      if (suggestedWorkloadMinutes[3 * i + 1] > maxMinutesDayTheoretical) {
        maxMinutesDayTheoretical = suggestedWorkloadMinutes[3 * i + 1];
      }
    }
    // console.log(maxMinutesDayWorkWeek, maxMinutesDayEffective, maxMinutesDayTheoretical)
    maxMinutesDayTemp = parseInt(
      Math.ceil(
        Math.max(
          maxMinutesDayWorkWeek,
          maxMinutesDayEffective,
          maxMinutesDayTheoretical
        ) / 60
      ) * 60
    );
    // console.log(maxMinutesDayTemp)
    const maxMinutesInDataSet = Math.max(
      maxMinutesDayTemp,
      maxMinutesDayWorkWeek + 60
    );

    return maxMinutesInDataSet;
  }

  /**
   * Check how many days the workload fits into the budget
   */
  fitsInWeek(personalWorkWeekData, workloadMinutesTemp) {
    let fitsInWeek = 0;
    for (let i = 0; i < 7; i++) {
      if (personalWorkWeekData[i] < workloadMinutesTemp[i]) {
        // count how many days the workload does not fit
        // 0 = no warning, 2 = small warning, 3+ = warning
        fitsInWeek = fitsInWeek + 1;
      }
    }
    return fitsInWeek;
  }
  /**
   * calculates WorkloadMinutes based on my_minutes value
   */
  calculateWorkloadMinutes(
    relevantUserAssignments,
    referenceDate,
    personalWorkWeekData
  ) {
    function isRelevantForDayFunction(assignment, day) {
      // due_date is today but daysToPrepare is 0 -> count it!
      return (
        (moment(assignment.due_date).isSame(day) &&
          assignment.suggested_days === 0) ||
        // due_date is today and status is not 4
        // (moment(assignment.due_date).isSame(moment(now).startOf('day')) && assignment.status !== 4) ||
        // DaysToPrepare
        (moment(assignment.due_date).isAfter(day) &&
          moment(assignment.due_date)
            .subtract(assignment.suggested_days, "days")
            .isSameOrBefore(day))
      );
    }

    const workloadMinutesTemp = [0, 0, 0, 0, 0, 0, 0];
    const minutesLeftDays = [
      personalWorkWeekData[0],
      personalWorkWeekData[1],
      personalWorkWeekData[2],
      personalWorkWeekData[3],
      personalWorkWeekData[4],
      personalWorkWeekData[5],
      personalWorkWeekData[6],
    ];

    const monday = moment(referenceDate).startOf("isoWeek");
    const now = moment(new Date()).startOf("day");

    let assignments = relevantUserAssignments.thisWeek.concat(
      relevantUserAssignments.additional.daysToPrepare
    );
    let relevantExtraDatesAssignments =
      relevantUserAssignments.additional.extraDates;

    let relevantAssignmentsByDay = {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    };

    // Get dict of relevant assignments by day (0: monday, 6: sunday)
    for (let key in relevantAssignmentsByDay) {
      let day = moment(monday).add(key, "days");
      for (let i in assignments) {
        let isRelevantForDay = isRelevantForDayFunction(assignments[i], day);
        if (isRelevantForDay) {
          relevantAssignmentsByDay[key].push(assignments[i]);
        }
      }
    }

    // calculate class workload (based on suggested_minutes) and individual workload (based on my_minutes)
    for (let key in relevantAssignmentsByDay) {
      // focus on the following week day (takes monday as a basis and adds the index (key) in days)
      let day = moment(monday).add(key, "days");
      for (let i in relevantAssignmentsByDay[key]) {
        let ass = relevantAssignmentsByDay[key][i];
        // consider extra days if such days have been added by the user
        let extraDates;
        try {
          extraDates = ass.extra_dates.split(",");
        } catch {
          extraDates = [];
        }
        let extraWeeksBetweenNowAndMonday = 0;
        for (let j in extraDates) {
          if (
            moment(extraDates[j])
              .startOf("isoWeek")
              .isSameOrAfter(moment(now).startOf("isoWeek")) &&
            moment(extraDates[j])
              .startOf("isoWeek")
              .isBefore(moment(monday).startOf("isoWeek"))
          ) {
            extraWeeksBetweenNowAndMonday++;
          }
        }
        let extraDaysBetweenNowAndMonday = 7 * extraWeeksBetweenNowAndMonday;
        let lastMonday = moment(monday).subtract(1, "week").startOf("isoWeek");
        for (let j in extraDates) {
          if (
            moment(extraDates[j])
              .startOf("isoWeek")
              .isSameOrAfter(moment(now).startOf("isoWeek")) &&
            moment(extraDates[j]).startOf("isoWeek").isSame(lastMonday)
          ) {
            extraDaysBetweenNowAndMonday -= Math.abs(
              moment(now).diff(lastMonday, "days")
            );
          }
        }

        // calculate individual Workload (based on my_minutes)
        // first, check how many days the user has individually calculated for the assignment
        let daysToPrepare =
          Math.min(
            ass.my_days,
            Math.abs(moment(ass.due_date).diff(now, "days"))
          ) + extraDaysBetweenNowAndMonday;
        if (day.isSameOrAfter(now)) {
          workloadMinutesTemp[key] +=
            daysToPrepare < 0
              ? 0
              : ass.my_days === 0
                ? round(ass.my_minutes * (4 - ass.status) * (25 / 100), 2)
                : round(
                  (ass.my_minutes * (4 - ass.status) * (25 / 100)) /
                  daysToPrepare,
                  2
                );
        }
      }
    }
    // console.log('Workloadminutes before distribution');
    // console.log(workloadMinutesTemp)
    // console.log('---');

    // calculate "minimal minutes to do until including" 0: monday, ... 6: sunday
    let minimumMinUntilIncl = [0, 0, 0, 0, 0, 0, 0];
    if (
      moment(monday)
        .startOf("isoWeek")
        .isSameOrAfter(moment(now).startOf("isoWeek"))
    ) {
      for (let key in relevantAssignmentsByDay) {
        let day = moment(monday).add(key, "days");
        for (let i in relevantAssignmentsByDay[key]) {
          let ass = relevantAssignmentsByDay[key][i];
          let extraDates;
          try {
            extraDates = ass.extra_dates.split(",");
          } catch {
            extraDates = [];
          }
          let extraWeeksBetweenNowAndMonday = 0;
          for (let j in extraDates) {
            if (
              moment(extraDates[j])
                .startOf("isoWeek")
                .isSameOrAfter(moment(now).startOf("isoWeek")) &&
              moment(extraDates[j])
                .startOf("isoWeek")
                .isBefore(moment(monday).startOf("isoWeek"))
            ) {
              extraWeeksBetweenNowAndMonday++;
            }
          }
          let extraDaysBetweenNowAndMonday = 7 * extraWeeksBetweenNowAndMonday;
          let lastMonday = moment(monday)
            .subtract(1, "week")
            .startOf("isoWeek");
          for (let j in extraDates) {
            if (
              moment(extraDates[j])
                .startOf("isoWeek")
                .isSameOrAfter(moment(now).startOf("isoWeek")) &&
              moment(extraDates[j]).startOf("isoWeek").isSame(lastMonday)
            ) {
              extraDaysBetweenNowAndMonday -= Math.abs(
                moment(now).diff(lastMonday, "days")
              );
            }
          }
          let realStartDate = moment(ass.due_date).subtract(
            ass.suggested_days + extraDaysBetweenNowAndMonday,
            "days"
          );
          if (realStartDate.isBefore(now)) {
            realStartDate = moment(now);
          }
          if (moment(ass.due_date).isSame(moment(day).add(1, "days"))) {
            if (realStartDate.isBefore(day) && realStartDate.isBefore(monday)) {
              let daysToPrepare =
                Math.min(
                  ass.my_days,
                  Math.abs(moment(ass.due_date).diff(now, "days"))
                ) + extraDaysBetweenNowAndMonday;
              minimumMinUntilIncl[key] += round(
                ((ass.my_minutes + ass.my_minutes * key) / daysToPrepare) *
                (4 - ass.status) *
                (25 / 100),
                2
              );
            } else {
              minimumMinUntilIncl[key] += round(
                ass.my_minutes * (4 - ass.status) * (25 / 100),
                2
              );
            }
          }
        }
      }
    }
    // console.log('Minimum');
    // console.log(minimumMinUntilIncl)
    // console.log('---');

    let workloadMinutesTempSave = [0, 0, 0, 0, 0, 0, 0];
    for (let i in workloadMinutesTempSave) {
      workloadMinutesTempSave[i] = workloadMinutesTemp[i];
    }

    // add assignments from extraDates
    for (let i in relevantExtraDatesAssignments) {
      let extraDates = relevantExtraDatesAssignments[i].extra_dates.split(",");
      let numExtraWeeksFromNow = 0;
      let numExtraDaysFromNow = 0;
      for (let j in extraDates) {
        if (
          moment(extraDates[j])
            .startOf("isoWeek")
            .isAfter(moment(now).startOf("isoWeek"))
        ) {
          numExtraWeeksFromNow++;
        } else if (
          moment(extraDates[j])
            .startOf("isoWeek")
            .isSame(moment(now).startOf("isoWeek"))
        ) {
          numExtraDaysFromNow =
            Math.abs(moment(now).diff(moment(now).endOf("isoWeek"), "days")) +
            1;
        }
      }
      if (
        moment(monday)
          .startOf("isoWeek")
          .isAfter(moment(now).startOf("isoWeek"))
      ) {
        for (let j in workloadMinutesTemp) {
          workloadMinutesTemp[j] = round(
            workloadMinutesTemp[j] +
            (relevantExtraDatesAssignments[i].my_minutes *
              (4 - relevantExtraDatesAssignments[i].status) *
              (25 / 100)) /
            (numExtraWeeksFromNow * 7 +
              numExtraDaysFromNow +
              relevantExtraDatesAssignments[i].my_days),
            2
          );
        }
      } else if (
        moment(monday).startOf("isoWeek").isSame(moment(now).startOf("isoWeek"))
      ) {
        for (let j = 7 - numExtraDaysFromNow; j < 7; j++) {
          workloadMinutesTemp[j] = round(
            workloadMinutesTemp[j] +
            (relevantExtraDatesAssignments[i].my_minutes *
              (4 - relevantExtraDatesAssignments[i].status) *
              (25 / 100)) /
            (numExtraWeeksFromNow * 7 +
              numExtraDaysFromNow +
              relevantExtraDatesAssignments[i].my_days),
            2
          );
        }
      }
    }
    // console.log(workloadMinutesTemp)

    // calc time to distribute total and update minutes left by day
    let timeToDistribute = 0;
    for (let t in workloadMinutesTemp) {
      let day = moment(monday).add(t, "days");
      if (workloadMinutesTemp[t] > personalWorkWeekData[t]) {
        timeToDistribute -= round(
          personalWorkWeekData[t] - workloadMinutesTemp[t],
          2
        );
        workloadMinutesTemp[t] = personalWorkWeekData[t];
      }
      if (day.isBefore(now)) {
        minutesLeftDays[t] = 0;
      } else {
        minutesLeftDays[t] = round(
          personalWorkWeekData[t] - workloadMinutesTemp[t],
          2
        );
      }
    }
    // console.log('---')
    // console.log('TimeToDistribute: ' + timeToDistribute + 'min')
    // console.log('MinutesLeftDays: ' + minutesLeftDays)
    // console.log(workloadMinutesTemp)
    // console.log('---');

    // calc num of days with spare time
    const getDaysWithTime = () => {
      let daysWithTime = 0;
      for (let t in minutesLeftDays) {
        let day = moment(monday).add(t, "days");
        if (
          moment(day).isSameOrAfter(now) &&
          minutesLeftDays[t] > 0 &&
          workloadMinutesTemp[t] > 0
        ) {
          daysWithTime++;
        }
      }
      return daysWithTime;
    };
    // console.log('Days with Time: ' + getDaysWithTime());

    // distribute time to days with time
    while (getDaysWithTime() > 0 && timeToDistribute > 0.05) {
      for (let t in workloadMinutesTemp) {
        if (minutesLeftDays[t] > 0 && workloadMinutesTemp[t] > 0) {
          let days = getDaysWithTime();
          let minutes = Math.min(
            minutesLeftDays[t],
            round(timeToDistribute / days, 2)
          );
          minutesLeftDays[t] -= minutes;
          workloadMinutesTemp[t] += minutes;
          timeToDistribute = round(timeToDistribute - minutes, 2);
        }
      }
    }
    // distribute overload
    let daysUntilInclSunday = 7;
    if (moment(now).isAfter(monday)) {
      daysUntilInclSunday =
        Math.abs(moment(now).diff(moment(now).endOf("isoWeek"), "days")) + 1;
    }
    let numDaysToDistribute = daysUntilInclSunday;
    for (let i = 7 - daysUntilInclSunday; i < 7; i++) {
      if (workloadMinutesTempSave[i] === 0) {
        numDaysToDistribute--;
      }
    }
    if (timeToDistribute > 1) {
      for (let i = 0; i < daysUntilInclSunday; i++) {
        if (workloadMinutesTempSave[6 - i] > 0) {
          workloadMinutesTemp[6 - i] = round(
            workloadMinutesTemp[6 - i] + timeToDistribute / numDaysToDistribute,
            2
          );
        }
      }
    }

    // controll, that minimum workload is still correct after distribution
    timeToDistribute = 0;
    for (let i = 0; i < 7; i++) {
      let sumMinimum = 0;
      let sumEffective = 0;
      for (let j = 0; j < i + 1; j++) {
        sumMinimum += minimumMinUntilIncl[j];
        sumEffective += workloadMinutesTemp[j];
      }
      if (sumMinimum > sumEffective + 1) {
        let diff = round(sumMinimum - sumEffective, 2);
        workloadMinutesTemp[i] = round(workloadMinutesTemp[i] + diff, 2);
        try {
          workloadMinutesTemp[i + 1] = round(
            workloadMinutesTemp[i + 1] - diff,
            2
          );
        } catch {
          // console.log('Problem with distribution');
        }
      }
    }
    // console.log(workloadMinutesTemp)

    //should be based on my_minutes value
    // let totalWorkloadMinutesIndividual = 0;

    // for (let i in workloadMinutesTemp) {
    //   totalWorkloadMinutesIndividual += workloadMinutesTemp[i];
    // }

    // console.log("total persönlich: " + totalWorkloadMinutesIndividual);

    const workloadMinutesChart = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ];

    for (let i = 0; i < 21; i++) {
      workloadMinutesChart[i] = workloadMinutesTemp[Math.floor(i / 3)];
    }
    return workloadMinutesChart;
  }
}

const workloadServiceInstance = new WorkloadService();
export default workloadServiceInstance;