import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { memo } from "react";
import StatisticsView from "./StatisticsView";

const styles = {
  card: {
    width: '13em', // Set your preferred width
    overflow: 'hidden',
    border: '1px solid #ddd', // Optional: Add border for better visual separation
  },
  cardContent: {
    maxHeight: '17em', // Set your preferred max height
    overflowY: 'auto',
  },
};

function StatisticsPopUpView({ assignment }) {

  return (
    <Card style={styles.card}>
      <CardHeader title="Statistik Lernende"
        subheader={assignment.name}>
      </CardHeader>
      <CardContent style={styles.cardContent}>
        <StatisticsView assignment={assignment} />
      </CardContent>
    </Card>
  );
}

export default memo(StatisticsPopUpView);
