import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import NameBoxComponent from "../CardElements/NameBoxComponent";
import TypeBoxComponent from "../CardElements/TypeBoxComponent";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";
// import ErrorBox from "../CardElements/ErrorBox";

function LineOne({ type, onChangeType, assignmentName, onChangeAssignmentName, restricted }){
    const {userCourses} = useAppContext()
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
            sx={{ marginTop: "4px" }}
        >
            {/* Type Container */}
            <Grid item xs={4}>
                {type && <TypeBoxComponent
                    restricted={restricted}
                    type={type}
                    userCourses={userCourses}
                    change={onChangeType}
                />}
            </Grid>

            {/* Assignment Name Container */}
            <Grid item xs={7}>
                {/* Name */}
                <NameBoxComponent
                    restricted={false}
                    assignmentName={assignmentName}
                    change={onChangeAssignmentName}
                />
                {/* <ErrorBox error={assignmentNameErr} /> */}
            </Grid>
        </Grid>

    );
}

export default memo(LineOne);
