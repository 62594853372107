import React from "react";
import { AppBar } from "@mui/material";

const AppBarBox = ({ children }) => (
  <AppBar
    position="fixed"
    sx={{
      height: "4rem",
      paddingLeft: "16px",
      paddingRight: "16px",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        height: "4em",
        // backgroundColor: "red"
      }}
    >
      {children}
    </div>
  </AppBar>
);

export default AppBarBox;
