import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import CountingFactorBox from "../CardElements/CountingFactorBox";
import ExamTypeBox from "../CardElements/ExamTypeBox";
//L for leader
function EditLineFourExam({
  previewAssignment,
  assignment,
  changesCount,
  setChangesCount,
  countingFactor,
  setCountingFactor,
  countingFactorErr,
  setAssignmentExamTypeId,
  husibuechExamTypes,
  assignmentExamTypeId,
  restricted,
}) {
  // console.log("countingFactor:", countingFactor)
  return (
    <Grid item style={{ marginTop: "0.75em" }}>
      <Grid
        container
        justifyContent="flex-start"
        alignItems={"center"}
        alignContent={"center"}
        direction="row"
        spacing={3}

      // sx={{ width: "100%", background: "orange" }}
      >
        {/* Gewicht */}

        <Grid item xs={4}>
          <CountingFactorBox
            restricted={restricted}
            previewAssignment={previewAssignment}
            countingFactor={countingFactor}
            setCountingFactor={setCountingFactor}
            changesCount={changesCount}
            setChangesCount={setChangesCount}
            countingFactorErr={countingFactorErr}
          />
        </Grid>

        {/* Prüfungstyp */}

        <Grid
          item
          xs={6}
          sx={{
            marginLeft: "0.9em",
            // background: "orange",
          }}
        >
          <ExamTypeBox
            restricted={restricted}
            assignment={assignment}
            previewAssignment={previewAssignment}
            assignmentExamTypeId={assignmentExamTypeId}
            setAssignmentExamTypeId={setAssignmentExamTypeId}
            changesCount={changesCount}
            setChangesCount={setChangesCount}
            husibuechExamTypes={husibuechExamTypes}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(EditLineFourExam);
