import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import '@atlaskit/css-reset'

import App from "./App";
import * as serviceWorkerRegistration from "./_1Authentication/serviceWorkerRegistration";
import reportWebVitals from "./_1Authentication/reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename={"/"}>
    {/* <BrowserRouter basename={"/husibuech"}> */}
    <App />
  </BrowserRouter>
);

serviceWorkerRegistration.register();
reportWebVitals();
