import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./DashboardContentMobile.css";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import RecentlyAdded from "../DashboardParts/RecentlyAdded/RecentlyAdded";
import DonutFlip from "../../../_4GeneralHelpers/8_Cardflip/DonutFlip";
import WorkloadChartRoman from "../DashboardParts/WorkloadChart/WorkloadChartRoman";
import YearView from "../DashboardParts/YearView/YearView";
import WorkloadChart from "../DashboardParts/WorkloadChart/WorkloadChart";


const DashboardCarousel2 = () => {
  const { isAStudent, appInDarkMode } = useAppContext();
  // console.log(isMobile)
  const dotColor = appInDarkMode ? "#fff" : "#000"; // Set color based on the dark mode state


  const renderComponent = (component) => (
    <div style={{
      justifyContent: "center",
    }}>
      {component}
    </div>
  );

  // Add custom styles to reduce the space between dots and content
  const customDotStyles = {
    // Add custom styles to reduce the space between dots and content
    margin: "-0.5em", // Adjust as needed
    // Change dot color to white
  }


  const settings = {
    dots: true,
    infinite: true,
    speed: 1100,
    slidesToShow: 1, // Adjust the number of slides to show
    slidesToScroll: 1,
    // centerMode: true,   // Enable center mode
    // centerPadding: "-3em",  // Add space between items (adjust as needed)
    arrows: false,    // Hide arrows
    swipe: true,       // Enable swipe interaction
    dots: true,  // Set to false to hide the swipe dots
    vertical: false,   // Enable vertical swiping
    appendDots: (dots) => (
      <div style={customDotStyles}>
        <ul>{dots}</ul>
      </div>
    ),
  };

  return (
    <div> <style>{`
    .slick-dots li button::before {
      color: ${dotColor};
      opacity: 0.4;
    }

    .slick-dots li.slick-active button::before {
      color: ${dotColor};
      opacity: 1;
    }
  `}</style>
      <Slider {...settings}>
        {renderComponent(
          <div
            // role="right-item"
            style={{
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "thin",
              scrollbarColor: "transparent transparent",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <WorkloadChart />
          </div>
        )}
        {renderComponent(
          <div
            role="center-item"
            style={{
              justifyContent: "center",
            }}
          >
            <DonutFlip />
          </div>
        )}
        {renderComponent(
          <div
            role="center-item"
            style={{
              justifyContent: "center",
            }}
          >
            <YearView />
          </div>
        )}

        {renderComponent(
          <RecentlyAdded type={isAStudent ? "student" : "teacher"} />
        )}
      </Slider>
    </div>
  );
};

export default DashboardCarousel2;
