import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import { IconButton, Grid, useMediaQuery } from "@mui/material";
import { Logout, UploadFile, Settings } from "@mui/icons-material";
import StyledTooltip from "../../../_4GeneralHelpers/2_Tooltips/StyledTooltip";
import MyAvatar from "../../../_4GeneralHelpers/10_Avatar/MyAvatar";
import NewNotificationsButton from "../../../_4GeneralHelpers/6_Notifications/NewNotificationsButton";
import { useTheme } from "@mui/styles";

function MenuTopBarRight() {
  const { currentUser, isAnAdmin, isATeacher, isAuthenticated, currentPage, setCurrentPage, logout } =
    useAppContext();

  const navigate = useNavigate();

  const goToSettingsPage = () => {
    setCurrentPage("settings");
    navigate("/einstellungen");
  };

  const goToProfilePage = () => {
    setCurrentPage("profile");
    navigate("/profil");
  };

  const goToUploadPage = () => {
    setCurrentPage("upload");
    navigate("/upload");
  };

  const renderAvatar = () => (
    <MyAvatar change={goToProfilePage}tooltipText={"Profil"} ariaLabel={"Profilseite"}/>
  );
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xsss"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // console.log(isMobile, isSmallScreen, useMediaQuery(theme.breakpoints.down("md")))
  return (
      <Grid
        container
        direction="row"
        spacing={0}
        justifyContent="flex-end"
        alignItems="center"
        display={"flex"}
        overflow={"hidden"}
      >
        <Grid item>{isAuthenticated && currentUser && renderAvatar()}</Grid>
        <Grid item>
          {isAuthenticated && isAnAdmin && !(isSmallScreen || isMobile) && (
            <StyledTooltip title="Einstellungen" placement="bottom-start" arrow>
              <IconButton
                size="medium"
                color="inherit"
                aria-label="Upload"
                aria-haspopup="true"
                onClick={goToSettingsPage}
              >
                <Settings />
              </IconButton>
            </StyledTooltip>
          )}
        </Grid>
        {(isAnAdmin || isATeacher) && !(isSmallScreen || isMobile) && (
          <Grid item>
            <StyledTooltip
              title="Prüfungsplan hochladen"
              placement="bottom-start"
              arrow
              sx={{ sm: "none" }}
            >
              <IconButton
                size="medium"
                color={currentPage === "upload" ? "inherit" : "primaryLight"}
                aria-label="Upload"
                aria-haspopup="true"
                onClick={goToUploadPage}
              >
                <UploadFile />
              </IconButton>
            </StyledTooltip>
          </Grid>
        )}
        <Grid item style={{marginLeft:"-0.6em"}}>
          <NewNotificationsButton/>
        </Grid>
        <Grid item style={{paddingRight:"0.3em"}}>
          {isAuthenticated && currentUser && (
            <StyledTooltip title="Logout" placement="bottom-start" arrow>
              <IconButton
                edge="end"
                size="medium"
                color="inherit"
                aria-label="Logout"
                onClick={logout}
              >
                <Logout />
              </IconButton>
            </StyledTooltip>
          )}
        </Grid>
      </Grid>
  );
}

export default memo(MenuTopBarRight);
