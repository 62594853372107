import React, { memo } from "react";
import { useTheme } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";

function SaveButton({ loading, saveColor, save, saveText, disabled }) {
  const theme = useTheme();

  return (
    <LoadingButton
      disabled={loading}
      color={saveColor}
      // fullWidth
      style={{
        backgroundColor: theme.palette.levels.green,
      }}
      type="submit"
      variant="contained"
      className="button-block"
      onClick={save}
    >
      {loading && (
        <div
          style={{
            disabled: disabled,
            visibility: loading ? "visible" : "visible",
            marginLeft: "-0.8em",
            marginRight: "0.8em",
          }}
        >
          <span className="spinner-border spinner-border-sm"></span>
        </div>
      )}
      <div
        style={{
          marginRight: "-0.4em",
          marginLeft: "-0.4em",
        }}
      >
        <span>{saveText}</span>
      </div>
    </LoadingButton>
  );
}

export default memo(SaveButton);
