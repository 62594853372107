import "chart.js/auto";
import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { DoghnutChart } from "./_DoghnutHelpers/DoghnutChart";
import { DistributionText } from "./DistributionText";
import AssignmentTable from "./AssignmentTable";


export const StatusDistributionDoghnut = ({ classAssignments, semesterStatusCount, hasStudents }) => {
  const theme = useTheme();
  const { statusList, percentageDone, appInDarkMode } = useAppContext();
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const containerRef = useRef(null);
  const [dataCurrentWeek, setDataCurrentWeek] = useState({
    datasets: [],
    labels: statusList,
  });

  useEffect(() => {
    const adjustFontSize = async () => {
      const containerWidth = containerRef.current.clientWidth;
      const containerHeight = containerRef.current.clientHeight; // Use clientHeight
      await setWidth(containerWidth);
      await setHeight(containerHeight);
      // console.log(containerWidth, containerHeight);
    };

    // Call the adjustFontSize function initially and whenever the window is resized
    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", adjustFontSize);
    };
  }, []);

  // useEffect(() => {
  //   console.log("hasStuds,", hasStudents)
  // }, [hasStudents])
  // useEffect(() => {
  //   console.log("count,", semesterStatusCount)
  // }, [semesterStatusCount])

  useEffect(() => {
    setDataCurrentWeek({
      datasets: [
        {
          //default semesterStatusCount is: [0, 0, 0, 0, 0]
          label: "Anz. Lernende",
          pointStyle: "rect",
          data:
            hasStudents && semesterStatusCount ? semesterStatusCount
              : [0, 0, 0, 0, 1],
          fill: false,
          data: semesterStatusCount,
          backgroundColor:
            [
              theme.palette.levels.red,
              theme.palette.levels.orange,
              theme.palette.levels.yellow,
              theme.palette.levels.lightGreen,
              theme.palette.levels.green,
            ],
          borderWidth: 2,
          borderColor: theme.palette.background.paper,
          hoverBorderColor: theme.palette.background.paper,
          hoverOffset: 25,
          cutout: "75%",
          spacing: percentageDone === 0 ? 1 : 5,
        },
      ],
      labels: statusList,
    });
  }, [semesterStatusCount]);

  //styles the doughnut https://www.chartjs.org/docs/latest/charts/doughnut.html#doughnut
  const options = {
    animation: true,
    layout: { padding: 0 },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        // labels: {
        //   usePointStyle: true,
        // },
        display: false,
        // title: {
        //   color: theme.palette.levels.green,
        // },
        // position: "bottom",
        // align: "start",
        // onClick: null,
        // color: appInDarkMode ? "black" : "black",
        // // marginBottom: "0.2em",
      },
      tooltip: {
        callbacks: {
          beforeLabel: function () {
            let label = "Anz. Lernende";
            return label;
          },
          labelTextColor: function () {
            return appInDarkMode ? theme.palette.levels.white : theme.palette.levels.dark;
          },
        },
        enabled: true,
        position: "average",
      },

    },
  };


  return (
    <>
      <Box
        ref={containerRef}
        sx={{
          marginTop: "-1.5em",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",

          height: { xs: 200 },
          width: "100%",
          overflow: "hidden",
          // backgroundColor: "red"
        }}
      >
        {/* Text component */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 1,
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DistributionText width={width * 0.70} height={height * 0.70} hasStudents={hasStudents} />
        </div>

        {/* Chart component */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 2,
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DoghnutChart
            dataCurrentWeek={dataCurrentWeek}
            options={options}
            width={width}
            height={height}
          />
        </div>
      </Box>
      <>{classAssignments && (
        <>
          <AssignmentTable classAssignments={classAssignments} />
        </>)}
      </>
    </>
  );
};
