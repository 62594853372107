import { Container, Grid } from "@mui/material";
import { memo } from "react";
// import SettingFunctions from "../_4GeneralHelpers/Setting/SettingFunctions";
import RegisterSubject from "./_RegisterHelpers/RegisterSubject";
import RegisterCourse from "./_RegisterHelpers/RegisterCourse";
import RegisterSemester from "./_RegisterHelpers/RegisterSemester";
import RegisterCoreCourse from "./_RegisterHelpers/RegisterCoreCourse";
import RegisterTeacherAdmin from "./_RegisterHelpers/RegisterTeacherAdmin";
import RegisterExamType from "./_RegisterHelpers/RegisterExamType";
import SettingUsersView from "./_RegisterHelpers/SettingUsersView";
import RegisterHoliday from "./_RegisterHelpers/RegisterHoliday";
import { PageTemplate } from "../0_PageTemplate/PageTemplate";

function AdminPage() {
  return (
    <div className="content" sx={{ clear: "left" }}>
      <PageTemplate type={"admin"}>
        <Container maxWidth="lg">
          <Grid
            container
            // marginTop={3}
            // paddingTop={3}
            spacing={3}
            sx={{ justifyContent: "space-between" }}
          >
            <Grid item lg={6} md={6} xs={12}>
              <RegisterSubject />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <RegisterCourse />
            </Grid>
          </Grid>

          <Grid
            container
            paddingTop={3}
            spacing={3}
            sx={{ justifyContent: "space-between" }}
          >
            <Grid item lg={6} md={6} xs={12}>
              <RegisterCoreCourse />
            </Grid>
          </Grid>

          <Grid
            container
            paddingTop={3}
            spacing={3}
            sx={{ justifyContent: "space-between" }}
          >
            <Grid item lg={6} md={6} xs={12}>
              <RegisterExamType />
            </Grid>
          </Grid>

          <Grid
            container
            paddingTop={3}
            spacing={3}
            sx={{ justifyContent: "space-between" }}
          >
            <Grid item lg={6} md={6} xs={12}>
              <RegisterSemester />
            </Grid>

            <Grid item lg={6} md={6} xs={12}>
              <RegisterHoliday />
            </Grid>
          </Grid>

          <Grid
            container
            paddingTop={3}
            spacing={3}
            sx={{ justifyContent: "space-between" }}
          >
            <Grid item lg={6} md={6} xs={12}>
              <RegisterTeacherAdmin />
            </Grid>
          </Grid>

          <Grid
            container
            paddingTop={3}
            spacing={3}
            sx={{ justifyContent: "space-between" }}
          >
            <Grid item lg={12} md={12} xs={12}>
              <SettingUsersView />
            </Grid>
          </Grid>
        </Container>
      </PageTemplate>
    </div>
  );
}
export default memo(AdminPage);
