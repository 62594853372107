import {
  Add,
  AssignmentInd,
  FlipCameraAndroid,
  Group,
  Info,
  Person,
  Visibility,
  HourglassEmpty,
  HourglassFull,
} from "@mui/icons-material";
import { IconButton, Popover, Tooltip, Typography } from "@mui/material";
import React, { memo, useState } from "react";

import { useTheme } from "@mui/styles";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import StyledTooltip from "../../../_4GeneralHelpers/2_Tooltips/StyledTooltip";
import CustomWidthTooltip from "../../../_4GeneralHelpers/2_Tooltips/CustomWidthTooltip";
import AssignmentAddPopoverForm from "../../../_5Pages/4DashboardPage/DashboardParts/AddAssignmentBox/AssignmentAddPopoverForm";
import longText from "../../../_5Pages/4DashboardPage/DashboardParts/Workboard/_WorkboardHelpers/LongText";

function BoxHeader({
  title,
  flipCard,
  options,
  flipAssignment,
  setFlipAssignment,
  flipTeacherAssignment,
  setFlipTeacherAssignment,
  workloadFlip,
  setWorkloadFlip,
}) {
  const {
    showDetail,
    setShowDetail,
    showCourseAssignmentOnly,
    setShowCourseAssignmentOnly,
    timeBudget,
    setTimeBudget,
    setPassKey
  } = useAppContext();

  const [showClass, setShowClass] = useState(false);
  const theme = useTheme();

  //popover functionalities for adding and for status selection:

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const flipAssignmentView = () => {
    setFlipAssignment(!flipAssignment);
  };

  const flipTeacherView = () => {
    setFlipTeacherAssignment(!flipTeacherAssignment);
  };
  const workloadFlipView = () => {
    setWorkloadFlip(!workloadFlip);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const toggleShowDetail = () => {
    setShowDetail(!showDetail);
  };

  const toggleShowClass = () => {
    setShowClass(!showClass);
  };
  const toggleShowCourseAssignments = () => {
    setShowCourseAssignmentOnly(!showCourseAssignmentOnly);
  };

  const toggleTimeBudget = () => {
    setPassKey(true)
    setTimeBudget(!timeBudget);
  };

  return (
    <>
      <Typography variant="h4" color={theme.palette.text.blue} noWrap={true}>
        {title}

        {options !== undefined && options.includes("info") ? (
          <CustomWidthTooltip title={longText}>
            <Info
              aria-label="zusatzinformation"
              fontSize="small"
              sx={{ margin: "-1em 0.1em 0 0" }}
            />
          </CustomWidthTooltip>
        ) : (
          <></>
        )}

        {options !== undefined && options.includes("classview") ? (
          <Tooltip
            title={showClass ? "Mein Überblick" : "Klassenüberblick"}
            arrow
          >
            <IconButton
              aria-label="settings"
              edge="end"
              size="small"
              // sx={{ float: "right", margin: "0 0.1em 0 0" }}
              sx={{ float: "right", margin: "0 0.1em 0 0" }}
              onClick={() => {
                toggleShowClass();
              }}
            >
              {showClass ? (
                <Person style={{ color: theme.palette.icon.primary }} />
              ) : (
                <Group style={{ color: theme.palette.icon.primary }} />
              )}
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
        {options !== undefined && options.includes("flip") ? (
          <StyledTooltip
            title={"Ansicht wechseln"}
            placement="bottom-start"
            arrow
          >
            <IconButton
              aria-label="settings"
              edge="end"
              size="small"
              sx={{ float: "right", margin: "0 0.1em 0 0" }}
              onClick={flipCard}
            >
              <FlipCameraAndroid
                style={{ color: theme.palette.icon.primary }}
              />
            </IconButton>
          </StyledTooltip>
        ) : (
          <></>
        )}

        {options !== undefined && options.includes("flipAssignment") && (
          <StyledTooltip
            title={
              flipAssignment
                ? "Assignment hinzufügen"
                : "Zusatzassignments verwalten?"
            }
            placement="bottom-start"
            arrow
          >
            <IconButton
              aria-label="settings"
              edge="end"
              size="small"
              sx={{ float: "right", margin: "0 0.1em 0 0" }}
              onClick={flipAssignmentView}
            >
              <FlipCameraAndroid
                style={{ color: theme.palette.icon.primary }}
              />
            </IconButton>
          </StyledTooltip>
        )}
        {options !== undefined && options.includes("teacherFlip") && (
          <StyledTooltip
            title={
              flipTeacherAssignment
                ? "Zusatzassignments sehen"
                : "Zurück zur Klasse"
            }
            placement="bottom-start"
            arrow
          >
            <IconButton
              aria-label="settings"
              edge="end"
              size="small"
              sx={{ float: "right", margin: "0 0.1em 0 0" }}
              onClick={flipTeacherView}
            >
              <FlipCameraAndroid
                style={{ color: theme.palette.icon.primary }}
              />
            </IconButton>
          </StyledTooltip>
        )}

        {options !== undefined && options.includes("add") ? (
          <>
            <StyledTooltip
              title={"Assignment hinzufügen"}
              placement="bottom-start"
              arrow
            >
              <IconButton
                aria-label="settings"
                edge="end"
                size="small"
                sx={{
                  float: "right",
                  margin: "0 0.1em 0 0",
                  color: theme.palette.icon.primary,
                }}
                onClick={
                  // handleOpenAddDialog
                  handleClick
                }
              >
                <Add />
              </IconButton>
            </StyledTooltip>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                // will open the popover -100 pixels to the right of the left margin
                horizontal: -425,
              }}
            >
              {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
              <AssignmentAddPopoverForm handleClose={handleClose} />
            </Popover>
          </>
        ) : (
          <></>
        )}


        {options !== undefined && options.includes("detail") ? (
          <IconButton
            aria-label="settings"
            edge="end"
            size="small"
            sx={{ margin: "0 0.1em 0 0" }}
            onClick={() => {
              toggleShowDetail();
            }}
          >
            {showDetail ? (
              <CustomWidthTooltip title={longText} placement="bottom" arrow>
                <Visibility style={{ color: theme.palette.icon.primary }} />
              </CustomWidthTooltip>
            ) : (
              <></>
            )}
          </IconButton>
        ) : (
          <></>
        )}
        {options !== undefined && options.includes("workloadFlip") && (
          <StyledTooltip
            title={
              workloadFlip
                ? "Wochenbelastung sehen"
                : "Jahresbelastung sehen"
            }
            placement="bottom-start"
            arrow
          >
            <IconButton
              aria-label="settings"
              edge="end"
              size="small"
              sx={{ float: "right", margin: "0 0.1em 0 0" }}
              onClick={workloadFlipView}
            >
              <FlipCameraAndroid
                style={{ color: theme.palette.icon.primary }}
              />
            </IconButton>
          </StyledTooltip>
        )}
        {options !== undefined && options.includes("courseView") ? (
          <StyledTooltip
            title={
              showCourseAssignmentOnly
                ? "andere Kurse einblenden"
                : "nur Kurs anzeigen"
            }
            placement="bottom-start"
            arrow
          >
            <IconButton
              aria-label="settings"
              edge="end"
              size="small"
              sx={{ float: "right", margin: "0 0.1em 0 0" }}
              onClick={toggleShowCourseAssignments}
            >
              {showCourseAssignmentOnly ? (
                <AssignmentInd style={{ color: theme.palette.icon.primary }} />
              ) : (
                <AssignmentInd
                  style={{ color: theme.palette.icon.secondary }}
                />
              )}
            </IconButton>
          </StyledTooltip>
        ) : (
          <></>
        )}
        {options !== undefined && options.includes("personalWeek") ? (
          <StyledTooltip
            title={
              timeBudget
                ? "Zeitbudget der Klasse anzeigen"
                : "Persönliches Zeitbudget anzeigen"
            }
            placement="bottom-start"
            arrow
          >
            <IconButton
              aria-label="settings"
              edge="end"
              size="small"
              sx={{ float: "right", margin: "0 0.1em 0 0" }}
              onClick={toggleTimeBudget}
            >
              {timeBudget ? (
                <HourglassEmpty
                  style={{ color: theme.palette.icon.secondary }}
                />
              ) : (
                <HourglassFull style={{ color: theme.palette.icon.primary }} />
              )}
            </IconButton>
          </StyledTooltip>
        ) : (
          <></>
        )}

      </Typography>
    </>
  );
}
export default memo(BoxHeader);
