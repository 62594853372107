import React, { memo } from "react";
import { CustomTextField } from "../../../../../_3HelperFunctions/CustomTextField";
import ErrorBox from "./ErrorBox";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";
import { useTheme } from "styled-components";

function CountingFactorBoxComponent({
  restricted,
  countingFactor,
  countingFactorErr,
  change,
}) {
  // console.log("count...", countingFactor)
  const theme = useTheme()
  const {appInDarkMode} = useAppContext()
  return (
    <>
      <CustomTextField
        id="countingFactor"
        type="number"
        disabled={restricted}
        onChange={change}
        value={countingFactor}
        label="Gewicht"
        inputProps={{
          style: { fontSize: "0.9em" },
          step: 0.1, // Set the step size to 0.1
        }}
        appindarkmode={appInDarkMode.toString()}
        theme={theme}
      />
      <ErrorBox error={countingFactorErr} />
    </>
  );
}

export default memo(CountingFactorBoxComponent);
