import { Typography } from "@mui/material";
import { WeekPlannerView } from "../../0_PageTemplate/2_PageRibbon/_RibbonHelpers/WeekPlannerView";
import { useState } from "react";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

export const DateOverviewBox = () => {
  const [semesterWeek, setSemesterWeek] = useState(0);
  const { isMobile, isSmallScreen } = useAppContext()

  return (
    // This is the date overview item
    <div style={{
      // backgroundColor: "red"
    }}>
      <div
        style={{
          width: "110%",
          whiteSpace: "nowrap", // Prevent content from wrapping
          textAlign: isMobile ? "center" : "", // Center along the x-axis
        }}
      >
        <Typography variant="h6" color="primary">
          Semesterwoche {semesterWeek}
        </Typography>
      </div>
      <WeekPlannerView setSemesterWeek={setSemesterWeek} isSmallScreen={isSmallScreen} isMobile={isMobile} />
    </div>
  );
};
