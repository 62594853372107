async function filterTasksByCourse(course, assignments) {
  // console.log(course, assignments)
  if(course){
  const filteredAssignments = await assignments.filter(
    (assignment) => assignment.course_id === course.id
  );

  return filteredAssignments;} else {
    return []
  }
}
exports.filterTasksByCourse = filterTasksByCourse;
