import { memo } from "react";
import ChangeButtons from "../../../_4GeneralHelpers/2_Buttons/ChangeButtons";
import { RibbonDetail } from "./_RibbonHelpers/RibbonDetail";
import ChangeSemesterButtons from "../../../_4GeneralHelpers/2_Buttons/ChangeSemesterButtons";
import DisabledButtons from "../../../_4GeneralHelpers/2_Buttons/DisabledButtons";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import RibbonCarousel from "./_RibbonHelpers/RibbonCarousel";

function MenuRibbon({ type }) {
  const {appInDarkMode, isSmallScreen} = useAppContext()

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        height: "6em",
        backgroundColor: appInDarkMode?"":"#ffffff",
        // Adjust padding for additional space around the buttons
        // padding: "0.5em",
      }}
    >
      {type === "dashboard" ? (
        <ChangeButtons page="dashboard">
          {isSmallScreen?<RibbonCarousel type={type} />:<RibbonDetail type={type}/>}
        </ChangeButtons>
      ) : type === "examView" ? (
        <ChangeSemesterButtons>
          {isSmallScreen?<RibbonCarousel type={type} />:<RibbonDetail type={type}/>}
        </ChangeSemesterButtons>
      ) : (
        <DisabledButtons>
          {isSmallScreen?<RibbonCarousel type={type} />:<RibbonDetail type={type}/>}
        </DisabledButtons>
      )}
    </div>
  );
}
export default memo(MenuRibbon);
