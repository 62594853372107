function filterOutByShowAssignments(
  assignment,
  showExams,
  showHomeworks,
  showPersonals,
) {
  return (assignment.type === "exam" && !showExams) ||
    (assignment.type === "homework" && !showHomeworks) ||
    (assignment.type === "personal" && !showPersonals)
};

exports.filterOutByShowAssignments = filterOutByShowAssignments;
