import React, { memo, useState, useEffect, useRef } from "react";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { useTheme } from "@mui/material";
import moment from "moment";
import "moment/locale/de";
import workloadService from "../../../../_6Services/workload.service";
import WorkloadFitsInComponent from "./WorkloadFitsInComponent";
import WorkloadChartRomanComponent from "./WorkloadChartRomanComponent";
import { calculateFitsInWeek } from "./_WorkloadChartHelpers/calculateFitsInWeek";
import { calculateWorkloadMinutesChart } from "./_WorkloadChartHelpers/calculateWorkloadMinutesChart";
import MiddleBox from "../../../../_4GeneralHelpers/1_Boxes/MiddleBox";

import { getRelevantAssignmentsByDay } from "./_WorkloadChartHelpers/getRelevantAssignmentsByDay";
import { updateRelevantClassAssignmentsForChart } from "./_WorkloadChartHelpers/updateRelevantClassAssignmentsForChart";
import BoxHeader from "../../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";
import { createTeacherName } from "../../../../_3HelperFunctions/_NameHelpers/nameHelpers";
moment.locale("de");

function WorkloadChartRoman({ workloadFlip, setWorkloadFlip }) {
  const [calibratingWorkload, setCalibratingWorkload] = useState(false);

  const theme = useTheme();
  const {
    currentUser,
    isAStudent,
    isALeader,
    relevantClassAssignments,
    relevantUserAssignments,
    selectedCourse,
    referenceDate,
    stopReRenderProcess,
    setStopWorkloadChartReRender,
    workloadMinutes,
    setWorkloadMinutes,
    suggestedWorkloadMinutes,
    setSuggestedWorkloadMinutes,
    setPersonalNumberOfDoesNotFit,
    setClassNumberOfDoesNotFit,
    verifyWorkloadChart,
    setVerifyWorkloadChart,
    showCourseAssignmentOnly,
    timeBudget,
    isMobile,
  } = useAppContext();

  // value based on workloadminutesTotal (this should also include personal assignments!)
  const [maxMinutesDay, setMaxMinutesDay] = useState(undefined);
  const [personalWorkWeekData, setPersonalWorkWeekData] = useState(
    // timeBudget && currentUser?.personalWorkWeek
    //   ? [
    //     currentUser.personalWorkWeek.working_minutes_monday,
    //     currentUser.personalWorkWeek.working_minutes_tuesday,
    //     currentUser.personalWorkWeek.working_minutes_wednesday,
    //     currentUser.personalWorkWeek.working_minutes_thursday,
    //     currentUser.personalWorkWeek.working_minutes_friday,
    //     currentUser.personalWorkWeek.working_minutes_saturday,
    //     currentUser.personalWorkWeek.working_minutes_sunday,
    //   ]
    //   : 
    [
      //define a class workWeek!
      60, 60, 60, 60, 60, 60, 60,
    ]
  );
  // useEffect(() => {
  //   console.log(personalWorkWeekData)
  // }, [personalWorkWeekData])

  //calculate the workWeekDataChart
  useEffect(() => {
    if (timeBudget) {
      setPersonalWorkWeekData([
        currentUser.personalWorkWeek.working_minutes_monday,
        currentUser.personalWorkWeek.working_minutes_tuesday,
        currentUser.personalWorkWeek.working_minutes_wednesday,
        currentUser.personalWorkWeek.working_minutes_thursday,
        currentUser.personalWorkWeek.working_minutes_friday,
        currentUser.personalWorkWeek.working_minutes_saturday,
        currentUser.personalWorkWeek.working_minutes_sunday,
      ]);
    } else {
      setPersonalWorkWeekData([
        //define a class workWeek!
        60, 60, 60, 60, 60, 60, 60,
      ]);
    }
  }, [timeBudget]);
  const personalWorkWeekDataChart = Array.from(
    { length: 21 },
    (_, i) => personalWorkWeekData[Math.floor(i / 3)]
  );

  const chartRef = useRef(null);

  const [dataCurrentWeek, setDataCurrentWeek] = useState({
    datasets: [],
    labels: [],
  });

  /**
   * main Freezer
   */

  useEffect(() => {
    // console.log("general: ", stopWorkloadChartReRender, "verify: ", verifyWorkloadChart, "calibrate: ", calibratingWorkload)
    // console.log("verify: ", verifyWorkloadChart, " calibrate: ", calibratingWorkload)
    if (
      verifyWorkloadChart !== undefined &&
      !verifyWorkloadChart &&
      !calibratingWorkload
    ) {
      // console.log("workload freeze process is over");
      setStopWorkloadChartReRender(false);
    }
  }, [verifyWorkloadChart, calibratingWorkload]);

  /**
   * calculate the maximum background level for visualization
   * (this does not calculate the Workload Minutes themselves!)
   */
  useEffect(() => {
    // console.log(
    //   workloadMinutes ===undefined
    // );
    if (workloadMinutes && suggestedWorkloadMinutes && personalWorkWeekData) {
      // console.log(workloadMinutes);
      // console.log(suggestedWorkloadMinutes);
      const maximumWorkloadMinutes =
        workloadService.calculateMaximumWorkloadMinutes(
          personalWorkWeekData,
          workloadMinutes,
          suggestedWorkloadMinutes
        );

      setMaxMinutesDay(maximumWorkloadMinutes);
    }
  }, [workloadMinutes, suggestedWorkloadMinutes]);

  /**
   * Calculate the workload for the class AND individual AND Teacher!
   */
  //unlock calibration
  useEffect(() => {
    const unlock = async () => {
      // console.log("We are allowed to calculate the graphs");
      await setCalibratingWorkload(true);
    };
    // console.log("verify: ", verifyWorkloadChart)
    if (verifyWorkloadChart) {
      unlock();
    }
  }, [verifyWorkloadChart]);

  useEffect(() => {
    // console.log("calibrate: ", calibratingWorkload, " relevant:", relevantUserAssignments == undefined)
    if (
      calibratingWorkload &&
      relevantUserAssignments &&
      relevantClassAssignments
    ) {
      calibrateWorkloadData(); //sets calibratingWorkload to false once process is finished
    }
  }, [calibratingWorkload, relevantUserAssignments, relevantClassAssignments]);

  const calibrateWorkloadData = async () => {
    await setVerifyWorkloadChart(false); // Change the locks
    // console.log(relevantUserAssignments)
    const relevantPersonalWeekAssignments = relevantUserAssignments;

    let relevantClassWeekAssignments;
    if (!isALeader) {
      relevantClassWeekAssignments = relevantPersonalWeekAssignments;
    } else {
      //prepare the relevant classWeekAssignments
      relevantClassWeekAssignments =
        await updateRelevantClassAssignmentsForChart(
          relevantClassAssignments,
          showCourseAssignmentOnly,
          selectedCourse
        );
    }
    // console.log("class assignments: ", relevantClassWeekAssignments);
    /**
     * Get Dictionaries
     */
    // Get dict of relevant assignments by day (0: monday, 6: sunday)

    const {
      relevantPersonalAssignmentsByDay,
      relevantClassAssignmentsByDay,
      relevantExtraDateAssignmentsByDay,
    } = await getRelevantAssignmentsByDay(
      relevantPersonalWeekAssignments,
      relevantClassWeekAssignments,
      referenceDate
    );
    // console.log(relevantPersonalAssignmentsByDay)
    /**
     * calculate WorkloadMinuts
     */
    //personal chart
    const personalWorkloadMinutes = await calculateWorkloadMinutesChart(
      referenceDate,
      relevantPersonalAssignmentsByDay,
      "personal",
      relevantExtraDateAssignmentsByDay
    );
    // console.log("minutes", personalWorkloadMinutes)
    await setWorkloadMinutes(personalWorkloadMinutes);

    //class chart
    const classWorkloadMinutes = await calculateWorkloadMinutesChart(
      referenceDate,
      relevantClassAssignmentsByDay,
      "class"
    );
    // console.log(classWorkloadMinutes);
    await setSuggestedWorkloadMinutes(classWorkloadMinutes);

    /**
     * Calculate how many times the current workload does not fit into the planned time
     */
    await setPersonalNumberOfDoesNotFit(
      await calculateFitsInWeek(
        referenceDate,
        relevantPersonalAssignmentsByDay,
        relevantExtraDateAssignmentsByDay,
        "personal",
        personalWorkWeekData
      )
    );

    await setClassNumberOfDoesNotFit(
      await calculateFitsInWeek(
        referenceDate,
        isAStudent
          ? relevantPersonalAssignmentsByDay
          : relevantClassAssignmentsByDay,
        relevantExtraDateAssignmentsByDay,
        "class",
        personalWorkWeekData
      )
    );

    // await console.log("calibrating is over")

    await setCalibratingWorkload(false);
  };

  /**
   * set the weekdata for the current week
   * once all the relevant elements have been calculated
   */
  useEffect(() => {
    // console.log(workloadMinutes)
    const setData = async () => {
      // console.log("we rerender with stop = ", stopReRenderProcess)
      await setDataCurrentWeek({
        datasets: [
          {
            // type: "line",
            data: !stopReRenderProcess
              ? workloadMinutes
              : Array.from({ length: 20 }, () => 0),
            fill: false,

            // stepped: 'middle',
            // backgroundColor: createBackgroundGradient(chart.ctx, chart.chartArea),
            // borderColor: createBackgroundGradient(chart.ctx, chart.chartArea),
            borderColor: theme.palette.levels.blue,
            backgroundColor: theme.palette.background.paper,

            borderWidth: 2,
            borderDash: [3, 3],
            radius: 0,
            pointHoverRadius: 0,
            label: "persönliche Zeitbelastung",
            labelColor: "rgba(0,0,0,1)",
          },
          {
            type: "line",
            data: !stopReRenderProcess
              ? suggestedWorkloadMinutes
              : Array.from({ length: 20 }, () => 0),
            fill: false,
            // stepped: 'middle',
            borderColor: theme.palette.levels.blue,
            backgroundColor: theme.palette.levels.blue,
            borderWidth: 2,

            radius: 0,
            pointHoverRadius: 0,
            label: isAStudent
              ? "Geschätzte Belastung der Klasse"
              : "Zeitliche Belastung der Klasse",
            labelColor: theme.palette.levels.blue,
          },
          {
            data: personalWorkWeekDataChart,
            fill: true,
            // stepped: 'middle',
            // backgroundColor: createBackgroundGradient(chart.ctx, chart.chartArea),
            borderColor: "rgba(46, 125, 50, 0.4)",
            backgroundColor: "rgba(46, 125, 50, 0.4)",
            borderWidth: 2,
            radius: 0,
            pointHoverRadius: 0,
            label: isAStudent
              ? "Persönliches Zeitbudget"
              : "Zeitbudget der Klasse",
          },
        ],
        labels: [
          "",
          moment(moment(referenceDate).startOf("isoWeek")).format("dddd"),
          "",
          "",
          moment(moment(referenceDate).startOf("isoWeek"))
            .add(1, "days")
            .format("dddd"),
          "",
          "",
          moment(moment(referenceDate).startOf("isoWeek"))
            .add(2, "days")
            .format("dddd"),
          "",
          "",
          moment(moment(referenceDate).startOf("isoWeek"))
            .add(3, "days")
            .format("dddd"),
          "",
          "",
          moment(moment(referenceDate).startOf("isoWeek"))
            .add(4, "days")
            .format("dddd"),
          "",
          "",
          moment(moment(referenceDate).startOf("isoWeek"))
            .add(5, "days")
            .format("dddd"),
          "",
          "",
          moment(moment(referenceDate).startOf("isoWeek"))
            .add(6, "days")
            .format("dddd"),
          "",
        ],
      });
    };
    // if (!stopReRenderProcess) {
    setData();
    // }
  }, [stopReRenderProcess]);

  //graph visualizations:
  const options = {
    maintainAspectRatio: false,
    animations: {
      radius: {
        duration: 100,
        easing: "linear",
        loop: (context) => context.active,
      },
    },
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          color: theme.palette.primary.main,
          text: "täglicher Zeitaufwand [Minuten]",
        },
        beginAtZero: true,
        min: 0,
        max: maxMinutesDay < 180 ? 180 : maxMinutesDay,
        // max: 180,
        grid: {
          display: true,
        },
        ticks: {
          display: true,
          stepSize: 60,
          color: theme.palette.primary.main,
        },
      },
      x: {
        display: true,
        grid: {
          display: false,
        },
        stacked: false,
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 0,
          color: theme.palette.primary.main,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
        labels: {
          boxWidth: 20, // Adjust the width as needed
        },
        title: {
          color: "black",
        },
        position: "top",
        align: isMobile?"end":"start",
        // onClick: null,
        color: theme.palette.primary.main,
        // marginBottom: "0.2em",
      },
      tooltip: {
        enabled: true,
        position: "nearest",
        //external: externalTooltipHandler
      },
    },
    elements: {
      point: {
        radius: 4,
      },
    },
    interaction: {
      intersect: false,
      axis: "x",
    },
  };

  return (
    <>
      <BoxHeader
        title={
          isAStudent
            ? "Dein Workload"
            : selectedCourse && showCourseAssignmentOnly
              ? `Workload im Kurs ${selectedCourse?.name ? selectedCourse.name : ""}`
              : selectedCourse
                ? `Workload der Klasse ${selectedCourse?.name ? createTeacherName(selectedCourse.name) : ""}`
                : `Arbeitsbelastung Klasse`
        }
        options={isMobile ? ["personalWeek"] : ["personalWeek", "workloadFlip"]}
        workloadFlip={workloadFlip}
        setWorkloadFlip={setWorkloadFlip}
      />

      <MiddleBox>
        <div style={{ height: "95%" }}>
          {/* Adjust the height as needed */}
          <WorkloadChartRomanComponent
            chartRef={chartRef}
            dataCurrentWeek={dataCurrentWeek}
            options={options}
          />
          {!calibratingWorkload && <WorkloadFitsInComponent />}
        </div>
      </MiddleBox>
    </>
  );
}
export default memo(WorkloadChartRoman);
