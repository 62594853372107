import {Grid, Typography } from "@mui/material";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

export const UploadTitle = () => {
  const { isMobile } = useAppContext();
  // console.log(currentUser)

  return (
    // This is the date overview item
    <>
      <div
        style={{
          width: "110%",
          whiteSpace: "nowrap", // Prevent content from wrapping
          textAlign: isMobile?"center":"", // Center along the x-axis

        }}
      >
        <Typography variant="h6" color="primary">
          schulNetz
        </Typography>
      </div>
      <>
        <Grid item sm={12}>
          <div
            style={{
              width: "110%",
              whiteSpace: "nowrap", // Prevent content from wrapping
              textAlign: isMobile?"center":"", // Center along the x-axis
            }}
          >
            <Typography color="primary" variant="h4" fontWeight={"bold"}>
              Importieren
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <div
            style={{
              width: "110%",
              whiteSpace: "nowrap", // Prevent content from wrapping
              textAlign: isMobile?"center":"", // Center along the x-axis
            }}
          > 
              <Typography color="primary" variant="caption">
                über Excel File
              </Typography>
          </div>
        </Grid>
      </>
    </>
  );
};
