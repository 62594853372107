import React from "react";
import { Box } from "@mui/material";

const WideBox = ({ children }) => (
  <Box
    marginTop={2}
    sx={{
      width: "100%",
      maxHeight: 500,
      // height: { xs: 275, sm: 275, xl: 275 },
      position: "relative",
      overflowY: "auto",
      overflowX: "auto", // Hide x-axis scrollbar
      "&::-webkit-scrollbar": {
        width: "0px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "your-desired-color", // Set your desired color
        borderRadius: "6px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent", // or any background color
      },
    }}
  >
    {children}
  </Box>
);

export default WideBox;
