import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { IconButton, Tooltip, useMediaQuery } from "@mui/material";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import WeekNavigation from "./WeekNavigation";
import { useTheme } from "@mui/styles";

function ChangeSemesterButtons({ children }) {
  const {
    referenceDate,
    currentSemesterStartDate,
    setCurrentSemesterStartDate,
    setCurrentSemesterEndDate,
    schoolYearData,
  } = useAppContext();
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [toggleBackward, setToggleBackward] = useState(
    schoolYearData.datesFirstSemester &&
    moment(new Date(referenceDate))
      .startOf("isoWeek")
      .subtract(6, "days")
      .isAfter(moment(new Date(schoolYearData.datesFirstSemester[0])))
  );
  const [toggleForward, setToggleForward] = useState(
    schoolYearData.datesSecondSemester &&
    moment(new Date(referenceDate))
      .startOf("isoWeek")
      .add(6, "days")
      .isBefore(moment(new Date(schoolYearData.datesSecondSemester[1])))
  );

  //set toggles
  useEffect(() => {
    if (schoolYearData.datesSecondSemester) {
      currentSemesterStartDate === schoolYearData.datesFirstSemester[0]
        ? setToggleBackward(false)
        : setToggleBackward(true);
      currentSemesterStartDate === schoolYearData.datesSecondSemester[0]
        ? setToggleForward(false)
        : setToggleForward(true);
    }
  }, [currentSemesterStartDate, setCurrentSemesterEndDate, schoolYearData]);

  const onClickChangeCurrentDate = (key) => {
    // let newSemester = "";

    if (key === "previous") {
      // console.log(currentSemesterStartDate);
      // console.log("we go here")
      setCurrentSemesterStartDate(schoolYearData.datesFirstSemester[0]);
      setCurrentSemesterEndDate(schoolYearData.datesFirstSemester[1]);
      // console.log(currentSemesterStartDate);
    } else if (key === "next") {
      // console.log("we go there")
      // console.log(currentSemesterStartDate);
      setCurrentSemesterStartDate(schoolYearData.datesSecondSemester[0]);
      setCurrentSemesterEndDate(schoolYearData.datesSecondSemester[1]);
    }
  };

  return (
    <div style={{
      display: "flex", width: "100%",
      alignItems: "center",
    }}>
      <div>
        <WeekNavigation
          title={
            toggleBackward ? "letztes Semester" : "kein Semester vorhanden"
          }
          disabled={!toggleBackward}
          onClick={() => onClickChangeCurrentDate("previous")}
          arrowLeft={true}
        />
      </div>
      <div
        style={{
          flex: "1",
          marginLeft: isSmallScreen ? "5%" : "", // Adjust margin as needed for space around the content
          marginRight: isSmallScreen ? "5%" : "-10%", // Adjust margin as needed for space around the content
          //         flex: "0 0 78%", // Allow the children to take up only 98% of the width
          // background: "green",
          width: isSmallScreen ? "60%" : ""

        }}
      >
        {children}
      </div>
      <div>
        <WeekNavigation
          title={
            toggleForward ? "nächstes Semester" : "kein Semester vorhanden"
          }
          disabled={!toggleForward}
          onClick={() => onClickChangeCurrentDate("next")}
        />
      </div>
    </div>
  );
}
export default memo(ChangeSemesterButtons);
