import { memo, useState } from "react";

import { Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import userService from "../../../_6Services/user.service";
import CoreCourseSelection from "../../../_5Pages/3ProfilePage/_CourseSelectionHelpers/CoreCourseSelection";
import CourseSelection from "../../../_5Pages/3ProfilePage/_CourseSelectionHelpers/CourseSelection";
import ButtonGrid from "../../../_5Pages/3ProfilePage/_CourseSelectionHelpers/ButtonGrid";
import ContentBox from "../../1_Boxes/ContentBox";
import FeedbackMessage from "../../../_5Pages/2LoginAndAuthentication/Login/LoginHelpers/FeedbackMessage";
import createUpdateObject from "../../../_5Pages/3ProfilePage/_CourseSelectionHelpers/createUpdateObject";
import MixedCourseSelection from "./MixedCourseSelection";
import activationFormValidation from "../../../_5Pages/3ProfilePage/_CourseSelectionHelpers/activationFormValidation";
import authService from "../../../_6Services/auth.service";

function CourseActivation({ handleClose }) {
  const {
    isAStudent,
    currentUser,
    setCurrentUser,
    userCourses,
    // setUserCourses,
    husibuechCourses,
    isAuthenticated,
    husibuechUserKurse,
    setHusibuechUserKurse,
    husibuechCoreCourses,
    // setMyCoreCourses,
    myCoreCourses,
    setSchoolYearLoading,
    setShowActivationForm,
    // setPassKey,
    setUpdateCurrentUserData
  } = useAppContext();
  const [change, setChange] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);

  const [message, setMessage] = useState("");
  const [saveColor, setSaveColor] = useState("primary");

  const [klasse, setKlasse] = useState(undefined);
  const [userKlasseErr, setUserKlasseErr] = useState([]);

  const [zusatzFaecher, setZusatzfaecher] = useState([]);
  const [abbreviation, setAbbreviation] = useState("");

  const onChangeAbbreviation = (e) => {
    if (e.target.value.length < 3) {
      setChange(true);
      setAbbreviation(e.target.value);
    }
  };


  /**
   * Set the views coreCourses
   */
  const [husibuechUserKlasseRegistered, setHusibuechUserKlasseRegistered] =
    useState(
      husibuechCoreCourses?.filter(
        (klasse) =>
          myCoreCourses?.filter((coreCourse) => coreCourse.id === klasse.id)
            .length > 0
      )
    );
  const [husibuechUserKlasseFiltered, setHusibuechUserKlasseFiltered] =
    useState(
      husibuechCoreCourses?.filter(
        (klasse) =>
          !myCoreCourses?.filter((coreCourse) => coreCourse.id === klasse.id)
            .length > 0
      )
    );
  // console.log(husibuechCoreCourses)

  /**
   * Set the views courses
   */
  const [husibuechUserKurseRegistered, setHusibuechUserKurseRegistered] = //get the courses that the user has already registered to
    useState(
      husibuechCourses?.filter(
        (kurs) =>
          userCourses?.filter((course) => course.id === kurs.id).length > 0
      )
    );
  // console.log("Kurse", husibuechCourses)
  const [husibuechUserKurseFiltered, setHusibuechUserKurseFiltered] = useState(
    isAStudent ?
      husibuechCourses?.filter(
        (kurs) =>
          kurs.mixed_course === 1 && !userCourses?.filter((course) => course.id === kurs.id).length > 0
      ) : husibuechCourses?.filter(
        (kurs) =>
          !userCourses?.filter((course) => course.id === kurs.id).length > 0
      )
  );

  /**
   * Handle Change Methods
   */

  const handleChangeKlasse = (e) => {
    setChange(true);
    setKlasse(e.target.value);
  };

  const handleChangeSelection = (e) => {
    setChange(true);
    const {
      target: { value },
    } = e;
    setZusatzfaecher(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.name.split(",") : value
    );
  };

  /**
   * Handle Selection view change for courses
   */

  useEffect(() => {
    if (
      husibuechCourses?.length > 0 &&
      userCourses?.length > 0
    ) {
      //  filter the courses that are already part of the user's course
      let husibuechKurseCopy
      if (isAStudent) {
        husibuechKurseCopy = husibuechCourses.filter(
          (kurs) =>
            !userCourses.filter((course) => course.id === kurs.id).length > 0 && kurs.mixed_course === 1
        );
      }

      setHusibuechUserKurse(husibuechKurseCopy);
    }
  }, [userCourses, husibuechCourses]);

  /**
   * Set View of Courses
   */
  useEffect(() => {
    const update = async () => {
      //  filter the courses that are already part of the user's course
      let husibuechKurseCopy
      if (isAStudent) {
        husibuechKurseCopy = husibuechCourses.filter(
          (kurs) =>
            kurs.mixed_course === 1 && !userCourses.filter((course) => course.id === kurs.id).length > 0
        );
      } else {
        husibuechKurseCopy = husibuechCourses.filter(
          (kurs) =>
            !userCourses.filter((course) => course.id === kurs.id).length > 0
        );
      }
      // console.log("we changed the view of the filtered Courses")
      /**
       * Deal with registered courses
       */
      let husibuechKurseRegisteredCopy
      if (isAStudent) {

        husibuechKurseRegisteredCopy =
          husibuechCourses.filter(
            (kurs) =>
              userCourses.filter((course) => course.id === kurs.id).length > 0 && kurs.mixed_course === 1
          );
      }
      setHusibuechUserKurseFiltered(husibuechKurseCopy);
      setHusibuechUserKurseRegistered(husibuechKurseRegisteredCopy);
    };
    if (husibuechCourses?.length > 0 && userCourses?.length > 0) {
      update();
    }
  }, [userCourses, husibuechCourses]);

  /**
   * Set View of CoreCourses
   */
  useEffect(() => {
    const update = async () => {
      //  filter the courses that are already part of the user's course

      let husibuechKlasseCopy = husibuechCoreCourses.filter(
        (kurs) =>
          !myCoreCourses?.filter((course) => course.id === kurs.id).length > 0
      );
      // console.log("we changed the view of the filtered Courses", husibuechKlasseCopy)
      /**
       * Deal with registered courses
       */
      let husibuechKlasseRegisteredCopy = husibuechCoreCourses.filter(
        (kurs) =>
          myCoreCourses?.filter((course) => course.id === kurs.id).length > 0
      );

      // console.log("we changed the view of the registered Courses", husibuechKlasseRegisteredCopy)

      setHusibuechUserKlasseFiltered(husibuechKlasseCopy ? husibuechKlasseCopy : []);
      setHusibuechUserKlasseRegistered(husibuechKlasseRegisteredCopy ? husibuechKlasseRegisteredCopy : []);
    };
    if (husibuechCoreCourses?.length > 0 && myCoreCourses?.length >= 0) {
      update();
    }
  }, [myCoreCourses, husibuechCoreCourses]);

  useEffect(() => {
    const update = async () => {
      await setHusibuechUserKurse(husibuechCourses);
    };
    if (isAuthenticated && husibuechCourses?.length && !husibuechUserKurse) {
      update();
    }
  }, [isAuthenticated, husibuechCourses]);

  const rejectAllChanges = async (event) => {
    event.preventDefault();

    //we return to default
    setKlasse([]);
    setZusatzfaecher([]);
    setMessage("");
    setUserKlasseErr([]);
    setLocalLoading(false);
    setChange(false);
  };

  /**
   * save the changes
   */
  const submitAllChanges = async (event) => {
    setLocalLoading(true)
    event.preventDefault();
    const { isValid, errors } = activationFormValidation(
      klasse,
      zusatzFaecher,
      abbreviation,
      currentUser
    );
    setUserKlasseErr(errors);

    if (isValid) {
      //we can work with the update information
      // console.log("success", klasse, zusatzFaecher)
      update();
    } else {
      setTimeout(() => {
        setLocalLoading(false)
        setTimeout(() => {
          setMessage("");
          setUserKlasseErr([]);
          setChange(false);
        }, 3000)
      }, 1000);
    }
  };

  const update = async () => {
    try {
      /**
       * Create the update object
       */
      const updateObject = await createUpdateObject(
        klasse,
        zusatzFaecher,
        abbreviation,
        userCourses,
        currentUser,
      );
      // console.log(updateObject);
      await userService.updateUser("studentactivation", updateObject, currentUser.id);
      //backend update worked successfully
      // await updateFrontendData(updateObject);
      // var editedUser = currentUser;
      // editedUser.abbreviation = abbreviation
      // authService.editCurrentUser(editedUser)
      // setCurrentUser(editedUser)

      //frontend update worked successfully

      setTimeout(async () => {
        setSaveColor("success");
        setMessage("Update erfolgreich");
        setLocalLoading(false);

        setTimeout(async () => {
          await setUpdateCurrentUserData(true)
          setTimeout(async () => {
            await setSchoolYearLoading(true);
            setShowActivationForm(false)
            setSaveColor("primary");
            setMessage("");
            setUserKlasseErr({});
            setChange(false);
          }, 1000);
        }, 1000);
      }, 1500);
    } catch (error) {
      console.log(error)
      if (handleClose) {
        handleClose();
      }
      setLocalLoading(false);
      setSaveColor("warning");
      setMessage("Proleme bei der Verarbeitung.")
      console.log(error);
      setMessage(
        error?.response?.data?.message
          ? error.response.data.message
          : "ungültige Angaben"
      );
      setTimeout(async () => {
        setSaveColor("primary");
        setMessage("");
        setUserKlasseErr({});
        setChange(false);
      }, 4000);
    }
  };

  // console.log("registered: ", husibuechUserKlasseRegistered, "filteredOptions: ", husibuechUserKurseFiltered)

  return (
    <form autoComplete="off" noValidate>
      <ContentBox>
        {!isAStudent &&
          <Grid container style={{ marginBottom: "1em" }}>
            <Grid item style={{ marginTop: "0.5em" }}>
              <TextField
                placeholder="Kürzel (Pf)"
                label="KSB Kürzel"
                fullWidth
                name="abbreviation"
                variant="outlined"
                autoFocus={false}
                value={abbreviation}
                onChange={onChangeAbbreviation}
              />
            </Grid>
          </Grid>
        }
        <Grid container spacing={3}>
          <CoreCourseSelection
            klasse={klasse}
            errors={[]}
            handleChange={handleChangeKlasse}
            selections={husibuechUserKlasseRegistered}
            options={husibuechUserKlasseFiltered}
          />
          {isAStudent ?
            <MixedCourseSelection
              zusatzFaecher={zusatzFaecher}
              handleChange={handleChangeSelection}
              selections={husibuechUserKurseRegistered}
              options={husibuechUserKurseFiltered}
            /> : <CourseSelection
              zusatzFaecher={zusatzFaecher}
              handleChange={handleChangeSelection}
              selections={husibuechUserKurseRegistered}
              options={husibuechUserKurseFiltered}
            />}

          <ButtonGrid
            disabled={!change}
            loading={localLoading}
            submit={submitAllChanges}
            clear={rejectAllChanges}
          />

          {message.length >= 0 && (
            <FeedbackMessage
              style={{ marginTop: "0.75em" }}
              errors={saveColor === "success" ? [] : userKlasseErr} //userKlasseErr is a list
              msg={saveColor !== "success" ? [] : [message]}
              color={saveColor}
            />
          )}
        </Grid>
      </ContentBox>
    </form>
  );
}
export default memo(CourseActivation);
