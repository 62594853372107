import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import CourseBoxComponent from "../CardElements/CourseBoxComponent";
import CountingFactorBoxComponent from "../CardElements/CountingFactorBoxComponent";
import ExamTypeBoxComponent from "../CardElements/ExamTypeBoxComponent";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";
// import ErrorBox from "../CardElements/ErrorBox";

function LineTwo({ type, selectedAssignmentCourse, onChangeAssignmentCourse, countingFactor, onChangeCountingFactor, countingFactorErr, assignmentExamTypeId, onChangeExamType }) {
    const {userCourses} = useAppContext()
    return (

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
        >
            {/* Course */}
            <Grid item xs={4}>
                <CourseBoxComponent
                    restricted={false}
                    selectedAssignmentCourse={selectedAssignmentCourse}
                    userCourses={userCourses}
                    change={onChangeAssignmentCourse}
                />
            </Grid>
            {/* Prüfungsdetails Container */}
            {type === "exam" && (
                <Grid item xs={5}>
                    <Grid container justifyContent="flex-start" spacing={1}>
                        {/* Gewicht */}

                        <Grid item xs={5}>
                            <CountingFactorBoxComponent
                                restricted={false}
                                countingFactor={countingFactor}
                                change={onChangeCountingFactor}
                                countingFactorErr={countingFactorErr}
                            />
                        </Grid>
                        {/* Prüfungstyp */}
                        <Grid item xs={5}>
                            <ExamTypeBoxComponent
                                restricted={false}
                                examTypeId={assignmentExamTypeId}
                                change={onChangeExamType}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}

export default memo(LineTwo);
