import React, { memo } from "react";
import { CustomTextField } from "../../../../../_3HelperFunctions/CustomTextField";
import { Grid } from "@mui/material";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";
import { useTheme } from "@mui/styles";

function NotesBox({
  restricted,
  previewAssignment,
  notes,
  setNotes,
  changesCount,
  setChangesCount,
}) {
  const theme = useTheme()
  const {appInDarkMode} = useAppContext()
  const original = previewAssignment.notes === null ? "" : notes

  const change = (e) => {
    let changesCountCopy = changesCount;
    if (e.target.value === original) {

      //we have gone back to default
      // console.log("default");
      setChangesCount(changesCountCopy - 1);
    } else if (notes !== original) {
      // console.log(previewAssignment.notes);
      // console.log("do noting");
      // do noting
    } else {
      // new change
      // console.log("new change");
      setChangesCount(changesCountCopy + 1);
    }

    setNotes(e.target.value);
  };
  return (
    <>
      {/* Personal Notes */}
      <Grid
        container
        justifyContent="flex-start"
        direction="column"

      // sx={{ width: "100%", background: "pink" }}
      >
        <Grid item xs={restricted ? 12 : 6} sx={{ marginTop: "-0.3em" }}>
          <CustomTextField
            sx={{ width: "100%" }}
            id="notes"
            label="Notizen"
            multiline
            rows={restricted ? 5 : 8}
            defaultValue={notes}
            placeholder="nur du siehst das!"
            onChange={change}
            variant="outlined"
            inputProps={{ style: { fontSize: "0.9em" } }}
            appindarkmode={appInDarkMode.toString()}
            theme={theme}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default memo(NotesBox);
