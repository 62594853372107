import React, { memo } from "react";
import DaysToPrepareBoxComponent from "./DaysToPrepareBoxComponent";
import ErrorBox from "./ErrorBox";

function DaysToPrepareBox({
  classView,
  restricted,
  previewAssignment,
  changesCount,
  setChangesCount,
  my_days,
  setMyDays,
  suggested_days,
  setSuggestedDays,
  availablePrepDaysErr,
  workloadErr,
  type,
  personalView,
}) {
  const change = (e, isTotalSugg) => {
    let changesCountCopy = changesCount;
    const previewDaysToPrepare = isTotalSugg
      ? previewAssignment.suggested_days
      : previewAssignment.my_days;

    if (parseInt(e.target.value) === previewDaysToPrepare) {
      // we have gone back to default
      setChangesCount(changesCountCopy - 1);
    } else if (
      isNaN(isTotalSugg ? suggested_days : my_days) ||
      previewDaysToPrepare !== (isTotalSugg ? suggested_days : my_days)
    ) {
      // do nothing
    } else {
      // new change
      setChangesCount(changesCountCopy + 1);
    }

    if (isTotalSugg) {
      setSuggestedDays(
        e.target.value === "" ? 0 : Math.max(0, parseInt(e.target.value, 10))
      );
    } else {
      setMyDays(
        e.target.value === "" ? 0 : Math.max(0, parseInt(e.target.value, 10))
      );
    }
  };

  return (
    <>
      <DaysToPrepareBoxComponent
        classView={classView}
        restricted={restricted}
        my_days={my_days}
        suggested_days={suggested_days}
        type={type}
        personalView={personalView}
        change={change}
      />
      {personalView && <ErrorBox error={availablePrepDaysErr} />}
      {!personalView && <ErrorBox error={workloadErr} />}
    </>
  );
}

export default memo(DaysToPrepareBox);
