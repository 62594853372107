import { useState } from "react";
import { SeverityPill } from "./severity-pill";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import { useTheme } from "@mui/material";
export default function WorkloadMinutesSeverityPill({
  assignment,
  disabled,
  classView,
}) {
  const { isALeader, isAStudent } = useAppContext();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    // console.log(assignment);
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
 

  return (
    <>
      <SeverityPill
        onClick={disabled ? null : handleClick}
        assignment={assignment}
        disabled={disabled}
        classView={classView}
        sx={{
          cursor: disabled ? "null" : "default",
          backgroundColor:
            assignment.status === 0
              ? theme.palette.levels.red
              : assignment.status === 1
              ? theme.palette.levels.orange
              : assignment.status === 2
              ? theme.palette.levels.yellow
              : assignment.status === 3
              ? theme.palette.levels.lightGreen
              : theme.palette.levels.green,
        }}
      >
        {isAStudent && disabled
          ? assignment.suggested_minutes
          : isAStudent && !disabled
          ? assignment.my_minutes - assignment.my_minutes_done
          : (isALeader && disabled) || (isALeader && classView)
          ? assignment.suggested_minutes
          : isALeader
          ? assignment.my_minutes - assignment.my_minutes_done
          : 999}
        &nbsp;min
      </SeverityPill>
    </>
  );
}
