import { memo } from "react";
import { Grid } from "@mui/material";
import AppStarter from "./LoginHelpers/AppStarter";
import LoginCard from "./LoginHelpers/LoginCard";
import LoginBox from "../../../_4GeneralHelpers/1_Boxes/LoginBox";

function LoginForm({ flipCard }) {
  return (
    <LoginBox>
      <Grid
        item
        xs={3}
        ss={4}
      >
        <AppStarter flipCard={flipCard} loginPage={true} />
      </Grid>
      <LoginCard />
    </LoginBox>
  );
}

export default memo(LoginForm);
