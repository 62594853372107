import React, { memo, useState } from "react";
import ReactCardFlip from "react-card-flip";
import { useTheme } from "@mui/styles";
import { StatusPriorityDoghnutView } from "../../_5Pages/4DashboardPage/DashboardParts/Doughnut/StatusPriorityDoghnutView";
import { StatusPriorityPie } from "../../_5Pages/4DashboardPage/DashboardParts/Doughnut/StatusPriorityPie";

function DonutFlip() {
  const theme = useTheme();
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };
  // console.log("this workds")

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
      <div style={{ background: theme.palette.background.paper }}>
        {/* <StatusPriorityDoghnutView
          parameter={"status"}
          flipCard={handleClick}
        /> */}
         <StatusPriorityPie
          parameter={"status"}
          flipCard={handleClick}
        />
      </div>

      <div style={{ background: theme.palette.background.paper }}>
        {/* <StatusPriorityDoghnutView
          parameter={"priority"}
          flipCard={handleClick}
        /> */}
        <StatusPriorityPie
          parameter={"priority"}
          flipCard={handleClick}
        />
      </div>
    </ReactCardFlip>
  );
}

export default memo(DonutFlip);

// ReactDOM.render(<CardFlip />, document.querySelector("#root"));
