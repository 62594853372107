async function dataWasUpdatedCheck(newAssignments, oldAssignments) {
  let response = false;
  // console.log(newAssignments)
  newAssignments.forEach(function (assignment, index) {
      // console.log( "realEditDate: ", assignment.real_edit_date, "oldEditDate: ", oldAssignments[index].real_edit_date)
    if (
      assignment.real_edit_date !== oldAssignments[index].real_edit_date ||
      assignment.real_edit_date_user !==
      oldAssignments[index].real_edit_date_user ||
      //some internal changes might update the status or ranking (e.g. Workboard)
      assignment.status !== oldAssignments[index].status ||
      assignment.ranking !== oldAssignments[index].ranking
    ) {
      // console.log("we found a difference");
      response = true;
      return response;
    }
  });
  // console.log("we coudn't find a change")
  return response;
}
exports.dataWasUpdatedCheck = dataWasUpdatedCheck;
