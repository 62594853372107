export const groupMixedAssignmentsByType = async (mixedAssignments) => {
  const groupedAssignments = {};

  mixedAssignments.forEach((assignment) => {
    const groupId = assignment.mixed_group_type_id;

    if (!groupedAssignments[groupId]) {
      groupedAssignments[groupId] = [];
    }

    groupedAssignments[groupId].push(assignment);
  });

  // Convert the groupedAssignments object to an array of lists
  const groupedLists = Object.values(groupedAssignments);

  return groupedLists;
};
