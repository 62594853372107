import React, { memo, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import dayjs from "dayjs";
import { updateDataSet } from "../../../../_3HelperFunctions/_LocalUpdates/updateDataSet";
import { saveAssignmentChanges } from "../../../../_3HelperFunctions/_AssignmentFunctions/saveAssignmentChanges";
import { userConfirmDialogue } from "../../../../_3HelperFunctions/userConfirmDialogue";
import { deleteAssignmentFromDB } from "../../../../_3HelperFunctions/_AssignmentFunctions/deleteAssignmentFromDB";
import DescriptionBox from "./CardElements/DescriptionBox";
import EditLineOne from "./_EditAssignmentHelpers/EditLineOne";
import EditLineTwoL from "./_EditAssignmentHelpers/EditLineTwoL";
import EditLineFourExams from "./_EditAssignmentHelpers/EditLineFourExams";
import EditLineFiveL from "./_EditAssignmentHelpers/EditLineFiveL";
import AssignmentEditButtons from "./PopoverContentHelpers/AssignmentEditButtons";

function AssignmentClassViewOpen({
  assignment,
  restricted,
  handleClose,
  classView,
}) {
  // console.log("PopUp: ", restricted);
  const {
    schoolYearData,
    setUnresolvedSchoolYearData,
    currentUser,
    isALeader,
    isAnAdmin,
    userCourses,
    husibuechExamTypes,
    husibuechCourses
  } = useAppContext();
  const [previewAssignment, setPreviewAssignment] = useState(assignment);
  // console.log("previewAssignment: ", previewAssignment);
  /**
   * Update Elements
   */
  const [type, setType] = useState(previewAssignment.type);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(previewAssignment.status);
  const [my_minutes_done, setWorkloadMinutesDone] = useState(
    parseInt(previewAssignment.my_minutes_done)
  );
  const [suggested_minutes, setWorkloadMinutesTotalSugg] = useState(
    parseInt(previewAssignment.suggested_minutes)
  );
  const [suggested_days, setSuggestedDays] = useState(
    parseInt(previewAssignment.suggested_days)
  );
  const [assignmentName, setAssignmentName] = useState(assignment.name);
  const [dueDate, setDueDate] = useState(
    dayjs(previewAssignment.due_date).format("YYYY-MM-DD")
  );
  const [selectedAssignmentCourse, setSelectedAssignmentCourse] = useState(
    previewAssignment.course_id
      ? husibuechCourses.filter(
        (course) => course.id === previewAssignment.course_id
      )[0]
      : userCourses[0]
  );
  const [countingFactor, setCountingFactor] = useState(
    assignment.counting_factor ? parseFloat(assignment.counting_factor) : 0
  );
  const [assignmentExamTypeId, setAssignmentExamTypeId] = useState(
    husibuechExamTypes[0].id
  );
  const [description, setDescription] = useState(
    previewAssignment.description === undefined
      ? ""
      : previewAssignment.description
  );

  // console.log("prev....", previewAssignment)

  /**
   * Processing
   */
  const [changesCount, setChangesCount] = useState(0);
  const [changes, setChanges] = useState(changesCount > 0 ? true : false);
  const [loadingCircle, setLoadingCircle] = useState(false);
  const [saveColor, setSaveColor] = useState("primary");
  /**
   * Errors
   */
  const [countingFactorErr, setCountingFactorErr] = useState({});
  const [workloadErr, setWorkloadErr] = useState({});
  const [availablePrepDaysErr, setAvailablePrepDaysErr] = useState({});

  /**
   * Testing
   */
  useEffect(() => {
    changesCount > 0 ? setChanges(true) : setChanges(false);
    // console.log(type);
  }, [changesCount]);

  // useEffect(() => {
  //   console.log(restricted, classView, previewAssignment);
  // }, [previewAssignment]);

  /**
   * SystemChanges
   */

  //save process
  const save = async () => {
    // console.log("final descr:", description)
    try {
      setLoadingCircle(true);
      const updateValues = [
        { property: "name", value: assignmentName },
        { property: "type", value: type },
        type !== "personal" &&
        { property: "course_id", value: selectedAssignmentCourse.id },
        type === "exam" &&
        { property: "counting_factor", value: countingFactor },
        type === "exam" &&
        { property: "exam_type_id", value: assignmentExamTypeId },
        { property: "due_date", value: dueDate },
        { property: "suggested_minutes", value: suggested_minutes },
        { property: "suggested_days", value: suggested_days },
        { property: "description", value: description },
        // Add other property-value pairs as needed
      ]
      //connect to db
      assignment.passStatusUpdate = true
      const responseAssignment = await saveAssignmentChanges(
        assignment,
        updateValues,
        "update",
        currentUser
      );
      // console.log("server response: ", responseAssignment)
      setSaveColor("success");
      setMessage("Änderungen gespeichert!");
      setLoadingCircle(false);


      //local update
      const updatedSchoolYearData = await updateDataSet(schoolYearData, responseAssignment, isALeader, "update")
      await setUnresolvedSchoolYearData(updatedSchoolYearData);

      // Set a timeout before resetting default values

      setTimeout(async () => {
        // console.log("we come here")
        setSaveColor("primary");
        setMessage("");
        setChanges(false);
      }, 750);
      setTimeout(async () => {
        handleClose();
      }, 1000);
    } catch (error) {
      console.log(error)
      setSaveColor("warning");
      setMessage(
        error.response?.data.message && isAnAdmin
          ? error.response.data.message
          : "Fehler beim Speichern"
      );
      setLoadingCircle(false);

      setTimeout(() => {
        setSaveColor("primary");
        setMessage("");
      }, 6000);
    }
  };

  const onClickDelete = (func) => {
    // set loading to true during submission process
    setLoadingCircle(true); //user confirmation interaction
    setTimeout(async () => {
      const answer = await userConfirmDialogue(type);
      if (!answer) {
        alert("Assignment wird beibehalten!");
        setLoadingCircle(false);
      } else {
        try { //delete the assignment!
          var responseAssignment = await deleteAssignmentFromDB(
            previewAssignment,
            currentUser
          );
          setSaveColor("success");
          setMessage("Bye bye... " + previewAssignment.name);
          setLoadingCircle(false);
          const updatedSchoolYear = await updateDataSet(
            schoolYearData,
            responseAssignment,
            isALeader,
            "delete"
          );
          setUnresolvedSchoolYearData(updatedSchoolYear);

          //set a timeout before default is reinstalled
          setTimeout(async () => {
            handleClose()
            setTimeout(async () => {
              // setOpenChangeManaged(true);
              setMessage("");
              setChanges(false);
            }, 300);
          }, 1500);


        } catch (error) {
          setSaveColor("warning");
          setMessage(
            error.response.data.message && isAnAdmin
              ? error.response.data.message
              : "Fehler beim Löschen"
          );
          setLoadingCircle(false);

          setTimeout(() => {
            setSaveColor("primary");
            setMessage("");
          }, 6000);
        }
      }
    }, 300);
  };

  return (
    <Grid
      container
      display="flex"
      justifyContent="flex-start"
      direction="column"
    // sx={{ width: "100%", background: "orange" }}
    >
      {/* 1st Line in Edit */}
      <EditLineOne
        previewAssignment={previewAssignment}
        assignment={assignment}
        changesCount={changesCount}
        setChangesCount={setChangesCount}
        assignmentName={assignmentName}
        setAssignmentName={setAssignmentName}
        type={type}
        dueDate={dueDate}
        setDueDate={setDueDate}
        status={status}
        restricted={restricted}
        classView={classView}
      />

      {/* Line 2 box */}
      <EditLineTwoL
        previewAssignment={previewAssignment}
        assignment={assignment}
        changesCount={changesCount}
        setChangesCount={setChangesCount}
        selectedAssignmentCourse={selectedAssignmentCourse}
        setSelectedAssignmentCourse={setSelectedAssignmentCourse}
        type={type}
        setType={setType}
        restricted={restricted}
      />

      {/* Line 3 for Exam information */}
      {type === "exam" && (
        <EditLineFourExams
          previewAssignment={previewAssignment}
          changesCount={changesCount}
          setChangesCount={setChangesCount}
          countingFactor={countingFactor}
          setCountingFactor={setCountingFactor}
          countingFactorErr={countingFactorErr}
          setAssignmentExamTypeId={setAssignmentExamTypeId}
          husibuechExamTypes={husibuechExamTypes}
          assignmentExamTypeId={assignmentExamTypeId}
          restricted={restricted}
        />
      )}

      {/* Workload Container */}
      <EditLineFiveL
        previewAssignment={previewAssignment}
        setPreviewAssignment={setPreviewAssignment}
        assignment={assignment}
        changesCount={changesCount}
        setChangesCount={setChangesCount}
        type={type}
        dueDate={dueDate}
        setDueDate={setDueDate}
        status={status}
        setStatus={setStatus}
        my_minutes_done={my_minutes_done}
        setWorkloadMinutesDone={setWorkloadMinutesDone}
        my_minutes={suggested_minutes}
        setMyMinutes={setWorkloadMinutesTotalSugg}
        suggested_minutes={suggested_minutes}
        setWorkloadMinutesTotalSugg={setWorkloadMinutesTotalSugg}
        workloadErr={workloadErr}
        setMyDays={setSuggestedDays}
        my_days={suggested_days}
        setSuggestedDays={setSuggestedDays}
        suggested_days={suggested_days}
        availablePrepDaysErr={availablePrepDaysErr}
        restricted={restricted}
      />

      {/* Asssignment Kommentar etc. */}
      <Grid item xs={12} style={{ marginTop: "0.75em" }}>
        <Grid
          container
          justifyContent="space-between"
          direction="column"
          rowGap={2}
        // sx={{ width: "100%", background: "pink" }}
        >
          <DescriptionBox
            restricted={restricted}
            assignment={assignment}
            previewAssignment={previewAssignment}
            description={description===null?"":description}
            setDescription={setDescription}
            changesCount={changesCount}
            setChangesCount={setChangesCount}
          />

        </Grid>
      </Grid>

      {/* Assignment Buttons */}
      <AssignmentEditButtons previewAssignment={previewAssignment} changes={changes} message={message} saveColor={saveColor} deleteFunction={onClickDelete} saveFunction={save} loadingCircle={loadingCircle} type={type} />

    </Grid>
  );
}

export default memo(AssignmentClassViewOpen);
