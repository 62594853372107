import getToBeAddedCourses from "./getToBeAddedCourses";

const createUpdateObject = async (klasse, zusatzFaecher, userCourses, currentUser) => {
  const newClasses = klasse ? { ...klasse, tutor_id: currentUser.id } : undefined;
  const newCourses = await getToBeAddedCourses(zusatzFaecher, userCourses);

  const updateObject = {
    coreCourseDTO: newClasses,
    courseDTOList: newCourses,
    ...(currentUser.roles.includes("Teacher") && { abbreviation: currentUser?.abbreviation }),
  };

  return updateObject;
};

export default createUpdateObject;