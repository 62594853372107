import { memo, useState } from "react";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import appService from "../../../_6Services/app.service";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RegisterSubject() {
  const { husibuechSubjects, setHusibuechSubjects } = useAppContext();

  const [tag, setTag] = useState("");
  const [tagErr, settagErr] = useState({});

  const [subjectName, setsubjectName] = useState("");
  const [subjectNameErr, setsubjectNameErr] = useState({});

  const [message, setMessage] = useState("");
  const [change, setChange] = useState(false);
  const [success, setSuccess] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const theme = useTheme();

  // useEffect(() => {
  //   console.log(husibuechSubjects);
  // }, [husibuechSubjects]);

  const onChangeSubjectName = (e) => {
    setsubjectNameErr({});
    setsubjectName(e.target.value);
    setChange(e.target.value.length ? true : false);
  };

  const onChangeTag = (e) => {
    settagErr({});
    setTag(e.target.value);
    setChange(e.target.value.length ? true : false);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      registerSubject();
    } else {
      setTag("");
      setTimeout(() => {
        // go back to normal
        setMessage("");
        setChange(false);
      }, 4000);
    }
  };

  const formValidation = () => {
    let tagErr = {};
    let subjectErr = {};
    let isValid = true;

    //design the desired rules for form completion here:

    //allow for 0.25 counting factor
    if (!tag.length || tag.length > 6) {
      tagErr.noTag = "Bitte Kürzel mit max 6 Zeichen!";
      isValid = false;
    }

    if (!subjectName.length || subjectName.length > 40) {
      subjectErr.wrongName = "Bitte passende Bezeichnung eingeben!";
      isValid = false;
    }

    if (
      husibuechSubjects &&
      husibuechSubjects.find((subject) => subject.tag === tag)
    ) {
      tagErr.notUnique = "Kürzel bereits verwendet!";
      isValid = false;
    }

    settagErr(tagErr);
    setsubjectNameErr(subjectErr);

    return isValid;
  };
  const updateLocalSystemData = (newSubject) => {
    let updatedSystemData = husibuechSubjects;
    updatedSystemData = updatedSystemData.concat(newSubject);
    setTimeout(() => {
      setHusibuechSubjects(updatedSystemData);
    }, 1000);
  };
  const registerSubject = () => {
    setLocalLoading(true);
    const newSubject = {
      name: subjectName,
      tag: tag,
    };
    appService
      .registerNewSubject(newSubject)
      .then(
        (response) =>
          updateLocalSystemData(response.data) +
          setLocalLoading(false) +
          setSuccess(true) +
          setMessage("Update erfolgreich") +
          setsubjectName("") +
          setTag("") +
          setTimeout(() => {
            setSuccess(false);
            setMessage("");
            settagErr({});
            setChange(false);
          }, 2500)
      )
      .catch((error) => {
        setLocalLoading(false);
        setMessage(
          error.response.data.message
            ? error.response.data.message
            : "ungültige Angaben"
        );
        setTimeout(() => {
          setMessage("");
          settagErr({});
          setChange(false);
        }, 4000);
      });
  };

  return (
    <Card>
      <CardHeader
        title="Neues Fach"
        subheader="hinzufügen"
        subheaderTypographyProps={{ color: theme.palette.levels.white }}
        sx={{
          color: theme.palette.levels.white,
          backgroundColor: theme.palette.levels.blue,
        }}
      />

      <CardContent>
        {/* Box container */}
        <Grid
          container
          direction="row"
          rowGap={3}
          
          justifyContent="space-between"
        >
          {/* Form item */}
          <Grid item md={6} ss={5} xs={5}>
            <form id="registerSubject" onSubmit={onSubmit}>
              {/* Form container including the button */}
              <Grid
                container
                direction="column"
                spacing={3}
                justifyContent="center"
              >
                <Grid item md={6} xs={12}>
                  <InputLabel id="demo-multiple-name-label">
                    Name für neues Fach *
                  </InputLabel>
                  <TextField
                    type="name"
                    placeholder="Name *"
                    fullWidth
                    name="name"
                    variant="outlined"
                    required
                    autoFocus={false}
                    value={subjectName}
                    onChange={onChangeSubjectName}
                  />
                </Grid>
                {Object.keys(subjectNameErr).map((key) => {
                  return (
                    <Grid
                      item
                      md={6}
                      xs={12}
                      className="alert alert-danger"
                      role="alert"
                    >
                      {subjectNameErr[key]}
                    </Grid>
                  );
                })}
                <Grid item md={6} xs={12}>
                  {/* Kürzel Input */}
                  <Grid item md={6} xs={12}>
                    <InputLabel id="demo-multiple-name-label">
                      Kürzel für neues Fach *
                    </InputLabel>
                    <TextField
                      type="tag"
                      placeholder="Kürzel *"
                      fullWidth
                      name="tag"
                      variant="outlined"
                      required
                      autoFocus={false}
                      value={tag}
                      onChange={onChangeTag}
                    />
                  </Grid>
                  {Object.keys(tagErr).map((key) => {
                    return (
                      <Grid
                        item
                        md={12}
                        xs={12}
                        className="alert alert-danger"
                        role="alert"
                      >
                        {tagErr[key]}
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </form>
          </Grid>


          {/* Right item view */} 
          <Grid item md={5} ss={5} xs={5}>
          <Grid
            container
            direction="row"
            spacing={3}
            justifyContent="flex-start"
          > 
            {/* Stystem information item */}
            <Grid item md={12} xs={12}>
              <InputLabel id="demo-multiple-name-label">
                Systemfächer *
              </InputLabel>
              <Select
                displayEmpty
                onChange={null}
                input={<OutlinedInput />}
                value={``}
                renderValue={(selected) => {
                  return <em>bereits im System vorhanden</em>;
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value="">
                  <em>bereits erfasst</em>
                </MenuItem>
                {husibuechSubjects &&
                  husibuechSubjects.length > 0 &&
                  husibuechSubjects.map((subject) => (
                    <MenuItem key={subject.id} value={subject}>
                      {subject.tag + " - " + subject.name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>

            {/* Button */}
            <Grid item md={6} xs={6}>
              <Button
                style={{ visibility: !localLoading && !change ? "hidden" : "visible" }}
                variant="contained"
                color="error"
                // type="submit"
                className="button-block"
                disabled={localLoading}
                onClick={onSubmit}
              >
                Registrieren
              </Button>

              {localLoading && <LinearProgress />}
            </Grid>
            {message && success && (
              <Grid item>
                <Typography className="alert alert-success" role="alert">
                  {message}
                </Typography>
              </Grid>
            )}
            {message && !success && (
              <Grid item>
                <Typography className="alert alert-danger" role="alert">
                  {message}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
export default memo(RegisterSubject);
