import styled from "styled-components";

export const ColumnHeader = styled.div`
  text-transform: uppercase;
  margin-bottom: 20px;
`;

export const TaskList = styled.div`
  // padding: 6px;
  border-radius: 6px;
  transition: background-color 0.5s ease;
  background-color: ${(props) => (props.isDraggingOver ? props.dragColor : props.color)};
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 450px;
  overflowY: auto
`;

export const DroppableStyles = styled.div`
  padding: 4px;
  border-radius: 6px;
  background-color: ${(props) => (props.color)};
  flex-grow: 1;
`;