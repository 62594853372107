export const generateDragList = (list, assignmentList, otherAssignmentsList) => {
    const getItems = (prefix) => {
      var elementList = [];
      if (prefix !== "spätere Arbeiten") {
        const index = list.indexOf(prefix);
        if (assignmentList && assignmentList[0] !== undefined) {
          elementList = assignmentList.filter(
            (assignment) => assignment.status + 1 === index
          );
        }
      } else if (otherAssignmentsList) {
        elementList = otherAssignmentsList;
      }
      elementList.sort(function (a, b) {
        var c = a.ranking;
        var d = b.ranking;
        var e = a.status;
        var f = b.status;
        return c - d || e - f;
      });
      return elementList;
    };
  
    if (assignmentList) {
      if (list.length !== 0) {
        var draglist = list.reduce(
          (acc, listKey) => ({ ...acc, [listKey]: getItems(listKey) }),
          {}
        );
        return draglist;
      }
    }
    return {};
  };
  