const {
  default: workloadService,
} = require("../../../../../_6Services/workload.service");

async function getDict(assignmentWeek, key, referenceDate) {
  // console.log(assignmentWeek)
  if (key === "extra") {
    //only deal with extra assignments
    let relevantExtraDatesAssignments = assignmentWeek.additional.extraDates;

    return await workloadService.createDictWithRelevantExtraDateAssignments(
      referenceDate,
      relevantExtraDatesAssignments
    );
  } else {
    //deal with personal or class assignments
    const propertyKey =
      key === "personal"
        ? "my_days"
        : "suggested_days";
    let relevantUserAssignments = assignmentWeek.thisWeek.concat(
      assignmentWeek.additional.daysToPrepare
    );

    return await workloadService.createDictWithRelevantAssignments(
      referenceDate,
      relevantUserAssignments,
      propertyKey
    );
  }
}
exports.getDict = getDict;
