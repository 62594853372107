async function getErrorMessageFromResponse(key, response) {
  // console.log(response?.request?.status)
  let errorMsg = "";

  if (key === "login") {
    if (response.request?.status === 409) {
      errorMsg = "Mail verschickt. User ist noch nicht verifiziert";
    } else if (
      response.request?.status === 400
    ) {
      errorMsg = "Ungültige Zugangsdaten! Versuche es nochmals.";
    } else {
      errorMsg = "Serverproblem. Melde dich bei info@husibuech.ch";
    }
  }
  return errorMsg;
}
exports.getErrorMessageFromResponse = getErrorMessageFromResponse;
