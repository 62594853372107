const { filterOutByShowAssignments } = require("./filterOutByShowAssignments");

async function filterTasksByStatusAndFilter(
  assignments,
  status,
  showExams,
  showHomeworks,
  showPersonals,
  getOnlyThisStatus
) {
  const filteredArray = assignments.filter((assignment) => {
    // Filter by status
    if (getOnlyThisStatus && assignment.status !== status) {
      return false;
    } else if (!getOnlyThisStatus && assignment.status === status) {
      //return everything but the status
      return false
    } else {
      return !filterOutByShowAssignments(assignment, showExams, showHomeworks, showPersonals)
    }
  });

  return filteredArray;
}

exports.filterTasksByStatusAndFilter = filterTasksByStatusAndFilter;
