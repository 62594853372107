import { Cottage, School } from "@mui/icons-material";
import {
  Avatar,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from "@mui/material";
import React, { memo, useState } from "react";
import EditAssignmentPopover from "../../_5Pages/4DashboardPage/DashboardParts/AddAssignmentBox/EditAssignmentPopover";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";

function AssignmentSneakListView({ assignment }) {
  const { isALeader, userCourses, isAReferenceStudent } = useAppContext()
  const isPersonalType = assignment?.type === "personal";
  const viewIsNotRestricted =
    (isALeader || isAReferenceStudent) &&
    (isPersonalType ||
      (userCourses &&
        userCourses.some(
          (course) => course.id === assignment.course_id
        )));


  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClickShowDetail = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>

      <ListItem disablePadding>
        <ListItemButton onClick={handleClickShowDetail}>
          <Avatar
            sx={{
              height: 30,
              width: 30,
              ml: 1,
            }}
          >
            {assignment.type === "exam" ? (
              <School
                sx={{
                  height: 20,
                  width: 20,
                  backgroundColor:
                    assignment.status === 0
                      ? "error.main"
                      : assignment.status === 4
                        ? "success.main"
                        : "warning.main",
                }}
              />
            ) : (
              <Cottage
                sx={{
                  height: 20,
                  width: 20,
                  backgroundColor:
                    assignment.status === 0
                      ? "error.main"
                      : assignment.status === 4
                        ? "success.main"
                        : "warning.main",
                }}
              />
            )}
          </Avatar>
          <ListItemText
            primary={` ${assignment?.name} (${assignment?.tag})`}
          />
        </ListItemButton>
      </ListItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <EditAssignmentPopover
          assignment={assignment}
          handleClose={handleClose}
          restricted={!viewIsNotRestricted}
          classView={isALeader}
        />
      </Popover>
    </>
  );
}
export default memo(AssignmentSneakListView);
