import React, { memo, useState, useEffect } from "react";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import dayjs from "dayjs";
import LineOne from "./_AssignmentAddHelpers/LineOne";
import LineTwo from "./_AssignmentAddHelpers/LineTwo";
import LineThree from "./_AssignmentAddHelpers/LineThree";
import SaveButtonPart from "../../../../_4GeneralHelpers/saving/SaveButtonPart";
import { formValidation } from "./_AssignmentAddHelpers/formValidation";
import { createAssignment } from "../../../../_3HelperFunctions/_AssignmentFunctions/createAssignment";
import LineInfo from "./_AssignmentAddHelpers/LineInfo";
import { updateDataSet } from "../../../../_3HelperFunctions/_LocalUpdates/updateDataSet";
import { Grid, MenuItem, Checkbox, InputLabel, OutlinedInput, Select } from "@mui/material";
import DatumBoxComponent from "./CardElements/DatumBoxComponent";


function AssignmentAddCard({ setFlipAssignment, handleClose, date, restricted, single, boxType }) {
  const _ = require('lodash');
  const {
    currentUser,
    isALeader,
    isAReferenceStudent,
    isAnAdmin,
    userCourses,
    selectedCourse,
    schoolYearData,
    setUnresolvedSchoolYearData,
    husibuechExamTypes,
    referenceDate,
  } = useAppContext();

  const [assignmentName, setAssignmentName] = useState("");
  const [dueDate, setDueDate] = useState(dayjs(date ? date : referenceDate)); //date can be picked in Semesterview
  const [repetitionDate, setRepetitionDate] = useState(dayjs(date ? date : referenceDate))
  const [repetitionValue, setRepetitionValue] = useState(0)
  const [checked, setChecked] = useState(false)
  const [type, setType] = useState(
    !(isALeader || isAReferenceStudent) ? "personal" : "homework"
  );
  const [myDays, setMyDays] = useState(0);
  const [myMinutes, setMyMinutes] = useState(
    type === "homework" || type === "personal" ? 20 : 90 //is an exam
  );
  const [countingFactor, setCountingFactor] = useState(1);
  const [assignmentExamTypeId, setAssignmentExamTypeId] = useState(
    husibuechExamTypes[0]?.id || 0
  );

  const [assignmentNameErr, setAssignmentNameErr] = useState({});
  const [countingFactorErr, setCountingFactorErr] = useState({});
  const [availablePrepDaysErr, setAvailablePrepDaysErr] = useState({});
  const [workloadErr, setWorkloadErr] = useState({});

  const [loadingCircle, setLoadingCircle] = useState(false);
  const [change, setChange] = useState(false);
  const [message, setMessage] = useState("");
  const [saveColor, setSaveColor] = useState("primary");
  const [selectedAssignmentCourse, setSelectedAssignmentCourse] = useState(
    selectedCourse ? selectedCourse : userCourses ? userCourses[0] : undefined
  );

  useEffect(() => {
    if (type === "exam") {
      const selectedExamType = husibuechExamTypes.find(
        (exam) => exam.id === assignmentExamTypeId
      );
      setMyMinutes(selectedExamType?.suggested_minutes || 0);
      setMyDays(selectedExamType?.suggested_days || 0);
    } else {
      setMyDays(0);
      setMyMinutes(20);
    }
  }, [type, assignmentExamTypeId, husibuechExamTypes]);

  const onChangeExamType = (e) => {
    const selectedTypeId = parseInt(e.target.value);
    setAssignmentExamTypeId(selectedTypeId);
  };

  const onChangeAssignmentName = (e) => {
    setAssignmentNameErr({});
    setAssignmentName(e.target.value);
    setChange(e.target.value.length > 0);
  };

  const onChangeCountingFactor = (e) => {
    setCountingFactor({});
    if (parseFloat(e.target.value) < 0) {
      setCountingFactor(0);
    } else if (parseFloat(e.target.value) > 5) {
      setCountingFactor(5);
    }
    else {
      setCountingFactor(parseFloat(e.target.value));
    }

  };
  const onChangeDueDate = (date) => {
    setDueDate(dayjs(date));
  };
  useEffect(() => {
    if (dayjs(dueDate).isAfter(dayjs(repetitionDate))) {
      setRepetitionDate(dayjs(dueDate))
    }
  }, [dueDate])

  const onChangeRepetitionDate = (date) => {
    setRepetitionDate(dayjs(date));
  };
  const handleChangeRepetitionType = (event) => {
    if (parseInt(event.target.value, 10) === 0) {
      setChecked(false)
    }
    setRepetitionValue(parseInt(event.target.value, 10))
  }
  const handleIsChecked = (event) => {
    if (checked) {
      setRepetitionValue(0)
    } else {
      setRepetitionValue(7)
    }
    setChecked(!checked)
  }
  const onChangeAvailablePrepDays = (e) => {
    if (parseInt(e.target.value) >= 0) {
      setAvailablePrepDaysErr({});
      // console.log(typeof e.target.value)
      // console.log(parseInt(e.target.value));
      setMyDays(parseInt(e.target.value));
    }
  };
  const onChangeWorkloadMinutesTotal = (e) => {
    setWorkloadErr({});
    // console.log(e.target.value);
    setMyMinutes(parseInt(e.target.value));
  };
  const onChangeType = (e) => {
    setType(e.target.value);
  };

  useEffect(() => {
    // Initialize the dueDate state with a default value or the desired initial date
    setDueDate(dayjs(date ? date : referenceDate)); //date can be picked in SemesterView
    setRepetitionDate(dayjs(date ? date : referenceDate)) //date can be picked in SemesterView

    if (type === "exam") {
      const selectedExamType = husibuechExamTypes.find(
        (exam) => exam.id === assignmentExamTypeId
      );
      // console.log("selectedExamType: ", selectedExamType)
      setMyMinutes(selectedExamType?.suggested_minutes || 0);
      setMyDays(selectedExamType?.suggested_days || 0);
    } else {
      setMyDays(0);
      setMyMinutes(20);
    }
  }, [type, assignmentExamTypeId, husibuechExamTypes, referenceDate]);

  const onChangeAssignmentCourse = (e) => {
    // console.log(e.target.value);
    setSelectedAssignmentCourse(e.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    // Validate the form fields
    const {
      isValid,
      assignmentNameErr,
      countingFactorErr,
      availablePrepDaysErr,
      workloadErr,
    } = formValidation(assignmentName, countingFactor, myDays, myMinutes);

    if (!isValid) {
      // Handle validation errors
      setAssignmentNameErr(assignmentNameErr);
      setCountingFactorErr(countingFactorErr);
      setAvailablePrepDaysErr(availablePrepDaysErr);
      setWorkloadErr(workloadErr);
      return;
    }

    setLoadingCircle(true);
    let updatedSchoolYearData = _.cloneDeep(schoolYearData);
    setSaveColor("success");
    setMessage("wir sind drann!");
    try {
      const dueDateCopy = dueDate
      let checkDate = dueDateCopy
      const repetitionCopy = repetitionDate
      // console.log(dayjs(dueDate).format("YYYY-MM-DD"), ", ", dayjs(repetitionDate).format("YYYY-MM-DD"))


      let dueDateFormatted = dueDate.format('YYYY-MM-DD HH:mm:ss')
      if (!checked) {
        updatedSchoolYearData = await storeChanges(updatedSchoolYearData, dueDateFormatted)
        await setUnresolvedSchoolYearData(updatedSchoolYearData);
      } else {
        updatedSchoolYearData = await storeChanges(updatedSchoolYearData, dueDateFormatted)
        while (dayjs(checkDate).isBefore(dayjs(repetitionCopy) || dayjs(checkDate).isSame(dayjs(repetitionCopy)))) {
          // console.log(dayjs(checkDate).format("YYYY-MM-DD"))
          checkDate = dayjs(checkDate).add(repetitionValue, "days")
          dueDateFormatted = checkDate.format('YYYY-MM-DD HH:mm:ss')
          updatedSchoolYearData = await storeChanges(updatedSchoolYearData, dueDateFormatted)
          // console.log(dayjs(checkDate).format("YYYY-MM-DD"))
          // console.log(dayjs(checkDate).isBefore(dayjs(repetitionCopy) || dayjs(checkDate).isSame(dayjs(repetitionCopy))))
        }
        await setUnresolvedSchoolYearData(updatedSchoolYearData);
      }


      setTimeout(async () => {
        await resetFormValues();
        if (!single) {
          setFlipAssignment(true)
        }
        if (boxType === "addPopUp") {
          handleClose();
        }
      }, 2000);
    } catch (error) {
      handleSubmissionError(error);
    }
  };
  const storeChanges = async (updatedSchoolYearData, dueDateFormatted) => {
    // console.log("hi", dueDateFormatted)
    const assignmentProperties = [
      { key: "name", value: assignmentName },
      { key: "due_date", value: dueDateFormatted },
      { key: "suggested_days", value: myDays },
      { key: "my_days", value: myDays },
      { key: "type", value: type },
      { key: "suggested_minutes", value: myMinutes },
      { key: "my_minutes", value: myMinutes },
      type !== "personal" && { key: "course_id", value: selectedAssignmentCourse?.id },
      type === "exam" && { key: "counting_factor", value: countingFactor },
      type === "exam" && { key: "exam_type_id", value: assignmentExamTypeId },
    ];
    const createdAssignment = await createAssignment(currentUser, assignmentProperties);

    updatedSchoolYearData = await updateDataSet(
      updatedSchoolYearData, createdAssignment, (isALeader || isAReferenceStudent),
      "add"
    );
    return updatedSchoolYearData
  }

  const resetFormValues = async () => {
    setAssignmentName("");
    setCountingFactor(1);
    setDueDate(dayjs(date ? date : referenceDate)); //date can be picked in semesterView
    setType(
      !(isALeader || isAReferenceStudent) ? "personal" : "homework"
    );
    setMyDays(0);
    setMessage("");
    setSaveColor("primary");
    setLoadingCircle(false);
    setChange(false);
    setChecked(false)
    setRepetitionValue(0)
    setRepetitionDate(dayjs(date ? date : referenceDate)); //date can be picked in semesterView

  };

  const handleSubmissionError = (error) => {
    setLoadingCircle(false);
    setSaveColor("warning");

    const errorMessage = error.response?.data.message;

    setMessage(
      (isAnAdmin && errorMessage) ||
        (error.response && error.response.status === 500)
        ? errorMessage
        : "Neuer Fehler"
    );

    setTimeout(() => {
      setSaveColor("primary");
      setMessage("");
    }, 6000);
  };
  // console.log("restricted, ", restricted)

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={{ marginTop: "6px" }}
    >
      {/* 1st input line in card */}
      <LineOne
        assignmentName={assignmentName}
        onChangeAssignmentName={onChangeAssignmentName}
        type={type}
        onChangeType={!restricted ? onChangeType : null}
        restricted={restricted}
      />
      {!restricted && type !== "personal" && selectedCourse && (
        <>
          {/* 2nd input line in card */}
          <br />
          <LineTwo
            type={type}
            selectedAssignmentCourse={selectedAssignmentCourse}
            onChangeAssignmentCourse={onChangeAssignmentCourse}
            countingFactor={countingFactor}
            onChangeCountingFactor={onChangeCountingFactor}
            countingFactorErr={countingFactorErr}
            assignmentExamTypeId={assignmentExamTypeId}
            onChangeExamType={onChangeExamType}
          />
        </>
      )}
      <br />
      {/* 3rd input line in card */}
      <LineThree
        type={type}
        date={dueDate}
        onChangeDueDate={onChangeDueDate}
        my_minutes={myMinutes}
        onChangeWorkloadMinutesTotal={onChangeWorkloadMinutesTotal}
        workloadErr={workloadErr}
        my_days={myDays}
        onChangeAvailablePrepDays={onChangeAvailablePrepDays}
        restricted={false}
        boxType={boxType}
      />

      {/* repetition line */}
      {type === "personal" && (
        <Grid container justifyContent="flex-start" direction="row" spacing={1} style={{ marginTop: "0.75em" }}>
          {/* Date */}

          <Grid item xs={4}>
            <InputLabel id="demo-multiple-name-label">
              Wiederholen?
              <Checkbox
                checked={checked}
                onChange={handleIsChecked}
                style={{ marginTop: "0em" }}
              />
            </InputLabel>
          </Grid>
          <Grid item xs={4} style={{
            visibility: checked ? "visible" : "hidden",
            marginTop: "-0.5em",
          }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              input={<OutlinedInput />}
              value={repetitionValue}
              label="Repetition"
              onChange={handleChangeRepetitionType}
            >
              <MenuItem value={1}>täglich bis:</MenuItem>
              <MenuItem value={7}>wöchentlich bis:</MenuItem>
              <MenuItem value={0}>nie</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={4} style={{
            visibility: checked && (repetitionValue === 1 || repetitionValue === 7) ? "visible" : "hidden",
            marginTop: "-0.5em",
          }}>
            <DatumBoxComponent
              restricted={false}
              classView={false}
              dueDate={repetitionDate}
              change={onChangeRepetitionDate}
              boxType={"repetition"}
              special={true}
              extraDate={dueDate}
            />
          </Grid>
        </Grid>
      )}
      <SaveButtonPart
        change={change}
        loadingCircle={loadingCircle}
        saveColor={saveColor}
        message={message}
        onSubmit={onSubmit}
      />
      {restricted && (
        <>
          {/*  4th line: Info Message for students */}
          <br />
          <br />
          <LineInfo />
        </>
      )}
    </Grid>
  );
}

export default memo(AssignmentAddCard);
