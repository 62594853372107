import { memo } from "react";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import { useTheme } from "@mui/styles";

function CoreCourseSelection({
  klasse,
  errors,
  handleChange,
  selections,
  options,
}) {
  const theme = useTheme();

  return (
    <Grid item xs={4}>
      <Grid item md={12} xs={12}>
        <InputLabel id="core-course-selection-label">Meine Klasse</InputLabel>
        <Select
          labelId="core-course-selection-label"
          displayEmpty
          required
          onChange={handleChange}
          input={<OutlinedInput />}
          value={klasse ? klasse : ""}
          renderValue={(selected) => {
            // console.log("selected: ", selected, selected===undefined, "selections: ", selections)
            return selected?.length !== 0 ? (
              selected.name
            ) : selections?.length>0? selections[0].name : (
              <em>Wähle aus</em>
            );
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxHeight: "175px",
                overflowY: "auto",
              },
            },
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            <em>Wähle aus</em>
          </MenuItem>

          {selections?.length > 0 &&
            selections.map((item) => (
              <MenuItem
                disabled
                value=""
                key={`selection-${item.id}`}
                style={{
                  color: theme.palette.levels.yellow,
                  fontWeight: "bold",
                }}
              >
                <em>{`${item.name}${item.tutor_abbreviation ? ` (${item.tutor_abbreviation})` : ""}`}</em>
              </MenuItem>
            ))}

          {options?.length > 0 &&
            options.map((coreCourse) => (
              <MenuItem
                key={`core-course-${coreCourse.id}`}
                value={coreCourse}
                style={{
                  backgroundColor:
                    klasse && klasse.id === coreCourse.id
                      ? theme.palette.levels.lightGreen
                      : "transparent",
                }}
              >
                {coreCourse.name}
              </MenuItem>
            ))}
        </Select>
      </Grid>
      <Grid item md={12} xs={12}>
        {Object.keys(errors).map((key, index) => (
          <Grid
            item
            md={6}
            xs={12}
            key={`error-${index}`}
            className="alert alert-danger"
            role="alert"
          >
            {errors[key]}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default memo(CoreCourseSelection);
