import { memo, useState } from "react";
import { createTeacherName } from "../../../_3HelperFunctions/_NameHelpers/nameHelpers";

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import appService from "../../../_6Services/app.service";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import FachAuswahl from "./FachAuswahl";
import MixedKursAuswahl from "./MixedKursAuswahl";
import ButtonController from "../../3ProfilePage/ProfileHelpers/ButtonController";
import NewCourseFormValidation from "./NewCourseFormValidation";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RegisterCourse() {
  const { husibuechCourses, setHusibuechCourses } =
    useAppContext();
  const [change, setChange] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const [localLoading, setLocalLoading] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [courseNameErr, setCourseNameErr] = useState({});

  const [subject, setSubject] = useState(undefined);
  const [subjectErr, setSubjectErr] = useState({});

  const [isMixedCourse, setIsMixedCourse] = useState(false);

  const [mixedCourse, setMixedCourse] = useState(undefined);
  const [mixedCourseErr, setMixedCourseErr] = useState({});

  const theme = useTheme();

  // useEffect(() => {
  //   console.log(husibuechCourses);
  // }, [husibuechCourses]);

  const onChangeCourseName = (e) => {
    setCourseNameErr({});
    setCourseName(e.target.value);
    setChange(e.target.value.length ? true : false);
  };

  const handleChangeSubject = (e) => {
    setSubjectErr({});
    setSubject(e.target.value);
  };

  const handleIsMixedCourse = (e) => {
    setIsMixedCourse(!isMixedCourse);
  };

  const handleChangeMixedCourse = (e) => {
    setMixedCourseErr({});
    setMixedCourse(e.target.value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const { isValid, errors } = NewCourseFormValidation(
      courseName,
      husibuechCourses,
      subject
    );
    if (isValid) {
      registerCourse();
    } else {
      setSubjectErr(errors.subject);
      setCourseNameErr(errors.course);

      setTimeout(() => {
        // go back to normal
        onClear();
      }, 4000);
    }
  };

  const onClear = () => {
    // event.preventDefault();
    setCourseNameErr({})
    setSubjectErr({})
    setMixedCourseErr({})
    setCourseName("");
    setSubject(undefined);
    setMixedCourse(undefined);
    setIsMixedCourse(false);
    setChange(false);
  };

  const updateLocalSystemData = (newCourse) => {
    let updatedSystemData = husibuechCourses;
    updatedSystemData = updatedSystemData.concat(newCourse);
    setTimeout(() => {
      setHusibuechCourses(updatedSystemData);
    }, 1000);
  };
  const registerCourse = async () => {

    try {
      setLocalLoading(true);
      const newCourse = {
        name: courseName,
        subjectId: subject.id,
        subjectTag: subject.tag,
        mixed_course: isMixedCourse,
        core_course_name: createTeacherName(courseName)
      };

      const response = await appService.registerNewCourse(newCourse)

      updateLocalSystemData(response.data)
      setSuccess(true)
      setMessage("Update erfolgreich")
      setLocalLoading(false)


      setTimeout(() => {
        setCourseName("")
        setSubject(undefined)
        setSuccess(false);
        setMessage("");
        onClear();
      }, 2500)

    } catch (error) {
      console.log(error)
      setLocalLoading(false);
      setMessage(
        error.response.data.message
          ? error.response.data.message
          : "ungültige Angaben"
      );
      setTimeout(() => {
        setMessage("");
        onClear();
      }, 4000);

    }



  };

  return (
    <Card>
      <CardHeader
        title="Neuen Kurs"
        subheader="hinzufügen"
        subheaderTypographyProps={{ color: theme.palette.levels.white }}
        sx={{
          color: theme.palette.levels.white,
          backgroundColor: theme.palette.levels.blue,
        }}
      />
      <Divider />
      <CardContent>
        {/* Box container */}
        <Grid
          container
          direction="row"
          spacing={3}
          justifyContent="space-between"
        >
          {/* Form item */}
          <Grid item md={6} xs={12}>
            <form id="registerCourse" onSubmit={onSubmit}>
              {/* Form container including the button */}
              <Grid
                container
                direction="row"
                spacing={3}
                justifyContent="center"
              >
                <Grid item md={12} xs={12}>
                  <InputLabel id="demo-multiple-name-label">
                    Name für neuen Kurs *
                  </InputLabel>
                  <TextField
                    type="name"
                    placeholder="Name * (Bsp.: EN-G20a)"
                    fullWidth
                    name="name"
                    variant="outlined"
                    required
                    autoFocus={false}
                    value={courseName}
                    onChange={onChangeCourseName}
                  />
                  {Object.keys(courseNameErr).map((key) => {
                    return (
                      <Grid
                        item
                        marginTop={1}
                        className="alert alert-danger"
                        role="alert"
                      >
                        {courseNameErr[key]}
                      </Grid>
                    );
                  })}
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    spacing={0}
                  // justifyContent="space-between"
                  // sx={{backgroundColor: "red"}}
                  >
                    <FachAuswahl
                      subject={subject}
                      change={handleChangeSubject}
                      error={subjectErr}
                    />
                    <MixedKursAuswahl
                      checked={isMixedCourse}
                      changeSelection={handleIsMixedCourse}
                      mixedCourse={mixedCourse}
                      change={handleChangeMixedCourse}
                      error={mixedCourseErr}
                    />
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  {!localLoading && change && (
                    <ButtonController
                      localLoading={localLoading}
                      disabled={localLoading}
                      save={onSubmit}
                      clear={onClear}
                      saveText={"speichern"}
                      clearText={"verwerfen"}
                    />
                  )}
                  {localLoading && <LinearProgress />}
                </Grid>
                {message && success && (
                  <Grid item>
                    <Typography className="alert alert-success" role="alert">
                      {message}
                    </Typography>
                  </Grid>
                )}
                {message && !success && (
                  <Grid item>
                    <Typography className="alert alert-danger" role="alert">
                      {message}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </form>
          </Grid>
          {/* Stystem information item */}
          <Grid item md={6} xs={12}>
            <InputLabel id="demo-multiple-name-label">Systemkurse *</InputLabel>
            <Select
              displayEmpty
              onChange={null}
              input={<OutlinedInput />}
              value={``}
              renderValue={(selected) => {
                return <em>bereits im System vorhanden</em>;
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem disabled value="">
                <em>bereits erfasst</em>
              </MenuItem>
              {husibuechCourses &&
                husibuechCourses.length > 0 &&
                husibuechCourses.map((course, i) => (
                  <MenuItem key={i} value={course}>
                    {course.name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  );
}
export default memo(RegisterCourse);
