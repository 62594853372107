import { List, ListItemButton, ListItemText, Popover } from "@mui/material";
import { memo, useState } from "react";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";
import moment from "moment";
import AssignmentListItem from "../../../../../_5Pages/4DashboardPage/DashboardParts/DeadlineBoxes/AssignmentListItem";
import AssignmentDistributionStats from "../../../../../_5Pages/4DashboardPage/DashboardParts/AddAssignmentBox/AssignmentDistributionStats";
import WorkloadMinutesSeverityPill from "../../../../../_4GeneralHelpers/3_SeverityPills/WorkloadMinutesSeverityPill";
import PickUpToDoButton from "../../../../../_4GeneralHelpers/2_Buttons/PickUpToDoButton";
import canEditAssignment from "../../../../../_3HelperFunctions/_AssignmentFunctions/canEditAssignment";
import EditAssignmentPopover from "../../AddAssignmentBox/EditAssignmentPopover";
const {
  createSubjectTag,
} = require("../../../../../_3HelperFunctions/_NameHelpers/nameHelpers");

function AssignmentList({ type, assignments, classView }) {
  const { userCourses, isALeader, isAReferenceStudent } = useAppContext();
  const [anchorEl, setAnchorEl] = useState(null);


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAssignmentPrimaryText = (assignment) => (
    <>
      {assignment.name}
      {assignment.type !== "personal" &&
        ` (${createSubjectTag(assignment.course_name)})`}
    </>
  );

  const getAssignmentSecondaryText = (assignment) => (
    <>
      {moment(assignment.due_date).locale("de").format("dd, DD. MMM")}
      &nbsp;
      <WorkloadMinutesSeverityPill
        assignment={assignment}
        disabled={!canEditAssignment(assignment, userCourses)}
        classView={classView}
      />
      {type === "personal" && <PickUpToDoButton assignment={assignment} />}
      {type === "class" &&
        userCourses?.some((course) => course.id === assignment.course_id) && (
          <>
            &nbsp;
            <AssignmentDistributionStats assignment={assignment} />
          </>
        )}
    </>
  );
  return (
    <List
      dense
      sx={{
        width: "100%",
        position: "relative",
        maxHeight: { xl: 330, xs: 310 },
        "& ul": { padding: 0 },
      }}
    >
      {assignments.map((assignment, i) => (
        <div key={i}>
          <ListItemButton
            divider={i < assignments.length - 1}
            key={i}
            // style={{ marginTop: "-0.4em", paddingBottom: "-0.4" }}
            // disablePadding
            onClick={handleClick}
          >
            <AssignmentListItem
              assignment={assignment}
              classView={classView}
              sx={{
                cursor: "pointer",
              }}
            />
            <ListItemText
              primary={getAssignmentPrimaryText(assignment)}
              secondary={getAssignmentSecondaryText(assignment)}
            />
          </ListItemButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <EditAssignmentPopover
              assignment={assignment}
              handleClose={handleClose}
              restricted={assignment.type === "personal"?false:(isALeader || isAReferenceStudent) &&
                  (userCourses &&
                    userCourses.some(
                      (course) => course.id === assignment.course_id
                    ))?false:true}
              classView={classView}
            />
          </Popover>
        </div>
      ))}
    </List>
  );
}

export default memo(AssignmentList);
