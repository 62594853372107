import React, { useEffect } from "react";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";

import { memo } from "react";
import HusibuechCircle from "./_HusibuechCircle";
import { useState } from "react";

function PrioritySelectionPopUpView({ onClose }) {
  const {
    priorityList,
    prioritySelection,
    setPrioritySelection,
  } = useAppContext();

  const changePrioritySelection = (i) => {
    var newPriorityArray = prioritySelection;
    const index = newPriorityArray.indexOf(i);
    if (index > -1) {
      newPriorityArray.splice(index, 1);
    } else {
      newPriorityArray.push(i);
    }
    // console.log(newPriorityArray);
    //must be written like this, otherwise array does not update!!
    setPrioritySelection([...newPriorityArray]);
  };


  // useEffect(() => {
  //   console.log(prioritySelection);
  // }, [prioritySelection]);

  return priorityList !== null ? (
    priorityList.map((status, i) => (
      <HusibuechCircle
        select={"priority"}
        key={i}
        type={"priority"}
        keyValue={i}
        fill={
          prioritySelection !== null && prioritySelection.includes(i)
        }
        onClick={() => changePrioritySelection(i)}
      />
    ))
  ) : (
    <></>
  );
}

export default memo(PrioritySelectionPopUpView);
