import React, { memo } from "react";
import { CustomTextField } from "../../../../../_3HelperFunctions/CustomTextField";
import ErrorBox from "./ErrorBox";
import { useTheme } from "@mui/styles";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";

function AufwandBoxComponent({
  classView,
  restricted,
  my_minutes,
  suggested_minutes,
  type,
  workloadErr,
  personalView,
  change,
}) {
  // console.log("classView: ", classView)
  const theme = useTheme()
  const {appInDarkMode} = useAppContext()
  
  return (
    <>
      {/* Aufwand */}
      <CustomTextField
        id={personalView ? "myaufwand" : "aufwand"}
        placeholder="Aufwand"
        label={
          personalView
            ? "myAufwand min"
            : restricted || type === "personal"
            ? "geplanter Aufwand"
            : "Aufwand SuS"
        }
        fullWidth
        variant="outlined"
        //
        autoFocus={false}
        type="number"
        // helperText="eingeplant"
        value={
          personalView
            ? my_minutes
            : //students can change this value box, it will change workloadminutesTotal
            (restricted && my_minutes === -1) || classView && restricted
            ? suggested_minutes
            : restricted
            ? my_minutes
            : !restricted && type === "personal"
            ? my_minutes
            : suggested_minutes
        }
        disabled={classView && restricted}
        onChange={change}
        inputProps={{ style: { fontSize: "0.9em" } }}
        appindarkmode={appInDarkMode.toString()}
        theme={theme}
      />
      <ErrorBox error={workloadErr} />
    </>
  );
}

export default memo(AufwandBoxComponent);
