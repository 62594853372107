import { Grid, Typography } from "@mui/material";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

export const AdminTitle = () => {
  const {isMobile} = useAppContext()
  // console.log(currentUser)

  return (
    <div style={{
      // backgroundColor: "red"
    }}>
      <div
        style={{
          width: "110%",
          whiteSpace: "nowrap", // Prevent content from wrapping
          textAlign: isMobile?"center":"", // Center along the x-axis
        }}
      >
        <Typography variant="h6" color="primary">
          Administrator
        </Typography>
      </div>
      <>
        <Grid item sm={12}>
          <div
            style={{
              width: "110%",
              whiteSpace: "nowrap", // Prevent content from wrapping
              textAlign: isMobile?"center":"", // Center along the x-axis

            }}
          >
            <Typography color="primary" variant="h4" fontWeight={"bold"}>
              Einstellungen
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <div
            style={{
              width: "110%",
              whiteSpace: "nowrap", // Prevent content from wrapping
              textAlign: isMobile?"center":"", // Center along the x-axis
            }}
          >      
              <Typography color="primary" variant="caption">
                im ganzen System
              </Typography>
          </div>
        </Grid>
      </>
    </div>
  );
};
