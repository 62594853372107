import React, { memo, useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import moment from "moment";
import SemesterTableContent from "./_SemesterTableHelpers/SemesterTableContent";
import { calculateSemesterValues } from "./_SemesterTableHelpers/calculateSemesterValues";
import userService from "../../_6Services/user.service";
import { PageTemplate } from "../0_PageTemplate/PageTemplate";

const SemesterTable = () => {
  const {
    currentUser,
    schoolYearData,
    selectedCourse,
    currentSemesterStartDate,
    setCurrentSemesterStartDate,
    setCurrentSemesterName,
    setLoading,
    personalView, 
    viewCourseOnly,
  } = useAppContext();

  // useEffect(()=>{
  //   console.log(viewCourseOnly)
  // },[viewCourseOnly])

  // do not use AppContext - the props Data changes depending on if the user toggles the semesterview
  const [currentSemesterData, setSemesterData] = useState(undefined);


  const fetchData = useCallback(async () => {
    // Your fetchData logic here
    // console.log("we fetch data")
    let data = schoolYearData;

    const weLookAtADateOutsideTheCurrentSchoolYear =
      (moment(currentSemesterStartDate).isBefore(
        moment(data.datesFirstSemester[0])
      ) &&
        data.existsEarlierSemester) ||
      (moment(currentSemesterStartDate).isAfter(
        moment(data.datesSecondSemester[1])
      ) &&
        data.existsLaterSemester);
    try {
      if (personalView) {// Check if the user is a student or in personal view
        if (weLookAtADateOutsideTheCurrentSchoolYear) {
          //get new SchoolYearData of the new SchoolYear and work with that
          // Fetch new schoolYearData (do not replace it though!)
          const response = await userService.getUserInfo(
            "schoolyeardata",
            currentUser.id,
            currentSemesterStartDate
          );

          await calculateSemesterValues(
            response.date,
            currentSemesterStartDate,
            setCurrentSemesterStartDate,
            setCurrentSemesterName,
            setSemesterData,
            selectedCourse,
            currentUser,
            personalView,
            viewCourseOnly
          );
          await setLoading(false);
        } else {
          await calculateSemesterValues(
            data,
            currentSemesterStartDate,
            setCurrentSemesterStartDate,
            setCurrentSemesterName,
            setSemesterData,
            selectedCourse,
            currentUser,
            personalView,
            viewCourseOnly
          );
          await setLoading(false);
        }
      } else if (selectedCourse) {
        // Fetch new data based on current semester choice if such data exists
        // console.log("we go to selectedCourse", selectedCourse)
        if (weLookAtADateOutsideTheCurrentSchoolYear) {
          // Fetch new schoolYearData (do not replace it though!)
          const response = await userService.getUserInfo(
            "schoolyeardata",
            currentUser.id,
            currentSemesterStartDate
          );

          await calculateSemesterValues(
            response.data,
            currentSemesterStartDate,
            setCurrentSemesterStartDate,
            setCurrentSemesterName,
            setSemesterData,
            selectedCourse,
            currentUser,
            personalView,
            viewCourseOnly // this allows toggling between courseOnly and all courses
          );
        } else {
          // console.log("semesterStart: ", currentSemesterStartDate)
          // console.log("we decide: course,", viewCourseOnly, "personal:", personalView)
          await calculateSemesterValues(
            data,
            currentSemesterStartDate,
            setCurrentSemesterStartDate,
            setCurrentSemesterName,
            setSemesterData,
            selectedCourse,
            currentUser,
            personalView,
            viewCourseOnly
          );
          await setLoading(false);
        }
      } else {
        // console.log(" we are undefined");
        setSemesterData(undefined);
        await setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching or processing school year data:", error);
    }

  }, [setCurrentSemesterStartDate, setLoading, currentSemesterStartDate, currentUser, personalView, viewCourseOnly, schoolYearData, selectedCourse]);

  useEffect(() => {
    if (schoolYearData && currentSemesterStartDate) {
      fetchData();
    }
  }, [schoolYearData, currentSemesterStartDate, personalView, viewCourseOnly, currentUser, selectedCourse, fetchData]);




  return (
    <div className="content" sx={{ clear: "left" }}>
      <PageTemplate type={"examView"}>
        <SemesterTableContent currentSemesterData={currentSemesterData} />
      </PageTemplate>
    </div>
  );
};

export default memo(SemesterTable);
