const NewCourseFormValidation = (courseName, husibuechCourses, subject) => {
  const errors = {
    subject: [],
    course: [],
  };

  // Design the desired rules for form completion here:
  if (!subject) {
    errors.subject.push("Fach auswählen");
  }

  if (
    husibuechCourses &&
    husibuechCourses.length > 0 &&
    husibuechCourses.find((course) => course.name === courseName)
  ) {
    errors.course.push("Kursname wird bereits verwendet!")
    
  }

  const isValid = Object.values(errors).every(
    (errorList) => errorList.length === 0
  );
  return {
    isValid,
    errors
  };
};



export default NewCourseFormValidation;
