import PropTypes from "prop-types";
import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { Box } from "@material-ui/core";
import { useState } from "react";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import { useTheme } from "@mui/styles";

const neverOften = {
  0: "",
  1: "nie",
  2: "selten",
  3: "manchmal",
  4: "oft",
  5: "sehr oft",
};
const rarelyAlot = {
  0: "",
  1: "sehr wenig",
  2: "wenig",
  3: "gerade ok",
  4: "viel",
  5: "sehr viel",
};

const customIcons = {
  1: {
    icon: <SentimentVerySatisfiedIcon />,
        // label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentSatisfiedAltIcon />,
    // label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon />,
    // label: "Neutral",
  },
  4: {
    icon: <SentimentDissatisfiedIcon />,
    // label: "Satisfied",
  },
  5: {
    icon: <SentimentVeryDissatisfiedIcon />,
    // label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function RatingSelectedOnly({type, data, setData}) {
  const {appInDarkMode}=useAppContext()
  const theme = useTheme()
  // const [value, setValue] = useState(0);
  const [hover, setHover] = useState(0);
  let levels = []
  if(type==='neverOften'){
    levels = neverOften;
  }else if(type==='rarelyAlot'){
    levels = rarelyAlot;
  };

  //   console.log(value)
  return (
    <>
      <Box
        sx={{
          width: 400,
          display: "flex",
          alignItems: "center",
          "& .MuiRating-iconEmpty": {
            color: appInDarkMode?theme.palette.levels.white:theme.palette.levels.dark,
            opacity: 0.3
          },
        }}
      >
        <Rating
          name="highlight-selected-only"
          value={data}
          defaultValue={0}
          IconContainerComponent={IconContainer}
          highlightSelectedOnly
          onChange={(event, newValue) => {
            setData(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          style={{color: ""}}
        />

        {data !== null && (
          <Box sx={{ ml: 2, fontWeight: 'normal'}}>{levels[hover !== 0 ? hover : data]}</Box>
        )}
      </Box>
    </>
  );
}
