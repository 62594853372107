/**
 * DEPLOYMENT
 */
export const SERVER_URL = "https://server.husibuech.ch/api/";



/**
 * LOCAL
 */
// export const SERVER_URL = "http://localhost:3006/api/";
