import { Cottage, DoneAll, Person, Save, School } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React, { memo } from "react";

function AssignmentAvatar(props) {
  return props.type === "save" ? (
    <Tooltip title="Änderungen speichern?" placement="right">
      <Save
        sx={{
          height: props.height === undefined ? 30 : props.height,
          width: props.width === undefined ? 30 : props.width,
          marginLeft: props.marginLeft === undefined ? 0.5 : props.marginLeft,
          marginTop: props.marginTop === undefined ? 0.5 : props.marginTop,
          color: props.color === undefined ? "rgba(0,0,0,1)" : props.color,
        }}
      />
    </Tooltip>
  ) : props.type === "exam" ? (
    <School
      sx={{
        height: props.height === undefined ? 30 : props.height,
        width: props.width === undefined ? 30 : props.width,
        marginLeft: props.marginLeft === undefined ? 0.5 : props.marginLeft,
        marginTop: props.marginTop === undefined ? 0.5 : props.marginTop,
        color: props.color === undefined ? "rgba(0,0,0,1)" : props.color,
      }}
    />
  ) : props.type === "homework" ? (
    <Cottage
      sx={{
        height: props.height === undefined ? 30 : props.height,
        width: props.width === undefined ? 30 : props.width,
        marginLeft: props.marginLeft === undefined ? 0.5 : props.marginLeft,
        marginTop: props.marginTop === undefined ? 0.5 : props.marginTop,
        color: props.color === undefined ? "rgba(0,0,0,1)" : props.color,
      }}
    />
  ) : props.type === "personal" ? (
    <Person
      sx={{
        height: props.height === undefined ? 30 : props.height,
        width: props.width === undefined ? 30 : props.width,
        marginLeft: props.marginLeft === undefined ? 0.5 : props.marginLeft,
        marginTop: props.marginTop === undefined ? 0.5 : props.marginTop,
        color: props.color === undefined ? "rgba(0,0,0,1)" : props.color,
      }}
    />
  ) : (
    <DoneAll
      sx={{
        height: props.height === undefined ? 30 : props.height,
        width: props.width === undefined ? 30 : props.width,
        marginLeft: props.marginLeft === undefined ? 0.5 : props.marginLeft,
        marginTop: props.marginTop === undefined ? 0.5 : props.marginTop,
        color: props.color === undefined ? "rgba(0,0,0,1)" : props.color,
      }}
    />
  );
}
export default memo(AssignmentAvatar);
