import React, { memo } from "react";
import { Table, Grid } from "@mui/material";

import MainContentGrid from "../../../_4GeneralHelpers/1_Boxes/MainContentGrid";
import { Item } from "../../../_5Pages/4DashboardPage/DashboardHelpers/Item";
import SemesterTableHead from "./SemesterTableHead";
import SemesterTableBody from "./SemesterTableBody";
import { useTheme } from "@mui/styles";

const SemesterTableContent = ({ currentSemesterData }) => {
  // console.log(currentSemesterData)
  const theme = useTheme()
  return (
    <MainContentGrid>
      <Grid item xs={12}>
        <Item
          elevation={5}
          sx={{
            paddingLeft: { xs: 1, ss: 2, lg: 3 },
            paddingRight: { xs: 1, ss: 2, lg: 3 },
          }}
        >
          <Grid
            container
            direction="row"
            spacing={0}>
            {/* Spacer to push down content */}
            <Grid item xs={12} 
            // style={{ backgroundColor: "red" }}
            >
              <div style={{ height: "1em" }} />
            </Grid>
            <Grid
              container
              direction="row"
              spacing={0}
              justifyContent="space-between"
              alignItems="flex-start"
              marginTop="0em"
              padding={0}
              // overflow="scroll"
              height="calc(100vh - 17em)"
              sx={{
                position: "relative",
                overflowY: "auto",
                overflowX: "auto", // Hide x-axis scrollbar
                "&::-webkit-scrollbar": {
                  width: "0px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "your-desired-color", // Set your desired color
                  borderRadius: "6px",
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent", // or any background color
                },
              }}
            >

              <Table
                stickyHeader
                aria-label="sticky table"
                style={{ position: "relative", zIndex: 0}}
              >
                <SemesterTableHead />
                <SemesterTableBody
                  currentSemesterData={currentSemesterData}
                />
              </Table>
            </Grid>
          </Grid>
        </Item>
      </Grid>
    </MainContentGrid>
  );
};

export default memo(SemesterTableContent);
