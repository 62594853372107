// Function to get all the coreCourses involved in an assignment
export const updateInvolvedCoreCourses = (assignment, samePropertyAssignments) => {
    const uniqueCoreCourseIds = new Set();

    // Loop through all assignments in samePropertyAssignments
    samePropertyAssignments.forEach((samePropertyAssignment) => {
        // Check if core_course_id is defined and not null
        if (samePropertyAssignment.core_course_id !== undefined && samePropertyAssignment.core_course_id !== null) {
            // Add unique core_course_id to the set
            uniqueCoreCourseIds.add(samePropertyAssignment.core_course_id);
        }
    });

    // Convert the set to an array and assign it to assignment.core_course_id
    assignment.core_course_id = Array.from(uniqueCoreCourseIds);

    return assignment;
};