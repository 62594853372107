import { memo } from "react";
import { Avatar, Box, Grid, LinearProgress, Typography } from "@mui/material";
import {
  SentimentSatisfied,
  SentimentVeryDissatisfied,
  SentimentDissatisfied,
  SentimentVerySatisfied,
} from "@mui/icons-material";
import { useTheme } from "@mui/styles";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";

function AssignmentProgress() {
  const { percentageDone, thisWeekMinutesTotal,  } = useAppContext();
  const theme = useTheme();

  const getAvatarIcon = () => {
    if (percentageDone < 20) {
      return SentimentVeryDissatisfied;
    } else if (percentageDone < 60) {
      return SentimentDissatisfied;
    } else if (percentageDone < 80) {
      return SentimentSatisfied;
    } else {
      return SentimentVerySatisfied;
    }
  };

  const getAvatarColor = () => {
    if (percentageDone < 20) {
      return theme.palette.levels.red;
    } else if (percentageDone < 60) {
      return theme.palette.levels.orange;
    } else if (percentageDone < 80) {
      return theme.palette.levels.lightGreen;
    } else {
      return theme.palette.levels.green;
    }
  };
  const AvatarIcon = getAvatarIcon();
  const avatarColor = getAvatarColor();


  // console.log("percentageDone,", percentageDone, "thisWeeksMinutesTotal: ", thisWeekMinutesTotal)

  return (
    <>
      <Grid container sx={{ justifyContent: "space-between", width: "100%" }}>
        <Grid item sx={{ width: "100%" }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h7" color="primary">
                {thisWeekMinutesTotal > 0 ? `${percentageDone}% erledigt` : `Diese Woche: nix zu tun`}
              </Typography>
            </Grid>
            <Grid item>
              {thisWeekMinutesTotal > 0 ?
                <Avatar
                  sx={{ backgroundColor: avatarColor, height: 20, width: 20 }}
                >
                  <AvatarIcon style={{ color: "black" }} fontSize="medium" />
                </Avatar> : <Avatar
                  sx={{
                    backgroundColor: theme.palette.levels.green,
                    height: 20,
                    width: 20,
                  }}
                >
                  <SentimentVerySatisfied
                    style={{ color: "black" }}
                    fontSize="medium"
                  />
                </Avatar>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "0.1em" }}>
        <LinearProgress value={thisWeekMinutesTotal > 0 ? percentageDone : 100} variant="determinate" />
      </Box>
    </>

  );
}

export default memo(AssignmentProgress);
