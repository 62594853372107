import React, { memo } from "react";
import { CustomTextField } from "../../../../../_3HelperFunctions/CustomTextField";
import { Grid } from "@mui/material";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";
import { useTheme } from "styled-components";

function DescriptionBox({
  restricted,
  previewAssignment,
  description,
  setDescription,
  changesCount,
  setChangesCount,
}) {
  const theme = useTheme()
  const { appInDarkMode } = useAppContext()
  const original = previewAssignment.description === null ? "" : description

  // useEffect(()=>{
  //   console.log("count", changesCount)
  // },[changesCount])
  const change = (e) => {
    let changesCountCopy = changesCount;
    if (e.target.value === original) {
      //we have gone back to default
      // console.log("back go normal...")
      setChangesCount(changesCountCopy - 1);
    } else if (description !== original) {
      // this is not our first change
      // console.log("do nothing...")
    } else {
      // new change
      // console.log("change...")
      setChangesCount(changesCountCopy + 1);
    }
    setDescription(e.target.value);
  };
  return (
    <>
      <Grid item xs={12}>
        <CustomTextField
          sx={{ width: "100%" }}
          id="description"
          label={
            !restricted
              ? "Kommentar / Inhalt vom Assignment"
              : "Lehrerkommentar: "
          }
          // InputLabelProps={{style: {: "white"}}}
          multiline
          disabled={restricted}
          rows={3}
          defaultValue={restricted && description === null ? "" : description}
          placeholder="das sehen alle"
          onChange={change}
          variant="outlined"
          inputProps={{ style: { fontSize: "0.9em" } }}
          appindarkmode={appInDarkMode.toString()}
          theme={theme}
        />
      </Grid>
    </>
  );
}

export default memo(DescriptionBox);
