import { useState } from "react";
import { memo } from "react";
import { DashboardContext } from "../../_8HusibuechContexts/dashboardContext";
import SnackbarTaskCompleted from "../../_4GeneralHelpers/5_Snackbars/SnackbarTaskCompleted";
import Dashboard from "./Dashboard";
import SnackbarChangesManaged from "../../_4GeneralHelpers/5_Snackbars/SnackbarChangesManaged";

function DashboardTheme() {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [changeManaged, setOpenChangeManaged] = useState(false);

  return (
    <>
      <DashboardContext.Provider
        value={{
          openSnackbar,
          setOpenSnackbar,
          changeManaged,
          setOpenChangeManaged,
        }}
      >
        <Dashboard />

        <SnackbarTaskCompleted open={openSnackbar} setOpen={setOpenSnackbar} />
        <SnackbarChangesManaged
          open={changeManaged}
          setOpen={setOpenChangeManaged}
        />
      </DashboardContext.Provider>
    </>
  );
}
export default memo(DashboardTheme);
