import { memo, useState } from "react";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import appService from "../../../_6Services/app.service";
import moment from "moment";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RegisterSemester() {
  const { husibuechSemesters, sethusibuechSemesters } = useAppContext();

  const [change, setChange] = useState(false);
  const [success, setSuccess] = useState(false);

  const [localLoading, setLocalLoading] = useState(false);

  const [startDate, setStartDate] = useState("");
  const [startDateErr, setStartDateErr] = useState({});
  const [endDate, setEndDate] = useState("");
  const [endDateErr, setEndDateErr] = useState({});

  const [message, setMessage] = useState("");
  const [semesterName, setSemesterName] = useState("");
  const [subjectNameErr, setsubjectNameErr] = useState({});
  const theme = useTheme();

  
  const onChangeSemesterName = (e) => {
    setsubjectNameErr({})
    setSemesterName(e.target.value);
    setChange(e.target.value.length?true:false)
  };

  const onChangeStartDate = (e) => {
    setStartDateErr({});
    setStartDate(e.target.value);
    setChange(e.target.value.length?true:false)

  };

  const onChangeEndDate = (e) => {
    setEndDateErr({});
    setEndDate(e.target.value);
    setChange(e.target.value.length?true:false)

  };

  const onSubmit = (event) => {
    event.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      registerSemester();
    } else {
      setStartDate("");
      setTimeout(() => {
        // go back to normal
        setMessage("");
        setChange(false);
      }, 4000);
    }
  };

  const formValidation = () => {
    let startDateErr = {};
    let endDateErr = {}

    let isValid = true;

    //design the desired rules for form completion here:

    if (!moment(startDate)) {
      startDateErr.noStartDate =
        "Bitte Anfangsdatum mit Format YYYY-MM-DD eingeben!";
      isValid = false;
    }

    if (!moment(endDate)) {
      endDateErr.noStartDate = "Bitte Enddatum mit Format YYYY-MM-DD eingeben!";
      isValid = false;
    }

    if (!moment(endDate).isAfter(moment(startDate))) {
      endDateErr.dateConflict = "Enddatum liegt for Startdatum!";
      isValid = false;
    }

    setStartDateErr(startDateErr);
    setEndDateErr(endDateErr);

    return isValid;
  };
  const updateLocalSystemData = (newSemester) => {
    let updatedSystemData = husibuechSemesters;
    updatedSystemData = updatedSystemData.concat(newSemester);
    sethusibuechSemesters(updatedSystemData);
  };
  const registerSemester = () => {
    setLocalLoading(true);
    const newSemester = {
      name: semesterName,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    };
    // console.log(newSemester)
    appService
      .registerNewSemester(newSemester)
      .then(
        (response) =>
          updateLocalSystemData(response.data) +
          setLocalLoading(false) +
          setSuccess(true) +
          setMessage("Update erfolgreich") +
          setSemesterName("") +
          setStartDate("") +
          setEndDate("") +
          setTimeout(() => {
            setSuccess(false);
            setMessage("");
            setStartDateErr({});
            setEndDateErr({});
            setChange(false);
          }, 2500)
      )
      .catch((error) => {
        setLocalLoading(false);
        setMessage(
          error.response.data.message
            ? error.response.data.message
            : "ungültige Angaben"
        );
        setTimeout(() => {
          setMessage("");
          setStartDateErr({});
          setEndDateErr({});
          setChange(false);
        }, 4000);
      });
  };

  return (
    <Card>
      <CardHeader
        title="Neues Semester"
        subheader="hinzufügen"
        subheaderTypographyProps={{ color: theme.palette.levels.white }}
        sx={{
          color: theme.palette.levels.white,
          backgroundColor: theme.palette.levels.blue,
        }}
      />
      <Divider />
      <CardContent>
        {/* Box container */}
        <Grid
          container
          direction="row"
          spacing={3}
          justifyContent="space-between"
        >
          {/* Form item */}
          <Grid item md={6} xs={12}>
            <form id="registerSemester" onSubmit={onSubmit}>
              {/* Form container including the button */}
              <Grid
                container
                direction="column"
                spacing={3}
                justifyContent="center"
              >
                <Grid item md={6} xs={12}>
                  <InputLabel id="semesterName">
                    Name für neues Semester *
                  </InputLabel>
                  <TextField
                    type="name"
                    placeholder="Name * (Bsp: HS 2324)"
                    fullWidth
                    name="name"
                    variant="outlined"
                    required
                    autoFocus={false}
                    value={semesterName}
                    onChange={onChangeSemesterName}
                  />
                </Grid>
                {Object.keys(subjectNameErr).map((key) => {
                  return (
                    <Grid
                      item
                      md={6}
                      xs={12}
                      className="alert alert-danger"
                      role="alert"
                    >
                      {subjectNameErr[key]}
                    </Grid>
                  );
                })}
                <Grid item md={6} xs={12}>
                  <Grid item md={6} xs={12}>
                    <InputLabel id="semesterStartDate">
                      Anfangsdatum *
                    </InputLabel>
                    <TextField
                      type="startDate"
                      placeholder="YYYY-MM-DD"
                      fullWidth
                      name="startDate"
                      variant="outlined"
                      required
                      autoFocus={false}
                      value={startDate}
                      onChange={onChangeStartDate}
                    />
                  </Grid>
                  {Object.keys(startDateErr).map((key) => {
                    return (
                      <Grid
                        item
                        md={12}
                        xs={12}
                        className="alert alert-danger"
                        role="alert"
                      >
                        {startDateErr[key]}
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid item md={6} xs={12}>
                    <InputLabel id="semesterEndDate">Enddatum *</InputLabel>
                    <TextField
                      type="endDate"
                      placeholder="YYYY-MM-DD"
                      fullWidth
                      name="endDate"
                      variant="outlined"
                      required
                      autoFocus={false}
                      value={endDate}
                      onChange={onChangeEndDate}
                    />
                  </Grid>
                  {Object.keys(endDateErr).map((key) => {
                    return (
                      <Grid
                        item
                        md={12}
                        xs={12}
                        className="alert alert-danger"
                        role="alert"
                      >
                        {endDateErr[key]}
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item md={6} xs={12}>
                  {!localLoading && change && (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="button-block"
                      disabled={localLoading}
                    >
                      Erfassen
                    </Button>
                  )}
                  {localLoading && <LinearProgress />}
                </Grid>
                {message && success && (
                  <Grid item>
                    <Typography className="alert alert-success" role="alert">
                      {message}
                    </Typography>
                  </Grid>
                )}
                {message && !success && (
                  <Grid item>
                    <Typography className="alert alert-danger" role="alert">
                      {message}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </form>
          </Grid>
          {/* Stystem information item */}
          <Grid item md={6} xs={12}>
            <InputLabel id="systemSemesters">Semester *</InputLabel>
            <Select
              displayEmpty
              onChange={null}
              input={<OutlinedInput />}
              value={``}
              renderValue={(selected) => {
                return <em>bereits im System vorhanden</em>;
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem disabled value="">
                <em>bereits erfasst</em>
              </MenuItem>
              {husibuechSemesters &&
                husibuechSemesters.length > 0 &&
                husibuechSemesters.map((semester, i) => (
                  <MenuItem key={i} value={semester}>
                    {semester.name +
                      ": " +
                      moment(semester.start_date).format("DD.MM.yyyy") +
                      " - " +
                      moment(semester.end_date).format("DD.MM.yyyy")}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
    </Card>
  );
}
export default memo(RegisterSemester);
