import "./styles.css";
import React, { memo, useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";
import ReactCardFlip from "react-card-flip";
import SignUpForm from "../SignUpForm/SignUpForm";
import LoginForm from "./LoginForm";
import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import AOS from "aos";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import {
  CheckBox,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";

function LoginStory() {
  const { isMobile } = useAppContext()
  const [seeLogin, setSeeLogin] = useState(true);
  const [seeRegister, setSeeRegister] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);
  const [showDropdown4, setShowDropdown4] = useState(false);
  const [showDropdown5, setShowDropdown5] = useState(false);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    setLoading(true)
    // AOS.init();
    // AOS.refresh(); // Refresh AOS after initialization
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  useEffect(()=>{
    if(!loading){
      AOS.init();
      AOS.refresh(); // Refresh AOS after initialization
    }
  })

  const changeView = () => {
    setSeeLogin(!seeLogin);
    setSeeRegister(!seeRegister);
  };

  if (loading === true) {
    return (
      <div className="background" sx={{
        width: "100%", overflowX: "hidden", // Hide horizontal overflow
      }}>
        <Grid
          container
          sx={{
            width: "100%",
            marginTop: "20em",
            display: "flex",
            justifyContent: "space-around",
            overflowX: "hidden"
          }}
          direction="row"
          spacing={0}
          alignItems="center"
        >
          <div className="monkey">
            <img className="loading" src="./static/images/login/logo512.png" />
          </div>
        </Grid>
      </div>
    );
  } else {
    return (
      <div
        className="background"
        style={{
          width: "100%", overflowX: "hidden", // Hide horizontal overflow
        }}
      >

        {/* <Grid
          container
          sx={{
            width: "100%",
            marginTop: "-4em",
            display: "flex",
            // justifyContent: "space-around",
          }}
          direction="row"
          spacing={0}
          alignItems="center"
        > */}
        <div className="App">
          <div className="monkey">
            <img style={{ opacity: "0" }} src="./static/images/login/ape.gif" />
          </div>
          <div className="clouds">
            <img style={{ opacity: "0", width: "100%" }} src="./static/images/login/clouds.png" />
          </div>
          <div className="header" data-aos="zoom-in-down" data-aos-duration="1000">
            <div className="headerBody">
            </div>
            <div>
              <Grid
                container
                sx={{
                  width: "100%",
                  // height: 400,
                  // marginTop: 10,
                  // backgroundColor: "red",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                direction="row"
                spacing={0}
                alignItems="center"
              >
                <Paper
                  variant="elevation"
                  elevation={5}
                  sx={{ borderRadius: 10, width: isMobile ? "90%" : "50%", maxWidth: 700, minWidth: isMobile ? 300 : 470 }}
                >
                  <Grid item xs={12} ss={12} sm={12} md={12} padding={3}>
                    <ReactCardFlip isFlipped={seeLogin} flipDirection="horizontal">
                      <SignUpForm flipCard={changeView} />
                      <LoginForm flipCard={changeView} />
                    </ReactCardFlip>
                  </Grid>
                </Paper>
              </Grid>
            </div>
          </div>

          <div className="body">
            <div className="storyline" id="storyline">
              <h1
                className="subTitle"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Was ist
              </h1>
              <h1 className="title" data-aos="fade-up" data-aos-duration="1000">
                Husibuech?
              </h1>
              <div
                key={1}
                className="section"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div>
                  <img className="left" src="./static/images/story/storyline1.png" />
                </div>
                <div className="rightText">
                  <h1>
                    Husibuech zeigt auf, wie die zeitliche Belastung bei Lernenden aussieht. Je genauer
                    Aufgaben erfasst werden, umso besser können wir visualisieren, was das bedeutet - sei es durch
                    die Woche hindurch, oder über das ganze Jahr hinweg.
                  </h1>
                </div>
              </div>
              <div
                key={2}
                className="section"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="leftText">
                  <h1>
                    Unsere verschiedenen Übersichten basieren auf den Aufgaben die im System erfasst werden.
                    Es gibt einige Features zu entdecken...
                  </h1>
                </div>
                <div>
                  <img className="right" src="./static/images/story/storyline9.png" />
                </div>
              </div>
              <div
                key={3}
                className="section"
                // style={{width:"50%"}}
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div>
                  <img className="left" src="./static/images/story/storyline3.png" />
                </div>
                <div className="rightText">
                  <h1>
                    Bei Husibuech kannst du auch persönliche Dinge, wie ein Fussball Training, eine Band Probe oder
                    Dinge, die du dir vorgenommen hast, eintragen. Referenzschüler*innen und Lehrpersonen können Hausaufgaben für
                    die ganze Klasse erfassen. Teamarbeit hilft, dass Nichts vergessen geht. Über den Upload können Lehrpersonen auch
                    die schulNetz Daten der Klassen importieren.
                  </h1>
                </div>
              </div>
              <div
                key={4}
                className="section"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="leftText">
                  <br />
                  <br />
                  <h1>Im Workboard kannst du Assignments verschieben und bequem den Status anpassen, während du
                    etwas am Erledigen bist. Hier kannst du auch zusätzliche Aufgaben, die noch etwas in der Zukunft liegen, aber deine Planung bereits
                    beeinflussen einplanen.
                  </h1>
                </div>
                <div>
                  <img className="right" src="./static/images/story/storyline11.png" />
                </div>
                <br />
              </div>
              <br />
              <div
                key={5}
                className="section"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div>
                  <img className="left" src="./static/images/story/storyline6.png" />
                </div>
                <div className="rightText">
                  <h1>Unser Prüfungplan bietet Lernenenden und Lehrpersonen eine klare Übersicht über die Prüfungen,
                    die anstehen. Man hat das ganze Semester auf einen Blick.
                  </h1>
                </div>
              </div>
              <div
                key={6}
                className="section"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="leftText">
                  <h1>Nutze die iDo Liste auf Husibuech. Gerade wenn man etwas unter Druck steht,
                    kann es sein, dass man Priorisieren muss. Nutze unsere Priorisierungstechnik, damit
                    du Wichtiges von und Wichtigem und Dringliches von nicht Dringlichem unterscheiden kannst.
                  </h1>
                </div>
                <div>
                  <img className="rightExtra" src="./static/images/story/storyline7.png" />
                </div>
              </div>
              <div
                key={7}
                className="section"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div>
                  <br />
                  <img className="left" src="./static/images/story/storyline8.png" />
                </div>
                <div className="rightText">
                  <br />
                  <br />
                  <h1>Um das Zeitmanagment nachhaltig in den Griff zu kriegen, sollte man auch etwas in Nachkontrolle investieren. Husibuech bietet einen guten Überblick darüber,
                    wo wie viel Zeit anfällt und wie man die Ziele erreicht hat.
                    Das bietet auch die Möglichkeit auf Lehrpersonen zuzugehen und zu zeigen,
                    wie viel Zeit effektiv in Aufgaben investiert wird.
                  </h1>
                  <br />
                </div>
                <br />
              </div>
              <br />
              <br />
              <br />
              <br />
              <br /> 
              <br />
              <br />
              <br />
              <br />
              <br />
              <div
                key={8}
                className="section"
                // data-aos="fade-up"
                data-aos="flip-left"

                data-aos-duration="1000"
              >
                <div className="leftText">
                  <h1 style={{ color: 'orange' }}> ...und JA! uns gibt es auch "mobile".</h1>
                  <h1>
                    Die mobile Version unserer App ist zwar nicht perfekt, aber
                    sie erlaubt dir, viele Dinge bequem auf dem Handy zu erledigen.
                    Einfach unseren Link auf dem Homescreen speichern und schon bist du
                    in einem Tap im Husibuech. Wir hoffen, dass dir das Tool bei deiner Planung hilft.
                    Entdecke noch viele weitere Dinge in unserer App und
                    gib uns ab und zu mal ein Feedback!
                  </h1>
                </div>
                <div>
                  {/* <br /> */}
                  <img className="rightExtra" src="./static/images/story/storyline10.png" />
                </div>
              </div>
            </div>
            <div className="roadmap" id="roadmap">
              <h1
                className="subTitle"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                ROADMAP
              </h1>
              <h1 className="title" data-aos="fade-up" data-aos-duration="1000">
                Neu bei Husibuech...
              </h1>

              <div
                key={9}
                className="section"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h3>Registrieren</h3>
                <br />
                <ul>
                  <li>Du registrierst dich auf dieser Seite mit deiner SLUZ-Adresse.
                    Danach wirst du eine Mail mit einem Verifizierungslink erhalten und du kannst dich bei Husibuech einloggen.</li>
                </ul>
                <br />
              </div>
              <div
                key={10}
                className="section"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h3>Das erste Login</h3>
                <br />
                <ul>
                  <li>Am Anfang braucht es halt etwas. Wähle deine Klasse aus. Lehrpersonen sollten ihr offizielles Kürzel verwenden, damit der Datenimport aus schulNetz gut funktioniert.</li>
                  <li>
                    Danach kannst du gleich anfangen Husibuech zu nutzen. Kontaktiere uns, wenn du Referenzschüler*in werden
                    möchtest (Kontakt unten). Dann kannst du auch für deine ganze Klasse Hausaufgaben und Prüfungen eintragen.
                  </li>
                  <li>
                    Für Lehrpersonen: Durch einen schulNezt Datenimport (Excel Datei im Bereich "Prüfungen" herunterladen) werden automatisch Kurse angelegt und Prüfungen erfasst.
                  </li>
                </ul>
                <br />
              </div>
              <div
                key={11}
                className="section"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h3>Husibuech nutzen</h3>
                <br />
                <ul>
                  <li>Du möchtest dein Passwort ändern oder hast einen Kurs falsch eingetragen? Kein Problem. Schau bei der Profilseite rein und passe deine Einstellungen an.</li>
                </ul>
              </div>
            </div>
            <div className="team" data-aos="fade-up" data-aos-duration="1000">
              <h1 className="subTitle">TEAM</h1>
              <h1 className="title">Husibuech Founders</h1>
              <div className="teamInner">
                <div className="teamMember">
                  <img src="./static/images/team/Maverick.png" />
                  <h1>Mr. P</h1>
                  <h2>Creator | Admin</h2>
                  <div className="line" />
                  <h3>
                    English & IT teacher, ICT Support
                  </h3>
                  <h3>
                    Kontakt: roman.pfaeffli@sluz.ch
                  </h3>
                </div>
                {/* <div className="teamMember">
                    <img src="./static/images/team/Iceman.png" />
                    <h1>Mr. B</h1>
                    <h2>Co-Creator</h2>
                    <div className="line" />
                    <h3>Informatik und Mathematik, Stundenplaner, ICT Support</h3>
                  </div>
                  <div className="teamMember">
                    <img src="./static/images/team/Zoomy.png" />
                    <h1>Aerial Ape</h1>
                    <h2>Project Advisor & Social Media</h2>
                    <div className="line" />
                    <h3>Dapper Ducks Co-Founder & Airline Pilot</h3>
                  </div> */}
              </div>
            </div>
            <div className="faq" data-aos="fade-up" data-aos-duration="1000">
              <h1 className="subTitle">FAQ</h1>
              <h1 className="title">Häufige Fragen</h1>
              <div
                className="question"
                onClick={() => setShowDropdown1(!showDropdown1)}
              >
                <div style={{ width: "100%", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                  <div style={{ width: "95%", marginLeft: "1.5em", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                    <h1>
                      Was ist Husibuech?
                    </h1>
                  </div>
                  <div style={{ width: "5%", display: 'flex', justifyContent: "center", alignItems: 'center', }}>
                    {showDropdown1 ? <CheckBox /> : <CheckBoxOutlineBlank />}
                  </div>
                </div>
                {showDropdown1 ? (
                  <h2>
                    Husibuech ist ein Planungstool, welches Lernenden helfen kann, einen besseren Überblick
                    über die anstehenden Aufgaben zu erhalten.
                    Durch ein cleveres Zeitmanagment kann Schulstress reduziert werden.
                  </h2>
                ) : null}
              </div>
              <div className="line" />
              <div
                className="question"
                onClick={() => setShowDropdown2(!showDropdown2)}
              >
                <div style={{ width: "100%", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                  <div style={{ width: "95%", marginLeft: "1.5em", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                    <h1>
                      Wer kann Husibuech nutzen?
                    </h1>
                  </div>
                  <div style={{ width: "5%", display: 'flex', justifyContent: "center", alignItems: 'center', }}>
                    {showDropdown2 ? <CheckBox /> : <CheckBoxOutlineBlank />}
                  </div>
                </div>
                {showDropdown2 ? (
                  <h2>
                    Aktuell ist Husibuech für Lernende und Lehrpersonen im Kanton Luzern zugänglich.
                    Für einen Ausbau des Systems an Ihrer Schule sollte Husibuech kontaktiert werden, damit
                    die App auf die jeweiligen Schuleigenschaften abgestimmt werden kann.
                  </h2>
                ) : null}
              </div>
              <div className="line" />
              <div
                className="question"
                onClick={() => setShowDropdown3(!showDropdown3)}
              >
                <div style={{ width: "100%", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                  <div style={{ width: "95%", marginLeft: "1.5em", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                    <h1>
                      Kostet Husibuech etwas?
                    </h1>
                  </div>
                  <div style={{ width: "5%", display: 'flex', justifyContent: "center", alignItems: 'center', }}>
                    {showDropdown3 ? <CheckBox /> : <CheckBoxOutlineBlank />}
                  </div>
                </div>
                {showDropdown3 ? (
                  <h2>
                    Nein, Husibuech ist kostenlos für die bereits erfassten Schulen.
                  </h2>
                ) : null}
              </div>
              <div className="line" />
              <div
                className="question"
                onClick={() => setShowDropdown4(!showDropdown4)}
              >
                <div style={{ width: "100%", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                  <div style={{ width: "95%", marginLeft: "1.5em", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                    <h1>
                      Verwendet Husibuech SchulNetz Daten?
                    </h1>
                  </div>
                  <div style={{ width: "5%", display: 'flex', justifyContent: "center", alignItems: 'center', }}>
                    {showDropdown4 ? <CheckBox /> : <CheckBoxOutlineBlank />}
                  </div>
                </div>
                {showDropdown4 ? (
                  <h2>
                    Husibuech ist ein unabhängiges System. Allerdings haben wir für Lehrpersonen eine Funktion
                    eingebaut, damit der Datenimport aus SchulNetz möglich ist. Dies vereinfacht den Anfangsprozess.
                    Achtung: Husibuech verändert keine Daten auf Schulnetz!
                  </h2>
                ) : null}
              </div>
              <div className="line" />
              <div
                className="question"
                onClick={() => setShowDropdown5(!showDropdown5)}
              >
                <div style={{ width: "100%", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                  <div style={{ width: "95%", marginLeft: "1.5em", display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                    <h1>
                      Wie kann ich Hausaufgaben und Prüfungen erfassen?
                    </h1>
                  </div>
                  <div style={{ width: "5%", display: 'flex', justifyContent: "center", alignItems: 'center', }}>
                    {showDropdown5 ? <CheckBox /> : <CheckBoxOutlineBlank />}
                  </div>
                </div>
                {showDropdown5 ? (
                  <h2>
                    Lernende können persönliche Assignments erfassen. Wer Hausaufgaben und Prüfungsdaten auch in der Klasse teilen möchte,
                    muss Referenzschüler*in werden. Kontaktiere dazu das Husibuech Team (siehe oben).
                  </h2>
                ) : null}
              </div>
            </div>
            <div className="footer">
              <h1>Copyright © KSB 2024</h1>
            </div>
          </div>
        </div >
        {/* </Grid > */}
      </div >


    );
  }
}

export default memo(LoginStory);
