import React, { memo } from "react";
import ExamTypeBoxComponent from "./ExamTypeBoxComponent";

function ExamTypeBox({
  restricted,
  previewAssignment,
  assignment,
  assignmentExamTypeId,
  setAssignmentExamTypeId,
  changesCount,
  setChangesCount,
}) {
  const change = (e) => {
    // console.log(husibuechExamTypes);
    // console.log(e.target.value);
    let changesCountCopy = changesCount;
    if (e.target.value === previewAssignment.husibuechExamTypes.id) {
      //we have gone back to default
      setChangesCount(changesCountCopy - 1);
    } else if (assignment.exam_type_id !== assignmentExamTypeId) {
      // this is not our first change
      // do noting
    } else {
      // it's our first change
      setChangesCount(changesCountCopy + 1);
    }
    setAssignmentExamTypeId(parseInt(e.target.value));
  };
  return (
    <>
      <ExamTypeBoxComponent
        restricted={restricted}
        examTypeId={assignmentExamTypeId}
        change={change}
      />
    </>
  );
}

export default memo(ExamTypeBox);
