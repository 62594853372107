import React, { useState, useRef, memo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Checkbox,
  LinearProgress,
} from "@mui/material";
import MainContentGrid from "../../../_4GeneralHelpers/1_Boxes/MainContentGrid";
import { Item } from "../../4DashboardPage/DashboardHelpers/Item";
import XLSX from "xlsx";
import dayjs from "dayjs";
import assignmentService from "../../../_6Services/assignment.service";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import { createCoreCourseName, createCourseName, createSubjectTag, createTeacherName } from "../../../_3HelperFunctions/_NameHelpers/nameHelpers";
import moment from "moment";
import CircularStatic from "../../0Navigation/3Loading/CircularProgressWithLabel";
import ButtonController from "../../3ProfilePage/ProfileHelpers/ButtonController";

const EXTENSIONS = ["xlsx", "xls"];
const initialCols = ["Name", "Datum", "Kurs", "Klasse", "Mischklasse?", "Fach", "Lehrperson", "Gewicht", "gespeichert am"];
const jsonCols = ["name", "due_date", "course_name", "core_course", "mixed_course", "tag", "creator", "counting_factor", "create_date", "hello"];

const styles = {
  table: {
    fontSize: '0.8rem', // Adjust the font size as needed
  },
  tableCell: {
    padding: '0.4rem', // Adjust the padding as needed
    fontSize: '0.7rem'
  },
};

function UploadPageContent() {
  const { setUpdateCurrentUserData, setSchoolYearLoading } = useAppContext()
  const [assignmentList, setAssignmentList] = useState([])
  const [localLoading, setLocalLoading] = useState(false)
  const [text, setText] = useState("Keine Daten vorhanden");
  const [colDefs, setColDefs] = useState(
    initialCols.map((col) => ({
      title: col,
      field: col.toLowerCase(),
    }))
  );
  const fileInputRef = useRef(null);

  const getExention = (file) => {
    const parts = file.name.split(".");
    const extension = parts[parts.length - 1];
    return EXTENSIONS.includes(extension);
  };

  const importExcel = (e) => {
    setLocalLoading(true)
    let file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const bstr = event.target.result;
      const workBook = XLSX.read(bstr, { type: "binary" });
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName];
      const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });

      // console.log("originalData: ", fileData)
      var HEADERINDEX = 0;
      var x = 0;
      while (fileData[x][0] === null || fileData[x][0] !== "Bezeichnung") {
        HEADERINDEX += 1;
        x++;
      }

      //deal with the header of the file
      var headers = fileData[HEADERINDEX];
      const kursIndex = headers.indexOf("Kurs");
      headers.splice(kursIndex + 1, 0, "Fach");
      headers.splice(kursIndex + 2, 0, "Lehrer");

      // var heads = headers.map((head) => ({
      //   title: head
      //     .replace(/Gesp./g, "gespeichert am")
      //     .replace(/Gw/g, "Gewicht")
      //     .replace(/Abg./g, "abgegeben"),
      //   field: head.charAt(0).toLowerCase() + head.slice(1),
      // }));

      // setColDefs(heads);

      for (let i = 0; i <= HEADERINDEX; i++) {
        fileData.splice(0, 1);
      }
      let updateAssignments = []
      // console.log(fileData)
      fileData.forEach((assignment) => {
        if (assignment && assignment[0] !== undefined && assignment[0] !== " ") {
          // console.log("original: ", assignment)

          let assignment_name = assignment[0]
          // let due_date = dayjs(assignment[1], 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm:ss');
          let due_date = assignment[1]
          let course_name = createCourseName(assignment[2]);
          let core_course = createCoreCourseName(assignment[2])
          let mixed_course = false
          let tag = createSubjectTag(assignment[2])
          let creator = createTeacherName(assignment[2])
          let counting_factor = assignment[4]
          // let create_date = dayjs(assignment[8], 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm:ss')
          let create_date
          if (assignment[8] === undefined) {
            create_date = moment(new Date()).format("DD.MM.YYYY")
          } else {
            create_date = assignment[8]
          }
          const row = [assignment_name, due_date, course_name, core_course, mixed_course, tag, creator, counting_factor, create_date]
          // console.log(row)
          updateAssignments.push(row)
        }
      })
      setAssignmentList(updateAssignments)
    };

    if (file) {
      if (getExention(file)) {
        reader.readAsBinaryString(file);
      } else {
        alert("Ungültiges Dokument, wähle ein Excel file");
        file = undefined
      }
    } else {
      setColDefs(initialCols.map((col) => ({
        title: col,
        field: col.toLowerCase(),
      })));
    }
    setLocalLoading(false)
  };

  const convertToJson = (assignment) => {
    let rowData = {};
    assignment.forEach((element, index) => {
      rowData[jsonCols[index]] = element;
    });
    return rowData;
  };

  const clear = () => {
    setAssignmentList([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the file input
    }
  };

  const uploadDataToHusibuech = async () => {
    setLocalLoading(true)
    let assignmentListCopy = [...assignmentList]
    let assignmentJson = []
    assignmentListCopy.forEach((assignment, index) => {
      // update date formats
      // console.log("assignment:", assignment)
      assignment[1] = dayjs(assignment[1], 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm:ss');
      assignment[8] = dayjs(assignment[8], 'DD.MM.YYYY').format('YYYY-MM-DD HH:mm:ss');
      assignmentJson.push(convertToJson(assignment))
    })
    // console.log(assignmentJson)

    try {
      await assignmentService.uploadAssignments(assignmentJson);
      //we fetch the entire userData...
      setTimeout(() => {
        setText("Assignments erfolgreich verarbeitet")
        setAssignmentList([])
        setLocalLoading(false)
        setTimeout(async () => {
          await setUpdateCurrentUserData(true)
          setTimeout(async () => {
            clear()
            await setSchoolYearLoading(true);
          }, 1000);
        }, 1000);
      }, 500*assignmentListCopy.length);
    } catch (error) {
      console.log(error)
    }
  };

  const changeSelection = (index) => {
    // console.log(index)
    let assignmentListCopy = [...assignmentList]
    let assignment = assignmentListCopy[index]
    assignment[4] = !assignment[4] // change the boolean
    assignmentListCopy[index] = assignment
    setAssignmentList(assignmentListCopy);
  };
  return (
    <MainContentGrid>
      <Grid item xs={12}>
        <Item
          elevation={5}
          sx={{
            padding: { xs: 2, md: 3 },
          }}
        >
          <Grid item xs={12} style={{ marginBottom: "1em" }}>
            <Typography variant="h6" gutterBottom>
              Importiere Dateien aus einem Schulnetz Excel File (Lehrpersonen)
            </Typography>
            <input type="file" ref={fileInputRef} onChange={importExcel} />
          </Grid>

          {assignmentList.length > 0 && (
            <Grid item xs={12}>
              <ButtonController
                localLoading={localLoading}
                disabled={localLoading}
                save={uploadDataToHusibuech}
                clear={clear}
                saveText={"Diese Daten im Husibuech speichern"} clearText={"verwerfen"}
              />
            </Grid>
          )}

          <br />
          <Grid
            container
            direction="row"
            spacing={0}
            justifyContent="space-between"
            alignItems="flex-start"
            padding={0}
            height="calc(100vh - 17em)"
            sx={{
              position: "relative",
              overflowY: "auto",
              overflowX: "auto",
              "&::-webkit-scrollbar": {
                width: "0px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "your-desired-color",
                borderRadius: "6px",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
            }}
          >
            <Table stickyHeader aria-label="sticky table" style={{ ...styles.table, position: "relative", zIndex: 0 }} sx={{ paddingBottom: "2em" }}>
              <TableHead>
                <TableRow>
                  {colDefs.map((col, index) => (
                    <TableCell key={index} align="left">
                      {col.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {localLoading ?
                  <TableRow>
                    <TableCell key={999} colSpan={colDefs.length} align="center">
                      <LinearProgress color="primary" />
                      <br />
                      <CircularStatic />
                      <br />
                      <LinearProgress color="primary" />
                    </TableCell>
                  </TableRow> :
                  assignmentList.length > 0 ? (
                    assignmentList.map((assignment, index) => (
                      <TableRow key={index}>
                        {assignment.map((col, colIndex) => (
                          colIndex === 4 ? <TableCell key={colIndex} align="left">
                            <Checkbox
                              checked={col}
                              onChange={(event) => changeSelection(index)}
                            />
                          </TableCell> :
                            <TableCell key={colIndex} align="left">
                              {col}
                            </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell key={999} colSpan={colDefs.length} align="center">
                        <Typography variant="body1" color="textSecondary">
                          {text}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </Grid>
        </Item>
      </Grid>
    </MainContentGrid>
  );
}

export default memo(UploadPageContent);

