import { memo } from "react";
import styles from "../../SignUpForm/styles.module.css";
import { Button } from "@mui/material";
import { useTheme } from "@mui/styles";

function MyButton({ job, text, disabled }) {
  const theme = useTheme();
  return (
    <Button
      disabled={disabled}
      variant="contained"
      onClick={() => job()}
      className={`${styles.white_btn}`}
      style={{
        borderRadius: "45px",
        marginBottom: "1em",
        backgroundColor: disabled ? theme.palette.primary.light : "", //while there are errors
      }}
    >
      {text}
    </Button>
  );
}

export default memo(MyButton);
