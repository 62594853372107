import { Stars } from "@mui/icons-material";
import { Typography, IconButton } from "@mui/material";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import StyledTooltip from "../../../_4GeneralHelpers/2_Tooltips/StyledTooltip";

function StarsCompleted({ flexValue }) {
  const {
    schoolYearData,
  } = useAppContext();
  const [stars, setStars] = useState(0);

  const getWeekAssignments = (arr, monday) => {
    let array = arr.filter(
      (assignment) =>
        moment(assignment.due_date).isSameOrAfter(
          moment(monday).startOf("day")
        ) &&
        moment(assignment.due_date).isSameOrBefore(
          moment(monday).endOf("isoWeek").endOf("day")
        )
    );
    return array;
  };

  useEffect(() => {
    const calcStars = async () => {
      try {
        if (!schoolYearData) {
          return 0;
        }

        const mondays = [
          ...schoolYearData?.mondaysFirstSemester,
          ...schoolYearData?.mondaysSecondSemester
        ].sort((a, b) => moment(a) - moment(b));

        let thisStars = 0;

        if (schoolYearData.userAssignments?.length > 0) {
          const assignments = schoolYearData.userAssignments;

          for (const monday of mondays) {
            const thisWeekAssignments = getWeekAssignments(assignments, moment(monday).startOf('day'));

            const allAssignmentsDone = thisWeekAssignments?.length === 0
              ? false
              : thisWeekAssignments.every(assignment => assignment.status === 4);

            if (allAssignmentsDone) {
              thisStars += 1;
            }
          }
        }

        return thisStars;
      } catch (error) {
        console.error('Error calculating stars:', error);
        return 0;
      }
    };

    const updateStars = async () => {
      const calculatedStars = await calcStars();
      setStars(calculatedStars);
    };

    updateStars();
  }, [schoolYearData]);

  return (
    <>
      <StyledTooltip
        title={`${stars} ${stars === 1 ? "Woche" : "Wochen"} mit 100% erledigt!`}
        placement="bottom-start"
        arrow
      >
        <IconButton sx={{ mt: -1 }}>
          <Typography
            variant="h5"
            sx={{ color: "#ffd700", fontSize: "0.85em", marginLeft: `${flexValue === "50%" ? "-0.4em" : "0"}`, mr: 0.2, mt: -0.2 }}
          >{stars}
          </Typography>
          <Stars sx={{ color: "#ffd700" }} />
        </IconButton>
      </StyledTooltip>
    </>
  );
}
export default memo(StarsCompleted);
