const {
  default: workloadService,
} = require("../../../../../_6Services/workload.service");
const { calculateWorkloadArray } = require("./calculateWorkloadArray");

async function calculateWorkloadMinutesChart(
  referenceDate,
  assignmentsByDay,
  key,
  extraAssignmentsByDay
) {
  // console.log(key, assignmentsByDay)
  const tempArray = await calculateWorkloadArray(
    referenceDate,
    assignmentsByDay,
    key,
    extraAssignmentsByDay
  );
  // console.log("tempArray", tempArray);

  return await workloadService.calculateWorkloadChart(tempArray);
}
exports.calculateWorkloadMinutesChart = calculateWorkloadMinutesChart;
