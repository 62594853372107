import { memo } from "react";
import { PageTemplate } from "../0_PageTemplate/PageTemplate";
import ProfileContent from "./ProfileHelpers/ProfileContent";

function Profile() {
  return (
    <div className="content" sx={{ clear: "left" }}>
      <PageTemplate type={"profile"}>
        <ProfileContent />
      </PageTemplate>
    </div>
  );
}

export default memo(Profile);
