import React, { useEffect, useState } from "react";
import { memo } from "react";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import AssignmentList from "./DeadlineBoxHelpers/AssignmentList";
import EmptyList from "./DeadlineBoxHelpers/EmptyList";
import { calculateDeadlineAssignments } from "./DeadlineBoxHelpers/calculateDeadlineAssignments";
import { calculateClassDeadlineAssignments } from "./DeadlineBoxHelpers/calculateClassDeadlineAssignments";
import { calculateAdditionalAssignments } from "./DeadlineBoxHelpers/calculateAdditionalAssignments";
import { calculateTopSix } from "../RecentlyAdded/RecentlyAddedHelpers.js/calculateTopSix";

function DeadlineAssignmentsList({ type }) {
  const {
    personalDeadlineAssignments,
    setPersonalDeadlineAssignments,
    additionalAssignments,
    setAdditionalAssignments,
    relevantUserAssignments,
    relevantClassAssignments,
    classDeadlineAssignments,
    setClassDeadlineAssignments,
    topSix, setTopSix,
    showCourseAssignmentOnly,
    selectedCourse,
    calculatingDeadlines,
    setCalculatingDeadlines,
    showExams,
    showHomeworks,
    statusSelection,
    prioritySelection,
    schoolYearData,
    isALeader
  } = useAppContext();

  const [calculatingInProgress, setCalculatingInProgress] = useState(undefined);

  useEffect(() => {
    const calculateData = async () => {
      if (type === "personal") {
        await calculateDeadlineAssignments(
          relevantUserAssignments,
          setPersonalDeadlineAssignments,
        );
      } else if (type === "additional") {
        await calculateAdditionalAssignments(
          relevantUserAssignments,
          setAdditionalAssignments
        );
      }
      else if (type === "topSix") {
        await calculateTopSix(
          schoolYearData,
          isALeader,
          showExams,
          showHomeworks,
          statusSelection,
          prioritySelection,
          setTopSix,
          selectedCourse
        );
      }
      else if (type === "class") {
        // console.log("relClass,", relevantClassAssignments)
        await calculateClassDeadlineAssignments(
          relevantClassAssignments,
          setClassDeadlineAssignments,
          selectedCourse,
          showCourseAssignmentOnly,
        );
      }
      setCalculatingDeadlines(false);
      setCalculatingInProgress(false);
    };

    if (calculatingDeadlines) {
      setCalculatingInProgress(true);
      calculateData();
    }
  }, [calculatingDeadlines]);

  const assignments =
    type === "personal"
      ? personalDeadlineAssignments
      : type === "additional"
        ? additionalAssignments
        : type === "topSix"
          ? topSix
          : classDeadlineAssignments;

  const hasAssignments = assignments?.length > 0;

  // useEffect(()=>{
  //   console.log("classDeadlineAssignments: ", classDeadlineAssignments)
  // })

  return (
    <>
      {calculatingInProgress && <p>Calculating deadlines...</p>}
      {!calculatingInProgress && (
        <>
          {hasAssignments ? (
            <AssignmentList type={type} assignments={assignments} classView={type === "class" || type === "topSix"} />
          ) : (
            assignments && <EmptyList type={type} />
          )}
        </>
      )}
    </>
  );
}

export default memo(DeadlineAssignmentsList);
