import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { useEffect, useState } from "react";
import moment from "moment";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { sortHolidays } from "./sortHolidays";
import { countWeeksUntilHoliday } from "./countWeeksUntilHoliday";
import { referenceDateIsDuringHoliday } from "./isDuringHoliday";

export const WeekPlannerView = ({ setSemesterWeek, isSmallScreen, isMobile }) => {
  const { referenceDate, holidays, schoolYearData, currentSemesterStartDate, setCurrentSemesterStartDate, setCurrentSemesterEndDate } =
    useAppContext();
  const [nextHoliday, setNextHoliday] = useState(undefined);
  const [weeksToHoliday, setWeeksToHoliday] = useState(0);
  const [isDuringHoliday, setIsDuringHoliday] = useState(false);
  const theme = useTheme();
  // const [show, setShow] = useState(false);

  //based on the reference date, define current semesterWeek, nextHoliday, and weeks until next holiday
  useEffect(() => {
    const fetchData = async () => {
      if (
        schoolYearData &&
        schoolYearData.datesFirstSemester &&
        schoolYearData.mondaysFirstSemester
      ) {
        const currentDate = new Date(referenceDate);
        if (referenceDate < schoolYearData.datesFirstSemester[1]) {
          setCurrentSemesterStartDate(schoolYearData.datesFirstSemester[0]);
          setCurrentSemesterEndDate(schoolYearData.datesFirstSemester[1])
        } else {
          setCurrentSemesterStartDate(schoolYearData.datesSecondSemester[0]);
          setCurrentSemesterEndDate(schoolYearData.datesSecondSemester[1])
        }
        // set reference to Monday
        const currentWeeksMonday = moment(currentDate)
          .startOf("week")
          .isoWeekday(1)
          .format("YYYY-MM-DD");

        // define referenced semester for SemesterWeek
        var mondaysList = [];
        // console.log("startDate:", currentSemesterStartDate)
        if (currentSemesterStartDate === schoolYearData.datesFirstSemester[0]) {
          mondaysList = schoolYearData.mondaysFirstSemester;
        } else {
          mondaysList = schoolYearData.mondaysSecondSemester;
        }
        // if currentDate is a Sunday, look ahead into the new week
        var currentSemesterWeek =
          mondaysList.indexOf(currentWeeksMonday)
        if (currentDate.getDay() === 0) {//offset if we use the app on a sunday
          currentSemesterWeek += 1
        }
        currentSemesterWeek += 1 //offset because index is 0

        setSemesterWeek(currentSemesterWeek);

        if (holidays) {
          try {
            const sortedHolidays = await sortHolidays(holidays, referenceDate);
            setNextHoliday(sortedHolidays[0]);

            if (sortedHolidays?.length) {
              const weeksToHoliday = await countWeeksUntilHoliday(
                sortedHolidays[0],
                referenceDate
              );
              await setWeeksToHoliday(weeksToHoliday);

              await setIsDuringHoliday(
                await referenceDateIsDuringHoliday(sortedHolidays[0], referenceDate)
              );
            }
          } catch (error) {
            console.error("Error fetching holidays:", error);
          }
        }
      }
    };
    if (referenceDate && holidays)
      fetchData(); // Invoke the asynchronous function immediately
  }, [referenceDate, schoolYearData, currentSemesterStartDate, holidays]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          style={{
            // backgroundColor: "yellow",
            width: "110%",
            whiteSpace: "nowrap", // Prevent content from wrapping
            textAlign: isMobile?"center":"", // Center along the x-axis
          }}
        >
          <Typography color="primary" variant="h4" fontWeight={"bold"}>
            {moment(referenceDate).startOf("isoWeek").format("DD") >
              moment(referenceDate).endOf("isoWeek").format("DD.")
              ? `${moment(referenceDate)
                .startOf("isoWeek")
                .format(isMobile || isSmallScreen ? "DD.MM." : "DD. MMM")}${isMobile || isSmallScreen ? " - " : "-"}${moment(referenceDate)
                  .endOf("isoWeek")
                  .format(isMobile || isSmallScreen ? "DD.MM." : "DD. MMMM")}`
              : `${moment(referenceDate)
                .startOf("isoWeek")
                .format("DD.")}${isMobile || isSmallScreen ? "-" : "-"}${moment(referenceDate)
                  .endOf("isoWeek")
                  .format(isMobile || isSmallScreen ? "DD. MMMM" : "DD. MMMM")}`}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            // backgroundColor: "yellow",
            width: "110%",
            whiteSpace: "nowrap", // Prevent content from wrapping
            textAlign: isMobile?"center":"", // Center along the x-axis
          }}
        >
          {/* <Box
            sx={{
              alignItems: "center",
              display: "flex",
              pt: 0,
            }} 
          >*/}
            {/* {weeksToHoliday > 1 ? ( */}
            {weeksToHoliday > 1 && (
              <Typography
                color={theme.palette.levels.green}
                sx={{
                  mr: 1,
                }}
                variant="caption"
                fontWeight={"bold"}
              >
                {weeksToHoliday}
              </Typography>
            )}

            {nextHoliday && <Typography color="primary" variant="caption">
              {isDuringHoliday
                ? nextHoliday.name
                : weeksToHoliday === 1
                  ? `Diese Woche ${isMobile || isSmallScreen ? "" : "beginnen "}${nextHoliday.name}!`
                  : `Wochen bis ${nextHoliday.name}!`}
            </Typography>}
          {/* </Box> */}
        </div>
      </Grid>
    </Grid>
  );
};
