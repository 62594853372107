export function countWeeksUntilHoliday(holiday, referenceDate) {
  // Get the start date of the first holiday
  const holidayStartDate = holiday.start_date;
  // Calculate the difference in milliseconds between the two dates
  const timeDifference = new Date(holidayStartDate) - new Date(referenceDate);

  // Convert the time difference to weeks
  const weeksDifference = timeDifference / (1000 * 60 * 60 * 24 * 7);

  // Round to the nearest whole number
  // console.log(Math.floor(weeksDifference))
  return Math.floor(weeksDifference) + 1;
}
