import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";

const logoutText = "wird ausgeloggt - mögen die Pläne gelingen!";

// export default function CircularProgressWithoutLabel() {
//   return (
//     <Box sx={{ display: 'flex' }}>
//       <CircularProgress />
//     </Box>
//   );
// }

export default function CircularProgressWithoutLabel(props) {
  return (
    <>
      <Grid container alignItems="center" direction="column">
        <Grid item>
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                Bye!
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item>
          <Typography variant="caption" component="div" color="text.secondary">
            {logoutText}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
