import axios from "axios";
import authHeader from "./auth-header";
import { SERVER_URL } from "../_2Deployment/constants";

class FeedbackService {
  
  createNewRegularFeedback(feedbackJSON) {
    // console.log(feedbackJSON);
    try {
      return axios.post(
        SERVER_URL + "feedback/",
        feedbackJSON,
        {
          headers: authHeader(),
        }
      );
    } catch (error) {
      // console.log("We had a problem with the feedack");
      return error;
    }
  }
}

const feedbackServiceInstance = new FeedbackService();
export default feedbackServiceInstance;

