import { dataDoesNotExistsCheck } from "../../_6Services/data.service.helpers/dataDoesNotExistsCheck.js";
import { dataWasUpdatedCheck } from "../../_6Services/data.service.helpers/dataWasUpdatedCheck.js";

export const schoolyearDataHasChanged = async (newDBData, currentSchoolyearData) => {
  try {
    let relevantWeekDataHasChanged = false;

    if (!currentSchoolyearData && newDBData) {
      relevantWeekDataHasChanged = true
    }

    if (!relevantWeekDataHasChanged) {
      let newAssignments = await currentSchoolyearData.userAssignments;
      let oldAssignments = await newDBData.userAssignments;

      if (await dataDoesNotExistsCheck(newAssignments, oldAssignments)) {
        relevantWeekDataHasChanged = false;
      } else if (newAssignments.length !== oldAssignments.length) {
        relevantWeekDataHasChanged = true;
      } else {
        relevantWeekDataHasChanged = await dataWasUpdatedCheck(
          newAssignments,
          oldAssignments
        );
      }
    }

    return relevantWeekDataHasChanged;
  } catch (error) {
    console.error("Error in relevantDataHasReallyChanged:", error);
    throw error; // Rethrow the error to indicate that an error occurred
  }
}
