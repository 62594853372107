const dayjs = require("dayjs");

function createUpdateAssignment(assignment, updateValues) {
  var updateAssignment = { ...assignment }; // Create a shallow copy
  // console.log("updateass:", updateAssignment, "assignment:", assignment, 
  // ", updateValues: ", updateValues 
  // )
  updateValues.forEach(({ property, value }) => {
    switch (property) {
      case "due_date":
        // console.log("value: ", value, dayjs(value).format("YYYY-MM-DD") )
        updateAssignment.due_date = dayjs(value).format("YYYY-MM-DD");
        break;

      default:
        // Handle other properties if needed
        // console.log(property)
        updateAssignment[property] = value;
        break;
    }
  });

  return updateAssignment;
}

exports.createUpdateAssignment = createUpdateAssignment;
