import { memo } from "react";
import CourseBoxComponent from "./CourseBoxComponent";

function CourseBox({
  restricted,
  assignment,
  selectedAssignmentCourse,
  setSelectedAssignmentCourse,
  changesCount,
  setChangesCount,
}) {
  const change = (e) => {
    // console.log(selectedAssignmentCourse);
    // console.log(e.target.value);
    let changesCountCopy = changesCount;
    if (e.target.value.id === assignment.course_id) {
      //we have gone back to default
      // console.log("go back");
      setChangesCount(changesCountCopy - 1);
    } else if (selectedAssignmentCourse.id !== assignment.course_id) {
      //we have already made a change
      // console.log("we go here and do nothing");
      // do noting
    } else {
      // this is our first change
      // console.log("we go there for a first change");
      // new change
      setChangesCount(changesCountCopy + 1);
    }
    setSelectedAssignmentCourse(e.target.value);
  };

  return (
    <>
      <CourseBoxComponent
        restricted={restricted}
        selectedAssignmentCourse={selectedAssignmentCourse}
        change={change}
      />
    </>
  );
}

export default memo(CourseBox);
