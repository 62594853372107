import { Box } from "@material-ui/core";
import { Star } from "@mui/icons-material";
import { Rating } from "@mui/material";
import React, { memo } from "react";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import { useTheme } from "@mui/styles";

const labelsRating = {
  0.5: "nutzlos+",
  1: "nutzlos+",
  1.5: "nicht hilfreich+",
  2: "nicht hilfreich",
  2.5: "ok",
  3: "ok+",
  3.5: "hilfreich",
  4: "hilfreich+",
  4.5: "ausgezeichnet",
  5: "ausgezeichnet+",
};

const labelsPlanability = {
  0.5: "ich kann so überhaupt nicht planen+",
  1: "ich kann so überhaupt nicht planen",
  1.5: "nice try, aber ich kann so nicht planen+",
  2: "nice try, aber ich kann so nicht planen",
  2.5: "ich kann mich an die App gewöhnen",
  3: "die App ist ganz okay",
  3.5: "ich kann mit der App gut planen",
  4: "ich kann mit der App gut planen+",
  4.5: "genau so kann ich besser planen",
  5: "genau so kann ich besser planen+",
};
const labelsVisibility = {
  0.5: "ist mir überhaupt nicht wichtig+",
  1: "ist mir überhaupt nicht wichtig",
  1.5: "ist mir nicht wichtig+",
  2: "ist mir nicht wichtig",
  2.5: "wär okay+",
  3: "wär okay",
  3.5: "würde mich zusätzlich motivieren",
  4: "würde mich zusätzlich motivieren+",
  4.5: "wäre eine super motivation für mich",
  5: "wäre eine super motivation für mich+",
};

function getAppRatingLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labelsRating[value]}`;
}
function getPlanabilityLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labelsPlanability[value]}`;
}
function getVisibilityLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labelsVisibility[value]}`;
}

function RatingBox({ type, data, setData, hover, setHover }) {
  const {appInDarkMode} = useAppContext()
  const theme = useTheme()
  return (
    <Box
      sx={{
        // width: 400,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Rating
        name="rating-box"
        value={data}
        precision={0.5}
        getLabelText={
          type === "rating"
            ? getAppRatingLabelText
            : type === "planability"
            ? getPlanabilityLabelText
            : type === "visibility"
            ? getVisibilityLabelText
            : ""
        }
        onChange={(event, newValue) => {
          setData(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<Star style={{ color: appInDarkMode?theme.palette.levels.white : theme.palette.levels.dark, opacity: 0.55 }} fontSize="inherit" />}
      />
      {data !== null && (
        <Box sx={{ ml: 2 }}>
          {type === "rating" ? (
            labelsRating[hover !== -1 ? hover : data]
          ) : type === "planability" ? (
            labelsPlanability[hover !== -1 ? hover : data]
          ) : type === "visibility" ? (
            labelsVisibility[hover !== -1 ? hover : data]
          ) : (
            <></>
          )}
        </Box>
      )}
    </Box>
  );
}
export default memo(RatingBox);
