import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import WeekNavigation from "./WeekNavigation";

function ChangeButtons({ children }) {
  const { referenceDate, setReferenceDate, schoolYearData, isSmallScreen } = useAppContext();
  const isDatesValid = schoolYearData && schoolYearData.datesFirstSemester;

  const startDate = moment(new Date(referenceDate)).startOf("isoWeek");
  const endDateFirstSemester =
    isDatesValid &&
    moment(new Date(schoolYearData.datesFirstSemester[0])).subtract(6, "days");
  const endDateSecondSemester =
    isDatesValid && moment(new Date(schoolYearData.datesSecondSemester[1]));

  const [toggles, setToggles] = useState({
    backward: isDatesValid && startDate.isAfter(endDateFirstSemester),
    forward:
      isDatesValid && startDate.add(6, "days").isBefore(endDateSecondSemester),
  });

  // Set toggles
  useEffect(() => {
    if (schoolYearData && schoolYearData.mondaysFirstSemester) {
      const startDate = moment(new Date(referenceDate)).startOf("isoWeek");
      const endDateFirstSemester = moment(
        new Date(schoolYearData.mondaysFirstSemester[0])
      ).subtract(6, "days");
      const endDateSecondSemester = moment(
        new Date(schoolYearData.datesSecondSemester[1])
      );

      setToggles({
        backward: startDate.isAfter(endDateFirstSemester),
        forward: startDate.add(6, "days").isBefore(endDateSecondSemester),
      });
    }
  }, [referenceDate, schoolYearData]);

  const updateWeek = async (direction) => {
    const offset = direction === "next" ? 7 : -7;
    await setReferenceDate(
      moment(referenceDate).add(offset, "days").format("YYYY-MM-DD")
    );
  };
  return (
    <div style={{
      display: "flex", width: "100%",
      alignItems: "center",
    }}>
      <div>
        <WeekNavigation
          title="letzte Woche"
          disabled={!toggles.backward}
          onClick={() => updateWeek("previous")}
          arrowLeft={true}
        />
      </div>
      <div
        style={{
          flex: "1",
          marginLeft: isSmallScreen?"5%":"", // Adjust margin as needed for space around the content
          marginRight: isSmallScreen?"5%":"-10%", // Adjust margin as needed for space around the content
          //         flex: "0 0 78%", // Allow the children to take up only 98% of the width
          // background: "green",
          width: isSmallScreen?"60%":""

        }}
      >
        {children}
      </div>
      <div>
        <WeekNavigation
          title="nächste Woche"
          disabled={!toggles.forward}
          onClick={() => updateWeek("next")}
        />
      </div>
    </div>
  );
}

export default memo(ChangeButtons);
