import { Box, Grid, Typography } from "@mui/material";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { createTeacherName } from "../../../../_3HelperFunctions/_NameHelpers/nameHelpers";

export const ClassOverviewBox = () => {
  const { selectedCourse, myCoreCourses, isAStudent, isATeacher, personalView, currentSemesterName, isMobile } = useAppContext();
  return (
    // This is the date overview item
    <>
      <div
        style={{
          width: "110%",
          whiteSpace: "nowrap", // Prevent content from wrapping
          textAlign: isMobile ? "center" : "", // Center along the x-axis
        }}
      >
        <Typography variant="h6" color="primary">
          {personalView && isATeacher ? "Prüfungsplan" : "Prüfungsplan der"}
        </Typography>
      </div>
      <>
        <Grid item sm={12}>
          <div
            style={{
              width: "110%",
              whiteSpace: "nowrap", // Prevent content from wrapping
              textAlign: isMobile ? "center" : "", // Center along the x-axis
            }}
          >
            <Typography color="primary" variant="h4" fontWeight={"bold"}>
              {isATeacher && !personalView ? `Klasse ${selectedCourse?.name ? createTeacherName(selectedCourse.name) : ""}`
                : isAStudent && myCoreCourses?.length > 0 ? `Klasse ${myCoreCourses[0].name}`
                  : isAStudent ? "Klasse"
                    : "Alle Klassen"}
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <div
            style={{
              width: "110%",
              whiteSpace: "nowrap", // Prevent content from wrapping
              textAlign: isMobile ? "center" : "", // Center along the x-axis
            }}
          >
            <Typography color="primary" variant="caption">
              im Semster {currentSemesterName}
            </Typography>
          </div>
        </Grid>
      </>
    </>
  );
};
