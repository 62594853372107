import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import DaysToPrepareBox from "../CardElements/DaysToPrepareBox";
import AufwandDoneBox from "../CardElements/AufwandDoneBox";
import AufwandBox from "../CardElements/AufwandBox";

function LineFiveS({
  classView,
  previewAssignment,
  setPreviewAssignment,
  changesCount,
  setChangesCount,
  type,
  status,
  setStatus,
  my_minutes,
  setMyMinutes,
  suggested_minutes,
  setWorkloadMinutesTotalSugg,
  my_minutes_done,
  setWorkloadMinutesDone,
  workloadErr,
  setMyDays,
  my_days,
  setSuggestedDays,
  suggested_days,
  availablePrepDaysErr,
  restricted,
}) {
  return (
    <Grid
      item
      // sx={{ marginTop: type === "exam" ? "0em" : "0.75em" }}
    >
      <Grid
        container
        // justifyContent="space-between"
        spacing={1}
        direction="row"
        style={{
          marginTop: "0.75em",
          // marginBottom: "0.75em"
        }}
        // sx={{ width: "100%", background: "blue" }}
      >
        {/* Aufwand */}
        <Grid
          item
          xs={4}
          // sx={{ background: "orange" }}
        >
          <AufwandBox
            classView={classView}
            restricted={restricted}
            previewAssignment={previewAssignment}
            setPreviewAssignment={setPreviewAssignment}
            changesCount={changesCount}
            setChangesCount={setChangesCount}
            my_minutes={my_minutes}
            setMyMinutes={setMyMinutes}
            suggested_minutes={suggested_minutes}
            setWorkloadMinutesTotalSugg={setWorkloadMinutesTotalSugg}
            my_minutes_done={my_minutes_done}
            setWorkloadMinutesDone={setWorkloadMinutesDone}
            type={type}
            status={status}
            setStatus={setStatus}
            workloadErr={workloadErr}
            personalView={type !== "personal" ? false : true}
          />
        </Grid>

        {/* Minutes Done */}
        {!classView && (
          <Grid
            item
            xs={4}
            // sx={{ background: "orange" }}
          >
            <AufwandDoneBox
              restricted={restricted}
              previewAssignment={previewAssignment}
              setPreviewAssignment={setPreviewAssignment}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
              my_minutes_done={my_minutes_done}
              setWorkloadMinutesDone={setWorkloadMinutesDone}
              my_minutes={my_minutes}
              setMyMinutes={setMyMinutes}
              suggested_minutes={suggested_minutes}
              status={status}
              setStatus={setStatus}
              workloadErr={workloadErr}
              personalView={false}
            />
          </Grid>
        )}

        {/* Vorbereitung SuS */}
        <Grid
          item
          xs={3}
          // sx={{ background: "yellow" }}
        >
          <DaysToPrepareBox
            classView={classView}
            restricted={restricted}
            previewAssignment={previewAssignment}
            changesCount={changesCount}
            setChangesCount={setChangesCount}
            my_days={my_days}
            setMyDays={setMyDays}
            suggested_days={suggested_days}
            setSuggestedDays={setSuggestedDays}
            availablePrepDaysErr={availablePrepDaysErr}
            workloadErr={workloadErr}
            type={type}
            personalView={false}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(LineFiveS);
