import React, { memo } from "react";
import { Grid } from "@mui/material";
import { useAppContext } from "../../../../../_8HusibuechContexts/contextLib";
import MessageBox from "../../../../../_4GeneralHelpers/1_Boxes/MessageBox";
import AssignmentDeleteButton from "../../../../../_4GeneralHelpers/2_Buttons/AssignmentDeleteButton";
import SaveButton from "../../../../../_4GeneralHelpers/2_Buttons/SaveButton";

function AssignmentEditButtons({ previewAssignment, changes, message, saveColor, deleteFunction, saveFunction, loadingCircle, type }) {
  const { currentUser, isAnAdmin } = useAppContext()
  // console.log("check...", previewAssignment.creator_id === currentUser.id)
  return (
    <Grid item style={{ marginTop: "0.75em" }}>
      <Grid
        container
        justifyContent="space-between"
        direction="row"

      // sx={{ background: "gray" }}
      >
        {!message && (
          <Grid
            item
            xs={4}
            style={{ visibility: changes ? "visible" : "hidden" }}
          >
            <SaveButton
              loading={loadingCircle}
              saveColor={saveColor}
              save={saveFunction}
              saveText={"speichern"}
            />
          </Grid>
        )}
        {!message &&
          (isAnAdmin || previewAssignment.creator_id === currentUser.id ||
            previewAssignment.type==="personal") && (
            <Grid item xs={7}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
              // sx={{ background: "orange" }}
              >
                <div
                  style={{
                    visibility:
                      // only admins || creators of the assignment can delete it
                      isAnAdmin ||  previewAssignment.creator_id === currentUser.id ||
                        previewAssignment.type==="personal"
                        ? "visible"
                        : "hidden",
                  }}
                >
                  <Grid item>
                    <AssignmentDeleteButton
                      loading={loadingCircle}
                      saveColor={saveColor}
                      deleteFunction={deleteFunction}
                      type={type}
                    />
                  </Grid>
                </div>
              </Grid>
            </Grid>
          )}
        {message && (
          <Grid
            item
            xs={12}
            style={{
              marginBottom: "-1.5em",
            }}
          >
            <MessageBox message={message} saveColor={saveColor} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default memo(AssignmentEditButtons);
