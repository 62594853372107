import { memo } from "react";
import { Grid } from "@mui/material";
import styles from "../../SignUpForm/styles.module.css";

function FeedbackMessage({ errors, msg, color }) {
  // console.log(errors.userKlasse)
  return (
    <Grid
      item
      style={{
        width: "90%",
        // Other styles...
      }}
    >
      {errors?.userKlasse?.length > 0 && (
        <div>
          {errors.userKlasse.map((error, index) => (
            <div
              key={index}
              className={`${styles.error_msg} ${styles.visible}`}
              role="alert"
              style={{ marginBottom: "1em" }} // Adjust the margin as needed
            >
              {error}
            </div>
          ))}
        </div>
      )}

      <br />

      {/* success */}
        <div
          className={`${errors?.length > 0?styles.error_msg:styles.success_msg} ${
            msg.length > 0 ? styles.visible : ""
          }`}
          role="alert"
        >
          {msg}
        </div>
    </Grid>
  );
}

export default memo(FeedbackMessage);
