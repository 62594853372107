import React, { memo } from "react";

import { Grid } from "@material-ui/core";
import AvatarBox from "../CardElements/AvatarBox";
import DatumBox from "../CardElements/DatumBox";
import NameBox from "../CardElements/NameBox";

function EditLineOne({
  previewAssignment,
  assignment,
  changesCount,
  setChangesCount,
  assignmentName,
  setAssignmentName,
  type,
  dueDate,
  setDueDate,
  status,
  restricted,
  classView
}) {
  return (
    <Grid item>
      <Grid
        container
        justifyContent="space-between"
        alignItems={"center"}
        alignContent={"center"}
        direction="row"
        style={{
          marginTop: "1.5em",
          // width: "100%",
          // background: "red"
        }}
      >
        {/* Assignment Name */}
        <Grid item xs={6}>
          <NameBox
            restricted={restricted}
            previewAssignment={previewAssignment}
            changesCount={changesCount}
            setChangesCount={setChangesCount}
            assignmentName={assignmentName}
            setAssignmentName={setAssignmentName}
          />
        </Grid>
        {/* Datum Box for students */}
       
          <Grid item xs={4}>
            <DatumBox
              restricted={restricted}
              classView={classView}
              assignment={assignment}
              previewAssignment={previewAssignment}
              changesCount={changesCount}
              setChangesCount={setChangesCount}
              dueDate={dueDate}
              setDueDate={setDueDate}
            />
          </Grid>

        {/* Assignment Avatar */}
        <AvatarBox type={type} status={status} />
      </Grid>
    </Grid>
  );
}

export default memo(EditLineOne);
