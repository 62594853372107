import { filterUniqueAssignments } from "../../../../../_3HelperFunctions/_AssignmentFunctions/filterUniqueAssignments";
import { filterTasksByCourse } from "../../../../../_3HelperFunctions/_AssignmentFunctions/filterTasksByCourse";
import { sortTasksByDueDateAndPriority } from "../../../../../_3HelperFunctions/_AssignmentFunctions/sortTasksByDueDateAndPriority";

export const calculateClassDeadlineAssignments = async (
  relevantAssignments,
  setClassDeadlineAssignments,
  selectedCourse,
  showCourseAssignmentOnly
) => {
  if (relevantAssignments?.thisWeek?.length > 0) {
    let filteredAssignments = await filterUniqueAssignments(//returns one assignment and calculates an average
      relevantAssignments.thisWeek,
      "assignment_id"
    );
    // console.log(filteredAssignments)
    if (selectedCourse) {
      filteredAssignments = filteredAssignments.filter((assignments) => assignments.core_course_id === selectedCourse.core_course_id)
    }

    if (showCourseAssignmentOnly) {
      filteredAssignments = await filterTasksByCourse(
        selectedCourse,
        filteredAssignments
      );
    }

    const sortedAssignments = await sortTasksByDueDateAndPriority(
      filteredAssignments
    );
    // console.log(sortedAssignments)
    await setClassDeadlineAssignments(sortedAssignments);
  } else {
    await setClassDeadlineAssignments([]);
  }
};
