import { useTheme } from "@mui/styles";

import { Button } from "@mui/material";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import { saveAssignmentChanges } from "../../_3HelperFunctions/_AssignmentFunctions/saveAssignmentChanges";
import { updateDataSet } from "../../_3HelperFunctions/_LocalUpdates/updateDataSet";

export default function AddSuggToDoButton({ text }) {
  const theme = useTheme();
  const { currentUser, isALeader, suggToDos, schoolYearData, setUnresolvedSchoolYearData } = useAppContext();

  const handleAddSuggestedToDo = async () => {
    var suggestionAssignment = suggToDos[0];

    const updateValues = [{ property: "is_a_to_do", value: true }];
      const responseAssignment = await saveAssignmentChanges(
        suggestionAssignment,
        updateValues,
        "toDoList",
        currentUser
      );
      let updatedSchoolYearData = { ...schoolYearData }
      updatedSchoolYearData = await updateDataSet(
        updatedSchoolYearData,
        responseAssignment,
        isALeader,
        "toDoList"
      );
      setUnresolvedSchoolYearData(updatedSchoolYearData);
  };

 
  return (
    <Button
      variant="contained"
      disabled={!suggToDos?.length>0}
      onClick={handleAddSuggestedToDo}
      style={{
        backgroundColor: theme.palette.levels.green,
        // marginLeft: "0.5rem",
        // marginBottom: "0.5rem",
        fontSize: "0.7rem",
      }}
    >
      {text}
    </Button>
  );
}
