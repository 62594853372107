import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAppContext } from "../../../../_8HusibuechContexts/contextLib";
import { FilterBox } from "../../../4DashboardPage/DashboardHelpers/FilterBox";
import { DateOverviewBox } from "../../../4DashboardPage/DashboardHelpers/DateOverviewBox";
import { CourseOverviewBox } from "../../../4DashboardPage/DashboardHelpers/CourseOverviewBox";
import { ClassOverviewBox } from "./ClassOverviewBox";
import { ProfileTitle } from "../../../3ProfilePage/ProfileHelpers/ProfileTitle";
import { ThemeSwitchBox } from "../../../../_7HusibuechTheme/themeSwitch/ThemeSwitchBox";
import { AssignmentStats } from "./AssignmentStats";
import { NKTitle } from "../../../5Nachkontrolle/_NachKontrolleHelpers/NKTitle";
import { AdminTitle } from "../../../6AdminPage/_RegisterHelpers/AdminTitle";
import { UploadTitle } from "../../../8UploadPage/_UploadHelpers/UploadTitle";
import "./RibbonCarousel.css";


const RibbonCarousel = ({ type }) => {
  const { isATeacher, isAStudent, isALeader, isMobile, appInDarkMode } = useAppContext();
  // console.log(isMobile)
  const dotColor = appInDarkMode ? "#fff" : "#000"; // Set color based on the dark mode state


  const renderComponent = (component) => (
    <div style={{
      justifyContent: "center",
    }}>
      {component}
    </div>
  );

  // Add custom styles to reduce the space between dots and content
  const customDotStyles = {
    // Add custom styles to reduce the space between dots and content
    margin: "-0.5em", // Adjust as needed
    // Change dot color to white
  }


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 2, // Adjust the number of slides to show
    slidesToScroll: 1,
    // centerMode: true,   // Enable center mode
    // centerPadding: "-3em",  // Add space between items (adjust as needed)
    arrows: false,    // Hide arrows
    swipe: type === "admin" || type === "upload" ? false : true,       // Enable swipe interaction
    dots: type === "admin" || type === "upload" ? false : true,  // Set to false to hide the swipe dots
    vertical: false,   // Enable vertical swiping
    appendDots: (dots) => (
      <div style={customDotStyles}>
        <ul>{dots}</ul>
      </div>
    ),
  };

  return (
    <div> <style>{`
    .slick-dots li button::before {
      color: ${dotColor};
      opacity: 0.4;
    }

    .slick-dots li.slick-active button::before {
      color: ${dotColor};
      opacity: 1;
    }
  `}</style>
      <Slider {...settings}>
        {renderComponent(
          type === "dashboard" ? <DateOverviewBox /> : type === "examView" ? <ClassOverviewBox /> : type === "nachkontrolle" ? <NKTitle /> : type === "admin" ? <AdminTitle /> : type === "profile" ? <ProfileTitle /> : <UploadTitle />
        )}
        {/* {renderComponent(<div>hi</div>)} */}
        {((isAStudent && (type === "dashboard" || type === "nachkontrolle" || type === "examView" || type === "profile")) || (isALeader && (type === "admin" || type === "upload" || type === "profile" || type === "examView" || type === "dashboard"))) &&
          renderComponent(
            <div
              role="center-item"
              style={{
                justifyContent: "center",
              }}
            >
              {isAStudent && type === "dashboard" ? <AssignmentStats /> : isALeader && (type === "examView" || type === "dashboard") ? <CourseOverviewBox /> : isAStudent && (type === "nachkontrolle" || type === "examView") ? <></> : type === "profile" ? <ThemeSwitchBox /> : <></>}
            </div>
          )}
        {(type === "dashboard" || type === "nachkontrolle" || (type === "examView" && isATeacher)) &&
          renderComponent(
            <div
              // role="right-item"
              style={{
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                scrollbarWidth: "thin",
                scrollbarColor: "transparent transparent",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "red"

              }}
            >
              {(type === "dashboard" || type === "nachkontrolle" || (type === "examView" && isATeacher)) && (
                <FilterBox reduced={type === "nachkontrolle"} toggleOnly={type === "examView"} />
              )}
            </div>
          )}
      </Slider>
    </div>
  );
};

export default RibbonCarousel;
