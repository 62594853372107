import { memo } from "react";
import {
  Grid,
} from "@mui/material";
import CoursesOverviewTable from "../../6AdminPage/_RegisterHelpers/CoursesOverviewTable";
import CoreCourseOverviewTable from "../../6AdminPage/_RegisterHelpers/CoreCourseOverviewTable";
import ContentBox from "../../../_4GeneralHelpers/1_Boxes/ContentBox";
import BoxHeader from "../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";

function CurrentCourseSelection() {
  return (
    <>
      <BoxHeader title={"Registrierte Kurse"} />
      <ContentBox>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            style={{marginLeft: "-0.9em"}}
          >
            <CoreCourseOverviewTable />

          </Grid>
          <Grid
            item
            xs={12}
            style={{marginLeft: "-0.9em"}}
          >
            <CoursesOverviewTable />

          </Grid>
        </Grid>
      </ContentBox>
    </>

  );
}
export default memo(CurrentCourseSelection);
