import { memo, useState } from "react";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";

import {
  Grid,
  TextField,
} from "@mui/material";
import userService from "../../../_6Services/user.service";
import { useTheme } from "@mui/styles";
import ButtonGrid from "../_CourseSelectionHelpers/ButtonGrid";
import ContentBox from "../../../_4GeneralHelpers/1_Boxes/ContentBox";
import BoxHeader from "../../../_4GeneralHelpers/1_Boxes/_BoxHeader/BoxHeader";

function UsernameDetail() {
  const theme = useTheme();
  const [change, setChange] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const { currentUser, setCurrentUser } = useAppContext();
  // console.log(currentUser)

  const [nickName, setNickName] = useState(
    currentUser.userNickName ? currentUser.userNickName : ""
  );

  const changeNickName = (event) => {
    event.target.value === currentUser.nickName ||
    (currentUser.nickName === undefined && event.target.value === "")
      ? setChange(false)
      : setChange(true);
    setNickName(event.target.value);
  };

  const updateLocalUser = () => {
    var updateUser = currentUser;
    if (nickName !== updateUser.userNickName) {
      updateUser.userNickName = nickName;
    }
    console.log(updateUser);
    setCurrentUser(updateUser);
    localStorage.setItem("user", JSON.stringify(updateUser));
  };

  const updateHandler = () => {
    // alert("function not implemented yet");
    setLocalLoading(true);
    userService
      .updateUser("nickName", { nickName: nickName }, currentUser.id)
      .then(updateLocalUser())
      .then(setLocalLoading(false))
      .then(setChange(false));
    // console.log(currentUser);
  };

  const clearHandler = () => {
    setNickName(currentUser.nickName ? currentUser.nickName : "");
    setChange(false);
  };

  return (
    <>
      <BoxHeader title={"Username einstellen"} />

      <ContentBox>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12} sx={{ marginTop: "5px" }}>
            <TextField
              fullWidth
              label="Username"
              name="name"
              onChange={changeNickName}
              required
              value={nickName}
              variant="outlined"
            />
          </Grid>
          <ButtonGrid
            disabled={!change}
            loading={localLoading}
            submit={updateHandler}
            clear={clearHandler}
          />
        </Grid>
      </ContentBox>
    </>
  );
}
export default memo(UsernameDetail);
