import React, { memo, useState, useEffect } from "react";

import { AppContext } from "./_8HusibuechContexts/contextLib.js";
import IdleTimer from "./_1Authentication/IdleTimer.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "./_7HusibuechTheme/App.css";
import { CssBaseline, useMediaQuery } from "@mui/material";

import moment from "moment";
import { ThemeProvider } from "@mui/material";
import husibuechTheme from "./_7HusibuechTheme/Theme.js";
import MenuTopBar from "./_5Pages/0Navigation/1MenuTopBar/MenuTopBar.js";
import AppRoutes from "./_5Pages/0Navigation/AppRoutes.js";
import MenuBottomBar from "./_5Pages/0Navigation/2MenuBottomBar/MenuBottomBar.js";
import authService from "./_6Services/auth.service.js";
import useSound from "use-sound";
import magicSpellSfx from "./_4GeneralHelpers/sounds/magicSpell.mp3";
import whizSfx from "./_4GeneralHelpers/sounds/whiz.mp3";
import husibuechThemeDark from "./_7HusibuechTheme/ThemeDark.js";
import { auth } from "./_1Authentication/firebase.js";
import { onAuthStateChanged } from "firebase/auth";
import { getToDoSuggestions } from "./_3HelperFunctions/getToDoSuggestions.js";
import { shouldUpdateLocalData } from "./_3HelperFunctions/_AppHelpers/shouldUpdateLocalData.js";
import { refetchSchoolYearData } from "./_3HelperFunctions/_AppHelpers/refetchSchoolYearData.js";
import { loadDataForUser } from "./_3HelperFunctions/_AppHelpers/loadDataForUser.js";
import userService from "./_6Services/user.service.js";
import { updateDataCheck } from "./_3HelperFunctions/_AppHelpers/updateDataCheck.js";
import { getRelevantAssignmentsObject } from "./_3HelperFunctions/getRelevantAssignmentsObject.js";
import EmergencyTimer from "./_1Authentication/EmergencyTimer.js";
import dayjs from "dayjs";
import { updateUserOnLogout } from "./_3HelperFunctions/_AppHelpers/updateUserOnLogout.js";

function App() {
  /**
   * app navigation - settings
   */
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [appInDarkMode, setAppInDarkMode] = useState(true);
  const [currentPage, setCurrentPage] = useState("dashboard");
  const theme = appInDarkMode ? husibuechThemeDark : husibuechTheme;

  const customDate = new Date("2022-05-11");
  const useCustomDate = false;

  /**
   * Lock processes
   */
  const [loading, setLoading] = useState(false);
  const [schoolYearLoading, setSchoolYearLoading] = useState(false);
  const [stopReRenderProcess, setStopReRenderProcess] = useState(undefined);
  const [stopWorkloadChartReRender, setStopWorkloadChartReRender] =
    useState(undefined);
  const [stopDoughnutChartReRender, setStopDoughnutChartReRender] =
    useState(undefined);
  const [stopStatusChartReRender, setStopStatusChartReRender] = useState(false);
  const [stopPriorityChartReRender, setStopPriorityChartReRender] =
    useState(false);
  const [passKey, setPassKey] = useState(false);

  // const [stopAssignmentChartReRender, setStopAssignmentChartReRender] =
  //   useState(false);

  const [verificationCodeStore, setVerificationCodeStore] = useState("");
  const [personalNumberOfDoesNotFit, setPersonalNumberOfDoesNotFit] =
    useState(undefined);
  const [classNumberOfDoesNotFit, setClassNumberOfDoesNotFit] =
    useState(undefined);

  /**
   * sound loading
   */
  const [playWelcome] = useSound(magicSpellSfx);
  const [playGoodBye] = useSound(whizSfx);

  /**
   * orientation
   */
  const [currentUser, setCurrentUser] = useState(undefined);
  const [isAStudent, setIsAStudent] = useState(false);
  const [isATeacher, setIsATeacher] = useState(false);
  const [isAnAdmin, setIsAnAdmin] = useState(false);
  const [isAReferenceStudent, setIsAReferenceStudent] = useState(false);
  const [isALeader, setIsALeader] = useState(false);
  // console.log("storage: ", localStorage)
  const [updateCurrentUserData, setUpdateCurrentUserData] = useState(false);
  const [referenceDate, setReferenceDate] = useState(
    moment(useCustomDate ? customDate : new Date()).format("YYYY-MM-DD")
  );

  /**
   * For Verification
   */
  const [currentVerificationUser, setCurrentVerificationUser] =
    useState(undefined);
  const [verificationMessage, setVerificationMessage] = useState(false);
  const [verificationUserName, setVerificationUserName] = useState("");
  const [verificationUserPassword, setVerificationUserPassword] = useState("");
  const [showActivationForm, setShowActivationForm] = useState(false);

  /**
   * for authentication:
   * once authenticated, this enables page views for the authenticated user
   */
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);

  /**
   * data sets
   */
  const [holidays, setHolidays] = useState(undefined);
  const [currentSemesterStartDate, setCurrentSemesterStartDate] = useState("");
  const [currentSemesterEndDate, setCurrentSemesterEndDate] = useState("");
  const [currentSemesterName, setCurrentSemesterName] = useState("");
  const [holidayDays, setHolidayDays] = useState([]);

  //SchoolYearData
  const [schoolYearData, setSchoolYearData] = useState(undefined);
  const [unresolvedSchoolYearData, setUnresolvedSchoolYearData] =
    useState(undefined);
  const [relevantUserAssignments, setRelevantUserAssignments] =
    useState(undefined);
  const [relevantClassAssignments, setRelevantClassAssignments] =
    useState(undefined);

  /**
   * Notifiers
   */
  const [newUserAssignmentsChanges, setNewUserAssignmentsChanges] =
    useState(false);
  const [newClassAssignmentsChanges, setNewClassAssignmentsChanges] =
    useState(false);

  //For ToDo List
  const [openToDoList, setOpenToDoList] = useState(false);
  const [suggToDos, setSuggToDos] = useState(undefined);
  const [toDos, setToDos] = useState(undefined);
  //for App
  const [userSubjects, setUserSubjects] = useState([]);
  const [userCourses, setUserCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(undefined);
  const [oldSelectedCourse, setOldSelectedCourse] = useState(undefined);
  const [checkTime, setCheckTime] = useState(dayjs()); // Use dayjs for checkTime

  //for Dashboard:
  const [dashBoardOrderIsFixed, setDashBoardOrderIsFixed] = useState(true);

  //for WorkloadChart:
  const [verifyWorkloadChart, setVerifyWorkloadChart] = useState(undefined);
  const [workloadMinutes, setWorkloadMinutes] = useState(0);
  const [suggestedWorkloadMinutes, setSuggestedWorkloadMinutes] = useState(0);

  //for DoughnutChart:
  const [verifyDoughnutChart, setVerifyDoughnutChart] = useState(undefined);
  const [verifyStatusChart, setVerifyStatusChart] = useState(undefined);
  const [verifyPriorityChart, setVerifyPriorityChart] = useState(undefined);

  const [weekStatusCount, setWeekStatusCount] = useState([]);
  const [weekPriorityCount, setWeekPriorityCount] = useState([]);
  const [thisWeekMinutesTotal, setThisWeeksMinutesTotal] = useState(undefined);
  const [thisWeekMinutesDone, setThisWeeksMinutesDone] = useState(undefined);
  const [percentageDone, setPercentageDone] = useState(undefined);
  useEffect(() => {
    // console.log(thisWeekMinutesDone, thisWeekMinutesTotal, percentageDone);
  }, [thisWeekMinutesDone, thisWeekMinutesTotal, percentageDone]);

  //for AssignnmentCharts
  // const [verifyAssignmentChart, setVerifyAssignmentChart] = useState(false);
  // const [verifyPersonalDeadlines, setVerifyPersonalDeadlines] = useState(false);
  const [additionalAssignments, setAdditionalAssignments] = useState(undefined);
  const [calculatingDeadlines, setCalculatingDeadlines] = useState(false);
  const [calculatingRecents, setCalculatingRecents] = useState(false);

  const [personalDeadlineAssignments, setPersonalDeadlineAssignments] =
    useState(undefined);
  const [classDeadlineAssignments, setClassDeadlineAssignments] = useState([]);
  const [topSix, setTopSix] = useState(undefined);

  // useEffect(()=>{
  //   console.log(topSix)
  // }, [topSix])

  /*
   * Data sets for admins and registrations
   */
  const [husibuechCoreCourses, setHusibuechCoreCourses] = useState(undefined);
  const [husibuechCourses, setHusibuechCourses] = useState(undefined);

  const [husibuechUserKurse, setHusibuechUserKurse] = useState(undefined);
  const [husibuechTeachers, setHusibuechTeachers] = useState(undefined);
  const [husibuechUsers, setHusibuechUsers] = useState(undefined);
  const [husibuechSubjects, setHusibuechSubjects] = useState([]);
  const [husibuechSemesters, sethusibuechSemesters] = useState(undefined);
  const [husibuechHolidays, sethusibuechHolidays] = useState(undefined);
  const [husibuechRoles, setHusibuechRoles] = useState(undefined);
  const [husibuechExamTypes, setHusibuechExamTypes] = useState(undefined);

  /**
   * For User only
   */
  const [myCoreCourses, setMyCoreCourses] = useState([]);
  const [mySelectedCoreCourse, setMySelectedCoreCourse] = useState([]);
  const [personalView, setPersonalView] = useState(false); // for semesterTable
  const [viewCourseOnly, setViewCourseOnly] = useState(false); //for semesterTable

  /**
   * app filters
   */
  const [showExams, setShowExams] = useState(true);
  const [showHomeworks, setShowHomeworks] = useState(true);
  const [showPersonals, setShowPersonals] = useState(true);
  const [showDetail, setShowDetail] = useState(false);
  const [showCourseAssignmentOnly, setShowCourseAssignmentOnly] =
    useState(false);
  const [timeBudget, setTimeBudget] = useState(false);
  const [statusSelection, setStatusSelection] = useState([0, 1, 2, 3, 4]);
  const [prioritySelection, setPrioritySelection] = useState([0, 1, 2, 3]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);

  //screenSize

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("ss"));

  //counts
  const statusList = [
    "Unbearbeitet",
    "Angefangen",
    "Mittendrin",
    "Fast fertig",
    "Erledigt",
  ];

  const priorityList = [
    "Unwichtig",
    "Vernachlässigbar!",
    "Wichtig",
    "Sofort erledigen",
  ];

  const [statusCount, setStatusCount] = useState([0, 0, 0, 0, 0]);
  const [numberOfFinishedExamStatus, setNumberOfFinishedExamStatus] =
    useState(0);
  const [numberOfFinishedHomeworkStatus, setNumberOfFinishedHomeworkStatus] =
    useState(0);

  // useEffect(() => {
  //   console.log("Rerender...", stopReRenderProcess)
  // }, [stopReRenderProcess])
  /*
   * Dark Mode Theme Setting
   */
  useEffect(() => {
    // setAppInDarkMode(prefersDarkMode);
    setAppInDarkMode(true);
  }, [prefersDarkMode]);

  /**
   * During Verificiation
   */
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentVerificationUser(user);
    });
  }, []);

  /**
   * User from local storage (if given)
   */
  // useEffect(() => {
  //   console.log(currentUser)
  // }, [currentUser])

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        // Call authService.getCurrentUser() asynchronously
        const currentUserFromStorage = await authService.getCurrentUser();

        if (!loading && currentUserFromStorage) {
          await setCurrentUser(currentUserFromStorage);
          // console.log("loading starts from storage")
          await setLoading(true);
        }
      } catch (error) {
        // Handle errors if necessary
        console.error("Error fetching user:", error);
      }
    };

    // Invoke the async function
    fetchCurrentUser();
  }, []);

  /*
   * after authentication process:
   */
  useEffect(() => {
    const acceptAuthentication = async () => {
      await setSchoolYearLoading(true);
      await setReferenceDate(moment(new Date()).format("YYYY-MM-DD"));
      //start limeout process:
      const timer = new IdleTimer({
        timeout: 300, // Expire after 300 seconds
        onTimeout: async () => {
          await logout("timeout");
        },
        onExpired: async () => {
          await logout("expired");
        },
      });

      return () => {
        timer.cleanUp();
      };
    };

    if (isAuthenticated) {
      acceptAuthentication();
    }
  }, [isAuthenticated]);

  // console.log(dayjs(checkTime).format('YYYY-MM-DD HH:mm:ss'))

  const logout = async (trigger) => {
    setLoggingOut(true);
    if (trigger !== "expired" && trigger !== "timer") {
      // console.log(localStorage.getItem("_expiredTime")>0)
      if (localStorage.getItem("_expiredTime") > 0 && currentUser)
        await updateUserOnLogout(checkTime, currentUser);
    } else {
      console.log("trigger:", trigger);
    }
    setTimeout(async () => {
      setOpenToDoList(false);
      setLoggingOut(true);
      //updateUser
      setCurrentSemesterStartDate("");
      setCurrentSemesterEndDate("");
      setCurrentSemesterName("");
      setSuggToDos(undefined);
      setSchoolYearData(undefined);
      setRelevantUserAssignments(undefined);
      setRelevantClassAssignments(undefined);
      setOldSelectedCourse(undefined);
      setSelectedCourse(undefined);
      setUserCourses([]);
      setMyCoreCourses([]);
      setIsAStudent(false);
      setIsATeacher(false);
      setIsAnAdmin(false);
      setIsAReferenceStudent(false);
      setIsALeader(false);
      setCurrentUser(undefined);
      await authService.clearStorage();
      // Clean up other relevant data if needed
      setTimeout(async () => {
        setLoggingOut(false);
      }, 300);
    }, 1000);
  };

  //load User Husibuech information
  useEffect(() => {
    const loadUserInformation = async () => {
      // console.log("...we load userData")
      try {
        await loadDataForUser(
          currentUser,
          setNumberOfFinishedExamStatus,
          setNumberOfFinishedHomeworkStatus,
          setShowActivationForm,
          setHolidays,
          setMyCoreCourses,
          setHusibuechTeachers,
          setHusibuechUsers,
          setHusibuechRoles,
          setHusibuechSubjects,
          sethusibuechSemesters,
          sethusibuechHolidays,
          setHusibuechCourses,
          setHusibuechCoreCourses,
          setUserSubjects,
          setUserCourses,
          setHusibuechExamTypes
        );
        if (currentUser.roles.includes("Student")) {
          setPersonalView(true); // students always have personal view in semesterTable, teachers can toggle
        }
        setIsAuthenticated(true);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setIsAuthenticated(false);
      }
    };

    if (currentUser || updateCurrentUserData) {
      // console.log("newUser: ", currentUser)
      loadUserInformation();
      setUpdateCurrentUserData(false);
    } else {
      setIsAuthenticated(false);
    }
  }, [updateCurrentUserData, currentUser]);

  useEffect(() => {
    //sound, see further: https://www.joshwcomeau.com/react/announcing-use-sound-react-hook/
    // console.log(isAuthenticated, currentUser===undefined, loggingOut);
    if (isAuthenticated && currentUser && !loggingOut) {
      playWelcome(); // Welcome Jingle
    } else if (loggingOut && !currentUser && !isAuthenticated) {
      playGoodBye();
    }
  }, [isAuthenticated, loggingOut]);

  /**
   * Main Freezer for AssignmentCharts
   */

  useEffect(() => {
    if (loading) {
      // console.log("rerender...");
      setStopReRenderProcess(true);
    }
  }, [loading]);
  //internal freezer
  useEffect(() => {
    // console.log(newClassAssignmentsChanges, newUserAssignmentsChanges)
    if (stopReRenderProcess) {
      setNewUserAssignmentsChanges(true);
      setNewClassAssignmentsChanges(true);
      //freezes the rendering of components
      setStopWorkloadChartReRender(true);
      setStopDoughnutChartReRender(true);
    } else {
      setCalculatingDeadlines(true);
      setCalculatingRecents(true);
    }
  }, [stopReRenderProcess]);

  //set main freeze
  useEffect(() => {
    if (!loading && referenceDate && (isAStudent || selectedCourse)) {
      // console.log(schoolYearData);
      // console.log("loading starts from authenticated & refDate")
      setLoading(true);
    }
  }, [referenceDate, selectedCourse, isAuthenticated]);

  /**
   * Screen Locks
   */
  useEffect(() => {
    //unfreezes the dashboard
    // console.log("loadingschoolyear:", schoolYearLoading, "stopReRender:", stopReRenderProcess)
    if (
      stopReRenderProcess !== undefined &&
      !stopReRenderProcess &&
      !schoolYearLoading
    ) {
      // console.log("loading is finished");
      setLoading(false);
    }
  }, [stopReRenderProcess, schoolYearLoading]);

  /**
   * Emergency Timer
   */
  const timeout = 5;
  useEffect(() => {
    const startTimer = () => {
      setTimeout(() => {
        checkExpiredTime();
      }, timeout * 1000);
    };

    const checkExpiredTime = () => {
      const expiredTime = parseInt(localStorage.getItem("_rerenderTime"), 10);
      if (expiredTime > 0 && Date.now() > expiredTime) {
        console.log("rendering expired");
        setStopWorkloadChartReRender(false);
        setStopDoughnutChartReRender(false);
      }
    };

    if (stopReRenderProcess) {
      startTimer();
    } else {
      const rerenderTime = Date.now() * 1000;
      localStorage.setItem("_rerenderTime", rerenderTime);
    }
  }, [stopReRenderProcess]);

  // In App.js or wherever you have stopReRenderProcess variable
  window.addEventListener("beforeunload", () => {
    // Dispatch the custom event before the page is unloaded
    window.dispatchEvent(new Event("stopReRenderProcessChange"));
  });

  // Example of changing stopReRenderProcess
  window.stopReRenderProcess = stopReRenderProcess; // Set to true when emergency needed

  //unfreezes the rendering
  useEffect(() => {
    // console.log("work", stopWorkloadChartReRender, "donut", stopDoughnutChartReRender)
    if (
      stopWorkloadChartReRender !== undefined &&
      !stopWorkloadChartReRender &&
      //while DoughnutComponent is not shown for teacher, unfreeze this too!
      stopDoughnutChartReRender !== undefined &&
      !stopDoughnutChartReRender
    ) {
      //allow reRendering
      setStopReRenderProcess(false);
    }
  }, [
    // stopAssignmentChartReRender,
    stopDoughnutChartReRender,
    stopWorkloadChartReRender,
  ]);

  // Improved useEffect for conditional rendering of the doughnut chart
  useEffect(() => {
    const shouldRenderDoughnutChart =
      !stopWorkloadChartReRender &&
      (currentUser?.roles?.includes("Teacher") ||
        currentUser?.roles?.includes("Admin"));
    if (shouldRenderDoughnutChart) {
      setStopDoughnutChartReRender(false); // Teachers and admins are not shown the doughnut chart
    }
  }, [stopWorkloadChartReRender, currentUser]);

  /**
   * Set the Main Data for the App: SchoolYearData
   */
  //definitely set schoolYear Data wherever the command is coming from
  useEffect(() => {
    const awaitResolve = async () => {
      const newSchoolYearData = await unresolvedSchoolYearData;
      // console.log("we have received new data", schoolYearData);
      await setSchoolYearData(newSchoolYearData);
      await setLoading(true); //triggers reload due to iternal changes
      if (schoolYearLoading) {
        //this is the login call
        // console.log("let's change setSchoolYearLoading")
        await setSchoolYearLoading(false);
      }

      setTimeout(async () => {}, 1500);
    };
    awaitResolve();
  }, [unresolvedSchoolYearData]);

  /**
   * set the relevant Week and CoreCourse assignments for user
   */
  useEffect(() => {
    // console.log("NewChanges: ", newUserAssignmentsChanges, " ", newClassAssignmentsChanges);

    const checkForUpdates = async () => {
      // console.log(referenceDate);
      try {
        // console.log("we check for data");
        // Ensure schoolYearData is resolved before proceeding
        const resolvedSchoolYearData = await schoolYearData;
        // console.log("our unresolved data is: ", resolvedSchoolYearData);

        await setRelevantSchoolYearAssignments(resolvedSchoolYearData);
        // console.log("we came 633");
        await setRelevantCoreCourseYearAssignments(resolvedSchoolYearData);

        // console.log("we came 635");
      } catch (error) {
        console.error("Error while processing updates:", error);
      }
    };

    if (
      currentUser &&
      schoolYearData &&
      newUserAssignmentsChanges &&
      newClassAssignmentsChanges
    ) {
      checkForUpdates();
    }
  }, [newUserAssignmentsChanges, newClassAssignmentsChanges, schoolYearData]);

  // helper functions
  const setRelevantSchoolYearAssignments = async () => {
    /**
     * create a relevantWeekAssignmentsObject for schoolYear
     */
    const newUserAssignmentsForThisWeek = await getRelevantAssignmentsObject(
      schoolYearData,
      showExams,
      showHomeworks,
      showPersonals,
      statusSelection,
      prioritySelection,
      referenceDate,
      true
    );

    const updateData = await updateDataCheck(
      relevantUserAssignments,
      newUserAssignmentsForThisWeek,
      selectedCourse,
      oldSelectedCourse,
      passKey,
      "personal"
    );
    if (updateData) {
      // console.log("enter the holy grail")
      if (passKey) {
        await setPassKey(false);
      }
      await setVerifyWorkloadChart(true);
      await setVerifyDoughnutChart(true);
      // setVerifyAssignmentChart(true);
      await setRelevantUserAssignments(newUserAssignmentsForThisWeek);
      await setNewUserAssignmentsChanges(false);
      //this needs to be done if a user is a student
      //(i.e. doe not have a coreCourseData)
      if (isAStudent) {
        await setNewClassAssignmentsChanges(false);
      }
    } else {
      await setNewUserAssignmentsChanges(false);
    }
  };

  const setRelevantCoreCourseYearAssignments = async () => {
    //this also defines a user who teaches a coreCourse!
    // console.log("we come here...")
    const newClassAssignmentsForThisWeek = await getRelevantAssignmentsObject(
      schoolYearData,
      showExams,
      showHomeworks,
      showPersonals,
      statusSelection,
      prioritySelection,
      referenceDate,
      false
    );
    // console.log("This is the new relevantCoreCourseWeekAssignment object:");
    const updateData = await updateDataCheck(
      relevantClassAssignments,
      newClassAssignmentsForThisWeek,
      selectedCourse,
      oldSelectedCourse,
      passKey,
      "coreCourse"
    );
    if (updateData) {
      // console.log("data has changed...");
      // setVerifyAssignmentChart(true);
      await setVerifyWorkloadChart(true);
      await setVerifyDoughnutChart(true);
      // console.log("newClassAss ", newClassAssignmentsForThisWeek);
      await setRelevantClassAssignments(newClassAssignmentsForThisWeek);
      await setNewClassAssignmentsChanges(false);
    } else {
      await setNewClassAssignmentsChanges(false);
    }
  };

  /**
   * Get schoolYearData (first call)
   */
  useEffect(() => {
    const firstloadingOfSchoolYearData = async () => {
      // console.log("We fetech the first schoolyeardata");
      try {
        const response = await userService.getUserInfo(
          "schoolyeardata",
          currentUser.id,
          selectedCourse?.id
        );
        //check what the current semester is.
        if (
          //set semesterStartDate
          moment(new Date()).isBefore(
            moment(new Date(response.data.datesFirstSemester[1]))
          )
        ) {
          //we are in the first semester
          await setCurrentSemesterStartDate(
            response.data.datesFirstSemester[0]
          );
          await setCurrentSemesterEndDate(response.data.datesFirstSemester[1]);
        } else {
          //we are in the second semester
          await setCurrentSemesterStartDate(
            response.data.datesSecondSemester[0]
          );
          await setCurrentSemesterEndDate(response.data.datesSecondSemester[1]);
        }
        // console.log("Schoolyear Data arrived");
        // console.log(response.data);
        // console.log(currentUser)
        await setUnresolvedSchoolYearData(response.data);
      } catch (err) {
        console.error("there was an error", err);
      }
    };
    // console.log(selectedCourse, schoolYearLoading, currentUser);
    if (
      currentUser &&
      (selectedCourse || isAStudent) &&
      schoolYearLoading &&
      !loggingOut
    ) {
      //selected Course is defined once user courses arrive
      firstloadingOfSchoolYearData();
    }
  }, [selectedCourse, schoolYearLoading, currentUser, isAStudent]);

  /**
   * Refetch school year data timer
   */
  useEffect(() => {
    const fetchDataAndCheckUpdate = async () => {
      try {
        const newDBData = await refetchSchoolYearData(
          currentUser,
          selectedCourse
        );
        // console.log(newDBData);
        //do not consider filters
        const shouldUpdate = await shouldUpdateLocalData(
          newDBData,
          schoolYearData,
          currentUser
        );
        // console.log("we should update:", shouldUpdate);
        if (shouldUpdate) {
          setUnresolvedSchoolYearData(newDBData);
          // Update local data or perform other actions with newDBData here
        }
      } catch (error) {
        console.error(
          "Error occurred while refetching school year data:",
          error
        );
      }
    };
    if (isAuthenticated && schoolYearData && (selectedCourse || isAStudent)) {
      // Set up an interval to periodically fetch and update data
      // console.log("timerRunning")
      const interval = setInterval(fetchDataAndCheckUpdate, 3000); // milliseconds

      // Clear the interval when the component unmounts or dependencies change
      return () => clearInterval(interval);
    }

    // Return an empty cleanup function if not authenticated or schoolYearData is null
    return () => {};
  }, [isAuthenticated, schoolYearData, selectedCourse, isAStudent]);

  /**
   * For Admin and Teacher - Fix a selected course for teachers and admins on log in
   */
  useEffect(() => {
    if (isALeader && myCoreCourses?.length > 0) {
      setMySelectedCoreCourse(myCoreCourses[0]);
    } else if (isAStudent && myCoreCourses?.length === 0) {
      console.log("we do this", myCoreCourses);
      setShowActivationForm(true);
    }
    // console.log(myCoreCourses)
  }, [myCoreCourses]);

  useEffect(() => {
    // console.log(userCourses)
    if (isALeader || isAReferenceStudent) {
      setSelectedCourse(userCourses?.length > 0 ? userCourses[0] : []);
    }
    if (isATeacher && currentUser?.abbreviation === null) {
      setShowActivationForm(true);
    }
    // console.log(userCourses)
  }, [userCourses]);

  /**
   * Filters changes: sets Relevant Week and CoreCourse Assignments for user if
   */
  useEffect(() => {
    if (currentUser && schoolYearData && referenceDate) {
      setLoading(true);
    }
  }, [
    showExams,
    showHomeworks,
    showPersonals,
    statusSelection,
    prioritySelection,
    showCourseAssignmentOnly,
    timeBudget,
  ]);

  /*
   * ToDo List - create suggestions based on the current Date (not the referenceDate)
   */
  useEffect(() => {
    const updateSuggestedToDos = async () => {
      // console.log(relevantUserAssignments)
      const newToDoSuggestions = await getToDoSuggestions(
        relevantUserAssignments
      );
      // console.log("current List", toDos, "Suggested List:", newToDoSuggestions);
      setSuggToDos(newToDoSuggestions);
    };

    if (relevantUserAssignments) {
      updateSuggestedToDos();
    }
  }, [relevantUserAssignments, toDos]);

  useEffect(() => {
    if (schoolYearData) {
      // console.log(schoolYearData)
      var filteredAssignments = schoolYearData?.userAssignments?.filter(
        function (assignment) {
          return assignment.is_a_to_do;
        }
      );
      setToDos(filteredAssignments);
    } else {
      setToDos(undefined);
    }
  }, [schoolYearData]);

  /**
   * Feedback channel
   */
  const [openFeedbackView, setOpenFeedbackView] = useState(false);
  //currently, after every 5th login, the login dialogue is called (as well as after the second login, to gain a first impression)
  useEffect(() => {
    if (currentUser?.roles) {
      setIsAStudent(currentUser.roles.includes("Student"));
      setIsATeacher(currentUser.roles.includes("Teacher"));
      setIsAnAdmin(currentUser.roles.includes("Admin"));
      setIsAReferenceStudent(currentUser.roles.includes("ReferenceStudent"));
      setIsALeader(
        currentUser.roles.includes("Teacher") ||
          currentUser.roles.includes("Admin")
      );
    }
    if (currentUser?.last_logout !== null) {
      setCheckTime(dayjs(currentUser?.last_logout));
    }
    if (
      currentUser?.loginCount % 10 === 3 &&
      !currentUser?.lastFeedbackProvided
    ) {
      setOpenFeedbackView(true);
    }
  }, [currentUser]);

  /**
   * Overwrites the scrollbar
   */
  useEffect(() => {
    // Add scrollbar customization styles to the body or html element
    const style = document.createElement("style");
    style.innerHTML = `
      body::-webkit-scrollbar {
        width: 0px;
      }

      body::-webkit-scrollbar-thumb {
        background-color: gray;
        border-radius: 6px;
      }

      body::-webkit-scrollbar-track {
        background-color: transparent;
      }
    `;
    document.head.appendChild(style);

    // Clean up the style element when the component unmounts
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <div>
      {/* <div className="container mt-3"> */}
      <AppContext.Provider
        value={{
          isSmallScreen,
          isMobile,
          husibuechSubjects,
          setHusibuechSubjects,
          isAuthenticated,
          schoolYearLoading,
          setSchoolYearLoading,
          holidays,
          currentUser,
          setCurrentUser,
          isAStudent,
          setIsAStudent,
          isATeacher,
          setIsATeacher,
          isAnAdmin,
          setIsAnAdmin,
          isAReferenceStudent,
          setIsAReferenceStudent,
          isALeader,
          setIsALeader,
          schoolYearData,
          setSchoolYearData,
          statusCount,
          setStatusCount,
          statusList,
          priorityList,
          numberOfFinishedExamStatus,
          setNumberOfFinishedExamStatus,
          numberOfFinishedHomeworkStatus,
          setNumberOfFinishedHomeworkStatus,
          userSubjects,
          setUserSubjects,
          userCourses,
          setUserCourses,
          selectedCourse,
          setSelectedCourse,
          oldSelectedCourse,
          setOldSelectedCourse,
          holidayDays,
          setHolidayDays,
          referenceDate,
          setReferenceDate,
          currentPage,
          setCurrentPage,
          currentSemesterStartDate,
          setCurrentSemesterStartDate,
          currentSemesterEndDate,
          setCurrentSemesterEndDate,
          currentSemesterName,
          setCurrentSemesterName,
          showExams,
          setShowExams,
          showHomeworks,
          setShowHomeworks,
          showPersonals,
          setShowPersonals,
          showDetail,
          setShowDetail,
          showCourseAssignmentOnly,
          setShowCourseAssignmentOnly,
          relevantUserAssignments,
          setRelevantUserAssignments,
          relevantClassAssignments,
          suggToDos,
          setSuggToDos,
          toDos,
          setToDos,
          statusSelection,
          setStatusSelection,
          prioritySelection,
          setPrioritySelection,
          openToDoList,
          setOpenToDoList,
          loggingOut,
          setLoggingOut,
          customDate,
          useCustomDate,
          husibuechCoreCourses,
          setHusibuechCoreCourses,
          husibuechCourses,
          setHusibuechCourses,
          husibuechUserKurse,
          setHusibuechUserKurse,
          husibuechTeachers,
          setHusibuechTeachers,
          husibuechUsers,
          setHusibuechUsers,
          husibuechSemesters,
          sethusibuechSemesters,
          husibuechHolidays,
          sethusibuechHolidays,
          husibuechRoles,
          setHusibuechRoles,
          husibuechExamTypes,
          setHusibuechExamTypes,
          appInDarkMode,
          setAppInDarkMode,
          selectedUsers,
          setSelectedUsers,
          selectedCourses,
          setSelectedCourses,
          currentVerificationUser,
          setCurrentVerificationUser,
          verificationMessage,
          setVerificationMessage,
          verificationUserName,
          setVerificationUserName,
          verificationUserPassword,
          setVerificationUserPassword,
          newUserAssignmentsChanges,
          setNewUserAssignmentsChanges,
          newClassAssignmentsChanges,
          setNewClassAssignmentsChanges,
          stopReRenderProcess,
          setStopReRenderProcess,
          stopDoughnutChartReRender,
          setStopDoughnutChartReRender,
          stopWorkloadChartReRender,
          setStopWorkloadChartReRender,
          loading,
          setLoading,
          workloadMinutes,
          setWorkloadMinutes,
          suggestedWorkloadMinutes,
          setSuggestedWorkloadMinutes,
          weekStatusCount,
          setWeekStatusCount,
          weekPriorityCount,
          setWeekPriorityCount,
          thisWeekMinutesTotal,
          setThisWeeksMinutesTotal,
          thisWeekMinutesDone,
          setThisWeeksMinutesDone,
          percentageDone,
          setPercentageDone,
          verificationCodeStore,
          setVerificationCodeStore,
          showActivationForm,
          setShowActivationForm,
          myCoreCourses,
          setMyCoreCourses,
          mySelectedCoreCourse,
          setMySelectedCoreCourse,
          personalNumberOfDoesNotFit,
          setPersonalNumberOfDoesNotFit,
          classNumberOfDoesNotFit,
          setClassNumberOfDoesNotFit,
          unresolvedSchoolYearData,
          setUnresolvedSchoolYearData,
          personalDeadlineAssignments,
          setPersonalDeadlineAssignments,
          classDeadlineAssignments,
          setClassDeadlineAssignments,
          topSix,
          setTopSix,
          verifyDoughnutChart,
          setVerifyDoughnutChart,
          verifyWorkloadChart,
          setVerifyWorkloadChart,
          verifyStatusChart,
          setVerifyStatusChart,
          verifyPriorityChart,
          setVerifyPriorityChart,
          stopStatusChartReRender,
          setStopStatusChartReRender,
          stopPriorityChartReRender,
          setStopPriorityChartReRender,
          additionalAssignments,
          setAdditionalAssignments,
          setPassKey,
          calculatingDeadlines,
          setCalculatingDeadlines,
          calculatingRecents,
          setCalculatingRecents,
          timeBudget,
          setTimeBudget,
          logout,
          dashBoardOrderIsFixed,
          setDashBoardOrderIsFixed,
          personalView,
          setPersonalView,
          updateCurrentUserData,
          setUpdateCurrentUserData,
          viewCourseOnly,
          setViewCourseOnly,
          checkTime,
          setCheckTime,
          openFeedbackView,
          setOpenFeedbackView,
        }}
      >
        {/* THEMED APP */}
        <ThemeProvider
          theme={appInDarkMode ? husibuechThemeDark : husibuechTheme}
        >
          <CssBaseline enableColorScheme />

          <MenuTopBar />
          <AppRoutes />
          <MenuBottomBar />
        </ThemeProvider>
      </AppContext.Provider>
    </div>
  );
}

export default memo(App);
