import { memo } from "react";
import AssignmentAddBox from "../../../_5Pages/4DashboardPage/DashboardParts/AddAssignmentBox/AssignmentAddBox";
import { Card, CardContent } from "@mui/material";

function AddExamPopup({ chosenDate, handleClose }) {
  // console.log("chosenDate, ", chosenDate) //picked by clicking

  return (
    <Card>
      <CardContent>
        <AssignmentAddBox
          type={"popOver"}
          date={chosenDate}
          handleClose={handleClose}
          boxType={"addPopUp"}
          single={true}
        /></CardContent></Card>
  );
}
export default memo(AddExamPopup);
