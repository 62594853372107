import React, { memo, useState } from "react";
import { Popover, TableRow } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../../_8HusibuechContexts/contextLib";
import SemesterWeekCell from "./SemesterWeekCell";
import SemesterWeekDateCell from "./SemesterWeekDateCell";
import SemesterWeekContent from "./SemesterWeekContent";
import EditAssignmentPopover from "../../4DashboardPage/DashboardParts/AddAssignmentBox/EditAssignmentPopover";
import AddExamPopup from "./AddExamPopup";

const SemesterTableWeekRow = ({ semesterWeek }) => {
  // semesterWeek.id === 21 && console.log(semesterWeek)
  const {
    isALeader,
    isAReferenceStudent,
    userCourses,
    holidays,
    setReferenceDate,
    setCurrentPage,
  } = useAppContext();
  const [selectedDate, setSelectedDate] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [assignment, setAssignment] = useState(undefined);
  const [assignmentAnchor, setAssignmentAnchor] = useState(null);
  const isPersonalType = assignment?.type === "personal";

  const viewIsNotRestricted =
    (isPersonalType) || ((isALeader || isAReferenceStudent) &&
      (userCourses?.some(
        (course) => course.id === assignment?.course_id
      )));
  // console.log(assignment)
  const navigate = useNavigate();

  const isAnyDayInWeekAHoliday = () => {
    for (let i = 0; i < 7; i++) {
      const currentDate = moment(semesterWeek.monday).add(i, "days");
      if (
        holidays?.some((x) =>
          currentDate.isSameOrAfter(moment(x.start_date)) &&
          currentDate.isSameOrBefore(moment(x.end_date))
        )
      ) {
        return true;
      }
    }
    return false;
  };

  const special = isAnyDayInWeekAHoliday()

  const handleClickOpen = (target, key) => {
    if (typeof key === "string") {
      // console.log(key) //clickedDate
      setSelectedDate(key);
      setAnchorEl(target);
    } else if (key.assignment_id) {
      setAssignment(key);
      setAssignmentAnchor(target);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const openAssignment = Boolean(assignmentAnchor);
  const idAssignment = openAssignment ? "simple-popover" : undefined;

  const handleClose = () => {
    // console.log("we were called")
    setAnchorEl(null);
    setAssignmentAnchor(null);
  };

  let clickCount = 0;

  const handleClick = (event, key) => {
    clickCount++;
    if (event.origin) {
      event.stopPropagation();
    }
    const target = event.currentTarget;

    setTimeout(() => {
      // console.log(key)
      if (clickCount === 1 || (clickCount === 2 && event.origin)) {
        // Single click logic
        handleClickOpen(target, key);
      } else if (clickCount === 2) {
        // Double click logic
        let monday = moment(new Date(key))
          .startOf("isoWeek")
          .format("YYYY-MM-DD");
        setReferenceDate(monday);
        setCurrentPage("dashboard");
        navigate("/");
      }

      clickCount = 0; // Reset clickCount after processing the click(s)
    }, 300); // 300 milliseconds timeout for handling double click
  };

  return (
    <>
      <TableRow hover key={semesterWeek.id}>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <AddExamPopup chosenDate={selectedDate} handleClose={handleClose} />
        </Popover>
        <Popover
          id={idAssignment}
          open={openAssignment}
          anchorEl={assignmentAnchor}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <EditAssignmentPopover
            assignment={assignment}
            handleClose={handleClose}
            restricted={!viewIsNotRestricted}
            classView={isALeader}

          />
        </Popover>
        <SemesterWeekCell
          semesterWeek={semesterWeek}
          handleClick={handleClick}
          special={special}
        />
        <SemesterWeekDateCell
          semesterWeek={semesterWeek}
          handleClick={handleClick}
          special={special}

        />

        <SemesterWeekContent
          semesterWeek={semesterWeek}
          handleClick={handleClick}
        />
      </TableRow>
    </>
  );
};

export default memo(SemesterTableWeekRow);
