import React, { forwardRef, memo, useState } from "react";
import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import RatingBox from "./RatingBox";
import RatingSlider from "./RatingSlider";
import RatingSelectedOnly from "./RatingSelectedOnly";
import moment from "moment";
import feedbackServiceInstance from "../../_6Services/feedback.service";
import ScrollToTop from "../scroller/ScrollToTop";
import { useAppContext } from "../../_8HusibuechContexts/contextLib";
import SaveButton from "../2_Buttons/SaveButton";
import MessageBox from "../1_Boxes/MessageBox";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RegularFeedbackForm({ open, setOpen }) {
  const { currentUser, setCurrentUser } = useAppContext();

  //Planability
  const [planability, setPlanability] = useState(0);
  const [hoverPlanability, setHoverPlanability] = useState(-1);

  const [usedFeatures, setUsedFeatures] = useState("");
  const [usedFeaturesErr, setUsedFeaturesErr] = useState({});

  //Workloadminutes
  const [weekWorkMinutes, setWeekWorkMinutes] = useState(0);
  const [weekWorkloadSentiment, setWeekWorkloadSentiment] = useState(0);

  //Stress Level
  const [stressLevel, setStressLevel] = useState(0);

  const [stressLevelExplanation, setStressLevelExplanation] = useState("");
  const [stressLevelExplanationErr, setStressLevelExplanationErr] = useState(
    {}
  );

  //App Rating
  const [appRating, setAppRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(-1);

  const [improvements, setImprovements] = useState("");
  const [improvementsErr, setImprovementsErr] = useState({});

  //form saving processs
  const [localLoading, setLocalLoading] = useState(false);
  //to show errors
  const [message, setMessage] = useState("");
  const [saveColor, setSaveColor] = useState("primary");

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeUsedFeatures = (e) => {
    setUsedFeaturesErr({});
    setUsedFeatures(e.target.value);
  };
  const onChangeStressLevelExplanation = (e) => {
    setStressLevelExplanationErr({});
    setStressLevelExplanation(e.target.value);
  };
  const onChangeImprovements = (e) => {
    setImprovementsErr({});
    setImprovements(e.target.value);
  };

  const onSubmit = async (event) => {
    setLocalLoading(true);
    event.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      await createFeedback();
      //update currentUser that user has provided the feedback
      //keep this so that the current session does only show feedback form until the user has provided a feedback...
      var updateUser = { ...currentUser };
      updateUser.lastFeedbackProvided = true;
      updateUser.lastFeedbackDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      setCurrentUser(updateUser);
      await localStorage.setItem("user", JSON.stringify(updateUser));

      handleClose();
    } else {
      setLocalLoading(false);
    }
  };
  // useEffect(()=>{
  //   console.log(currentUser)
  // }, [currentUser])

  const createFeedback = async () => {
    // setLocalLoading(true);
    const feedback = {
      currentUser: currentUser.id,
      planability: planability,
      usedFeatures: usedFeatures,
      weekWorkMinutes: weekWorkMinutes,
      weekWorkloadSentiment: weekWorkloadSentiment,
      stressLevel: stressLevel,
      stressLevelExplanation: stressLevelExplanation,
      appRating: appRating,
      improvements: improvements,
      // userVisibility: userVisibility,
    };

    // console.log(feedback);
    try {
      const response = await feedbackServiceInstance
        .createNewRegularFeedback(feedback)
      // console.log(response.data)
      setSaveColor("success")
      setMessage(response?.data?.message)
      setLocalLoading(false)

      setTimeout(() => {
        setMessage("")
        setSaveColor("primary");
        setTimeout(() => {
          handleClose();
        }, 750)
      }, 3000)
    } catch (error) {
      console.log(error)
      setSaveColor("warning");
      setMessage(
        error?.response?.data?.message
          ? error.response.data.message
          : "Fehler beim Verarbeiten des Feedbacks"
      );
      setLocalLoading(false);
      setTimeout(() => {
        setSaveColor("primary");
        setMessage("");
      }, 6000);
    }
  };

  const formValidation = () => {
    // let usedFeaturesErr = {};
    // let stressLevelExplanationErr = {};
    let improvementsErr = {};

    let isValid = true;

    //design the desired rules for form completion here:
    // if (usedFeatures === "") {
    //   usedFeaturesErr.improvementNameEmpty =
    //     "Dein Feedback wäre sehr willkommen!";
    //   isValid = false;
    // }
    // if (stressLevelExplanation === "") {
    //   stressLevelExplanationErr.improvementNameEmpty =
    //     "Dein Feedback wäre sehr willkommen!";
    //   isValid = false;
    // }
    if (improvements === "") {
      improvementsErr.improvementNameEmpty =
        "Dein Feedback ist sehr wertvoll!";
      isValid = false;
    }

    setImprovementsErr(improvementsErr);
    // setCountingFactorErr(countingFactorErr);

    return isValid;
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "fixed" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>{" "}
          <Typography sx={{ ml: 2, flex: 1 }} variant="h2" component="div">
            Feedback Formular
          </Typography>
        </Toolbar>
      </AppBar>
      {/* <DialogTitle>Feedback</DialogTitle> */}

      <DialogContent>
        <ScrollToTop />
        <br />
        <br />
        <br />
        <br />
        {/* <DialogContentText> */}
        <Typography variant="h5" color="primary" sx={{ marginBottom: "0.5em" }}>
          <strong>Hi!</strong>
        </Typography>
        <Typography variant="h6" color="primary" sx={{ marginBottom: 5 }}>
          Wir fragen in regelmässigen Abständen für ein Feedback und sind
          sehr dankbar, wenn du dir dafür etwas Zeit nimmst.
        </Typography>
        {/* </DialogContentText> */}

        {/* PLANABILITY */}
        <div className="form-group">
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            1. Hilft dir husibuech, deine Assignments besser zu planen?&nbsp;
          </Typography>
          <RatingBox
            type={"planability"}
            data={planability}
            setData={setPlanability}
            hover={hoverPlanability}
            setHover={setHoverPlanability}
          />
          <br />
          <Typography variant="h6" sx={{ marginBottom: -1 }}>
            2. Welche Funktionen von husibuech nutzt du?&nbsp;
          </Typography>
          <TextField
            autoFocus={false}
            margin="dense"
            id="name"
            label="benutzte Funktionen"
            value={usedFeatures}
            fullWidth
            variant="standard"
            onChange={onChangeUsedFeatures}
          />
          {Object.keys(usedFeaturesErr).map((key, i) => {
            return (
              <div key={i} className="alert alert-danger" role="alert">
                {usedFeaturesErr[key]}
              </div>
            );
          })}
        </div>

        {/* WORKLOAD: TIME */}
        <div className="form-group">
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            3. Wie viel Zeit hast du die letzen 7 Tage insgesamt für Hausaufgaben
            und Prüfungsvorbereitungen aufgewendet?&nbsp;
          </Typography>
          <RatingSlider
            value={weekWorkMinutes}
            setValue={setWeekWorkMinutes}
          />
          <br />
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            4. Wie hast du diesen Aufwand empfunden? Als ...&nbsp;
          </Typography>
          <RatingSelectedOnly
            type="rarelyAlot"
            data={weekWorkloadSentiment}
            setData={setWeekWorkloadSentiment}
          />
        </div>

        {/* WORKLOAD: STRESS */}
        <div className="form-group">
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            5. Wie oft fühltest du dich während der letzten Schulwoche
            gestresst?&nbsp;
          </Typography>
          <RatingSelectedOnly
            type="neverOften"
            data={stressLevel}
            setData={setStressLevel}
          />
          <br />
          <Typography variant="h6" sx={{ marginBottom: -1 }}>
            6. Weshalb hast du dich so gefühlt?
          </Typography>
          <TextField
            autoFocus={false}
            margin="dense"
            id="stressLevelExplenation"
            label="Begründung"
            value={stressLevelExplanation}
            fullWidth
            variant="standard"
            onChange={onChangeStressLevelExplanation}
            multiline
            rows={3}
            placeholder="Wenn du deine Gedanken möglichst präzise ausformulierst, sind wir dir sehr dankbar!"
          />
          {Object.keys(stressLevelExplanationErr).map((key, i) => {
            return (
              <div key={i} className="alert alert-danger" role="alert">
                {stressLevelExplanationErr[key]}
              </div>
            );
          })}
        </div>

        {/* APP-RATING */}
        <div className="form-group">
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            7. Wie findest du die App?&nbsp;
          </Typography>

          <RatingBox
            type={"rating"}
            data={appRating}
            setData={setAppRating}
            hover={hoverRating}
            setHover={setHoverRating}
          />
          <br />
          <Typography variant="h6" sx={{ marginBottom: -1 }}>
            8. Was sollte anders sein, damit du einen Stern mehr vergibst?&nbsp;
          </Typography>
          <TextField
            autoFocus={false}
            margin="dense"
            id="improvements"
            label="Deine Verbesserungsvorschläge"
            value={improvements}
            fullWidth
            variant="standard"
            onChange={onChangeImprovements}
            multiline
            rows={3}
            placeholder="Wenn du deine Gedanken möglichst präzise ausformulierst, sind wir dir sehr dankbar!"
          />
          {Object.keys(improvementsErr).map((key, i) => {
            return (
              <div key={i} className="alert alert-danger" role="alert">
                {improvementsErr[key]}
              </div>
            );
          })}
        </div>

        {/* </Form> */}
        <SaveButton loading={localLoading} color={saveColor} save={onSubmit} saveText={"Abschicken"} disabled={localLoading} />
        <br />
        {/* Error messages */}
        <div>
          {message?.length>0 && <MessageBox message={message} saveColor={saveColor} />}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default memo(RegularFeedbackForm);

{
  /*

          <div className="form-group">
            <Typography sx={{ ml: 2, flex: 1 }} component="div">
              Wäre es für dich wichtig, den Lernfortschritt von anderen
              Lernenden zu sehen?&nbsp;
            </Typography>
          </div>

          <RatingBox
            type={"visibility"}
            data={userVisibility}
            setData={setUserVisibility}
            hover={hoverVisibility}
            setHover={setHoverVisibility}
          /> 
          <br/>*/
}
