import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useAppContext } from '../../../../_8HusibuechContexts/contextLib';

const styles = {
    table: {
        fontSize: '0.8rem', // Adjust the font size as needed
    },
    tableCell: {
        padding: '0.4rem', // Adjust the padding as needed
        fontSize: '0.7rem'
    },
};

const AssignmentTable = ({ classAssignments }) => {
    const { statusList, priorityList } =
        useAppContext();
    return (
        <Table stickyHeader aria-label="sticky table" style={{ ...styles.table, position: "relative", zIndex: 0 }} sx={{paddingBottom: "2em"}}>
            <TableHead>
                <TableRow>
                    <TableCell align="left" style={styles.tableCell}>Vorname</TableCell>
                    <TableCell align="left" style={styles.tableCell}>Nachname</TableCell>
                    <TableCell align="left" style={styles.tableCell}>Status</TableCell>
                    <TableCell align="left" style={styles.tableCell}>Priorität</TableCell>
                    <TableCell align="center" style={styles.tableCell}>geplant</TableCell>
                    <TableCell align="center" style={styles.tableCell}>erledigt</TableCell>
                    <TableCell align="center" style={styles.tableCell}>offen</TableCell>

                </TableRow>
            </TableHead>
            <TableBody>
                {classAssignments.map((assignment, index) => (
                    <TableRow key={index}>
                        <TableCell align="left" style={styles.tableCell}>
                            {assignment.student_first_name}
                        </TableCell>
                        <TableCell align="left" style={styles.tableCell}>
                            {assignment.student_last_name}
                        </TableCell>
                        <TableCell align="left" style={styles.tableCell}>
                            {statusList[assignment.status]}
                        </TableCell>
                        <TableCell align="left" style={styles.tableCell}>
                            {priorityList[assignment.priority]}
                        </TableCell>
                        <TableCell align="center" style={styles.tableCell}>
                            {assignment.my_minutes}
                        </TableCell>
                        <TableCell align="center" style={styles.tableCell}>
                            {assignment.my_minutes_done}
                        </TableCell>
                        <TableCell align="center" style={styles.tableCell}>
                            {assignment.my_minutes - assignment.my_minutes_done}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default AssignmentTable;
